import {
  isTrackReference,
  TrackMutedIndicator,
  useEnsureTrackRef,
  useIsMuted,
  useParticipantInfo,
  VideoTrack,
} from "@livekit/components-react";
import { Track } from "livekit-client";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { IoPersonCircle } from "react-icons/io5";
import { useChatContext } from "../../../context/ChatProvider";

const ParticpantView = ({ trackRef, className }: any) => {
  const chatContext = useChatContext();
  const isVideoMuted = useIsMuted(trackRef);
  const trackReference = useEnsureTrackRef(trackRef);
  const { metadata } = useParticipantInfo({
    participant: trackReference.participant,
  });

  const [displayName, setDisplayName] = useState<any>({});

  useEffect(() => {
    if (metadata) {
      try {
        const parsedData = JSON.parse(metadata);
        if (parsedData) {
          setDisplayName(parsedData);
        }
      } catch (error) {
        console.error("Failed to parse metadata:", error);
      }
    }
  }, [metadata]);
  return (
    <div>
      <div className=" position-relative">
        <VideoTrack trackRef={trackRef} />
        <TrackMutedIndicator
          trackRef={{
            participant: trackReference.participant,
            source: Track.Source.Microphone,
          }}
          show={"muted"}
        ></TrackMutedIndicator>
        <div
          className={` ${
            isVideoMuted
              ? "participant-name-audio flex-column"
              : "participant-name-video"
          }`}
        >
          {isVideoMuted && (
            <span className=" mb-1">
              <IoPersonCircle
                fontSize={150}
                color="var(--light)"
                title={displayName?.name}
              />
            </span>
          )}

          {displayName?.name}
        </div>
        {isTrackReference(trackRef) && isVideoMuted && (
          <div className=" participant-media-video-off d-flex justify-content-center align-items-center">
            <div className=" img-blur">
              {/* ------------------------------------------------------------- */}
              {displayName?.avatar ? (
                <img src={displayName?.avatar} alt={displayName?.name} />
              ) : (
                <div className="bg-white h-100 "></div>
              )}

              {/* --------------------------------------------------------- */}
            </div>

            <div className="muted-call d-flex justify-content-center align-items-center">
              {displayName?.avatar ? (
                <img src={displayName?.avatar} alt={displayName?.name} />
              ) : (
                <Avatar
                  className=""
                  name={displayName?.name}
                  maxInitials={2}
                  round={true}
                  size="100%"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticpantView;
