import React from "react";

const MapThumbnail = ({ url }: { url: string }) => {
  function getLatLongFromUrl(url: string) {
    const a = document.createElement("a");
    a.href = url;
    const query = a.search.substring(1);
    const params = new URLSearchParams(query);
    const center = params.get("center");
    if (center) {
      const [latitude, longitude] = center.split(",");
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    }
    return "";
  }

  return (
    <div>
      <a
        href={getLatLongFromUrl(url)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={url}
          alt="Google Map Thumbnail"
          style={{ width: "300px", height: "200px", cursor: "pointer" }}
        />
      </a>
    </div>
  );
};

export default MapThumbnail;
