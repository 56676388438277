import { isMobile, isTablet } from "react-device-detect";
import { Route, Routes } from "react-router-dom";
import ChatProvider from "../context/ChatProvider";
import ChatPage from "../pages/chat/ChatPage/ChatPage";
import UnSelectedChatPage from "../pages/chat/UnSelectedChatPage/UnSelectedChatPage";
import { routes } from "../../../constants/routes";

export default function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path={routes.chatId} element={<ChatPage />} />
        <Route
          path={routes.chat}
          element={
            <div>
              {isTablet || isMobile ? <ChatPage /> : <UnSelectedChatPage />}
            </div>
          }
        />
      </Routes>
    </>
  );
}
