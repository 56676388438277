import DatePicker from "react-multi-date-picker";
import CustomInputField from "../InputField/CustomInputField";
import { Field, FieldProps, FormikValues } from "formik";
import moment from "moment";
import { AnySchema } from "yup";
import "./FormikDateField.scss";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

export interface FormikDateFieldProps {
  name: string;
  label: string;
  errors?: string | undefined;
  validationSchema?: AnySchema<any>;
  minDate?: Date | string | number | undefined;
  maxDate?: Date | string | number | undefined;
  isDisabled?: boolean;
  onlyYearPicker?: boolean;
  onlyMonthPicker?: boolean;
}

export default function FormikDateField({
  name,
  label,
  errors,
  validationSchema,
  minDate,
  maxDate,
  isDisabled,
  onlyYearPicker = false,
  onlyMonthPicker = false,
}: FormikDateFieldProps) {
  const inputId = `formikDateField_${name}`;
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<FormikValues>) => (
        <CustomInputField
          validationSchema={validationSchema}
          label={label}
          error={errors || ""}
          field={field}
          inputId={inputId}
        >
          <div className="position-relative">
            <DatePicker
              plugins={[<DatePanel header="Selected Dates" />]}
              editable={false}
              name={name}
              value={
                form.values[name] && onlyMonthPicker
                  ? moment().month(form.values[name]).toDate()
                  : form.values[name] && !onlyMonthPicker
                  ? new Date(form.values[name])
                  : ""
              }
              className={"form-control"}
              disabled={isDisabled}
              format={
                onlyYearPicker
                  ? "YYYY"
                  : onlyMonthPicker
                  ? "MMMM"
                  : "DD-MM-YYYY"
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(date: any) => {
                if (date === null) {
                  form.setFieldValue(name, date);
                } else {
                  form.setFieldValue(
                    name,
                    onlyMonthPicker
                      ? moment.unix(date?.unix).format("MMMM")
                      : onlyYearPicker
                      ? moment.unix(date?.unix).format("yyyy")
                      : moment.unix(date?.unix).format("YYYY-MM-DD")
                  );
                }
              }}
              minDate={minDate}
              maxDate={maxDate}
              onlyMonthPicker={onlyMonthPicker}
              onlyYearPicker={onlyYearPicker}
              data-testid={inputId}
              id={inputId}
            />
            {/* <span className="calender_icon">
              <img src={dateIcon} alt="dateIcon" />
            </span> */}
          </div>
        </CustomInputField>
      )}
    </Field>
  );
}
