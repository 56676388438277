import Avatar from "react-avatar";
import { AiFillAudio } from "react-icons/ai";
import { FaVideo } from "react-icons/fa";
import {
  IoCameraSharp,
  IoCheckmarkDoneSharp,
  IoCheckmarkSharp,
} from "react-icons/io5";
import { RiEmojiStickerLine, RiVideoOnFill } from "react-icons/ri";
import { useUserContext } from "../../../../context/UserProvider";

import { BiSolidPhoneIncoming, BiSolidPhoneOutgoing } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosArrowRoundUp, IoMdDocument } from "react-icons/io";
import { useParams } from "react-router-dom";
import {
  formatTimestamp,
  lastMessageType,
} from "../../../../../../utils/axiosInstance";
import "./InboxContact.scss";

type InboxContactProps = {
  inbox: any;
  onChangeChat?: (inbox: any) => void;
  isResponsive?: boolean;
  setisResponsive?: Function;
};

export default function InboxContact({
  inbox,
  onChangeChat,
  isResponsive,
  setisResponsive,
}: any) {
  const userContext = useUserContext();
  const id = useParams();
  const { last_message, participants, unread, last_message_sent_at } = inbox;
  const handleChangeChat = () => {
    if (onChangeChat) {
      onChangeChat(inbox);
    }
  };
  const opponentId = inbox.participants?.find(
    (item: any) => item._id !== userContext?.user.id
  )?._id;

  const GroupName =
    inbox?.group_id?.group_name?.length > 20
      ? inbox?.group_id?.group_name?.substring(0, 20) + "..."
      : inbox?.group_id?.group_name;

  const participantName =
    participants?.find((item: any) => item?._id !== userContext?.user?.id)?.name
      .length > 30
      ? participants
          ?.find((item: any) => item?._id !== userContext?.user?.id)
          ?.name?.substring(0, 30) + "..."
      : participants?.find((item: any) => item?._id !== userContext?.user?.id)
          ?.name;
  return (
    <div
      className={`contact ${inbox?.id === id?.id ? "contact-active" : ""}`}
      onClick={() => {
        handleChangeChat();
        if (setisResponsive) {
          setisResponsive(true);
        }
      }}
      key={inbox?._id}
    >
      <div className="avatar-wrapper-section position-relative">
        {(inbox?.group_id?.group_name === undefined &&
          participants?.find((item: any) => item?._id !== userContext?.user?.id)
            ?.avatar) ||
        "" ? (
          <img
            src={
              inbox?.group_id?.group_name === undefined
                ? participants?.find(
                    (item: any) => item?._id !== userContext?.user?.id
                  )?.avatar
                : inbox?.group_id?.group_name
            }
            alt={
              inbox?.group_id?.group_name === undefined
                ? participants?.find(
                    (item: any) => item?._id !== userContext?.user?.id
                  )?.name
                : inbox?.group_id?.group_name
            }
          />
        ) : (
          <Avatar
            name={
              inbox?.group_id?.group_name === undefined
                ? participants?.find(
                    (item: any) => item?._id !== userContext?.user?.id
                  )?.name
                : inbox?.group_id?.group_name
            }
            size="40"
            maxInitials={2}
            round={true}
          />
        )}
        {/* {inbox?.group_id === null &&
          remoteParticipants?.find(
            (item: any) =>
              item?.identity ===
              participants?.find(
                (item: any) => item?.id !== userContext?.user?.id
              )?.id
          ) && (
            <div className="styled-badge-wrapper">
              <div className="styled-badge">
                <div className="MuiBadge-badge"></div>
              </div>
            </div>
          )} */}
      </div>
      <div className=" flex-grow-1 overflow-hidden">
        <div className=" d-flex justify-content-between align-items-center ">
          <div className=" text-dark fw-medium text-capitalize">
            {GroupName || participantName}
          </div>

          <div className="Time">
            {formatTimestamp(last_message_sent_at) === "NaN/NaN/NaN"
              ? formatTimestamp(inbox?.updatedAt)
              : formatTimestamp(last_message_sent_at)}
          </div>
        </div>

        <div className="MessageWrapper d-flex justify-content-between align-items-center">
          <Message
            lastMessage={last_message}
            messageStatus={
              last_message?.from?.id !== userContext?.user?.id
                ? "NOTICK"
                : last_message?.seen[opponentId] === null
                ? "SENT"
                : "DELIVERED"
            }
            lastMessageType={lastMessageType(last_message)}
            userContext={userContext}
          />
          {last_message_sent_at && last_message && (
            <Trailing isPinned={inbox.isPinned} notificationsCount={unread} />
          )}
        </div>
      </div>
    </div>
  );
}

function Message({
  lastMessage,
  messageStatus = "SENT",
  lastMessageType,
  userContext,
}: {
  lastMessage: any;
  messageStatus: any;
  lastMessageType?:
    | "TEXT"
    | "IMAGE"
    | "VIDEO"
    | "AUDIO"
    | "STICKER"
    | "LOCATION";
  userContext: any;
}) {
  const LatestMessage =
    lastMessage?.message?.length > 15
      ? lastMessage?.message.substring(0, 15) + "..."
      : lastMessage?.message;

  const renderMessageContent = (lastMessageType: any) => {
    switch (lastMessageType) {
      case "TEXT":
        return <div>{LatestMessage}</div>;
      case "IMAGE":
        return (
          <div className=" d-flex align-items-center">
            <span className="pe-2">
              <IoCameraSharp
                size={17}
                color="var(--secondary)"
                title="last message as photo"
              />
            </span>
            Photo
          </div>
        );
      case "VIDEO":
        return (
          <div className=" d-flex align-items-center">
            <span className="pe-2">
              <FaVideo
                size={17}
                color="var(--secondary)"
                title="last message as video"
              />
            </span>
            Video
          </div>
        );
      case "AUDIO":
        return (
          <div className=" d-flex align-items-center">
            <span className="pe-2 ">
              <AiFillAudio
                size={17}
                color="var(--secondary)"
                title="last message as audio"
              />
            </span>
            Audio
          </div>
        );
      case "FILE":
        return (
          <div className=" d-flex align-items-center">
            <span className="pe-2 ">
              <IoMdDocument
                fontSize={17}
                color="var(--secondary)"
                title=" last message as document"
              />
            </span>
            Document
          </div>
        );

      case "STICKER":
        return (
          <div className=" d-flex align-items-center">
            <span className="pe-2 ">
              <RiEmojiStickerLine
                size={17}
                color="var(--secondary)"
                title="last message as sticker"
              />
            </span>
            Stciker
          </div>
        );
      case "LOCATION":
        return (
          <div className=" d-flex align-items-center">
            <span className="pe-2 ">
              <FaLocationDot
                size={17}
                color="var(--secondary)"
                title="last message as sticker"
              />
            </span>
            Location
          </div>
        );
      case "VIDEO_CALL":
        return (
          <div className=" d-flex align-items-center">
            <span className="pe-2">
              {lastMessage?.from !== userContext?.user.id ? (
                <span className="position-relative">
                  <RiVideoOnFill size={17} color={"var(--secondary)"} />
                  <span className=" video-call-incoming ">
                    <IoIosArrowRoundUp color="var(--color-white)" />
                  </span>
                </span>
              ) : (
                <span className=" position-relative">
                  <RiVideoOnFill size={17} color="var(--secondary)" />
                  <span className=" video-call-outgoing ">
                    <IoIosArrowRoundUp color="var(--color-white)" />
                  </span>
                </span>
              )}
            </span>
            Video call
          </div>
        );
      case "AUDIO_CALL":
        return (
          <div className=" d-flex align-items-center">
            <span className="pe-2">
              {lastMessage?.from !== userContext?.user.id ? (
                <BiSolidPhoneIncoming color={"var(--secondary)"} size={17} />
              ) : (
                <BiSolidPhoneOutgoing color="var(--secondary)" size={17} />
              )}
            </span>
            Voice call
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className=" d-flex ">
      {messageStatus !== "NOTICK" && (
        <div
          className={`message-status pe-2 ${
            messageStatus === "DELIVERED" ? "read" : ""
          }`}
        >
          {messageStatus === "SENT" ? (
            <IoCheckmarkSharp size={16} title="sent" />
          ) : (
            <IoCheckmarkDoneSharp size={16} title="seen" />
          )}
        </div>
      )}

      {renderMessageContent(lastMessageType)}
    </div>
  );
}

function Trailing({
  notificationsCount,
}: Pick<any, "isPinned" | "notificationsCount">) {
  return (
    <div>
      {notificationsCount !== undefined && notificationsCount > 0 && (
        <div className="UnreadContact">{notificationsCount}</div>
      )}
    </div>
  );
}
