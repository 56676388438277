import axios from "axios";

export const BASE_URL: string = "https://api-alarabia.coducer.xyz/";
export const CHAT_URL = "https://api-chat.coducer.xyz/";
export const LIVE_URL = "https://livekit.coducer.xyz/";
export const LIVEKIT_WEBSOCKET = "wss://livekit-server.coducer.xyz"

// export const BASE_URL: string = "https://bms.al-arabia.com/api/";
// export const CHAT_URL = "https://bms.al-arabia.com/api-chat/";
// export const LIVE_URL = "https://bms.al-arabia.com/api-livekit/";
// export const LIVEKIT_WEBSOCKET = "wss://livekit-server.coducer.xyz"

// export const BASE_URL: string = "https://alarabia-airport.coducer.xyz/api/";
// export const CHAT_URL = "https://alarabia-airport.coducer.xyz/api-chat/";
// export const LIVE_URL = "https://alarabia-airport.coducer.xyz/api-livekit/";
// export const LIVEKIT_WEBSOCKET = "wss://livekit-server.coducer.xyz"

export const filterQueryParams = (params: any) => {
  if (params) {
    Object.keys(params).forEach((key) => {
      if (
        params[key] === null ||
        params[key]?.length < 1 ||
        params[key] === ""
      ) {
        delete params[key];
      }
    });
    let rating: Array<number> = [];
    if (params?.rating?.length) {
      rating = [...params.rating];
      delete params.rating;
    }
    const searchParams = new URLSearchParams(params);
    rating.forEach((rate) => searchParams.append("rating", rate.toString()));
    return searchParams.toString();
  }
};

export const post = async (
  url: string,
  token: string | null,
  _data = {},
  _config = {}
) => {
  const config: any = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return axios
    .post(url, _data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }: any) => {
      return response;
    });
};

export const get = async (
  url: string,
  token: string | null,
  _config: any = {}
) => {
  const config: any = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return await axios
    .get(url, { ...config })
    .then((res: any) => res?.data)
    .catch(({ response }: any) => response?.data);
};

export const put = async (
  url: string,
  token: string | null,
  _data = {},
  _config = {}
) => {
  const config: any = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return axios
    .put(url, _data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }: any) => {
      return response;
    });
};

export const _delete = async (
  url: string,
  token: string | null,
  _config: any = {}
) => {
  const config: any = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return await axios
    .delete(url, { ...config })
    .then((res: any) => {
      if (res.status) {
        return res.data;
      } else {
        return res.data;
      }
    })
    .catch(({ response }: any) => {
      return response?.data;
    });
};

export const capitalizeTitle = (title: string) => {
  if (typeof title !== "string" || title?.length === 0) {
    return "";
  }
  return title?.charAt(0)?.toUpperCase() + title?.slice(1)?.toLowerCase();
};

export const formatTimestamp = (timestamp: string) => {
  const inputDate: any = new Date(timestamp);
  const now = new Date();
  const today: any = new Date(now.setHours(0, 0, 0, 0));
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const diffTime = today - inputDate;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === -1) {
    return ` ${inputDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    })}`;
  } else if (diffDays === 0) {
    return 'Yesterday';
  } else if (diffDays < 7) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    return `${dayOfWeek}`;
  } else {
    const day = String(inputDate.getDate()).padStart(2, '0');
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const year = inputDate.getFullYear();
    return `${day}/${month}/${year}`;
  }
};

export const calculateDuration = (startTimeStr: string, endTimeStr: string): object | string | any => {
  const startTime: Date = new Date(startTimeStr);
  const endTime: Date = new Date(endTimeStr);
  if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
    return "Invalid date(s)";
  }
  const durationInMilliseconds: number = endTime.getTime() - startTime.getTime();
  const durationInSeconds: number = Math.floor(durationInMilliseconds / 1000); // seconds
  const durationInMinutes: number = Math.floor(durationInSeconds / 60); // minutes
  const durationInHours: number = Math.floor(durationInMinutes / 60); // hours
  const remainingSeconds: number = durationInSeconds % 60;
  const remainingMinutes: number = durationInMinutes % 60;
  if (durationInHours > 0) {
    let result = `${durationInHours}hr`;
    if (remainingMinutes > 0) {
      result += `${remainingMinutes}m`;
    }
    return result;
  } else if (remainingMinutes > 0) {
    let result = `${remainingMinutes}m`;
    if (remainingSeconds > 0) {
      result += `${remainingSeconds}s`;
    }
    return result;
  } else {
    return `${remainingSeconds}s`;
  }
}

export const formatedDate = (timestamp: string) => {
  const dt = new Date(timestamp);
  const now = new Date();
  const timeString = dt.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
  const isToday = dt.toDateString() === now.toDateString();
  if (isToday) {
    return `Today at ${timeString}`;
  } else {
    const dateString = dt.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    return `${dateString} at ${timeString}`;
  }
}

export const convertBytes = (bytes: number) => {
  const kb = bytes / 1024;
  const mb = kb / 1024;

  if (kb >= 1000) {
    return mb.toFixed(2) + ' MB';
  } else {
    return kb.toFixed(2) + ' KB';
  }
}

export const lastMessageType = (message: any) => {
  if (message?.attachment_type) {
    return message.attachment_type
  }
  if (message?.is_video) {
    return 'VIDEO_CALL';
  } else if (message?.is_audio) {
    return 'AUDIO_CALL';
  } else if (message?.is_location) {
    return 'LOCATION'
  }
  return "TEXT"
}

export function fetchBlob(blobUrl: string) {
  return fetch(blobUrl).then((response) => response.blob());
}
export function blobToFile(blob: any, fileName?: any) {
  return new File([blob], fileName, { type: blob.type });
}