import { ReactNode } from "react";
import { Dropdown } from "react-bootstrap";
import { TbDotsVertical } from "react-icons/tb";
import "./OptionsDropdown.scss";

interface optionsDropdownProps {
  children: ReactNode;
  iconSize?: string;
}

const OptionsDropdown = ({ children, iconSize }: optionsDropdownProps) => {
  return (
    <div className="Actions-drop">
      <Dropdown className="Actions_title">
        <Dropdown.Toggle variant="success" id="dropdown-basicActions">
          <TbDotsVertical
            size={iconSize || 19}
            color="var(--secondary)"
            title="Options"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default OptionsDropdown;
