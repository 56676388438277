import { ReactNode } from "react";
import filterby from "../../assets/filter.png";
import reset from "../../assets/reset.png";
import { useTranslation } from "react-i18next";
import CommonButton from "../CommonButton/CommonButton";

export interface FilterProps {
  children?: ReactNode;
  className?: string;
  isFilterActive?: boolean;
  openfilter?: boolean;
  openfilterClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  resetClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  filterClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  filterApply?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  filterDisabled?: boolean;
  isFilterMobile?: boolean;
}

const CommonFilter = ({
  children,
  className = "",
  isFilterActive,
  openfilter,
  openfilterClick,
  resetClick,
  filterApply,
  filterClose,
  isFilterMobile,
  filterDisabled,
}: FilterProps) => {
  const { t } = useTranslation();

  return (
    <div className={` ${isFilterMobile ? "" : "filter-z"} ${className}`}>
      <div className={isFilterMobile ? "" : "position-relative"}>
        <div
          className={` d-flex ${
            isFilterMobile
              ? ""
              : ` filterbox ${isFilterActive ? "foractivefilter" : ""}`
          }`}
          role="button"
          tabIndex={0}
          onClick={openfilterClick}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              openfilterClick?.(e as any);
            }
          }}
        >
          <h6 className={` mb-0 by fw-normal ${isFilterMobile ? "mx-3" : ""}`}>
            {t("filter.filter")}
          </h6>
          <img
            src={filterby}
            alt="filter"
            width={16}
            className={isFilterMobile ? "" : "mx-2"}
          />
        </div>
        <div
          className={` ${
            isFilterMobile ? "dropdownfilterMobile" : "dropdownfilter"
          }  ${openfilter ? "active" : "inactive"}`}
          id="filterDropDown"
        >
          <div className=" d-flex justify-content-between align-items-center filterline pt-2">
            <h6 className=" fw-bold m-0"> {t("filter.fil")}</h6>
            <div
              role="button" // Adds role
              tabIndex={0} // Makes the element focusable
              onClick={resetClick}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  resetClick?.(e as any);
                }
              }}
            >
              <img src={reset} alt="reset" width={16} />
            </div>
          </div>
          {children}
          <div className=" d-flex justify-content-start pt-1">
            <CommonButton
              text={t("filter.close")}
              type="submit"
              variant="outline-danger"
              className=" bg-transparent text-danger"
              onClick={filterClose}
              // isDisabled={
              //   !(
              //     role.length > 0 ||
              //     status.length > 0 ||
              //     department.length > 0 ||
              //     responsibleArea.length > 0
              //   )
              // }
            />
            <CommonButton
              text={t("filter.apply")}
              type="submit"
              variant="success"
              className="btn-i18n text-white"
              onClick={filterApply}
              isDisabled={filterDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonFilter;
