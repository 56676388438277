import { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/CommonButton/CommonButton";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import CommonSearch from "../../components/CommonSearch/CommonSearch";
import CommonSort from "../../components/CommonSort/CommonSort";
import { CommonStatusButton } from "../../components/CommonStatusButton/CommonStatusButton";
import DownloadPopUp from "../../components/DownloadPopUp";
import CommonLoadingBar from "../../components/LoadingBar/CommonLoadingBar";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import { routes } from "../../constants/routes";
import { useBillBoard } from "../../context/BillBoardProvider";
import AppLayout from "../../layouts/AppLayout";
import { convertExcel, convertPdf, updateText } from "../../utils/api";
import BillBoardTable from "./BillBoardTable";
import CommonFilter from "../../components/CommonFilter/CommonFilter";
import Pagination from "../../components/Pagination";

let timedPopupSetTimeOut: any = undefined;
const ListBillBoards = ({ userContext, billBoardType, screenUnits }: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const context = useBillBoard();
  const { t, i18n } = useTranslation();
  const [timedPopup, setTimedPopup] = useState(false);
  const navigate = useNavigate();
  const [sort, setSort] = useState<any>(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );
  const [ticketCount, setTicketCount] = useState<any>(
    queryParams.get("pending") ? queryParams.get("pending") : ""
  );
  const [search, setSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [status, setStatus] = useState(
    queryParams.get("board_status") ? queryParams.get("board_status") : ""
  );
  const [boardType, setBoardType] = useState<any>(
    queryParams.get("board_type") ? queryParams.get("board_type") : ""
  );
  const [screenSize, setScreenSize] = useState<any>(
    queryParams.get("screen_size") ? queryParams.get("screen_size") : ""
  );
  const [teamviewerStatus, setTeamviewerStatus] = useState<string | null>(
    queryParams.get("teamviewer_status")
      ? queryParams.get("teamviewer_status")
      : "null"
  );
  const [novastarStatus, setNovastarStatus] = useState<string | null>(
    queryParams.get("novastar_status")
      ? queryParams.get("novastar_status")
      : "null"
  );
  const [ivmsStatus, setivmsStatus] = useState<string | null>(
    queryParams.get("ivms_status") ? queryParams.get("ivms_status") : "null"
  );
  const [isIvms, setIsIvms] = useState<string | null>(
    queryParams.get("is_ivms") ? queryParams.get("is_ivms") : "null"
  );
  const [isNovastar, setIsNovastar] = useState<string | null>(
    queryParams.get("is_novastar") ? queryParams.get("is_novastar") : "null"
  );

  const [branchLocation, setBranchLocation] = useState<any>(
    queryParams.get("city") ? queryParams.get("city") : ""
  );
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);
  const [currentBillboardId, setCurrentBillboardId] = useState("");
  const [deleteText, setDeleteText] = useState<any>("");
  const [headerMessage, setHeaderMessage] = useState<any>("");
  const [isDeletePopUp, setIsDeletePopUp] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(
    queryParams.get("page") ? queryParams.get("page") : 1
  );
  const [show, setShow] = useState(false);

  const [billBoardtypeList, setBillBoardtypeList] = useState<any>([]);
  const [billBoardCity, setBillBoardCity] = useState<any>([]);
  const [boardscreenResolutionList, setBoardscreenResolutionList] =
    useState<any>([]);
  const [boardscreenPixelList, setBoardscreenPixelList] = useState<any>([]);
  const pdfRef = useRef<any>(null);
  useEffect(() => {
    loadData();
  }, [search, sort, currentPage]);

  useEffect(() => {
    cityData();
    getbillBoardtypeDetail();
    getbillBoardscreenResolutionDetail();
    getbillBoardscreenPixelListDetail();
  }, []);

  const getbillBoardtypeDetail = async () => {
    const { success, data } = await userContext?.getAllCleangingConfigDetails(
      {}
    );
    if (success) {
      let res = data?.map((d: any) => {
        return {
          label: updateText(d?.billboard_type?.replace("_", " ")),
          value: d?.billboard_type,
        };
      });
      setBillBoardtypeList(res);
    }
  };

  const cityData = async () => {
    const data = await context?.cityList({ city: "" });
    if (data?.length) {
      let res = data?.map((d: any) => {
        return {
          label: d.city,
          value: d.city,
        };
      });
      setBillBoardCity(res);
    }
  };

  const getbillBoardscreenResolutionDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "SCREEN_RESOLUTION",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setBoardscreenResolutionList(res);
    }
  };

  const getbillBoardscreenPixelListDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "SCREEN_PIXEL",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setBoardscreenPixelList(res);
    }
  };

  const openDeletePopUp = (id: any, value: any, cancelText: any) => {
    setCurrentBillboardId(id);
    setIsDeletePopUp(true);
    setDeleteText(cancelText);
    setHeaderMessage(value);
  };

  const handleDelete = async () => {
    const { success, error } = await context?.deleteBillboard(
      currentBillboardId
    );
    setIsSuccess(success);
    if (success) {
      setIsDeletePopUp(false);
      loadData();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(`${t("successError.delete this billboard successfully")}`);
    } else {
      setIsDeletePopUp(false);
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const queryParamsDetails = () => {
    let params: any = {
      search,
      sort,
      limit: 10,
      board_status: status,
      page: currentPage,
      city: branchLocation,
      board_type: boardType,
      teamviewer_status: teamviewerStatus,
      novastar_status: novastarStatus,
      ivms_status: ivmsStatus,
      screen_resolution: screenSize,
      pending: ticketCount,
      is_ivms: isIvms,
      is_novastar: isNovastar,
    };
    if (queryParams.get("over_all")) {
      params["over_all"] = queryParams.get("over_all");
    }
    return params;
  };

  const loadData = async () => {
    await context?.loadBillBoards(queryParamsDetails());
    navigate(
      routes.listBillBoardUrl({
        ...queryParamsDetails(),
      })
    );
  };

  const resetloadData = async () => {
    let params = {
      search,
      sort,
      limit: 10,
      page: currentPage,
    };
    await context?.loadBillBoards(params);
    navigate(routes.listBillBoardUrl(params));
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const applyFilter = () => {
    setCurrentPage(1);
    loadData();
    setOpenfilter(false);
  };

  const resetFilter = () => {
    setCurrentPage(1);
    setTicketCount("");
    setStatus("");
    setBoardType("");
    setScreenSize("");
    setBranchLocation("");
    setTeamviewerStatus("null");
    setNovastarStatus("null");
    setivmsStatus("null");
    setIsIvms("null");
    setIsNovastar("null");
    resetloadData();
    setOpenfilter(false);
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const filterByOpen = () => {
    setSort(queryParams.get("sort") ? queryParams.get("sort") : "-createdAt");
    setTicketCount(
      queryParams.get("pending") ? queryParams.get("pending") : ""
    );
    setSearch(queryParams.get("search") ? queryParams.get("search") : "");
    setStatus(
      queryParams.get("board_status") ? queryParams.get("board_status") : ""
    );
    setBoardType(
      queryParams.get("board_type") ? queryParams.get("board_type") : ""
    );
    setScreenSize(
      queryParams.get("screen_size") ? queryParams.get("screen_size") : ""
    );
    setBranchLocation(queryParams.get("city") ? queryParams.get("city") : "");
    setTeamviewerStatus(
      queryParams.get("teamviewer_status")
        ? queryParams.get("teamviewer_status")
        : "null"
    );
    setNovastarStatus(
      queryParams.get("novastar_status")
        ? queryParams.get("novastar_status")
        : "null"
    );
    setivmsStatus(
      queryParams.get("ivms_status") ? queryParams.get("ivms_status") : "null"
    );
    setIsIvms(queryParams.get("is_ivms") ? queryParams.get("is_ivms") : "null");
    setIsNovastar(
      queryParams.get("is_novastar") ? queryParams.get("is_novastar") : "null"
    );
    setCurrentPage(queryParams.get("page") ? queryParams.get("page") : 1);
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  const download = async (type: string) => {
    switch (type) {
      case "excel":
        const res = await context?.downloadExcel(queryParamsDetails());
        convertExcel(res, "Billboard_List");
        setIsSuccess(true);
        timeOutPopUp(true);
        setTimedPopup(true);
        setBodyMessage(
          `${t("successError.billboard downloaded successfully")}`
        );
        break;
      case "qrcore":
        const dataRes = await context?.downloadQrCodePdf(queryParamsDetails());
        convertPdf(dataRes, "Billboard_List");
        setIsSuccess(true);
        timeOutPopUp(true);
        setTimedPopup(true);
        setBodyMessage(
          `${t("successError.billboard downloaded successfully")}`
        );
        break;
      case "pdf":
        const pdfRes = await context?.downloadPdf(queryParamsDetails());
        convertPdf(pdfRes, "Billboard_List");
        setIsSuccess(true);
        timeOutPopUp(true);
        setTimedPopup(true);
        setBodyMessage(
          `${t("successError.billboard downloaded successfully")}`
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        filterByOpen();
      }
    };
    window.addEventListener("keyup", close);
    return () => window.removeEventListener("keyup", close);
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        sortByOpen();
      }
    };
    window.addEventListener("sortDropDown", close);
    return () => window.removeEventListener("sortDropDown", close);
  }, []);

  return (
    <AppLayout>
      <>
        <div className="billboard position-relative p-md-3">
          <div
            className={`${
              isMobile ? "container-fluid position-relative" : "container"
            }`}
          >
            <div
              className={`${
                isMobile
                  ? "d-none "
                  : "pt-4 d-flex align-items-center justify-content-between "
              }`}
            >
              {context?.isLoading && <CommonLoadingBar />}
              <div className="members ">
                <h5 className=" mb-0">
                  {t("billboard.billboard")} (
                  {context?.meta ? context?.meta?.total_count : 0})
                </h5>
              </div>
              <div
                className={`${
                  isMobile ? "d-none " : "container-fluid p-0 forinput"
                }`}
              >
                <CommonSearch
                  placeholder={t("billboard.search") || ""}
                  onKeyDown={(e: any) => handleEnterKey(e)}
                  onChange={(e: any) =>
                    e.target.value.length === 0 && setSearch(e.target.value)
                  }
                />
              </div>

              <div className="">
                <div className="d-flex  align-items-center justify-content-end">
                  <CommonSort
                    sort={sort}
                    opensort={opensort}
                    opensortClick={sortByOpen}
                    setCurrentPage={setCurrentPage}
                    setSort={setSort}
                    sortAtoZ="board_no"
                    sortZtoA="-board_no"
                  />
                  <CommonFilter
                    openfilterClick={filterByOpen}
                    isFilterActive={
                      (ticketCount?.length > 0 && ticketCount > 0) ||
                      boardType?.length > 0 ||
                      screenSize?.length > 0 ||
                      branchLocation?.length > 0 ||
                      (teamviewerStatus?.length ?? 0) > 4 ||
                      (novastarStatus?.length ?? 0) > 4 ||
                      (ivmsStatus?.length ?? 0) > 4 ||
                      (isIvms !== "null" && (isIvms?.length ?? 0) > 0) ||
                      (isNovastar !== "null" && (isNovastar?.length ?? 0) > 0)
                    }
                    openfilter={openfilter}
                    resetClick={resetFilter}
                    filterClose={filterByOpen}
                    filterApply={applyFilter}
                    filterDisabled={
                      teamviewerStatus === null ||
                      novastarStatus === null ||
                      ivmsStatus === null ||
                      isIvms === null ||
                      isNovastar === null ||
                      status === null
                    }
                  >
                    <Dropdownfilter
                      setTicketCount={setTicketCount}
                      ticketCount={ticketCount}
                      status={status}
                      setStatus={setStatus}
                      setBoardType={setBoardType}
                      screenSize={screenSize}
                      setScreenSize={setScreenSize}
                      teamviewerStatus={teamviewerStatus}
                      novastarStatus={novastarStatus}
                      ivmsStatus={ivmsStatus}
                      isIvms={isIvms}
                      isNovastar={isNovastar}
                      setTeamviewerStatus={setTeamviewerStatus}
                      setNovastarStatus={setNovastarStatus}
                      setivmsStatus={setivmsStatus}
                      setIsIvms={setIsIvms}
                      setIsNovastar={setIsNovastar}
                      boardType={boardType}
                      setBranchLocation={setBranchLocation}
                      branchLocation={branchLocation}
                      billBoardType={billBoardType}
                      screenUnits={screenUnits}
                      billBoardtypeList={billBoardtypeList}
                      billBoardCity={billBoardCity}
                      boardscreenResolutionList={boardscreenResolutionList}
                      boardscreenPixelList={boardscreenPixelList}
                      userContext={userContext}
                    />
                  </CommonFilter>
                  {/* <div>
                    <CommonButton
                      text={t("BillboardMap.Map View")}
                      variant="outline-info"
                      className=" bg-transparent text-info btn-i18n d-none"
                      onClick={() => {
                        navigate(routes.billBoardMap);
                      }}
                    />
                  </div> */}
                  <div>
                    <CommonButton
                      text={t("listmember.Download")}
                      variant="outline-secondary"
                      className=" bg-transparent text-secondary btn-i18n"
                      onClick={() => setShow(true)}
                    />
                  </div>
                  {userContext?.roleAccess["BOARD"].includes("WRITE") && (
                    <CommonButton
                      onClick={() => {
                        context?.removeBillBoard(null);
                        navigate(
                          routes.getNewBillBoardUrl(
                            context?.billBoardQueryParamsDetails()
                          )
                        );
                      }}
                      text={t("billboard.createboards")}
                      variant="success"
                      className=" btn-i18n text-white"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
              <div
                className={`${
                  isDesktop
                    ? "d-none "
                    : " d-flex align-items-center justify-content-between flex-wrap"
                }`}
              >
                <div className="members ">
                  <h5 className=" mb-3">
                    {t("billboard.billboard")} (
                    {context?.meta ? context?.meta?.total_count : 0})
                  </h5>
                </div>
                <div className="d-flex">
                  {/* -------------------------------------------------For tablet---------------------------- */}
                  <div className={`${isTablet ? " d-flex" : "d-none  "}`}>
                    <CommonSort
                      sort={sort}
                      opensort={opensort}
                      opensortClick={sortByOpen}
                      setCurrentPage={setCurrentPage}
                      setSort={setSort}
                      sortAtoZ="board_no"
                      sortZtoA="-board_no"
                    />
                    <CommonFilter
                      openfilterClick={filterByOpen}
                      isFilterActive={
                        (ticketCount?.length > 0 && ticketCount > 0) ||
                        boardType?.length > 0 ||
                        screenSize?.length > 0 ||
                        branchLocation?.length > 0 ||
                        (teamviewerStatus?.length ?? 0) > 4 ||
                        (novastarStatus?.length ?? 0) > 4 ||
                        (ivmsStatus?.length ?? 0) > 4 ||
                        (isIvms !== "null" && (isIvms?.length ?? 0) > 0) ||
                        (isNovastar !== "null" && (isNovastar?.length ?? 0) > 0)
                      }
                      openfilter={openfilter}
                      resetClick={resetFilter}
                      filterClose={filterByOpen}
                      filterApply={applyFilter}
                      filterDisabled={
                        teamviewerStatus === null ||
                        novastarStatus === null ||
                        ivmsStatus === null ||
                        isIvms === null ||
                        isNovastar === null ||
                        status === null
                      }
                    >
                      <Dropdownfilter
                        setTicketCount={setTicketCount}
                        ticketCount={ticketCount}
                        status={status}
                        setStatus={setStatus}
                        setBoardType={setBoardType}
                        screenSize={screenSize}
                        setScreenSize={setScreenSize}
                        teamviewerStatus={teamviewerStatus}
                        novastarStatus={novastarStatus}
                        ivmsStatus={ivmsStatus}
                        isIvms={isIvms}
                        isNovastar={isNovastar}
                        setTeamviewerStatus={setTeamviewerStatus}
                        setNovastarStatus={setNovastarStatus}
                        setivmsStatus={setivmsStatus}
                        setIsIvms={setIsIvms}
                        setIsNovastar={setIsNovastar}
                        boardType={boardType}
                        setBranchLocation={setBranchLocation}
                        branchLocation={branchLocation}
                        billBoardType={billBoardType}
                        screenUnits={screenUnits}
                        billBoardtypeList={billBoardtypeList}
                        billBoardCity={billBoardCity}
                        boardscreenResolutionList={boardscreenResolutionList}
                        boardscreenPixelList={boardscreenPixelList}
                        userContext={userContext}
                      />
                    </CommonFilter>
                  </div>
                  {/* <div>
                    <CommonButton
                      text={t("BillboardMap.Map View")}
                      variant="outline-info"
                      className=" bg-transparent text-info d-none "
                      onClick={() => {
                        navigate(routes.billBoardMap);
                      }}
                    />
                  </div> */}
                  <div>
                    <CommonButton
                      text={t("listmember.Download")}
                      variant="outline-secondary"
                      className="  bg-transparent text-secondary btn-i18n"
                      onClick={() => setShow(true)}
                    />
                  </div>
                  {userContext?.roleAccess["BOARD"].includes("WRITE") && (
                    <CommonButton
                      onClick={() => {
                        context?.removeBillBoard(null);
                        navigate(
                          routes.getNewBillBoardUrl(
                            context?.billBoardQueryParamsDetails()
                          )
                        );
                      }}
                      text={t("billboard.createboards")}
                      variant="success"
                      className=" btn-i18n text-white"
                    />
                  )}
                </div>
              </div>
              <div className="pt-5 position-relative">
                <CommonSearch
                  placeholder={t("billboard.search") || ""}
                  onKeyDown={(e: any) => handleEnterKey(e)}
                  onChange={(e: any) => {
                    e.target.value.length === 0 && setSearch(e.target.value);
                    if (isMobile) {
                      setMobileSearch(e.target.value);
                    }
                  }}
                />

                <Button
                  className={
                    i18n.language === "en"
                      ? "mobile-search-btn"
                      : "mobile-search-btn-right"
                  }
                  onClick={() =>
                    handleEnterKey({
                      key: "Enter",
                      target: { value: mobileSearch },
                    })
                  }
                >
                  {t("chat.Search")}
                </Button>
              </div>
            </div>

            <div className=" pt-4 table-responsive" ref={pdfRef}>
              <Table responsive="sm table_class">
                {context?.billBoards && context?.billBoards?.length > 0 && (
                  <tbody className="table_white">
                    {context?.billBoards?.map(
                      (billBoard: any, index: number) => {
                        return (
                          <tr
                            key={billBoard.id}
                            className={`table_white tableposition ${
                              billBoard?.board_status === "NON_OPERATIONAL"
                                ? "nonmarkcolor"
                                : billBoard?.board_status === "REQUEST"
                                ? "nonmarkPendingcolor"
                                : ""
                            }`}
                            style={{ height: isMobileOnly ? "" : "20vh" }}
                          >
                            <BillBoardTable
                              billBoard={billBoard}
                              userContext={userContext}
                              context={context}
                              openDeletePopUp={openDeletePopUp}
                            />
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                )}
              </Table>
              {!context?.billBoards?.length && (
                <div className=" d-flex justify-content-center align-items-center">
                  <div>{t("billboard.No BillBoard Found")}</div>
                </div>
              )}
            </div>
            {context?.meta &&
              context?.billBoards &&
              context?.billBoards?.length > 0 && (
                <Pagination
                  meta={context?.meta}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  currentPageCount={context?.billBoards?.length}
                  component={"bill"}
                />
              )}
          </div>
          <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
            <SuccessErrorToast
              bodyMessage={bodyMessage}
              isSuccess={isSuccess}
              footButton={false}
            />
          </ToastPopup>
          <ToastPopupSecond
            trigger={isDeletePopUp}
            setTrigger={setIsDeletePopUp}
          >
            <DeleteBlockToast
              setTrigger={(status: boolean) => {
                loadData();
                setIsDeletePopUp(status);
              }}
              closePopUp={(data: any) => setIsDeletePopUp(data)}
              submit={() => handleDelete()}
              headerMessage={headerMessage}
              textMessage={deleteText}
            />
          </ToastPopupSecond>

          {/* ------------------------------------------For Mobile responsive------------------------------------- */}

          <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
            <div className="sortfilterMobile row m-0">
              <CommonSort
                sort={sort}
                opensort={opensort}
                opensortClick={sortByOpen}
                setCurrentPage={setCurrentPage}
                setSort={setSort}
                sortAtoZ="board_no"
                sortZtoA="-board_no"
                isSortMobile
              />
              <div className="col p-0 d-flex justify-content-evenly align-items-center ">
                <CommonFilter
                  isFilterMobile
                  isFilterActive={
                    (ticketCount?.length > 0 && ticketCount > 0) ||
                    boardType?.length > 0 ||
                    screenSize?.length > 0 ||
                    branchLocation?.length > 0 ||
                    (teamviewerStatus?.length ?? 0) > 4 ||
                    (novastarStatus?.length ?? 0) > 4 ||
                    (ivmsStatus?.length ?? 0) > 4 ||
                    (isIvms !== "null" && (isIvms?.length ?? 0) > 0) ||
                    (isNovastar !== "null" && (isNovastar?.length ?? 0) > 0)
                  }
                  openfilter={openfilter}
                  resetClick={resetFilter}
                  filterClose={filterByOpen}
                  filterApply={applyFilter}
                  filterDisabled={
                    teamviewerStatus === null ||
                    novastarStatus === null ||
                    ivmsStatus === null ||
                    isIvms === null ||
                    isNovastar === null ||
                    status === null
                  }
                >
                  <Dropdownfilter
                    setTicketCount={setTicketCount}
                    ticketCount={ticketCount}
                    status={status}
                    setStatus={setStatus}
                    setBoardType={setBoardType}
                    screenSize={screenSize}
                    setScreenSize={setScreenSize}
                    teamviewerStatus={teamviewerStatus}
                    novastarStatus={novastarStatus}
                    ivmsStatus={ivmsStatus}
                    isIvms={isIvms}
                    isNovastar={isNovastar}
                    setTeamviewerStatus={setTeamviewerStatus}
                    setNovastarStatus={setNovastarStatus}
                    setivmsStatus={setivmsStatus}
                    setIsIvms={setIsIvms}
                    setIsNovastar={setIsNovastar}
                    boardType={boardType}
                    setBranchLocation={setBranchLocation}
                    branchLocation={branchLocation}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                    billBoardtypeList={billBoardtypeList}
                    billBoardCity={billBoardCity}
                    boardscreenResolutionList={boardscreenResolutionList}
                    boardscreenPixelList={boardscreenPixelList}
                    userContext={userContext}
                  />
                </CommonFilter>
              </div>
            </div>
          </div>
          <ToastPopup
            trigger={context?.isLoading}
            setTrigger={context?.setIsLoading}
          >
            <SuccessErrorToast isLoading={true} />
          </ToastPopup>
          <DownloadPopUp
            show={show}
            setShow={setShow}
            download={download}
            isBillboard={true}
          />
        </div>
      </>
    </AppLayout>
  );
};

export default ListBillBoards;

const Dropdownfilter = ({
  setTicketCount,
  ticketCount,
  setStatus,
  status,
  setBoardType,
  screenSize,
  setScreenSize,
  teamviewerStatus,
  novastarStatus,
  ivmsStatus,
  isIvms,
  isNovastar,
  setTeamviewerStatus,
  setNovastarStatus,
  setivmsStatus,
  setIsIvms,
  setIsNovastar,
  boardType,
  setBranchLocation,
  branchLocation,
  billBoardtypeList,
  billBoardCity,
  boardscreenResolutionList,
  userContext,
}: any) => {
  const { t } = useTranslation();

  const getbillBoardtypeList: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { data } = await userContext?.getAllCleangingConfigDetails({
      search: inputValue,
      status: "ACTIVE",
    });
    callback(
      data?.map((d: any) => ({
        label: updateText(d?.billboard_type?.replace("_", " ")),
        value: d?.billboard_type,
      }))
    );
  };

  const getCityOptions: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const data = await userContext?.cityList({
      city: inputValue,
    });
    callback(
      data?.map((d: any) => ({
        label: d?.city,
        value: d?.city,
      }))
    );
  };

  const getbillBoardscreenResolutionList: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
      config_type: "SCREEN_RESOLUTION",
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  return (
    <div>
      <div className="py-2 ">
        <h6>{t("filter.status")}</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"ACTIVE"}
            text={t("filter.active")}
            activeText={status}
            onClick={(e: string) => {
              setStatus(status === "ACTIVE" ? "" : e);
            }}
          />

          <CommonStatusButton
            pillText={"NON_OPERATIONAL"}
            text={t("filter.NON_OPERATIONAL")}
            activeText={status}
            onClick={(e: string) => {
              setStatus(status === "NON_OPERATIONAL" ? "" : e);
            }}
          />

          <CommonStatusButton
            pillText={"REQUEST"}
            text={t("filter.pending")}
            activeText={status}
            onClick={(e: string) => {
              setStatus(status === "REQUEST" ? "" : e);
            }}
          />
        </div>
      </div>
      <div className="py-2">
        <h6>{t("billboard.billLocation")}</h6>
        <div className={`${isMobile ? " " : "container-fluid p-0"}`}>
          <CommonAsyncSelect
            isMulti={false}
            values={{
              label: branchLocation,
              value: branchLocation,
            }}
            options={billBoardCity}
            cacheOptions
            loadOptionValue={getCityOptions}
            updateField={(data: any) => {
              setBranchLocation(data?.value);
            }}
            isClearable={false}
          />
        </div>
      </div>
      <div className="py-2">
        <h6 className="">{t("billboard.ticketcount")}</h6>
        <div className={`${isMobile ? " " : "container-fluid p-0"}`}>
          <input
            id="doc_searchQueryInputfilter"
            type="number"
            name="doc_searchQueryInputfilter"
            value={ticketCount}
            onChange={(e: any) => setTicketCount(e.target.value)}
          />
        </div>
      </div>
      <div className="py-2 w-100">
        <h6> {t("billboard.billtype")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            isMulti={false}
            values={{
              label: updateText(boardType),
              value: boardType,
            }}
            options={billBoardtypeList}
            cacheOptions
            loadOptionValue={getbillBoardtypeList}
            updateField={(data: any) => {
              setBoardType(data?.value);
            }}
            isClearable={false}
          />
        </div>
      </div>

      <div className="py-2 ">
        <h6>{t("billboard.team")}</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"Both"}
            text={t("BillboardTicketPage.Both")}
            activeText={teamviewerStatus === "null" ? "Both" : ""}
            onClick={(e: string) => {
              setTeamviewerStatus("null");
            }}
          />
          <CommonStatusButton
            pillText={"online"}
            text={t("chat.Online")}
            activeText={teamviewerStatus}
            onClick={(e: string) => {
              setTeamviewerStatus(teamviewerStatus === "online" ? "null" : e);
            }}
          />
          <CommonStatusButton
            pillText={"offline"}
            text={t("chat.Offline")}
            activeText={teamviewerStatus}
            onClick={(e: string) => {
              setTeamviewerStatus(teamviewerStatus === "offline" ? "null" : e);
            }}
          />
        </div>
      </div>

      <div className="py-2 ">
        <h6>{t("billboard.novastar")}</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"Both"}
            text={t("BillboardTicketPage.Both")}
            activeText={novastarStatus === "null" ? "Both" : ""}
            onClick={(e: string) => {
              setNovastarStatus("null");
            }}
          />
          <CommonStatusButton
            pillText={"online"}
            text={t("chat.Online")}
            activeText={novastarStatus}
            onClick={(e: string) => {
              setNovastarStatus(novastarStatus === "online" ? "null" : e);
            }}
          />
          <CommonStatusButton
            pillText={"offline"}
            text={t("chat.Offline")}
            activeText={novastarStatus}
            onClick={(e: string) => {
              setNovastarStatus(novastarStatus === "offline" ? "null" : e);
            }}
          />

          <CommonStatusButton
            pillText={"alaram"}
            text={t("filter.alaram")}
            activeText={novastarStatus}
            onClick={(e: string) => {
              setNovastarStatus(novastarStatus === "alaram" ? "null" : e);
            }}
          />
          <CommonStatusButton
            pillText={"fault"}
            text={t("filter.Fault")}
            activeText={novastarStatus}
            onClick={(e: string) => {
              setNovastarStatus(novastarStatus === "fault" ? "null" : e);
            }}
          />
        </div>
      </div>

      <div className="py-2 ">
        <h6>IVMS 4200</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"Both"}
            text={t("BillboardTicketPage.Both")}
            activeText={ivmsStatus === "null" ? "Both" : ""}
            onClick={(e: string) => {
              setivmsStatus("null");
            }}
          />
          <CommonStatusButton
            pillText={"online"}
            text={t("chat.Online")}
            activeText={ivmsStatus}
            onClick={(e: string) => {
              setivmsStatus(ivmsStatus === "online" ? "null" : e);
            }}
          />
          <CommonStatusButton
            pillText={"offline"}
            text={t("chat.Offline")}
            activeText={ivmsStatus}
            onClick={(e: string) => {
              setivmsStatus(ivmsStatus === "offline" ? "null" : e);
            }}
          />
        </div>
      </div>

      <div className="py-2 ">
        <h6>IVMS 4200 N/A</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"installed"}
            text={t("BillboardTicketPage.Installed")}
            activeText={isIvms === "true" ? "installed" : ""}
            onClick={() => {
              setIsIvms(isIvms === "true" ? "null" : "true");
            }}
          />
          <CommonStatusButton
            pillText={"not_installed"}
            text={t("BillboardTicketPage.Not Installed")}
            activeText={isIvms === "false" ? "not_installed" : ""}
            onClick={() => {
              setIsIvms(isIvms === "false" ? "null" : "false");
            }}
          />
        </div>
      </div>

      <div className="py-2 ">
        <h6>{t("billboard.novastar")} N/A</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          {/* <CommonStatusButton
            pillText={"Both"}
            text={t("BillboardTicketPage.Both")}
            activeText={isNovastar === "null" ? "Both" : ""}
            onClick={(e: string) => {
              setIsNovastar("null");
            }}
          /> */}
          <CommonStatusButton
            pillText={"installed"}
            text={t("BillboardTicketPage.Installed")}
            activeText={isNovastar === "true" ? "installed" : ""}
            onClick={() => {
              setIsNovastar(isNovastar === "true" ? "null" : "true");
            }}
          />
          <CommonStatusButton
            pillText={"not_installed"}
            text={t("BillboardTicketPage.Not Installed")}
            activeText={isNovastar === "false" ? "not_installed" : ""}
            onClick={() => {
              setIsNovastar(isNovastar === "false" ? "null" : "false");
            }}
          />
        </div>
      </div>

      <div className="py-2 " style={{ width: "100%" }}>
        <h6> {t("billboard.screenresolution")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            isMulti={false}
            values={{
              label: updateText(screenSize),
              value: screenSize,
            }}
            options={boardscreenResolutionList}
            cacheOptions
            loadOptionValue={getbillBoardscreenResolutionList}
            updateField={(data: any) => {
              setScreenSize(data?.value);
            }}
            isClearable={false}
          />
        </div>
      </div>
    </div>
  );
};
