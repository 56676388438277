import React from "react";
import "./ChatLoader.scss";
import loader from "../../context/assets/images/chatgif.gif";

const ChatLoader = () => {
  return (
    <div className=" chat-loader d-flex justify-content-center align-items-center">
      <div className=" position-relative flex-column d-flex justify-content-center align-items-center chat-gif">
        <img src={loader} alt="loading..." />
        <div className="chat-loading-text" data-loading-text="Loading..."></div>
      </div>
    </div>
  );
};

export default ChatLoader;
