import { useTranslation } from "react-i18next";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useEffect } from "react";
import CustomLocationSelect from "../../components/CommonComponents/CustomLocationSelect";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CommonButton from "../../components/CommonButton/CommonButton";
import FormikField from "../../components/FormikField/FormikField";
import FormikPhoneNumber from "../../components/FormikPhoneNumber/FormikPhoneNumber";
import CustomInputField from "../../components/InputField/CustomInputField";

const PersonalProfileForm = ({
  memberId = "",
  isProfile = false,
  setBodyMessage,
  userContext,
  setUpdateProfileDetail,
  context,
  setTextMessage,
  setFormikFunction,
  setShowPopUp,
  setHeaderMessage,
}: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (userContext?.isProfileUpdated) {
      getMemberDetail();
    }
  }, [userContext?.isProfileUpdated]);

  const getMemberDetail = async () => {
    userContext?.setIsProfileUpdated(false);
    if (context && isProfile) {
      await context.getUserDetail();
    } else if (context && !isProfile) {
      await context.getMemberDetail(memberId);
    }
  };

  const phoneRegExp =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

  const initialValues = {
    name: isProfile
      ? context?.user?.name
        ? context?.user?.name
        : ""
      : context?.member?.name
      ? context?.member?.name
      : "",
    contact_number: isProfile
      ? context?.user?.contact_number
        ? context?.user?.contact_number
        : ""
      : context?.member?.contact_number
      ? context?.member?.contact_number
      : "",
    alternate_number: isProfile
      ? context?.user?.alternate_number
        ? context?.user?.alternate_number
        : ""
      : context?.member?.alternate_number
      ? context?.member?.alternate_number
      : "",
    whatsapp_number: isProfile
      ? context?.user?.whatsapp_number
        ? context?.user?.whatsapp_number
        : ""
      : context?.member?.whatsapp_number
      ? context?.member?.whatsapp_number
      : "",
    location: isProfile
      ? context?.user?.location
        ? context?.user?.location
        : ""
      : context?.member?.location
      ? context?.member?.location
      : "",
    biography: isProfile
      ? context?.user?.biography
        ? context?.user?.biography
        : ""
      : context?.member?.biography
      ? context?.member?.biography
      : "",
    iqama_no: isProfile
      ? context?.user?.iqama_no
        ? context?.user?.iqama_no
        : ""
      : context?.member?.iqama_no
      ? context?.member?.iqama_no
      : "",
    user_name: isProfile
      ? context?.user?.user_name
        ? context?.user?.user_name
        : ""
      : context?.member?.user_name
      ? context?.member?.user_name
      : "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    contact_number: Yup.string()
      .typeError("It doesn't look like a contact number")
      .min(13, "It doesn't look like a contact number")
      .max(14, "It doesn't look like a contact number")
      .matches(phoneRegExp, "That doesn't look like a Contact Number")
      .required("Contact number is required"),
    alternate_number: Yup.string()
      .typeError("It doesn't look like a contact number")
      .min(13, "It doesn't look like a contact number")
      .max(14, "It doesn't look like a contact number")
      .notOneOf(
        [Yup.ref("contact_number")],
        "Alternate number should not be the same as contact number"
      ),
    whatsapp_number: Yup.string()
      .typeError("It doesn't look like a contact number")
      .min(13, "It doesn't look like a contact number")
      .max(14, "It doesn't look like a contact number")
      .required("Whatsapp number is required"),
    iqama_no: Yup.string()
      .min(1, "Please enter id more than 1 character")
      .max(10)
      .required("IQAMA Number is required"),
    location: Yup.string()
      .min(2, "Too Short!")
      .max(1000)
      .required("Location is required"),
    biography: Yup.string().min(2, "Too Short!").max(1000),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setUpdateProfileDetail({
        ...values,
        alternate_number:
          values?.alternate_number?.length === 0 ||
          values?.alternate_number === undefined
            ? null
            : values?.alternate_number,
      });
      setHeaderMessage(`${t("successError.Updated personal profile details")}`);
      setTextMessage(`${t("successError.update")}`);
      setBodyMessage(`${t("successError.Personal profile updated")}`);
      setFormikFunction(formik);
      setShowPopUp(true);
    },
  });

  const { errors, handleSubmit, isSubmitting, isValid, dirty } = formik;
  return (
    <div className="">
      <FormikProvider value={formik}>
        <Form validated={true} onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className=" mb-4">
              <FormikField
                name="name"
                type="text"
                autoFocus
                validationSchema={validationSchema}
                label={t("memberTable.name")}
                errors={errors as Record<string, string>}
                disabled={
                  !isProfile &&
                  !userContext?.roleAccess["USER"].includes("UPDATE")
                }
              />
            </Col>
            <Col md={6} className=" mb-4">
              <FormikPhoneNumber
                validationSchema={validationSchema}
                name="contact_number"
                label={t("personalprofile.contactNumber")}
                errors={errors["contact_number"] as string}
                isDisabled={
                  (!isProfile &&
                    !userContext?.roleAccess["USER"].includes("UPDATE")) ||
                  (formik?.values?.user_name ===
                    formik?.values?.contact_number &&
                    userContext?.user?.role !== "ADMIN")
                    ? true
                    : false
                }
              />
            </Col>

            <Col md={6} className=" mb-4">
              <FormikPhoneNumber
                validationSchema={validationSchema}
                name="alternate_number"
                label={t("personalprofile.alternateNumber")}
                errors={errors["alternate_number"] as string}
                isDisabled={
                  !isProfile &&
                  !userContext?.roleAccess["USER"].includes("UPDATE")
                }
              />
            </Col>

            <Col md={6} className=" mb-4">
              <FormikPhoneNumber
                validationSchema={validationSchema}
                name="whatsapp_number"
                label={t("personalprofile.whatsappNumber")}
                errors={errors["whatsapp_number"] as string}
                isDisabled={
                  !isProfile &&
                  !userContext?.roleAccess["USER"].includes("UPDATE")
                }
              />
            </Col>

            <Col md={6} className="formprofile mb-4">
              <Field name="board_location">
                {({ field }: FieldProps<string>) => {
                  return (
                    <CustomInputField
                      label={`${t("personalprofile.location")} *`}
                      error={errors["location"] as string}
                      validationSchema={validationSchema}
                      inputId={""}
                      field={field}
                    >
                      <CustomLocationSelect
                        fiedName={"location"}
                        isFieldDisabled={
                          !isProfile &&
                          !userContext?.roleAccess["USER"].includes("UPDATE")
                        }
                        className={"autolocation"}
                        updateField={(data: any) =>
                          formik.setFieldValue(
                            "location",
                            data?.formatted_address
                          )
                        }
                        defaultValue={formik?.values?.location}
                      />
                    </CustomInputField>
                  );
                }}
              </Field>
            </Col>

            <Col md={6} className=" mb-4">
              <FormikField
                name="iqama_no"
                type="text"
                validationSchema={validationSchema}
                label={t("companyprofile.iqamaNo")}
                errors={errors as Record<string, string>}
                disabled={
                  !isProfile &&
                  !userContext?.roleAccess["USER"].includes("UPDATE")
                }
              />
            </Col>

            <Col className=" mb-4">
              <FormikField
                as="textarea"
                name="biography"
                type="text"
                validationSchema={validationSchema}
                label={t("personalprofile.biography")}
                errors={errors as Record<string, string>}
                disabled={
                  !isProfile &&
                  !userContext?.roleAccess["USER"].includes("UPDATE")
                }
              />
            </Col>
          </Row>
          {(isProfile
            ? true
            : userContext?.roleAccess["USER"].includes("UPDATE")) && (
            <div className="d-flex justify-content-end my-5">
              <CommonButton
                text={t("personalprofile.cancel")}
                isLoading={isSubmitting}
                variant="outline-danger"
                type="button"
                className="bg-transparent text-danger"
                isDisabled={isSubmitting || !dirty}
                onClick={() => {
                  setHeaderMessage(`${t("successError.cancel the changes")}`);
                  setTextMessage(`${t("successError.cancel it")}`);
                  setFormikFunction(formik);
                  setShowPopUp(true);
                }}
              />
              <CommonButton
                text={t("personalprofile.Update")}
                isLoading={isSubmitting}
                type="submit"
                variant="success"
                className="btn-i18n text-white"
                isDisabled={isSubmitting || !isValid || !dirty}
              />
            </div>
          )}
        </Form>
      </FormikProvider>
    </div>
  );
};
export default PersonalProfileForm;
