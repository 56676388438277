import { useCallback, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { isMobile } from "react-device-detect";
import { MdCall, MdCallEnd } from "react-icons/md";
import {
  ChatContextProp,
  useChatContext,
} from "../../../../context/ChatProvider";
import "./IncomingCall.scss";

const IncomingCall = () => {
  const chatContext = useChatContext();
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    chatContext.ringTone.loop = true;
    chatContext?.ringTone.play();
  }, []);

  const handleAcceptCall = useCallback(() => {
    setIsClicked(true);
    chatContext.setCallRequested(false);
    chatContext?.handleAcceptCall();
  }, [chatContext]);

  const handleRejectCall = () => {
    chatContext.setCallAccepted(false);
    chatContext.setCallRequested(false);
    chatContext.handleDisconnect(
      "DISCONNECT",
      chatContext?.userInfo?.user?.id,
      "MISCALL",
      chatContext?.userInfo?.conversationId
    );
  };

  return (
    <div
      className={`${
        isMobile ? "incoming-wrapper-responsive py-3 px-4" : "incoming-wrapper"
      }`}
    >
      {isMobile ? (
        <IncomingCallResponsive
          handleAcceptCall={handleAcceptCall}
          handleRejectCall={handleRejectCall}
          chatContext={chatContext}
          isClicked={isClicked}
        />
      ) : (
        <div className="incoming border">
          <div className=" position-relative">
            <div className="position-relative">
              <div className="incoming-avatar">
                {chatContext?.userInfo?.user?.avatar || "" ? (
                  <img
                    src={chatContext?.userInfo?.user?.avatar || ""}
                    alt={chatContext?.userInfo?.user?.name || ""}
                  />
                ) : (
                  <Avatar
                    className="mr-2"
                    name={chatContext?.userInfo?.user?.name || ""}
                    size="100%"
                    round={true}
                    maxInitials={2}
                  />
                )}
              </div>
              <div className="incoming-bg ">
                {chatContext?.userInfo?.user?.avatar || "" ? (
                  <img
                    src={chatContext?.userInfo?.user?.avatar || ""}
                    alt={chatContext?.userInfo?.user?.name || ""}
                  />
                ) : (
                  // <Avatar
                  //   name={chatContext?.userInfo?.user?.name || ""}
                  //   size="100%"
                  //   round={true}
                  //   maxInitials={2}
                  // />
                  <div className="incoming-avatar-bg h-100"></div>
                )}
              </div>
            </div>

            <h6 className="incoming-text text-capitalize">
              Incoming {chatContext?.mode} Call
            </h6>
            <h3 className="contact-name">
              {chatContext?.userInfo?.user?.name || ""}
            </h3>
          </div>

          {/* <CallNotifcation /> */}
          <div className=" d-flex align-items-center justify-content-center gap-4 py-5">
            <div
              className={` ignore d-flex align-items-center justify-content-center`}
              title="Decline the call"
            >
              <MdCallEnd
                size={26}
                color="var(--color-white)"
                onClick={handleRejectCall}
              />
            </div>

            <div
              className={`  d-flex align-items-center justify-content-center ${
                isClicked ? "accept-disabled" : "accept"
              }`}
              title="Accept the call"
            >
              <MdCall
                size={26}
                color="var(--color-white)"
                onClick={handleAcceptCall}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncomingCall;
interface IncomingCallResponsiveProps {
  handleAcceptCall: Function;
  handleRejectCall: Function;
  chatContext: ChatContextProp;
  isClicked?: boolean;
}

const IncomingCallResponsive = ({
  handleAcceptCall,
  handleRejectCall,
  chatContext,
  isClicked,
}: IncomingCallResponsiveProps) => {
  return (
    <div className=" d-flex justify-content-between align-items-center">
      <div className=" d-flex align-items-center gap-3">
        <div className="incoming-avatar-responsive">
          {chatContext?.userInfo?.user?.avatar || "" ? (
            <img
              src={chatContext?.userInfo?.user?.avatar || ""}
              alt={chatContext?.userInfo?.user?.name || ""}
            />
          ) : (
            <Avatar
              name={chatContext?.userInfo?.user?.name || ""}
              size={isMobile ? "40" : "50"}
              round={true}
              maxInitials={2}
            />
          )}
        </div>
        <div>
          <h3 className="contact-name mb-0 pb-1 ">
            {chatContext?.userInfo?.user?.name || ""}
          </h3>
          <h6 className=" text-capitalize mb-0 incoming-alert">
            Incoming {chatContext?.mode} Call
          </h6>
        </div>
      </div>
      <div className=" d-flex align-items-center gap-3">
        <div
          className={` ignore-responsive d-flex align-items-center justify-content-center`}
          title="Decline the call"
        >
          <MdCallEnd
            size={24}
            color="var(--color-white)"
            onClick={() => {
              handleRejectCall();
            }}
          />
        </div>
        <div
          className={`  d-flex align-items-center justify-content-center ${
            isClicked ? "accept-disabled-responsive" : "accept-responsive"
          }`}
          title="Accept the call"
        >
          <MdCall
            size={24}
            color="var(--color-white)"
            onClick={() => {
              handleAcceptCall();
            }}
          />
        </div>
      </div>
    </div>
  );
};
