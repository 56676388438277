import { HiOutlinePhoneMissedCall } from "react-icons/hi";
import { TbPhoneCall, TbPhoneCalling } from "react-icons/tb";
import "./CallConnection.scss";

interface CallConnectionProps {
  ConnectingText: string;
  callDisconnecting?: boolean;
  callStarting?: boolean;
  callWaiting?: boolean;
}

const CallConnection = ({
  ConnectingText,
  callDisconnecting,
  callStarting,
  callWaiting,
}: CallConnectionProps) => {
  return (
    <div className=" d-flex justify-content-center align-items-center call-connections">
      <div className=" d-flex justify-content-center align-items-center gap-3 call-connection-wrapper p-3">
        <h6 className="text-conntection mb-0 fw-semibold">{ConnectingText}</h6>
        <span className="call-controls">
          {callDisconnecting && <HiOutlinePhoneMissedCall />}
          {callWaiting && <TbPhoneCalling />}
          {callStarting && <TbPhoneCall />}
        </span>
        {/* <div className="running">
          <div className="outer">
            <div className="running-body">
              <div className="arm behind"></div>
              <div className="arm front"></div>
              <div className="leg behind"></div>
              <div className="leg front"></div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CallConnection;
