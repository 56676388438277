import { BsFillPeopleFill } from "react-icons/bs";
import "./NewGroupPopup.scss";
import { IoCameraSharp } from "react-icons/io5";
import { useCallback, useEffect, useRef, useState } from "react";
import Picker from "emoji-picker-react";
import { VscSmiley } from "react-icons/vsc";
import { useChatContext } from "../../../context/ChatProvider";
import { useUserContext } from "../../../context/UserProvider";
import CommonButton from "../components/CommonButton/CommonButton";
import { useNavigate } from "react-router-dom";

interface PopupProps {
  setShow?: (show: boolean) => void;
}

function NewGroupPopup({ setShow }: PopupProps) {
  const [groupSubject, setGroupSubject] = useState<any>("");
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const chatContext = useChatContext();
  const userContext = useUserContext();
  const emojiRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleCreateGroup = async () => {
    setShow?.(false);
    const memberIds = chatContext?.groupMembers
      .map((member) => member.id)
      .filter((id) => id !== undefined);
    const grouInfo = await chatContext?.createGroup({
      group_name: groupSubject,
      group_member: [userContext?.user?.id, ...(memberIds as string[])],
    });
    if (grouInfo.success) {
      navigate(`/chat/${grouInfo?.conversation?.id}`);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.target.value = "";
    }
  };

  const handleEmojiClick = useCallback((emojiData: { emoji: string }) => {
    const input = inputRef.current;
    if (input) {
      const { selectionStart, selectionEnd } = input;
      const start = selectionStart ?? 0;
      const end = selectionEnd ?? start;
      const currentValue = input.value;
      const newValue =
        currentValue.slice(0, start) +
        emojiData.emoji +
        currentValue.slice(end);
      setGroupSubject(newValue);
      input.value = newValue;
      input.setSelectionRange(
        start + emojiData.emoji.length,
        start + emojiData.emoji.length
      );
      input.focus();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiRef.current &&
        !emojiRef.current.contains(event.target as Node)
      ) {
        setPreviewEmoji(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className=" h-100">
      <div className=" d-flex justify-content-center">
        <div className="new-group-icon d-flex justify-content-center align-items-center position-relative">
          <BsFillPeopleFill />
          <div
            title="Add group icon"
            className="add-group-icon d-flex justify-content-center align-items-center flex-column"
          >
            <IoCameraSharp size={24} color="var(--color-white)" />
            <span className=" fw-medium fs-13">Add group icon</span>
          </div>
          <div className=" file-input-group">
            <input type="file" name="myfile" />
          </div>
        </div>
      </div>
      <div className=" d-flex mt-5 justify-content-between flex-column">
        <div className=" d-flex position-relative align-items-center">
          <div className=" emoji-wrapper-group" ref={emojiRef}>
            {previewEmoji && (
              <Picker
                autoFocusSearch={false}
                searchDisabled
                lazyLoadEmojis={true}
                onEmojiClick={handleEmojiClick}
              />
            )}
          </div>
          <div className=" flex-grow-1">
            <input
              ref={inputRef}
              className="footer-input w-100"
              placeholder=" Enter group name "
              onChange={(e) => setGroupSubject(e?.target?.value)}
              onKeyDown={handleKeyDown}
              id="inputID"
              maxLength={30}
              autoFocus
            />
          </div>
          <span
            onClick={() => setPreviewEmoji(!previewEmoji)}
            className=" cursor-pointer ms-4"
          >
            <VscSmiley size={28} color="var(--secondary)" title="emoji" />
          </span>
        </div>
        {groupSubject.length >= 1 && (
          <div className=" d-flex justify-content-end mt-5">
            <CommonButton
              text="Create new group"
              type="submit"
              variant="primary"
              isDisabled={chatContext?.groupMembers.length > 0 ? false : true}
              className="text-white"
              onClick={() => {
                handleCreateGroup();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NewGroupPopup;
