import React from "react";
import { isMobile } from "react-device-detect";

const ToastQr = (props: any) => {
  return props.trigger ? (
    <>
      <div className="popup">
        <div className={`${isMobile ? "ToastQr p-4 m-3" : " ToastQr p-4"}`}>
          {props.children}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default ToastQr;
