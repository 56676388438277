import CreateNewBillBoard from "./CreateNewBillBoard";
import CreateBillBoardForm from "./CreateBillBoardForm";
import { useBillBoard } from "../../context/BillBoardProvider";
import UploadBillBoard from "./UploadBillBoard";
import { routes } from "../../constants/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import { useState } from "react";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import { useTranslation } from "react-i18next";
import NewConfigModal from "../masters/NewConfigModal";
import Modal from "../../components/PopupToast/Modal";
import NewBomForm from "../masters/BOM/bomForm";
import DownloadPopUp from "../../components/DownloadPopUp";

let timedPopupSetTimeOut: any = undefined;

const NewBillBoardNested = ({
  userContext,
  billBoardType,
  screenUnits,
}: any) => {
  const context = useBillBoard();
  const location = useLocation();
  const urlParams = useParams();
  const [textMessage, setTextMessage] = useState<any>("");
  const [showPopUp, setShowPopUp] = useState<any>(false);
  const [reasonPopUp, setReasonPopUp] = useState<any>(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [updateBillBoardDetail, setUpdatedBillBoardDetail] = useState<any>({});
  const [formikDetails, setFormikDetails] = useState<any>({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [headerMessage, setHeaderMessage] = useState<any>("");
  const [reasonValue, setReasonValue] = useState("");
  const [show, setShow] = useState(false);
  const pathName = window?.location?.pathname;

  const markAsNonOperational = async () => {
    const { success, error } =
      headerMessage === `${t("successError.mark the billboard operational")}`
        ? await context?.markAsOperational(urlParams?.billboardId)
        : await context?.markAsNonOperational(
            urlParams?.billboardId,
            reasonValue
          );
    setShowPopUp(false);
    setIsSuccess(success);
    if (success) {
      setButtonPopup(true);
      setBodyMessage(
        headerMessage === `${t("successError.mark the billboard operational")}`
          ? `${t("successError.Updated as billboard is operational")}`
          : `${t("successError.Updated as billboard is non-operational")}`
      );
      timeOutPopUp(true);
    } else {
      setButtonPopup(true);
      setBodyMessage(error);
      timeOutPopUp(false);
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        if (headerMessage === `${t("successError.cancel the changes")}`) {
          setButtonPopup(false);
        } else {
          navigate(
            routes.listBillBoardUrl(context?.billBoardQueryParamsDetails())
          );
        }
        setBodyMessage("");
      } else {
        setButtonPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const approveBillBoard = async () => {
    const { success, error } = await context?.approveBillBoard(
      urlParams?.billboardId
    );
    setIsSuccess(success);
    setShowPopUp(false);
    if (success) {
      setButtonPopup(true);
      setBodyMessage(
        `${t("successError.Approve as billboard is non-operational")}`
      );
      timeOutPopUp(true);
    } else {
      setButtonPopup(true);
      setBodyMessage(error);
      timeOutPopUp(false);
    }
  };

  const rejectBillBoard = async () => {
    const { success, error } = await context?.rejectBillBoard(
      urlParams?.billboardId
    );
    setIsSuccess(success);
    setShowPopUp(false);
    if (success) {
      setButtonPopup(true);
      setBodyMessage(
        `${t("successError.Reject as billboard is non-operational")}`
      );
      timeOutPopUp(true);
    } else {
      setButtonPopup(true);
      setBodyMessage(error);
      timeOutPopUp(false);
    }
  };

  const handleUpdateData = async (values: any) => {
    setShowPopUp(false);
    if (
      values?.installation_date?.length === 0 ||
      values?.installation_date === "Invalid date"
    ) {
      delete values?.installation_date;
    }
    if (
      values?.operational_date?.length === 0 ||
      values?.operational_date === "Invalid date"
    ) {
      delete values?.operational_date;
    }

    values = {
      ...values,
      screen_size: {
        width: parseFloat(values?.screen_width),
        height: parseFloat(values?.screen_height),
        units: values?.screen_units,
      },
      no_of_cabinets: values?.no_of_cabinets
        ? parseInt(values?.no_of_cabinets)
        : null,
      board_no: values.board_no,
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
    };
    delete values.screen_width;
    delete values.screen_height;
    delete values.screen_units;
    const { success, error } = urlParams.billboardId
      ? await context?.updateBillBoardDetail(urlParams.billboardId, values)
      : await context?.createBillBoardDetail(values);
    setIsSuccess(success);
    if (success) {
      timeOutPopUp(true);
      setButtonPopup(true);
      setBodyMessage(
        `${
          urlParams.billboardId
            ? `${t("successError.Billboard updated")}`
            : `${t("successError.Billboard created")}`
        }`
      );
    } else {
      setButtonPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const cancelForm = () => {
    formikDetails?.resetForm();
    setShowPopUp(false);
  };

  const updateStatus = () => {
    setReasonPopUp(false);
    return headerMessage ===
      `${t("successError.approve the billboard as non-operational")}`
      ? approveBillBoard()
      : headerMessage ===
        `${t("successError.reject the billboard as non-operational")}`
      ? rejectBillBoard()
      : headerMessage === `${t("successError.update the billboard")}`
      ? handleUpdateData(updateBillBoardDetail)
      : headerMessage ===
          `${t("successError.mark the billboard non-operational")}` ||
        headerMessage === `${t("successError.mark the billboard operational")}`
      ? markAsNonOperational()
      : headerMessage === `${t("successError.cancel the changes")}`
      ? cancelForm()
      : handleUpdateData(updateBillBoardDetail);
  };

  return (
    <>
      <CreateNewBillBoard
        userContext={userContext}
        context={context}
        setTextMessage={setTextMessage}
        setShowPopUp={setShowPopUp}
        setHeaderMessage={setHeaderMessage}
      >
        {((location.pathname?.includes("/new") &&
          location?.pathname?.includes("/billboards")) ||
          (location.pathname?.includes("/edit") &&
            location?.pathname?.includes("/billboards"))) && (
          <CreateBillBoardForm
            userContext={userContext}
            context={context}
            setTextMessage={setTextMessage}
            setShowPopUp={setShowPopUp}
            setUpdatedBillBoardDetail={setUpdatedBillBoardDetail}
            setFormikDetails={setFormikDetails}
            handleUpdateData={handleUpdateData}
            setHeaderMessage={setHeaderMessage}
            setBodyMessage={setBodyMessage}
            billBoardType={billBoardType}
            screenUnits={screenUnits}
            setShow={setShow}
          />
        )}
        {location.pathname?.includes("/upload_new") && (
          <UploadBillBoard context={context} />
        )}
        {location.pathname?.includes("/bom") &&
          (context?.billBoard?.bom?._id ? (
            <NewBomForm
              userContext={userContext}
              billboardContext={context}
              pathName={pathName}
            />
          ) : (
            <div className="d-flex justify-content-center mt-5">
              {t("masters.No Bom Selected for this billboard")}
            </div>
          ))}
      </CreateNewBillBoard>
      <ToastPopupSecond trigger={showPopUp} setTrigger={setShowPopUp}>
        <DeleteBlockToast
          closePopUp={() => setShowPopUp(false)}
          submit={() => {
            if (
              headerMessage ===
              `${t("successError.mark the billboard non-operational")}`
            ) {
              setShowPopUp(false);
              setReasonPopUp(true);
            } else {
              updateStatus();
            }
          }}
          textMessage={textMessage}
          headerMessage={headerMessage}
        />
      </ToastPopupSecond>
      <ToastPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
          // buttonName='Login Now!'
          footButton={false}
        />
      </ToastPopup>
      <Modal trigger={reasonPopUp} setTrigger={setReasonPopUp}>
        <NewConfigModal
          setTrigger={(status: boolean) => {
            setReasonPopUp(status);
          }}
          isReasonPop={true}
          setReasonValue={setReasonValue}
          reasonValue={reasonValue}
          updateStatus={updateStatus}
        />
      </Modal>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <DownloadPopUp
        show={show}
        setShow={setShow}
        userContext={userContext}
        billboardContext={context}
        pathName={pathName}
        isBom={true}
      />
    </>
  );
};

export default NewBillBoardNested;
