import React from "react";
import AppLayout from "../../layouts/AppLayout";
import { useTranslation } from "react-i18next";

const File502 = () => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  return (
    <AppLayout>
      <section className="File404" aria-labelledby="error-header">
        <div>
          <h4
            id="error-header"
            className="oops text-center text-uppercase fw-semibold m-0"
          >
            {t("404.oops")}
          </h4>

          <h1
            className={`d-flex justify-content-center ${
              isEnglish ? "font404" : "font404eorr"
            }`}
            aria-label="502 Error"
          >
            <span>5</span>
            <span>0</span>
            <span>2</span>
          </h1>

          <h4 className="sorry text-center text-uppercase fw-semibold m-0">
            {t("404.sorry")}
          </h4>
        </div>
      </section>
    </AppLayout>
  );
};

export default File502;
