import { get, getCookie } from "../api";
import { BASE_URL, filterQueryParams } from "../axiosInstance";

const getUser = async (
): Promise<any> => {
    try {
        const response = await get(
            `${BASE_URL}user`,
            getCookie("auth")
        );
        return response as any;
    } catch (e: any) {
        return {
            success: false,
            user: e?.user,
        };
    }
};

const getMembers = async (
    params:
        | any
        | undefined,
): Promise<any> => {
    try {
        const response = await get(
            `${BASE_URL}members?${filterQueryParams(params)}`,
            getCookie("auth")
        );
        return response as any;
    } catch (e: any) {
        return {
            success: false,
            user: e?.user,
        };
    }
};

const UserService = {
    getUser,
    getMembers
};

export default UserService;