import Picker from "emoji-picker-react";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { VscSmiley } from "react-icons/vsc";
import { ChatContextProp } from "../../../context/ChatProvider";
import ChatSendBtn from "../ChatPage/ChatSendBtn";

interface EditMessageProps {
  setEditMessagePopup: Function;
  editMessagePopup: boolean;
  editedMessage?: any;
  chatContext?: ChatContextProp | any;
}

const EditMessagePopup = ({
  editMessagePopup,
  setEditMessagePopup,
  chatContext,
  editedMessage,
}: EditMessageProps) => {
  const [messageEdit, setMessageEdit] = useState<any>(editedMessage?.Message);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (messageEdit !== editedMessage?.Message) {
        handleEditMessage();
        event.target.value = "";
      }
    }
  };
  useEffect(() => {
    if (inputRef.current && editMessagePopup) {
      const input = inputRef.current;
      input.focus();
      const length = input.value.length;
      input.setSelectionRange(length, length);
    }
  }, [editMessagePopup]);

  const handleEditMessage = () => {
    chatContext.messageEdit(editedMessage?.referenceId, {
      message: messageEdit,
    });
    setEditMessagePopup(false);
  };
  const handleEmojiClick = useCallback((emojiData: { emoji: string }) => {
    const input = inputRef.current;
    if (input) {
      const { selectionStart, selectionEnd } = input;
      const start = selectionStart ?? 0;
      const end = selectionEnd ?? start;
      const currentValue = input.value;
      const newValue =
        currentValue.slice(0, start) +
        emojiData.emoji +
        currentValue.slice(end);
      setMessageEdit(newValue);
      input.value = newValue;
      input.setSelectionRange(
        start + emojiData.emoji.length,
        start + emojiData.emoji.length
      );
      input.focus();
    }
  }, []);

  const editedMessageView =
    editedMessage?.Message?.length > 300
      ? editedMessage?.Message?.substring(0, 300) + " ....."
      : editedMessage?.Message;

  return (
    <Modal show={editMessagePopup} centered>
      <Modal.Body className="p-0 ">
        <div className=" d-flex align-items-center p-3 justify-content-between edit-message">
          <div>
            <h6 className=" mb-0 fw-medium">Edit Message</h6>
          </div>
          <div
            className=" cursor-pointer"
            onClick={() => {
              setEditMessagePopup(false);
            }}
            title="close the Edit message"
          >
            <RxCross2 fontSize={24} color="var(--secondary)" />
          </div>
        </div>
        <div className="bg-white p-3">
          <div className=" edit-message-bg p-3 rounded-2">
            {editedMessageView}
            <div className="fs-12 pt-3 text-end">
              {moment(editedMessage?.createdAt).format("LT")}
              {/* {editedMessage.from.id === userContext?.user.id && (
                <div
                  className={` ms-2 ${
                    editedMessage?.seen[opponentId] === null
                      ? "text-white"
                      : "read-message"
                  }`}
                >
                  {editedMessage?.seen[opponentId] === null ? (
                    <IoCheckmarkDoneSharp size={16} title="sent" />
                  ) : (
                    <IoCheckmarkDoneSharp size={16} title="seen" />
                  )}
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className=" d-flex p-3 edit-message-snd gap-2 position-relative align-items-center">
          <div className=" emoji-wrapper-edit">
            {previewEmoji && (
              <Picker
                autoFocusSearch={false}
                searchDisabled
                lazyLoadEmojis={true}
                onEmojiClick={handleEmojiClick}
              />
            )}
          </div>
          <div className=" flex-grow-1">
            <textarea
              ref={inputRef}
              className="edit-input w-100"
              placeholder="Type a message"
              onChange={(e) => setMessageEdit(e?.target?.value)}
              onKeyDown={handleKeyDown}
              value={messageEdit}
              autoFocus
              rows={
                messageEdit.length > 246 ? 3 : messageEdit.length > 123 ? 2 : 1
              }
            />
          </div>
          <span
            onClick={() => setPreviewEmoji(!previewEmoji)}
            className=" cursor-pointer"
          >
            <VscSmiley size={24} color="var(--secondary)" title="emoji" />
          </span>

          <span
            className=" cursor-pointer send-btn"
            title="Send edit message"
            onClick={() => handleEditMessage()}
          >
            {inputRef?.current &&
              inputRef?.current.value.trim() &&
              messageEdit !== editedMessage?.Message && (
                <ChatSendBtn size="35px" />
              )}
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditMessagePopup;
