import AppLayout from "../../layouts/AppLayout";
import {
  NavLink,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { isDesktop, isMobile } from "react-device-detect";
import CreateNewTicketForm from "./CreateNewTicketForm";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { useTicket } from "../../context/TicketProvider";
import ProgressBar from "../BillBoards/ProgressBar/ProgressBar";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ActivityFeed from "../ActivityFeed";

const CreateOrViewTicket = ({ userContext }: any) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const urlParams = useParams();
  const context = useTicket();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  return (
    <AppLayout>
      <div
        className="edit_members p-3 "
        style={!isDesktop ? {} : { background: "#FAFAFA", height: "100%" }}
      >
        <div className="members py-4 mt-2 d-flex align-items-center ">
          <h5
            className={`${
              isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
            }`}
            onClick={() =>
              navigate(
                searchParams?.get("name") && searchParams?.get("id")
                  ? routes.editCleaningUrl(searchParams?.get("id"), {
                      status: searchParams?.get("status"),
                    })
                  : routes.listTicketsUrl(context?.ticketQueryParamsDetails())
              )
            }
          >
            {t("CreateOrViewTicket.Tickets")}
          </h5>
          <>
            <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
              <MdOutlineArrowForwardIos />
            </span>
            <span className={i18n.language === "en" ? "d-none" : "px-2"}>
              <MdArrowBackIos />
            </span>
            <h5
              className={`${isMobile ? "fs-6 text-capitalize m-0 " : " m-0"}`}
            >
              {urlParams?.ticketId
                ? context?.ticket?.ticket_no
                : `${t("new_tickets.New_ticket")}`}
            </h5>
          </>
        </div>
        <div
          style={!isMobile ? { background: "#fefefe" } : {}}
          className={`${isDesktop ? "p-5 mt-4 rounded-1 " : " p-0"}`}
        >
          {urlParams?.ticketId && (
            <div
              className={`${
                isMobile ? "container-fluid p-0 " : "container p-0"
              }`}
            >
              <div
                className={`${
                  isMobile ? " overflow-scroll" : " overflow-scroll"
                }`}
              >
                <ul className="d-flex nestedul">
                  <li className=" m-0">
                    <NavLink
                      to={routes.getEditTicketUrl(
                        urlParams?.ticketId,
                        context?.ticketQueryParamsDetails()
                      )}
                      className="text-decoration-none  "
                    >
                      <span
                        className={`edit_link_routes ${
                          window.location.pathname.includes("/edit") && "active"
                        }`}
                      >
                        {t("CreateOrViewTicket.View_Ticket")}
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="
                  mx-4"
                  >
                    <NavLink
                      to={routes.getActivityTicketUrl(
                        urlParams?.ticketId,
                        context?.ticketQueryParamsDetails()
                      )}
                      className="text-decoration-none "
                    >
                      <span
                        className={`edit_link_routes  ${
                          window.location.pathname.includes("/activity") &&
                          "active"
                        }`}
                      >
                        {t("CreateOrViewTicket.Ticket_Activity")}
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {(location.pathname.includes("/new") ||
            location.pathname.includes("/edit")) && (
            <>
              {location.pathname.includes("/edit") &&
                context?.ticket &&
                context?.ticket.status !== "CLOSED" && (
                  <div className="pb-2 pt-5">
                    <ProgressBar context={context} />
                  </div>
                )}
              {
                <CreateNewTicketForm
                  context={context}
                  userContext={userContext}
                />
              }
            </>
          )}
          {location.pathname.includes("/activity") && (
            <ActivityFeed context={context} />
          )}
        </div>
        <ToastPopup
          trigger={context?.isLoading}
          setTrigger={context?.setIsLoading}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
      </div>
    </AppLayout>
  );
};

export default CreateOrViewTicket;
