import { useRef, useEffect } from "react";
import { IoArrowBackSharp, IoSearchSharp } from "react-icons/io5";
import "./SearchField.scss";

type SearchFieldProps = {
  placeholder?: string;
  onSearch?: (query: string) => void;
  setSearchQuery?: (query: string) => void;
  searchQuery?: string;
  modalPadding?: boolean;
};

export default function SearchField({
  placeholder,
  onSearch,
  setSearchQuery,
  searchQuery,
  modalPadding,
}: SearchFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onSearch) {
      onSearch(searchQuery || "");
    }
  };

  return (
    <div
      className={` search-wrapper  ${
        modalPadding ? "" : "px-3 search-wrapper-height"
      }`}
    >
      <div
        className={`search-icon ${
          modalPadding ? "search-modal-left" : "search-list-left"
        }`}
      >
        {searchQuery && searchQuery.length > 0 ? (
          <IoArrowBackSharp
            fontSize={24}
            color="var(--primary)"
            title="Back to list"
            onClick={() => setSearchQuery && setSearchQuery("")}
          />
        ) : (
          <IoSearchSharp size={24} title="Search" color="var(--primary)" />
        )}
      </div>
      <input
        ref={inputRef}
        placeholder={placeholder}
        className="input-list-search"
        value={searchQuery}
        onChange={(e) => setSearchQuery && setSearchQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
