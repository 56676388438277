import IncomingCall from "./components/IncomingCall/IncomingCall";
import { useChatContext } from "../../context/ChatProvider";
import AudioAndVideo from "./AudioAndVideo/AudioAndVideo";

const AudioVideoCallModal = () => {
  const context = useChatContext();
  return (
    <div>
      {context?.callRequested && <IncomingCall />}
      {context?.isCallAccepted && <AudioAndVideo />}
    </div>
  );
};

export default AudioVideoCallModal;
