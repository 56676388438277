import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { NavLink, useNavigate } from "react-router-dom";
import avatar from "../../assets/avatarprofile.png";
import deleteButton from "../../assets/delete.png";
import edit from "../../assets/edit.png";
import edityellow from "../../assets/edity.png";
import recent from "../../assets/recent.png";
import CommonButton from "../../components/CommonButton/CommonButton";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import CommonFilter from "../../components/CommonFilter/CommonFilter";
import CommonSearch from "../../components/CommonSearch/CommonSearch";
import CommonSort from "../../components/CommonSort/CommonSort";
import { CommonStatusButton } from "../../components/CommonStatusButton/CommonStatusButton";
import CommonToggleSwitch from "../../components/CommonToggleSwitch/CommonToggleSwitch";
import DownloadPopUp from "../../components/DownloadPopUp";
import CommonLoadingBar from "../../components/LoadingBar/CommonLoadingBar";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import { routes } from "../../constants/routes";
import { useTicket } from "../../context/TicketProvider";
import AppLayout from "../../layouts/AppLayout";
import { api, convertExcel, convertPdf, updateText } from "../../utils/api";
import Pagination from "../../components/Pagination";
import CommonAvatar from "../../components/Avatar/CommonAvatar";

let timedPopupSetTimeOut: any = undefined;

const TicketOverView = ({ userContext }: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const context = useTicket();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [timedPopup, setTimedPopup] = useState(false);
  const [sort, setSort] = useState<any>(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );
  const [role, setRole] = useState<any>(
    queryParams.get("role") ? queryParams.get("role") : ""
  );
  const [search, setSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [status, setStatus] = useState<any>(
    queryParams.get("status") ? queryParams.get("status")?.split(",") : ["All"]
  );
  const [department, setDepartment] = useState<any>(
    queryParams.get("department") ? queryParams.get("department") : ""
  );
  const [billBoardId, setBillBoardId] = useState<any>(
    queryParams.get("bill_board_id") ? queryParams.get("bill_board_id") : ""
  );
  const [billBoardNum, setBillBoardNum] = useState(
    queryParams.get("billBoardNum") ? queryParams.get("billBoardNum") : ""
  );
  const [assigneeId, setAssigneId] = useState(
    queryParams.get("assignee") ? queryParams.get("assignee") : ""
  );
  const [assignee, setAssigne] = useState<any>(
    queryParams.get("assigneeName") ? queryParams.get("assigneeName") : ""
  );
  const [city, setCity] = useState<any>(
    queryParams.get("city") ? queryParams.get("city") : ""
  );
  const [isCleaning, setisCleaning] = useState<any>(
    queryParams.get("is_cleaning")
      ? queryParams.get("is_cleaning") === "true"
      : ""
  );

  const [groupName, setGroupName] = useState<any>(
    queryParams.get("groupName") ? queryParams.get("groupName") : ""
  );
  const [groupID, setGroupID] = useState<any>(
    queryParams.get("cleaning") ? queryParams.get("cleaning") : ""
  );
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(
    queryParams.get("page") ? queryParams.get("page") : 1
  );
  const [billBoardList, setBillBoardList] = useState<any>([]);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [groupNameList, setGroupNameList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [isDeletePopUp, setisDeletePopUp] = useState(false);
  const [deleteText, setDeleteText] = useState<any>("");
  const [ticketId, setTicketId] = useState<any>("");
  const [headerMessage, setHeaderMessage] = useState<any>("");
  const [ticketsDetails, setTicketsDetails] = useState<any>();

  const pdfRef = useRef<any>(null);
  useEffect(() => {
    const loadDataInterval = () => {
      loadData();
      loadTicketsDetails();
    };
    loadDataInterval();
    const intervalId = setInterval(loadDataInterval, 3 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [search, sort, currentPage]);

  const loadTicketsDetails = async () => {
    const { data } = await api.ticketsDetails({ is_cleaning: isCleaning });
    setTicketsDetails(data);
    return { data };
  };

  useEffect(() => {
    const loadBillBoard = async () => {
      const { success, billboards } = await context?.loadBillBoards({
        status: "ACTIVE",
        sort: "board_no",
      });
      if (success) {
        let res = billboards?.map((d: any) => {
          return {
            label: d.board_no,
            value: d.id,
          };
        });
        setBillBoardList(res);
      }
      return { success, billboards };
    };
    const loadMember = async () => {
      const { success, user } = await context?.loadMembers({
        status: "ACTIVE",
        sort: "name",
      });
      if (success) {
        let res = user?.map((d: any) => {
          return {
            label: d.name,
            value: d.id,
          };
        });
        setAssigneeList(res);
      }
      return { success, user };
    };

    const loadGroupName = async () => {
      const { success, cleanings } = await context?.loadCleaningsList({
        status: "ACTIVE",
        sort: "-createdAt",
      });
      if (success) {
        let res = cleanings
          ?.map((d: any) =>
            d?.group_name
              ? {
                  label: d?.group_name,
                  value: d?.id,
                }
              : null
          )
          .filter((item: any) => item !== null);

        setGroupNameList(res);
      }
      return { success, cleanings };
    };

    const cityData = async () => {
      const data = await context?.cityList({ city: "" });
      if (data?.length) {
        let res = data?.map((d: any) => {
          return {
            label: d.city,
            value: d.city,
          };
        });
        setCityList(res);
      }
    };
    cityData();
    loadMember();
    loadBillBoard();
    loadGroupName();
  }, []);

  const queryParamsDetails = () => {
    let params: any = {
      search,
      sort: status?.includes("PENDING") ? "end_date" : sort,
      role,
      status: status?.includes("All") ? "" : status,
      department,
      bill_board_id: billBoardId,
      assignee: assigneeId,
      limit: 10,
      page: currentPage,
      assigneeName: assignee,
      city: city,
      billBoardNum: billBoardNum,
      is_cleaning: isCleaning,
      cleaning: isCleaning ? groupID : "",
      groupName: isCleaning ? groupName : "",
    };
    return params;
  };

  const loadData = async () => {
    await context?.loadTickets(queryParamsDetails());
    navigate(routes.OverViewTicketsUrl(queryParamsDetails()));
  };

  const download = async (type: string) => {
    switch (type) {
      case "excel":
        const res = await context?.downloadExcel(queryParamsDetails());
        convertExcel(res, "Ticket_List");
        break;
      case "pdf":
        const pdfRes = await context?.downloadPdf(queryParamsDetails());
        convertPdf(pdfRes, "Ticket_List");
        break;
      default:
        break;
    }
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const applyFilter = () => {
    setCurrentPage(1);
    loadData();
    loadTicketsDetails();
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const resetFilter = () => {
    setRole("");
    setCurrentPage(1);
    setStatus(["All"]);
    setDepartment("");
    setBillBoardId("");
    setAssigneId("");
    setAssigne("");
    setBillBoardNum("");
    setCity("");
    setOpenSort(false);
    setisCleaning(false);
    setOpenfilter(!openfilter);
    setGroupName("");
    setGroupID("");
  };

  const filterByOpen = () => {
    setSort(queryParams.get("sort") ? queryParams.get("sort") : "-createdAt");
    setRole(queryParams.get("role") ? queryParams.get("role") : "");
    setSearch(queryParams.get("search") ? queryParams.get("search") : "");
    setStatus(
      queryParams.get("status")
        ? queryParams.get("status")?.split(",")
        : ["All"]
    );
    setDepartment(
      queryParams.get("department") ? queryParams.get("department") : ""
    );
    setBillBoardId(
      queryParams.get("bill_board_id") ? queryParams.get("bill_board_id") : ""
    );
    setBillBoardNum(
      queryParams.get("billBoardNum") ? queryParams.get("billBoardNum") : ""
    );
    setAssigneId(
      queryParams.get("assignee") ? queryParams.get("assignee") : ""
    );
    setAssigne(
      queryParams.get("assigneeName") ? queryParams.get("assigneeName") : ""
    );
    setCurrentPage(queryParams.get("page") ? queryParams.get("page") : 1);
    setCity(queryParams.get("city") ? queryParams.get("city") : "");
    setisCleaning(
      queryParams.get("is_cleaning")
        ? queryParams.get("is_cleaning") === "true"
        : ""
    );
    setGroupName(
      queryParams.get("groupName") ? queryParams.get("groupName") : ""
    );
    setGroupID(queryParams.get("cleaning") ? queryParams.get("cleaning") : "");
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  const timeOutPopUp = (value: any, data?: string) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (data === "reload") {
        loadData();
      }
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        filterByOpen();
      }
    };
    window.addEventListener("keyup", close);
    return () => window.removeEventListener("keyup", close);
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        sortByOpen();
      }
    };
    window.addEventListener("sortDropDown", close);
    return () => window.removeEventListener("sortDropDown", close);
  }, []);

  const deleteTicket = async () => {
    setisDeletePopUp(false);
    const res = await context?.deleteTicket(ticketId);
    setIsSuccess(res?.success);
    if (res?.success) {
      timeOutPopUp(true, "reload");
      setTimedPopup(true);
      setBodyMessage(`${t("successError.Ticket Deleted")}`);
    } else {
      timeOutPopUp(false);
      setTimedPopup(true);
      setBodyMessage(res?.error);
    }
  };

  const openDeleteTicket = (id: any, value: any, cancelText: any) => {
    setTicketId(id);
    setisDeletePopUp(true);
    setDeleteText(cancelText);
    setHeaderMessage(value);
  };

  const getStatusBasedDate = (ticket: any) => {
    switch (ticket?.status) {
      case "CREATED":
        return ticket?.createdAt || "-";
      case "VIEWED":
        return ticket?.viewed_on || "-";
      case "SUBMITTED":
        return ticket?.submitted_at || "-";
      case "VERIFIED":
        return ticket?.approved_on_supervisor || "-";
      // case "APPROVED":
      //   return ticket?.approved_on || "-";
      // case "REJECTED":
      //   return ticket?.rejected_at || "-";
      case "CLOSED":
        return ticket?.approved_on || ticket?.rejected_at || "-";
      case "OVERDUE":
        return ticket?.overdue || "-";
      case "REASSIGNED":
        return ticket?.reassigned_on || "-";
      default:
        return "-";
    }
  };
  return (
    <div className="p-5">
      <div>
        <div className="pt-4 d-flex align-items-center justify-content-between ">
          {context?.isLoading && <CommonLoadingBar />}
          <div className="members ">
            <h5 className=" mb-0 ">
              {t("ListTickets.All_Tickets")} (
              {context?.meta ? context?.meta?.total_count : 0})
            </h5>
          </div>
          <div className="container-fluid p-0 forinput">
            <CommonSearch
              placeholder={t("ListTickets.search") || ""}
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) =>
                e.target.value.length === 0 && setSearch(e.target.value)
              }
            />
          </div>

          <div className="d-flex  align-items-center justify-content-end">
            <CommonSort
              sort={sort}
              opensort={opensort}
              opensortClick={sortByOpen}
              setCurrentPage={setCurrentPage}
              setSort={setSort}
              sortAtoZ="ticket_no"
              sortZtoA="-ticket_no"
            />

            <CommonFilter
              openfilterClick={filterByOpen}
              isFilterActive={
                status?.length > 0 ||
                department?.length > 0 ||
                billBoardId?.length > 0 ||
                assignee.length > 0
              }
              openfilter={openfilter}
              resetClick={resetFilter}
              filterClose={filterByOpen}
              filterApply={applyFilter}
              filterDisabled={
                !(
                  status?.length > 0 ||
                  department?.length > 0 ||
                  billBoardId?.length > 0 ||
                  assignee.length > 0
                )
              }
            >
              <Dropdownfilter
                setRole={setRole}
                role={role}
                status={status}
                setStatus={setStatus}
                setDepartment={setDepartment}
                department={department}
                setBillBoardId={setBillBoardId}
                setBillBoardNum={setBillBoardNum}
                setAssigneId={setAssigneId}
                billBoardList={billBoardList}
                assigneeList={assigneeList}
                setAssigne={setAssigne}
                assigneeId={assigneeId}
                assignee={assignee}
                billBoardNum={billBoardNum}
                billBoardId={billBoardId}
                context={context}
                userContext={userContext}
                cityList={cityList}
                city={city}
                setCity={setCity}
                setisCleaning={setisCleaning}
                isCleaning={isCleaning}
                groupNameList={groupNameList}
                setGroupName={setGroupName}
                setGroupID={setGroupID}
                groupID={groupID}
                groupName={groupName}
              />
            </CommonFilter>

            <div>
              <CommonButton
                text={t("listmember.Download")}
                variant="outline-secondary"
                className=" bg-transparent text-secondary btn-i18n"
                onClick={() => setShow(true)}
              />
            </div>
            {/* {userContext?.roleAccess["TICKETS"].includes("WRITE") && (
              <NavLink
                to={routes.getNewTicketUrl(context?.ticketQueryParamsDetails())}
              >
                <CommonButton
                  text={t("new_tickets.New_ticket")}
                  variant="success"
                  className=" btn-i18n text-white"
                />
              </NavLink>
            )} */}
          </div>
        </div>
        <div className="d-none">
          <div className="d-none">
            <div className="members">
              <h5 className="mb-3">
                {t("ListTickets.All_Tickets")} (
                {context?.meta ? context?.meta?.total_count : 0})
              </h5>
            </div>
            <div className="d-flex">
              {/* -------------------------------------------------For tablet---------------------------- */}
              <div className="d-none">
                <CommonSort
                  sort={sort}
                  opensort={opensort}
                  opensortClick={sortByOpen}
                  setCurrentPage={setCurrentPage}
                  setSort={setSort}
                  sortAtoZ="ticket_no"
                  sortZtoA="-ticket_no"
                />

                <CommonFilter
                  openfilterClick={filterByOpen}
                  isFilterActive={
                    status?.length > 0 ||
                    department?.length > 0 ||
                    billBoardId?.length > 0 ||
                    assignee.length > 0
                  }
                  openfilter={openfilter}
                  resetClick={resetFilter}
                  filterClose={filterByOpen}
                  filterApply={applyFilter}
                  filterDisabled={
                    !(
                      status?.length > 0 ||
                      department?.length > 0 ||
                      billBoardId?.length > 0 ||
                      assignee.length > 0
                    )
                  }
                >
                  <Dropdownfilter
                    setRole={setRole}
                    role={role}
                    status={status}
                    setStatus={setStatus}
                    setDepartment={setDepartment}
                    department={department}
                    setBillBoardId={setBillBoardId}
                    setBillBoardNum={setBillBoardNum}
                    setAssigneId={setAssigneId}
                    billBoardList={billBoardList}
                    assigneeList={assigneeList}
                    setAssigne={setAssigne}
                    assigneeId={assigneeId}
                    assignee={assignee}
                    billBoardNum={billBoardNum}
                    billBoardId={billBoardId}
                    context={context}
                    userContext={userContext}
                    cityList={cityList}
                    city={city}
                    setCity={setCity}
                    setisCleaning={setisCleaning}
                    isCleaning={isCleaning}
                    groupNameList={groupNameList}
                    setGroupName={setGroupName}
                    setGroupID={setGroupID}
                    groupID={groupID}
                    groupName={groupName}
                  />
                </CommonFilter>
              </div>
              <div>
                <CommonButton
                  text={t("listmember.Download")}
                  variant="outline-secondary"
                  className=" bg-transparent text-secondary btn-i18n"
                  onClick={() => setShow(true)}
                />
              </div>
              {/* {userContext?.roleAccess["TICKETS"].includes("WRITE") && (
                <div className="d-flex align-items-center ">
                  <NavLink
                    to={routes.getNewTicketUrl(
                      context?.ticketQueryParamsDetails()
                    )}
                  >
                    <CommonButton
                      text={t("new_tickets.New_ticket")}
                      variant="success"
                      className=" btn-i18n text-white"
                    />
                  </NavLink>
                </div>
              )} */}
            </div>
          </div>
          <div className="pt-5 position-relative">
            <CommonSearch
              placeholder={t("ListTickets.search") || ""}
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) => {
                e.target.value.length === 0 && setSearch(e.target.value);
                if (isMobile) {
                  setMobileSearch(e.target.value);
                }
              }}
            />
            <Button
              className={
                i18n.language === "en"
                  ? "mobile-search-btn"
                  : "mobile-search-btn-right"
              }
              onClick={() =>
                handleEnterKey({
                  key: "Enter",
                  target: { value: mobileSearch },
                })
              }
            >
              {t("chat.Search")}
            </Button>
          </div>
        </div>

        <div className="TicketCard p-4 mt-5">
          {/* <h6 className=" fw-semibold mb-4"> Statistics</h6> */}
          <div className="d-md-flex flex-wrap align-items-center gap-2 justify-content-between">
            <div className=" d-flex align-items-center d-md-block gap-2 justify-content-between ">
              <h6 className="total fw-medium"> Created</h6>
              <h3 className=" fw-bold text-center">
                {ticketsDetails?.created}
              </h3>
            </div>
            <div className=" d-flex align-items-center d-md-block gap-2 justify-content-between ">
              <h6 className="total fw-medium"> Viewed</h6>
              <h3 className=" fw-bold text-center">{ticketsDetails?.viewed}</h3>
            </div>
            <div className=" d-flex align-items-center d-md-block gap-2 justify-content-between ">
              <h6 className="total fw-medium"> Submitted</h6>
              <h3 className=" fw-bold text-center">
                {ticketsDetails?.submitted}
              </h3>
            </div>
            <div className=" d-flex align-items-center d-md-block gap-2 justify-content-between ">
              <h6 className="total fw-medium"> Verified</h6>
              <h3 className=" fw-bold text-center">
                {ticketsDetails?.verfied}
              </h3>
            </div>

            <div className="d-flex align-items-center d-md-block gap-2 justify-content-between">
              <h6 className="total fw-medium"> Reassigned</h6>
              <h3 className=" fw-bold text-center">
                {ticketsDetails?.reassign}
              </h3>
            </div>

            <div className=" d-flex align-items-center d-md-block gap-2 justify-content-between">
              <h6 className="total fw-medium"> Closed</h6>
              <h3 className=" fw-bold text-center">
                {ticketsDetails?.approve + ticketsDetails?.rejected || 0}
              </h3>
            </div>
            {/* <div className=" d-flex align-items-center d-md-block gap-2 justify-content-between ">
                <h6 className="total fw-medium"> Rejected</h6>
                <h3 className=" fw-bold text-center">
                  {ticketsDetails?.rejected}
                </h3>
              </div> */}

            <div className=" d-flex align-items-center d-md-block gap-2 justify-content-between ">
              <h6 className="total fw-medium"> Overdue</h6>
              <h3 className=" fw-bold text-center">
                {ticketsDetails?.overdue}
              </h3>
            </div>
          </div>
        </div>

        <div className=" pt-4 table-responsive" ref={pdfRef}>
          <Table responsive="sm table_class">
            <thead>
              <tr className="table_white_head">
                <th
                  className={`${
                    isMobile ? "" : "  d-flex justify-content-center py-5"
                  }`}
                >
                  <div className="memberimageshape">
                    <img src={avatar} alt="logo" />
                  </div>
                </th>
                <th className={`${isMobileOnly ? " py-4" : " "}`}>
                  {t("ListTickets.Ticket_Number")}
                  <h6 className=" pt-2"> {t("ListTickets.Assignee")}</h6>
                </th>
                <th className={`${isMobileOnly ? " py-4 d-none" : " "}`}>
                  {queryParams.get("is_cleaning") === "true"
                    ? `${t("cleaning.GroupName")}`
                    : `${t("cleaning.Created By")}`}

                  <h6 className=" pt-2">{t("cleaning.Ticket Title")}</h6>
                </th>
                <th className={`${isMobile ? "d-none " : " "}`}>
                  {t("ListTickets.BillBoard_Number")}
                  <h6 className=" pt-2"> {t("ListTickets.Location")}</h6>
                </th>
                <th className={`${isMobile ? "d-none " : " "}`}>
                  {t("ListTickets.Department")}
                  <h6 className=" pt-2">{t("ListTickets.Priority")}</h6>
                </th>
                <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                  {t("ListTickets.start_Date")}
                  <h6 className=" pt-2">{t("ListTickets.End_Date")}</h6>
                </th>
                <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                  {t("ListTickets.Status")}
                  <h6 className=" pt-2">{t("ListTickets.Date")}</h6>
                </th>
                <th
                  className={`${
                    isMobileOnly ? "  d-none" : "py-5 text-center"
                  }`}
                >
                  {/* {t("ListTickets.Action")}
                  <h6 className=" pt-2">&nbsp;</h6> */}
                </th>
              </tr>
            </thead>
            {context?.tickets && context?.tickets?.length > 0 && (
              <tbody className="table_white">
                {context?.tickets?.map((ticket: any, index: number) => {
                  return (
                    <tr key={ticket.id} className="table_white">
                      <td
                        className={`${
                          isMobile ? "" : "  d-flex justify-content-center py-5"
                        }`}
                      >
                        <div className=" position-relative memberimageshape">
                          {ticket?.assignee?.avatar !== null &&
                          ticket?.assignee?.avatar !== undefined ? (
                            <>
                              <img
                                src={ticket?.assignee?.avatar}
                                alt="logo"
                                width={50}
                              />

                              <div
                                className={
                                  i18n.language === "en"
                                    ? `${
                                        isMobileOnly
                                          ? `${
                                              ticket.priority === "ACTIVE"
                                                ? "img_circle_active "
                                                : ticket.priority === "PENDING"
                                                ? "img_circle_pending"
                                                : "img_circle_inactive"
                                            }`
                                          : ""
                                      }`
                                    : `${
                                        isMobileOnly
                                          ? `${
                                              ticket.priority === "ACTIVE"
                                                ? "img_circle_activeArabic "
                                                : ticket.priority === "PENDING"
                                                ? "img_circle_pendingArabic"
                                                : "img_circle_inactiveArabic"
                                            }`
                                          : ""
                                      }`
                                }
                              ></div>
                            </>
                          ) : (
                            <>
                              <CommonAvatar
                                name={ticket?.assignee?.name?.toUpperCase()}
                                size={(isMobileOnly ? 50 : 50).toString()}
                                style={{
                                  backgroundColor: "#6b5ca4",
                                }}
                              />
                              <div
                                className={
                                  i18n.language === "en"
                                    ? `${
                                        isMobileOnly
                                          ? `${
                                              ticket.priority === "ACTIVE"
                                                ? "img_circle_active "
                                                : ticket.priority === "PENDING"
                                                ? "img_circle_pending"
                                                : "img_circle_inactive"
                                            }`
                                          : ""
                                      }`
                                    : `${
                                        isMobileOnly
                                          ? `${
                                              ticket.priority === "ACTIVE"
                                                ? "img_circle_activeArabic "
                                                : ticket.priority === "PENDING"
                                                ? "img_circle_pendingArabic"
                                                : "img_circle_inactiveArabic"
                                            }`
                                          : ""
                                      }`
                                }
                              ></div>
                            </>
                          )}
                        </div>
                      </td>
                      <td
                        className={`${
                          isMobile
                            ? " py-4 d-flex justify-content-between align-items-center"
                            : " "
                        }`}
                      >
                        <div>
                          {ticket.ticket_no || "-"}
                          <h6 className=" pt-2">
                            {ticket?.assignee?.name || "-"}
                          </h6>
                        </div>
                        {userContext?.roleAccess["TICKETS"].includes(
                          "UPDATE"
                        ) && (
                          <td
                            className={`${isMobileOnly ? "   " : " d-none  "}`}
                          >
                            <div className="text-decoration-none ">
                              <div
                                onClick={() => {
                                  context?.removeTicket(null);
                                  navigate(
                                    routes.getEditTicketUrl(
                                      ticket?.id || ticket?._id,
                                      context?.ticketQueryParamsDetails()
                                    )
                                  );
                                }}
                              >
                                <img
                                  src={
                                    (ticket.status === "VIEWED" ||
                                      ticket.status === "CREATED") &&
                                    moment(new Date())
                                      .add(1, "day")
                                      .format("DD/MM/yyyy") ===
                                      moment(ticket.end_date).format(
                                        "DD/MM/yyyy"
                                      )
                                      ? recent
                                      : ticket.status !== "CLOSED"
                                      ? edit
                                      : edityellow
                                  }
                                  alt=""
                                  width={22}
                                  className="pointer"
                                />
                              </div>
                            </div>
                          </td>
                        )}
                        {userContext?.roleAccess["TICKETS"].includes(
                          "DELETE"
                        ) && (
                          <td
                            className={`${isMobileOnly ? "   " : " d-none  "}`}
                          >
                            <div className="text-decoration-none ">
                              <div
                                onClick={() =>
                                  openDeleteTicket(
                                    ticket?.id || ticket?._id,
                                    `${t("successError.delete this ticket")}`,
                                    `${t("successError.delete it")}`
                                  )
                                }
                              >
                                <img
                                  src={deleteButton}
                                  alt=""
                                  width={22}
                                  className="pointer"
                                />
                              </div>
                            </div>
                          </td>
                        )}
                      </td>
                      <td className={`${isMobile ? "d-none " : " "}`}>
                        <div>
                          {queryParams.get("is_cleaning") === "true"
                            ? `${ticket?.cleaning?.group_name || "-"}`
                            : `${ticket?.created_by?.name || "-"}`}

                          <h6 className=" pt-2">
                            {ticket?.title.toLowerCase() || "-"}
                          </h6>
                        </div>
                      </td>
                      <td className={`${isMobile ? "d-none " : " "}`}>
                        {ticket?.bill_board_id?.board_no || "-"}
                        <h6 className=" pt-2">
                          {ticket?.bill_board_id?.board_location?.length > 25
                            ? ticket?.bill_board_id?.board_location?.slice(
                                0,
                                25
                              ) + "..."
                            : ticket?.bill_board_id?.board_location || "-"}
                        </h6>
                      </td>
                      <td className={`${isMobile ? "d-none " : " "}`}>
                        <div
                          className={
                            i18n.language === "en"
                              ? "dateandtime"
                              : "dateandtimeArabic"
                          }
                        >
                          {ticket.department.replace("_", " ")}
                        </div>

                        <h6 className=" pt-2">
                          <CommonStatusButton
                            pillText={ticket.priority}
                            isPill
                          />
                        </h6>
                      </td>
                      <td className={`${isMobile ? "d-none " : " "}`}>
                        <Moment format="DD/MM/yyyy" className=" text-dark">
                          {ticket.start_date || "-"}
                        </Moment>

                        <h6 className=" pt-2">
                          <Moment format="DD/MM/yyyy">
                            {ticket.end_date || "-"}
                          </Moment>
                        </h6>
                      </td>
                      <td
                        className={`${
                          isMobileOnly ? "d-none " : "text-center "
                        }`}
                      >
                        <CommonStatusButton pillText={ticket.status} isPill />
                        <h6 className=" pt-2">
                          {ticket.status === "OVERDUE" ? (
                            <h4 className=" text-center">-</h4>
                          ) : (
                            <Moment format="DD/MM/yyyy">
                              {getStatusBasedDate(ticket || "-")}
                            </Moment>
                          )}
                        </h6>
                      </td>

                      <td
                        className={`${
                          isMobileOnly ? "  py-5  d-none" : " py-5  d-flex "
                        }`}
                      >
                        {userContext?.roleAccess["TICKETS"].includes(
                          "UPDATE"
                        ) && (
                          <div className="text-decoration-none d-flex justify-content-evenly align-items-center">
                            <div
                              onClick={() => {
                                context?.removeTicket(null);
                                navigate(
                                  routes.getEditTicketUrl(
                                    ticket?.id || ticket?._id,
                                    context?.ticketQueryParamsDetails()
                                  )
                                );
                              }}
                            >
                              {/* <img
                                src={
                                  (ticket.status === "VIEWED" ||
                                    ticket.status === "CREATED") &&
                                  ticket?.status === "OVERDUE"
                                    ? recent
                                    : ticket.status !== "CLOSED"
                                    ? edit
                                    : edityellow
                                }
                                alt=""
                                width={22}
                                className="pointer"
                              /> */}
                            </div>
                          </div>
                        )}
                        {userContext?.roleAccess["TICKETS"].includes(
                          "DELETE"
                        ) &&
                          (ticket.status === "VIEWED" ||
                            ticket.status === "CREATED") && (
                            <div className="text-decoration-none d-flex justify-content-evenly align-items-center ms-2">
                              <div
                                onClick={() =>
                                  openDeleteTicket(
                                    ticket?.id || ticket?._id,
                                    `${t("successError.delete this ticket")}`,
                                    `${t("successError.delete it")}`
                                  )
                                }
                              >
                                <img src={deleteButton} alt="" width={22} />
                              </div>
                            </div>
                          )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
          {!context?.tickets?.length && (
            <div className=" d-flex justify-content-center align-items-center">
              <div>{t("ListTickets.No Tickets Found")}</div>
            </div>
          )}
        </div>

        {context?.meta && context?.tickets && context?.tickets?.length > 0 && (
          <Pagination
            meta={context?.meta}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            currentPageCount={context?.tickets?.length}
            component={"ticket"}
          />
        )}
      </div>
      <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>
      <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
        <div className="sortfilterMobile row m-0">
          <CommonSort
            sort={sort}
            opensort={opensort}
            opensortClick={sortByOpen}
            setCurrentPage={setCurrentPage}
            setSort={setSort}
            sortAtoZ="ticket_no"
            sortZtoA="-ticket_no"
            isSortMobile
          />

          <div className="col p-0 d-flex justify-content-evenly align-items-center ">
            <CommonFilter
              isFilterMobile
              openfilterClick={filterByOpen}
              isFilterActive={
                status?.length > 0 ||
                department?.length > 0 ||
                billBoardId?.length > 0 ||
                assignee.length > 0
              }
              openfilter={openfilter}
              resetClick={resetFilter}
              filterClose={filterByOpen}
              filterApply={applyFilter}
              filterDisabled={
                !(
                  status?.length > 0 ||
                  department?.length > 0 ||
                  billBoardId?.length > 0 ||
                  assignee.length > 0
                )
              }
            >
              <Dropdownfilter
                setRole={setRole}
                role={role}
                status={status}
                setStatus={setStatus}
                setDepartment={setDepartment}
                department={department}
                setBillBoardId={setBillBoardId}
                setBillBoardNum={setBillBoardNum}
                setAssigneId={setAssigneId}
                billBoardList={billBoardList}
                assigneeList={assigneeList}
                setAssigne={setAssigne}
                assigneeId={assigneeId}
                assignee={assignee}
                billBoardNum={billBoardNum}
                billBoardId={billBoardId}
                context={context}
                userContext={userContext}
                cityList={cityList}
                city={city}
                setCity={setCity}
                setisCleaning={setisCleaning}
                isCleaning={isCleaning}
                groupNameList={groupNameList}
                setGroupName={setGroupName}
                setGroupID={setGroupID}
                groupID={groupID}
                groupName={groupName}
              />
            </CommonFilter>
          </div>
        </div>
      </div>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopupSecond trigger={isDeletePopUp} setTrigger={setisDeletePopUp}>
        <DeleteBlockToast
          setTrigger={(status: boolean) => {
            loadData();
            setisDeletePopUp(status);
          }}
          closePopUp={(data: any) => setisDeletePopUp(data)}
          submit={() => deleteTicket()}
          headerMessage={headerMessage}
          textMessage={deleteText}
        />
      </ToastPopupSecond>
      <DownloadPopUp show={show} setShow={setShow} download={download} />
    </div>
    // </AppLayout>
  );
};

export default TicketOverView;

const Dropdownfilter = ({
  status,
  setStatus,
  setDepartment,
  department,
  billBoardId,
  setBillBoardId,
  setAssigneId,
  billBoardList,
  assigneeList,
  setAssigne,
  assigneeId,
  assignee,
  setBillBoardNum,
  billBoardNum,
  context,
  userContext,
  cityList,
  city,
  setCity,
  setisCleaning,
  isCleaning,
  groupNameList,
  setGroupName,
  setGroupID,
  groupID,
  groupName,
}: any) => {
  const { t } = useTranslation();
  const [departMentList, setDepartMentList] = useState<any>([]);

  useEffect(() => {
    getDepartmentDetail();
  }, []);

  const getDepartmentDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "DEPARTMENT",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setDepartMentList(res);
    }
  };
  interface billboardAutocompleteProps {
    readonly label: string;
    readonly value: string;
  }

  const getDepartmentOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  const getBillboardOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { billboards } = await context?.loadBillBoards({
      status: "ACTIVE",
      sort: "board_no",
      search: inputValue,
    });
    callback(
      billboards?.map((d: any) => ({
        label: d?.board_no,
        value: d?.id,
      }))
    );
  };

  const getAssignieOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { user } = await context?.loadMembers({
      status: "ACTIVE",
      sort: "name",
      search: inputValue,
    });
    callback(
      user?.map((d: any) => ({
        label: d?.name,
        value: d?.id,
      }))
    );
  };

  const getGroupName: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { cleanings } = await await context?.loadCleaningsList({
      status: "ACTIVE",
      sort: "-createdAt",
      search: inputValue,
    });

    callback(
      cleanings
        ?.map((d: any) =>
          d?.group_name
            ? {
                label: d?.group_name,
                value: d?.id,
              }
            : null
        )
        .filter((item: any) => item !== null)
    );
  };

  const getCityOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const data = await context?.cityList({
      city: inputValue,
    });
    callback(
      data?.map((d: any) => ({
        label: d?.city,
        value: d?.city,
      }))
    );
  };

  return (
    <div className="">
      <div className="py-2 ">
        <h6>{t("filter.status")}</h6>
        <div className="Status ">
          <CommonStatusButton
            pillText={"All"}
            text={t("ListTickets.All")}
            activeText={status.join()}
            onClick={() => setStatus([...["All"]])}
          />
          <CommonStatusButton
            pillText={"CLOSED"}
            text={t("ListTickets.CLOSED")}
            activeText={status.join()}
            onClick={() =>
              setStatus(
                status?.includes("CLOSED")
                  ? status?.filter((item: string) => item !== "CLOSED")
                      ?.length > 0
                    ? status?.filter((item: string) => item !== "CLOSED")
                    : [...["All"]]
                  : status?.includes("All")
                  ? [...["CLOSED"]]
                  : [...status, ...["CLOSED"]]
              )
            }
          />

          <CommonStatusButton
            pillText={"CREATED"}
            text={t("ListTickets.CREATED")}
            activeText={status.join()}
            onClick={() =>
              setStatus(
                status?.includes("CREATED")
                  ? status?.filter((item: string) => item !== "CREATED")
                      ?.length > 0
                    ? status?.filter((item: string) => item !== "CREATED")
                    : [...["All"]]
                  : status?.includes("All")
                  ? [...["CREATED"]]
                  : [...status, ...["CREATED"]]
              )
            }
          />

          <CommonStatusButton
            pillText={"PENDING"}
            text={t("ListTickets.PENDING")}
            activeText={status.join()}
            onClick={() =>
              setStatus(
                status?.includes("PENDING")
                  ? status?.filter((item: string) => item !== "PENDING")
                      ?.length > 0
                    ? status?.filter((item: string) => item !== "PENDING")
                    : [...["All"]]
                  : status?.includes("All")
                  ? [...["PENDING"]]
                  : [...status, ...["PENDING"]]
              )
            }
          />

          <CommonStatusButton
            pillText={"REASSIGNED"}
            text={t("ListTickets.REASSIGNED")}
            activeText={status.join()}
            onClick={() =>
              setStatus(
                status?.includes("REASSIGNED")
                  ? status?.filter((item: string) => item !== "REASSIGNED")
                      ?.length > 0
                    ? status?.filter((item: string) => item !== "REASSIGNED")
                    : [...["All"]]
                  : status?.includes("All")
                  ? [...["REASSIGNED"]]
                  : [...status, ...["REASSIGNED"]]
              )
            }
          />

          <CommonStatusButton
            pillText={"SUBMITTED"}
            text={t("ListTickets.SUBMITTED")}
            activeText={status.join()}
            onClick={() =>
              setStatus(
                status?.includes("SUBMITTED")
                  ? status?.filter((item: string) => item !== "SUBMITTED")
                      ?.length > 0
                    ? status?.filter((item: string) => item !== "SUBMITTED")
                    : [...["All"]]
                  : status?.includes("All")
                  ? [...["SUBMITTED"]]
                  : [...status, ...["SUBMITTED"]]
              )
            }
          />

          <CommonStatusButton
            pillText={"VERIFIED"}
            text={t("ListTickets.VERIFIED")}
            activeText={status.join()}
            onClick={() =>
              setStatus(
                status?.includes("VERIFIED")
                  ? status?.filter((item: string) => item !== "VERIFIED")
                      ?.length > 0
                    ? status?.filter((item: string) => item !== "VERIFIED")
                    : [...["All"]]
                  : status?.includes("All")
                  ? [...["VERIFIED"]]
                  : [...status, ...["VERIFIED"]]
              )
            }
          />
          <CommonStatusButton
            pillText={"VIEWED"}
            text={t("ListTickets.VIEWED")}
            activeText={status.join()}
            onClick={() =>
              setStatus(
                status?.includes("VIEWED")
                  ? status?.filter((item: string) => item !== "VIEWED")
                      ?.length > 0
                    ? status?.filter((item: string) => item !== "VIEWED")
                    : [...["All"]]
                  : status?.includes("All")
                  ? [...["VIEWED"]]
                  : [...status, ...["VIEWED"]]
              )
            }
          />

          <CommonStatusButton
            pillText={"OVERDUE"}
            text={t("cleaning.Overdue")}
            activeText={status.join()}
            onClick={() =>
              setStatus(
                status?.includes("OVERDUE")
                  ? status?.filter((item: string) => item !== "OVERDUE")
                      ?.length > 0
                    ? status?.filter((item: string) => item !== "OVERDUE")
                    : [...["All"]]
                  : status?.includes("All")
                  ? [...["OVERDUE"]]
                  : [...status, ...["OVERDUE"]]
              )
            }
          />
        </div>
      </div>
      <div className="py-2">
        <h6 className="">{t("ListTickets.BillBoard_Number")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            className={"select-wrapper-async"}
            isMulti={false}
            values={{ label: billBoardNum, value: billBoardId }}
            options={billBoardList}
            cacheOptions
            loadOptionValue={getBillboardOptions}
            updateField={(option: any) => {
              setBillBoardId(option.value);
              setBillBoardNum(option.label);
            }}
            isClearable={false}
          />
        </div>
      </div>
      <div className="py-2">
        <h6 className="">{t("ListTickets.Assignee")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            className={"select-wrapper-async"}
            isMulti={false}
            values={{ label: assignee, value: assigneeId }}
            options={assigneeList}
            cacheOptions
            loadOptionValue={getAssignieOptions}
            updateField={(option: any) => {
              setAssigneId(option?.value);
              setAssigne(option?.label);
            }}
            isClearable={false}
          />
        </div>
      </div>
      <div className="py-2">
        <h6 className="">{t("CreateBillBoardForm.City")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            className={"select-wrapper-async"}
            isMulti={false}
            values={{ label: city, value: city }}
            options={cityList}
            cacheOptions
            loadOptionValue={getCityOptions}
            updateField={(option: any) => {
              setCity(option?.value);
            }}
            isClearable={false}
          />
        </div>
      </div>
      <div className="py-2" style={{ width: "100%" }}>
        <h6> {t("filter.department")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            className={"select-wrapper-async"}
            isMulti={false}
            values={{
              label: updateText(department?.replace("_", " ")),
              value: department,
            }}
            options={departMentList}
            cacheOptions
            loadOptionValue={getDepartmentOptions}
            updateField={(option: any) => {
              setDepartment(option?.value);
            }}
            isClearable={false}
          />
        </div>
      </div>
      {isCleaning && (
        <div className="py-2" style={{ width: "100%" }}>
          <h6> {t("cleaning.GroupName")}</h6>
          <div className="fordesign">
            <CommonAsyncSelect
              className={"select-wrapper-async"}
              isMulti={false}
              values={{ label: groupName, value: groupID }}
              options={groupNameList}
              cacheOptions
              loadOptionValue={getGroupName}
              updateField={(option: any) => {
                setGroupName(option?.label);
                setGroupID(option?.value);
              }}
              isClearable={false}
            />
          </div>
        </div>
      )}
      <div className="py-2 mb-2" style={{ width: "100%" }}>
        <h6> {t("filter.Cleaning Ticket")}</h6>
        <div className="fordesign">
          <CommonToggleSwitch
            textOff={t("filter.Non-Cleaning")}
            textOn={t("filter.Cleaning")}
            onChange={(e) => setisCleaning(e.target.checked)}
            checked={isCleaning}
          />
        </div>
      </div>
    </div>
  );
};
