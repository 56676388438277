import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BiSolidPhoneIncoming, BiSolidPhoneOutgoing } from "react-icons/bi";
import { FaDownload, FaRegClock } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosArrowRoundUp, IoMdDocument } from "react-icons/io";
import { IoCheckmarkDoneSharp, IoCheckmarkSharp } from "react-icons/io5";
import { RiShareForwardFill, RiVideoOnFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import logochat from "../../../context/assets/images/chat.svg";
import useScrollToBottom from "../../../common/hooks/useScrollToBottom";
import { useChatContext } from "../../../context/ChatProvider";
import { useUserContext } from "../../../context/UserProvider";
import DeletePopup from "../components/DeletePopup/DeletePopup";
import Nodata from "../components/Nodata/Nodata";
import OptionsDropdown from "../components/OptionsDropdown/OptionsDropdown";
import EditMessagePopup from "./EditMessagePopup";
import "./MessagesList.scss";
import PreviewFilePopup from "./PreviewFilePopup";
import { calculateDuration } from "../../../../../utils/axiosInstance";
import MapThumbnail from "../components/MapThumbnail";

type MessagesListProps = {
  onShowBottomIcon: Function;
  shouldScrollToBottom?: boolean;
  replyshow: boolean;
  setReplyshow: Function;
  forwardshow: boolean;
  setForwardshow: Function;
  mediaBlobUrl?: string | undefined;
  search: string;
};

export default function MessagesList(props: MessagesListProps) {
  const {
    onShowBottomIcon,
    shouldScrollToBottom,
    replyshow,
    setReplyshow,
    forwardshow,
    setForwardshow,
    mediaBlobUrl,
    search,
  } = props;

  const params = useParams();
  const chatContext = useChatContext();
  const [deleteMessageObject, setDeleteMessageObject] = useState({
    test: "",
    id: "",
  });

  const handleCheckboxChange = (messageID: any) => {
    chatContext?.setSelectedMsgIds((prevIds: any) => {
      let updatedIds;
      if (prevIds.includes(messageID)) {
        updatedIds = prevIds.filter(
          (selectedId: any) => selectedId !== messageID
        );
      } else {
        updatedIds = [...prevIds, messageID];
      }
      return updatedIds;
    });
  };
  useEffect(() => {
    chatContext?.setForwardMessageCount(chatContext?.selectedMsgIds);
  }, [chatContext, chatContext?.selectedMsgIds]);

  const { containerRef, lastMessageRef }: any = useScrollToBottom(
    onShowBottomIcon,
    shouldScrollToBottom,
    params.id
  );
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [chatContext?.newMessage]);

  let result: any;
  if (chatContext?.messages?.length > 0) {
    let data = chatContext?.messages?.reduce((r: any, e: any) => {
      let group: any =
        moment(new Date()).format("dddd, MMMM D, YYYY") ===
        moment(e?.createdAt).format("dddd, MMMM D, YYYY")
          ? "Today"
          : moment(new Date())
              .subtract(1, "day")
              .format("dddd, MMMM D, YYYY") ===
            moment(e?.createdAt).format("dddd, MMMM D, YYYY")
          ? "Yesterday"
          : moment(e?.createdAt).format("D MMMM, YYYY");
      if (!r[group]) r[group] = { group, children: [e] };
      else r[group].children.push(e);
      return r;
    }, {});
    result = Object?.values(data);
  }

  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop } = containerRef.current;
    if (scrollTop === 0) {
      loadMoreData();
    }
  };

  const loadMoreData = () => {
    chatContext?.setMessageLimit(chatContext?.messageLimit + 50);
  };

  const deleteMessage = (messageId: string) => {
    chatContext.messageDelete(messageId);
  };

  const textToBeDeleted =
    deleteMessageObject?.test?.length > 30
      ? deleteMessageObject?.test?.substring(0, 30) + "....."
      : deleteMessageObject?.test;
  return (
    <div
      className={` msg-container ${
        replyshow ? "reply-show-extra-padding" : ""
      }`}
      ref={containerRef}
      onScroll={handleScroll}
    >
      <div className="MessageGroup">
        {result?.length > 0 ? (
          result?.map((groupValue: any, index: any) => (
            <>
              <div className="DateWrapper">
                <div className="Date"> {groupValue?.group} </div>
              </div>
              {groupValue?.children?.map((message: any, i: number) => {
                if (i === chatContext?.messages.length - 1) {
                  return (
                    <SingleMessage
                      key={message.id}
                      message={message}
                      ref={lastMessageRef}
                      setReplyshow={setReplyshow}
                      setForwardshow={setForwardshow}
                      forwardshow={forwardshow}
                      mediaBlobUrl={mediaBlobUrl}
                      handleCheckboxChange={handleCheckboxChange}
                      megDetails={chatContext?.selectedMsgIds}
                      setDeleteMessageObject={setDeleteMessageObject}
                      searchQuery={search}
                    />
                  );
                } else {
                  return (
                    <SingleMessage
                      key={message.id}
                      message={message}
                      setReplyshow={setReplyshow}
                      setForwardshow={setForwardshow}
                      forwardshow={forwardshow}
                      mediaBlobUrl={mediaBlobUrl}
                      handleCheckboxChange={handleCheckboxChange}
                      megDetails={chatContext?.selectedMsgIds}
                      setDeleteMessageObject={setDeleteMessageObject}
                      searchQuery={search}
                      ref={lastMessageRef}
                    />
                  );
                }
              })}
            </>
          ))
        ) : (
          <Nodata
            noDataText={search ? "No Chat Found" : "Start new conversation"}
            dataImage={!search ? logochat : undefined}
          />
        )}
        {chatContext?.deleteMessage && (
          <DeletePopup
            textMessage="Are you sure, you want to delete this Message?"
            textCancel="No, don't delete it!"
            textSuccess="Yes, delete it"
            textWantToBeDeleted={textToBeDeleted}
            onClickCancel={() => {
              chatContext?.setDeleteMessage(false);
            }}
            onClickSuccess={() => {
              deleteMessageObject?.id && deleteMessage(deleteMessageObject?.id);
              chatContext?.setDeleteMessage(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

interface SingleMessageProps {
  message: any;
  setReplyshow: Function;
  setForwardshow: Function;
  forwardshow: Boolean;
  mediaBlobUrl?: string;
  megDetails: any;
  handleCheckboxChange: Function;
  setDeleteMessageObject: Function;
  searchQuery: string;
}

const SingleMessage = forwardRef<HTMLDivElement, SingleMessageProps>(
  (props, ref) => {
    const {
      message,
      setReplyshow,
      setForwardshow,
      forwardshow,
      mediaBlobUrl,
      megDetails,
      handleCheckboxChange,
      setDeleteMessageObject,
      searchQuery,
    } = props;
    const userContext = useUserContext();
    const chatContext = useChatContext();
    const [previewModal, setPreviewModal] = useState(false);

    const [editMessagePopup, setEditMessagePopup] = useState(false);
    const [editedMessage, setEditedMessage] = useState({
      messageId: "",
      Message: "",
      referenceId: "",
    });

    const opponentId = chatContext?.meta?.participants?.participants?.find(
      (item: any) => item.id !== userContext?.user.id
    )?.id;

    const replyMessage = async (messageObject: any) => {
      setReplyshow(true);
      chatContext?.setRepliedMessage(messageObject?.messageObject);
    };

    const forwardMessage = () => {
      setForwardshow(true);
      handleCheckboxChange(message?.reference_id);
    };

    const highlightText = (text: string, query: string) => {
      if (!query.trim()) return text;
      const parts = text?.split(new RegExp(`(${query})`, "gi"));
      return parts?.map((part, index) =>
        part?.toLowerCase() === query?.toLowerCase() ? (
          <mark key={index}>{part}</mark>
        ) : (
          part
        )
      );
    };
    const [loading, setLoading] = useState(false);

    const loadMoreItems = () => {
      console.log("Loading more items");
    };
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
          document.documentElement.offsetHeight ||
        loading
      ) {
        return;
      }
      loadMoreItems();
    };

    const handleDownload = () => {
      if (message.attachment) {
        const link = document.createElement("a");
        link.href = message.attachment;
        link.download = message.attachment.split("/").pop(); // Get filename from URL
        link.click();
      }
    };
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [loading]);

    const previewFileModal = () => {
      setPreviewModal(true);
    };

    function cleanGoogleMapsLink(url: string) {
      const parsedUrl = new URL(url);
      const params = parsedUrl.searchParams;
      params.delete("key");
      params.delete("zoom");
      params.delete("size");
      params.delete("markers");
      parsedUrl.search = params.toString();
      return parsedUrl.toString();
    }

    const handleClick = (event: any, url: string) => {
      const parsedUrl = new URL(url);
      const params = parsedUrl.searchParams;
      event.preventDefault();

      const center = params.get("center");
      if (center) {
        const [latitude, longitude] = center.split(",").map(Number);
        const zoom = 14;
        const size = "400x400";
        const mapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}&zoom=${zoom}&size=${size}`;

        window.open(mapsUrl, "_blank");
      }
    };

    return (
      <div
        className={` py-3 ps-3 pe-4 mb-3 position-relative d-flex align-items-center ${
          message?.from?.id === userContext?.user.id ? "is-sent" : "is-received"
        } ${
          megDetails.includes(message?.id) && forwardshow
            ? "is-both-checked "
            : ""
        }`}
      >
        <div className="pe-3 check-box-margin">
          {!message?.sessionInfo && forwardshow && (
            <span className=" me-3">
              <label className="checkbox" htmlFor={message?.reference_id}>
                <input
                  type="checkbox"
                  name="message"
                  value={message?.reference_id}
                  id={message?.reference_id}
                  checked={megDetails?.includes(message?.reference_id)}
                  onChange={() => {
                    handleCheckboxChange(message?.reference_id);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </span>
          )}
        </div>
        <div
          key={message?._id}
          className={`ChatMessage flex-column position-relative ${
            message.from.id === userContext?.user.id
              ? "chat__msg--sent"
              : "chat__msg--received"
          } 
        `}
          ref={ref}
        >
          {message?.from?.name && chatContext?.meta?.participants?.group_id && (
            <span className=" text-capitalize fs-12 fw-semibold group-name-tag">
              {message?.from?.id === userContext?.user?.id
                ? ""
                : message?.from?.name}{" "}
            </span>
          )}
          {message?.is_reply && (
            <div className=" is-reply mb-2 d-flex  align-items-center p-1">
              <div className="is-reply-box flex-grow-1 px-2">
                <div className="reply-name ">
                  {message?.from?.id === userContext?.user?.id
                    ? "You"
                    : message?.from?.name}
                </div>
                <div className="reply-subtext text-dark">
                  {message?.reply_message_id?.message && (
                    <div className="pb-1 text-break chat-text">
                      <span>{message?.reply_message_id?.message}</span>
                    </div>
                  )}

                  {message?.reply_message_id?.attachment_type === "VIDEO" && (
                    <video controls className="msg-video mt-3">
                      <source
                        src={message?.reply_message_id?.attachment}
                        type="video/mp4"
                      />
                    </video>
                  )}
                  {message?.reply_message_id?.attachment_type === "AUDIO" && (
                    <div>
                      <audio
                        src={
                          message?.reply_message_id?.attachment || mediaBlobUrl
                        }
                        controls
                      />
                    </div>
                  )}
                  {message?.reply_message_id?.attachment_type === "IMAGE" && (
                    <div className="msg-image-reply pt-3 cursor-pointer">
                      <img src={message?.reply_message_id?.attachment} alt="" />
                    </div>
                  )}
                  {message?.reply_message_id?.attachment_type === "FILE" && (
                    <div className="file-show px-3 py-2 d-flex align-items-center mb-2">
                      <div>
                        <IoMdDocument
                          fontSize={30}
                          color="var(--secondary)"
                          title="Document"
                        />
                      </div>
                      <div className=" fw-bold fs-14  mx-2 ">
                        {message?.reply_message_id?.attachment.split("/").pop()}
                      </div>
                      <IconToggle />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {message?.is_forwarded && (
            <div className="forwarded-text d-flex align-items-center ">
              <span>
                <RiShareForwardFill size={12} title="Forward" />
              </span>
              <span className="m-inline-start fs-11">Forwarded</span>
            </div>
          )}

          {!message?.attachment &&
            !message?.is_location &&
            highlightText(message?.message, searchQuery) && (
              <div className="text-break chat-text">
                <span>{highlightText(message.message, searchQuery)}</span>
              </div>
            )}

          {message?.attachment_type === "IMAGE" && (
            <div
              className="msg-image  mb-1 cursor-pointer"
              onClick={() => {
                previewFileModal();
              }}
            >
              <img src={message?.attachment} alt="" />
            </div>
          )}
          {message?.is_location && (
            <div className="msg-image mb-1 cursor-pointer">
              <MapThumbnail url={message?.message} />
              {/* <a href="" onClick={(e) => handleClick(e, message?.message)}>
                {cleanGoogleMapsLink(message.message)}
              </a> */}
            </div>
          )}
          {previewModal && (
            <PreviewFilePopup
              previewModal={previewModal}
              setPreviewModal={setPreviewModal}
              messageObj={message}
            />
          )}

          {message?.attachment_type === "VIDEO" && (
            <video controls className="msg-video mb-1 ">
              <source src={message?.attachment} type="video/mp4" />
            </video>
          )}
          {message?.attachment_type === "AUDIO" && (
            <div>
              <audio src={message?.attachment || mediaBlobUrl} controls loop />
            </div>
          )}
          {message?.attachment_type === "FILE" && (
            <div className="file-show px-3 py-2 d-flex align-items-center mb-1">
              <div>
                <IoMdDocument
                  fontSize={30}
                  color="var(--secondary)"
                  title="Document"
                />
              </div>
              <div className=" fw-bold fs-14  mx-2 ">
                {message.attachment.split("/").pop()}
              </div>
              <IconToggle />
            </div>
          )}
          {message?.attachment &&
            highlightText(message.message, searchQuery) && (
              <div className="pt-1 text-break chat-text msg-text-for-attachment">
                <span>{highlightText(message.message, searchQuery)}</span>
              </div>
            )}
          {message?.sessionInfo && (
            <div className=" p-2 d-flex align-items-center gap-2 call-wrapper">
              <span className=" call-icon">
                {message?.sessionInfo?.mode === "VIDEO" ? (
                  message?.from?.id !== userContext?.user.id ? (
                    <span className=" position-relative">
                      <RiVideoOnFill
                        size={24}
                        color={
                          message?.sessionInfo?.status === "PENDING"
                            ? "var(--danger)"
                            : "var(--secondary)"
                        }
                      />
                      <span className=" video-call-incoming ">
                        <IoIosArrowRoundUp color="var(--color-white)" />
                      </span>
                    </span>
                  ) : (
                    <span className=" position-relative">
                      <RiVideoOnFill size={24} color="var(--secondary)" />
                      <span className=" video-call-outgoing ">
                        <IoIosArrowRoundUp color="var(--color-white)" />
                      </span>
                    </span>
                  )
                ) : message?.from?.id !== userContext?.user.id ? (
                  <BiSolidPhoneIncoming
                    color={
                      message?.sessionInfo?.status === "PENDING"
                        ? "var(--danger)"
                        : "var(--secondary)"
                    }
                  />
                ) : (
                  <BiSolidPhoneOutgoing color="var(--secondary)" />
                )}
              </span>
              <div>
                <h6 className=" fw-bold fs-14 mb-0">
                  {message?.sessionInfo?.mode === "VIDEO"
                    ? "Video call"
                    : "Voice call"}
                </h6>
                {message?.sessionInfo?.status === "PENDING" && (
                  <h6 className=" fw-medium fs-12 mb-0">
                    {message?.sessionInfo?.from !== userContext?.user?.id
                      ? "Missed call"
                      : "No answer"}
                  </h6>
                )}
                {message?.sessionInfo?.status === "REQUESTED" && (
                  <h6 className=" fw-medium fs-12 mb-0">Calling</h6>
                )}
                {message?.sessionInfo?.status === "COMPLETED" && (
                  <h6 className=" fw-medium fs-12 mb-0">
                    {`${calculateDuration(
                      message?.sessionInfo?.start_time
                        ? message?.sessionInfo?.start_time
                        : message.createdAt,
                      message?.sessionInfo?.end_time
                    )}`}
                  </h6>
                )}
              </div>
            </div>
          )}

          <span className=" d-flex justify-content-end align-items-end time-container">
            <div className="d-flex align-items-center gap-1 h-100">
              {message.is_edited && (
                <span className=" fs-11 h-100">Edited</span>
              )}
              <span className=" fs-11 fw-medium h-100">
                {moment(message?.createdAt).format("LT")}
              </span>
              {message?.from?.id === userContext?.user.id && (
                <div
                  className={
                    message?.seen[opponentId] === null ? "text-white" : "read"
                  }
                >
                  {chatContext?.isLoading ? (
                    <FaRegClock size={13} title="Loading..." />
                  ) : message.seen[opponentId] === null ? (
                    <IoCheckmarkSharp size={13} title="sent" />
                  ) : (
                    <IoCheckmarkDoneSharp size={13} title="seen" />
                  )}
                </div>
              )}
            </div>
          </span>
          {!forwardshow && !message?.sessionInfo && (
            <div className="msg-option-list">
              <OptionsDropdown iconSize="20">
                <>
                  <Dropdown.Item
                    onClick={() =>
                      replyMessage({
                        messageObject: message,
                      })
                    }
                  >
                    Reply
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      forwardMessage();
                    }}
                  >
                    Forward
                  </Dropdown.Item>

                  {message?.from?.id === userContext?.user.id &&
                    !message.attachment && (
                      <Dropdown.Item
                        onClick={() => {
                          setEditedMessage({
                            messageId: message?.id,
                            Message: message.message,
                            referenceId: message.reference_id,
                          });
                          setEditMessagePopup(true);
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                    )}

                  {message?.attachment && (
                    <Dropdown.Item onClick={handleDownload}>
                      Download
                    </Dropdown.Item>
                  )}

                  {message?.from?.id === userContext?.user.id && (
                    <Dropdown.Item
                      onClick={() => {
                        chatContext?.setDeleteMessage(true);
                        setDeleteMessageObject({
                          id: message?.reference_id,
                          test: message.message,
                        });
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                </>
              </OptionsDropdown>
            </div>
          )}

          {/* <button onClick={notify}>Notifyy !</button>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <ToastContainer /> */}
          {editMessagePopup && (
            <EditMessagePopup
              editMessagePopup={editMessagePopup}
              setEditMessagePopup={setEditMessagePopup}
              editedMessage={editedMessage}
              chatContext={chatContext}
            />
          )}
        </div>
      </div>
    );
  }
);

const IconToggle = () => {
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [fadeClass, setFadeClass] = useState("");

  const handleClick = () => {
    setFadeClass("fade-out");
  };

  useEffect(() => {
    if (fadeClass === "fade-out") {
      const timer = setTimeout(() => {
        setIsDownloaded(!isDownloaded);
        setFadeClass("");
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [fadeClass, isDownloaded]);

  return (
    <div
      className={`file-download m-inline-start ${fadeClass}`}
      onClick={handleClick}
    >
      {isDownloaded ? (
        <FaDownload title="Download file" color="var(--secondary)" size={24} />
      ) : (
        <FaCircleCheck
          title="Downloaded successfully"
          color="var(--secondary)"
          size={24}
        />
      )}
    </div>
  );
};
