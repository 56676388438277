import { getCookie } from "../api";
import { BASE_URL, post } from "../axiosInstance";
export interface FileInterface {
  success: boolean,
  url?: string,
  message?: string,
  error?: string
}
const fileUpload = async (data: any, feature: string): Promise<FileInterface | undefined> => {
  try {
    const response = await post(
      `${BASE_URL}file/upload?feature=${feature}`,
      getCookie("auth"),
      data,
      { headers: { "content-type": "multipart/form-data" } }
    );
    return response?.data as FileInterface;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const FileService = {
  fileUpload,
};

export default FileService;
