import { useState } from "react";
import { FiMail } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { isMobile } from "react-device-detect";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup } from "react-bootstrap";
import { BiErrorCircle } from "react-icons/bi";
import * as Yup from "yup";
import { BsTelephone } from "react-icons/bs";
import FormikPhoneNumber from "../components/FormikPhoneNumber/FormikPhoneNumber";
import ToastPopup from "../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../components/PopupToast/SuccessErrorToast";
import AppLayout from "../layouts/AppLayout";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../context/AuthenticationProvider";
import { api } from "../utils/api";

const HelpCenter = () => {
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(true);
  const [buttonPopup, setButtonPopup] = useState(false);

  const context = useAuthentication();
  const { t, i18n } = useTranslation();

  const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    email: Yup.string()
      .matches(
        /^([_A-Za-z0-9+]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/,
        "Invalid email address or phone number"
      )
      .min(13, "Invalid email address or phone number")
      .required("E-mail Address or phone number is required"),
    contactNumber: Yup.string()
      .typeError("That doesn't look like a phone number")
      .min(8, "Contact Number must be at least 8 characters")
      .matches(phoneRegExp, "That doesn't look like a Contact Number")
      .required("Contact Number is required"),
    biography: Yup.string()
      .min(2, "Too Short!")
      .max(1000, "Too Long!")
      .required("Biography is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (context && context?.user?.name) || "",
      email: (context && context?.user?.email) || "",
      contactNumber: (context && context?.user?.contact_number) || "",
      biography: "",
    },
    validationSchema,
    onSubmit: async (values: any, { resetForm }) => {
      try {
        const res = await context?.helpCenterMsg({
          message: values.biography,
        });

        if (res?.data?.success) {
          setButtonPopup(true);
          setIsPrivacyChecked(true);
          setTimeout(() => {
            setButtonPopup(false);
            resetForm();
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { errors, isValid, handleSubmit, dirty } = formik;

  return (
    <AppLayout>
      <div className="position-relative p-3" style={{ background: "#fafafa" }}>
        <div
          className={`${
            isMobile ? "container-fluid position-relative " : "container"
          }`}
          style={{ height: "100%" }}
        >
          <div className="row" style={{ height: "100%" }}>
            <div
              className={isMobile ? "d-none" : "col-4 p-4 rounded-1"}
              style={{
                background: "#6b5ca4",
                color: "#fefefe",
              }}
            >
              <h5 className="fw-bold">{t("help.Get In touch")}</h5>
              <h6 className="fw-light">{t("help.loveChat")}</h6>
              <div className="py-3 d-flex">
                <div style={{ paddingInlineEnd: ".7rem" }}>
                  <FiMail color="#fefefe" fontSize={24} />
                </div>
                <div>
                  <h6 className="fw-semibold mt-1">{t("help.Chattous")}</h6>
                  <h6 className="fw-light">{t("help.friendly")}</h6>
                  <h6 className="fw-semibold">arabia@arabia.com</h6>
                </div>
              </div>

              <div className="py-3 d-flex">
                <div style={{ paddingInlineEnd: ".7rem" }}>
                  <HiOutlineLocationMarker color="#fefefe" fontSize={24} />
                </div>
                <div>
                  <h6 className="fw-semibold mt-1">{t("help.Office")}</h6>
                  <h6 className="fw-light">{t("help.officeHQ")}</h6>
                  <h6 className="fw-semibold">{t("help.address")}</h6>
                </div>
              </div>

              <div className="py-3 d-flex">
                <div style={{ paddingInlineEnd: ".7rem" }}>
                  <BsTelephone color="#fefefe" fontSize={24} />
                </div>
                <div>
                  <h6 className="fw-semibold mt-1">{t("help.phone")}</h6>
                  <h6 className="fw-light">{t("help.Mon_Fri")}</h6>
                  <h6
                    className={
                      i18n.language === "en"
                        ? "dateandtime pt-2 fw-semibold"
                        : "dateandtimeArabic pt-2 fw-semibold"
                    }
                  >
                    +1(555) 000-0000
                  </h6>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 col-12 py-4"
              style={
                isMobile
                  ? {
                      background: "#fefefe",
                      height: "100%",
                    }
                  : {
                      background: "#fefefe",
                      height: "100%",
                      paddingInlineStart: "3rem",
                    }
              }
            >
              <div>
                <div>
                  <h2 className="fw-semibold">{t("help.level")}</h2>
                  <h6 style={{ color: "#858780" }} className="fw-light">
                    {t("help.via")}
                    <span style={{ color: "#6b5ca4" }}>arabia@arabia.com</span>
                  </h6>

                  <FormikProvider value={formik}>
                    <Form validated={true} onSubmit={handleSubmit}>
                      <div className="">
                        <FormGroup className="my-5 position-relative">
                          <label>{t("help.name")}</label>
                          <Field
                            name="name"
                            type="text"
                            disabled
                            className={`form-control ${
                              errors.name ? "form-control-invalid" : ""
                            }`}
                            maxLength={50}
                            id={i18n.language === "en" ? "" : "ArabicDate"}
                          />
                          {formik?.errors?.name && (
                            <div
                              className={
                                i18n.language === "en"
                                  ? "eyeicon"
                                  : "eyeicon-right"
                              }
                            >
                              <BiErrorCircle color="#db2c59" />
                            </div>
                          )}
                          <small className="text-danger">
                            <ErrorMessage name="name" />
                          </small>
                        </FormGroup>
                        <FormGroup className="my-5 position-relative">
                          <label>{t("login.label")}</label>
                          <Field
                            name="email"
                            disabled
                            type="text"
                            className={`form-control ${
                              errors.email ? "form-control-invalid" : ""
                            }`}
                            maxLength={50}
                            id={i18n.language === "en" ? "" : "ArabicDate"}
                          />
                          {formik?.errors?.email && (
                            <div
                              className={
                                i18n.language === "en"
                                  ? "eyeicon"
                                  : "eyeicon-right"
                              }
                            >
                              <BiErrorCircle color="#db2c59" />
                            </div>
                          )}
                          <small className="text-danger">
                            <ErrorMessage name="email" />
                          </small>
                        </FormGroup>
                        <FormGroup className="my-5">
                          <FormikPhoneNumber
                            name="contactNumber"
                            isDisabled
                            label={t("invitation.contactnumber")}
                            errors={errors["contactNumber"] as string}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3 position-relative">
                          <label>{t("help.Howcanwehelp")}</label>{" "}
                          <Field
                            as="textarea"
                            name="biography"
                            type="text"
                            className={`form-control ${
                              errors.biography ? "form-control-invalid" : ""
                            }`}
                            style={{ height: "12rem" }}
                            maxLength={1000}
                            id={i18n.language === "en" ? "" : "ArabicDate"}
                          />
                          {formik?.errors?.biography && (
                            <div
                              className={
                                i18n.language === "en"
                                  ? "eyeicon"
                                  : "eyeicon-right"
                              }
                            >
                              <BiErrorCircle color="#db2c59" />
                            </div>
                          )}
                          <small className="text-danger">
                            <ErrorMessage name="biography" />
                          </small>
                        </FormGroup>
                      </div>
                      <FormGroup className="mb-4">
                        <div
                          className="d-flex"
                          style={{ alignItems: "center", gap: ".5rem" }}
                        >
                          <input
                            type="checkbox"
                            name="isPrivacyChecked"
                            id="rememberMe"
                            checked={isPrivacyChecked}
                            onChange={() =>
                              setIsPrivacyChecked(!isPrivacyChecked)
                            }
                          />
                          <h6
                            style={{
                              color: "#858780",
                              paddingInlineStart: ".7rem",
                            }}
                            className="fw-light"
                          >
                            {t("help.Youagreetoourfriendly")}&nbsp;
                            <a
                              href="https://al-arabia.com/privacy-and-policy/"
                              className=" text-decoration-underline"
                            >
                              {t("help.privacypolicy")}
                            </a>
                          </h6>
                        </div>
                      </FormGroup>
                      <Button
                        type="submit"
                        id="signin"
                        disabled={!isPrivacyChecked || !isValid || !dirty}
                        variant="primary"
                        className="my-4 px-5 py-2 rounded-1 d-flex justify-content-center align-items-center pointer w-100"
                      >
                        <span className={` ${!isValid ? "" : "fw-semibold"}`}>
                          {t("help.Sendmessage")}
                        </span>
                      </Button>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastPopup
          show={buttonPopup}
          hide={() => setButtonPopup(false)}
          body={<SuccessErrorToast message={t("help.success")} />}
        />
      </div>
    </AppLayout>
  );
};

export default HelpCenter;
