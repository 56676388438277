import React, { useState } from "react";
import "./Header.scss";
import OptionsDropdown from "../components/OptionsDropdown/OptionsDropdown";
import { Dropdown } from "react-bootstrap";
import { IoArrowBackSharp, IoCall, IoPersonAddSharp } from "react-icons/io5";
import useChatRoom from "../../../common/hooks/useChatRoom";
import useNavigateToChat from "../../../common/hooks/useNavigateToChat";
import { FaVideo } from "react-icons/fa";
import { isMobile, isTablet } from "react-device-detect";
import { RemoteParticipant, RoomEvent } from "livekit-client";
import { useRemoteParticipants } from "@livekit/components-react";
import { useUserContext } from "../../../context/UserProvider";
import { useChatContext } from "../../../context/ChatProvider";
import Avatar from "react-avatar";
import MessagesListSearch from "../MessagesList/MessagesListSearch";
import ForwardPopup from "../forwardPopup/ForwardPopup";

type HeaderProps = {
  title: string;
  image: string;
  setIsProfileOpen: (isOpen: boolean) => void;
  setisResponsive: (isResponsive: boolean) => void;
  isResponsive: boolean;
  opponentId: string;
  search: string;
  setSearch: (search: string) => void;
  groupId?: any;
};

const Header: React.FC<HeaderProps> = ({
  title,
  image,
  setIsProfileOpen,
  isResponsive,
  setisResponsive,
  opponentId,
  search,
  setSearch,
  groupId,
}) => {
  const { activeInbox } = useChatRoom();
  const chatContext = useChatContext();
  const userContext = useUserContext();
  const remoteParticipants: RemoteParticipant[] = useRemoteParticipants({
    updateOnlyOn: [
      RoomEvent.ParticipantConnected,
      RoomEvent.ParticipantDisconnected,
      RoomEvent.Disconnected,
      RoomEvent.Reconnected,
      RoomEvent.ParticipantMetadataChanged,
      RoomEvent.ParticipantNameChanged,
    ],
  });

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [addMember, setAddMember] = useState(false);
  const [show, setShow] = useState(false);

  const addExtraMember = () => {
    setAddMember(true);
    setShow(true);
  };

  useNavigateToChat(activeInbox);

  const handleAudioVideoCall = (mode: string) => {
    chatContext.handleIncommingCall(mode, activeInbox.id, opponentId);
  };

  const handleOpenProfile = () => {
    setIsProfileOpen(true);
    setIsSearchOpen(false);
  };
  const remoteParticipant = remoteParticipants.find(
    (item) => item.identity === opponentId
  );
  const userName = title?.length > 20 ? title?.substring(0, 20) + "..." : title;
  return (
    <div className="container-header position-relative">
      {(isTablet || isMobile) && (
        <div
          className={`me-2 cursor-pointer ${
            isResponsive ? "d-block" : "d-none"
          }`}
          onClick={() => setisResponsive(false)}
        >
          <IoArrowBackSharp
            fontSize={22}
            color="var(--secondary)"
            title="Back to list"
          />
        </div>
      )}
      <div
        className="avatar-wrapper-section-header position-relative"
        onClick={handleOpenProfile}
      >
        {chatContext?.activeChat?.group_id?.group_name === undefined &&
        chatContext?.activeChat?.participants?.find(
          (item: any) => item._id !== userContext?.user?.id
        )?.avatar ? (
          <img src={image} alt={""} />
        ) : (
          <Avatar
            name={
              chatContext?.activeChat?.group_id?.group_name === undefined
                ? chatContext?.activeChat?.participants?.find(
                    (item: any) => item._id !== userContext?.user?.id
                  )?.name
                : chatContext?.activeChat?.group_id?.group_name
            }
            size="40"
            maxInitials={2}
            round
          />
        )}

        {chatContext?.activeChat?.group_id?.group_name === undefined &&
          remoteParticipant && (
            <div className="styled-badge-wrapper">
              <div className="styled-badge">
                <div className="MuiBadge-badge"></div>
              </div>
            </div>
          )}
      </div>
      <div className="ProfileWrapper" onClick={handleOpenProfile}>
        <div className="text-dark fw-medium text-capitalize">{userName}</div>
        {activeInbox?.isOnline && <div className="subtitle-header">Online</div>}
      </div>

      <div className="d-flex align-items-center">
        {chatContext?.activeChat?.group_id && !isMobile && (
          <>
            <div className="cursor-pointer " onClick={() => addExtraMember()}>
              <IoPersonAddSharp
                fontSize={22}
                title="Add Member"
                color="var(--secondary)"
              />
            </div>
            {addMember && (
              <ForwardPopup
                show={show}
                setShow={setShow}
                addMember={addMember}
                setAddMember={setAddMember}
                forwardModalshow={false}
                groupID={groupId?._id}
              />
            )}
          </>
        )}
        <MessagesListSearch
          setIsSearchOpen={setIsSearchOpen}
          isSearchOpen={isSearchOpen}
          setSearchQuery={setSearch}
          searchQuery={search}
        />

        {/* -----------------commented for deploying purpose---------------------------- */}

        {/* {!chatContext.callRequested &&
          !isMobile &&
          !chatContext?.meta?.participants?.group_id && (
            <div className=" d-flex align-items-center">
              <div
                className="cursor-pointer"
                onClick={() => handleAudioVideoCall("audio")}
              >
                <IoCall
                  fontSize={23}
                  color="var(--secondary)"
                  title="Audio Call"
                />
              </div>
              <div
                className="mx-3 cursor-pointer"
                onClick={() => handleAudioVideoCall("video")}
              >
                <FaVideo
                  fontSize={23}
                  color="var(--secondary)"
                  title="Video Call"
                />
              </div>
            </div>
          )} */}

        <OptionsDropdown iconSize="23">
          <>
            <Dropdown.Item onClick={handleOpenProfile}>
              {chatContext?.activeChat?.group_id
                ? "Group Information"
                : "Profile Information"}
            </Dropdown.Item>
            {isMobile && (
              <>
                {/* -----------------commented for deploying purpose---------------------------- */}

                {/* {!chatContext?.meta?.participants?.group_id && (
                  <>
                    <Dropdown.Item
                      onClick={() => handleAudioVideoCall("audio")}
                    >
                      Audio Call
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleAudioVideoCall("video")}
                    >
                      Video Call
                    </Dropdown.Item>
                  </>
                )} */}

                {chatContext?.meta?.participants?.group_id && (
                  <Dropdown.Item onClick={() => addExtraMember()}>
                    Add Member
                  </Dropdown.Item>
                )}
              </>
            )}
          </>
        </OptionsDropdown>
      </div>
    </div>
  );
};

export default Header;
