const filterQueryParams = (params: any) => {
  if (params) {
    Object.keys(params).forEach((key) => {
      if (
        params[key] === null ||
        params[key]?.length < 1 ||
        params[key] === ""
      ) {
        delete params[key];
      }
    });
    let rating: Array<number> = [];
    if (params?.rating?.length) {
      rating = [...params.rating];
      delete params.rating;
    }
    const searchParams = new URLSearchParams(params);
    rating.map((rate) => searchParams.append("rating", rate.toString()));
    return searchParams.toString();
  }
};
export const routes = {
  root: "/",
  file404: "/404error",
  file502: "/502error",
  login: "/login",
  forgetPassword: "/forget_password",
  resetPassword: "/reset_password",
  changePassword: "/login/change_password",
  invitation: "/accept_invitation",
  rolesandaccess: "/roles",
  helpCenter: "/helpcenter",
  listMembers: "/members",
  dashboard: "/dashboard",
  overView: "/overview",
  chat: "/chat",
  chatId: '/chat/:id',
  chattickets: "/chattickets",
  newMember: "/members/new",
  getMember: "/members/:id",
  listBillBoard: "/billboards",
  newBillBoard: "/billboards/new",
  billBoardMap: "/billboards/map",
  uploadBillBoard: "/billboards/upload_new",
  billOfMaterials: "/billboards/:billboardId/bom",
  getBillBoard: "/billboards/:billboardId",
  editBillBoard: "/billboards/:billboardId/edit",
  getBillBoardTicketsAdd: "/billboards/:billboardId/tickets/new",
  getBillBoardTickets: "/billboards/:billboardId/tickets",
  getBillBoardTicketsId: "/billboards/:billboardId/tickets/:ticketId/edit",
  getBillBoardTicketsActivityFeed:
    "/billboards/:billboardId/tickets/:ticketId/activities",
  listTickets: "/tickets",
  ticketOverView: "/ticketOverview",
  newTicket: "/tickets/new",
  getTicket: "/tickets/:ticketId",
  editTicket: "/tickets/:ticketId/edit",
  activityTicket: "/tickets/:ticketId/activity",
  createNewTicket: "/billboards/new",
  viewNewTicket: "/billboards/view",
  listCircuits: "/circuits",
  masters: "/masters",
  mastersSupplier: "/masters/suppliers",
  createMastersSupplier: "/masters/suppliers/new",
  mastersViewSupplier: "/masters/suppliers/:suppliersId/view",
  mastersEditSupplier: "/masters/suppliers/:suppliersId/edit",
  listMasterBillBoardType: "/masters/billBoardType",
  mastersMaterial: "/masters/materials",
  createMastersMaterial: "/masters/materials/new",
  mastersViewMaterial: "/masters/materials/:materialId/view",
  mastersEditMaterial: "/masters/materials/:materialId/edit",
  bomList: "/masters/bom",
  AddBomList: "/masters/bom/new",
  editBomList: "/masters/bom/:bomId/edit",
  viewBomList: "/masters/bom/:bomId/view",
  timeSheet: "/timesheet",
  timeSheetUpload: "/timesheet/upload",

  inventoryPurchase: "/inventory/purchases",
  createInventoryPurchase: "/inventory/purchases/new",
  inventoryViewPurchase: "/inventory/purchases/:purchaseId/view",
  inventoryEditPurchase: "/inventory/purchases/:purchaseId/edit",

  inventoryMaterialToTechnician: "/inventory/materialToTechnician",
  createInventoryMaterialToTechnician: "/inventory/materialToTechnician/new",
  viewInventoryMaterialToTechnician:
    "/inventory/materialToTechnician/:materialToTechnicianId/view",
  editInventoryMaterialToTechnician:
    "/inventory/materialToTechnician/:materialToTechnicianId/edit",

  inventoryMaterialRequest: "/inventory/materialRequest",
  createInventoryMaterialRequest: "/inventory/materialRequest/new",
  viewInventoryMaterialRequest:
    "/inventory/materialRequest/:materialToTechnicianId/view",
  editInventoryMaterialRequest:
    "/inventory/materialRequest/:materialToTechnicianId/edit",

  inventoryReturnMaterialFromTechnician:
    "/inventory/returnMaterialFromTechnician",
  createInventoryReturnMaterialFromTechnician:
    "/inventory/returnMaterialFromTechnician/new",
  viewInventoryReturnMaterialFromTechnician:
    "/inventory/returnMaterialFromTechnician/:returnMaterialFromTechnicianId/view",
  editInventoryReturnMaterialFromTechnician:
    "/inventory/returnMaterialFromTechnician/:returnMaterialFromTechnicianId/edit",

  inventoryReturnMaterialToSupplier: "/inventory/returnMaterialToSupplier",
  createInventoryReturnMaterialToSupplier:
    "/inventory/returnMaterialToSupplier/new",
  viewInventoryReturnMaterialToSupplier:
    "/inventory/returnMaterialToSupplier/:returnMaterialToSupplierId/view",
  editInventoryReturnMaterialToSupplier:
    "/inventory/returnMaterialToSupplier/:returnMaterialToSupplierId/edit",

  inventoryreplaceMaterialFromSupplier:
    "/inventory/replaceMaterialFromSupplier",
  createInventoryreplaceMaterialFromSupplier:
    "/inventory/replaceMaterialFromSupplier/new",
  viewInventoryreplaceMaterialFromSupplier:
    "/inventory/replaceMaterialFromSupplier/:replaceMaterialFromSupplierId/view",
  editInventoryreplaceMaterialFromSupplier:
    "/inventory/replaceMaterialFromSupplier/:replaceMaterialFromSupplierId/edit",
  report: "/inventory/report",
  stockAdjustment: "/inventory/stockAdjustment",
  stockAdjustmentAdd: "/inventory/stockAdjustment/add",

  // inventoryStocksMasters: "/inventory/stocks_master",
  // createInventoryStocksMasters: "/inventory/stocks_master/new",
  // inventoryViewStocksMasters: "/inventory/stocks_master/:stocks_masterId/view",

  // inventorySupplierInfo: "/inventory/supplier_info",
  // createInventorySupplierInfo: "/inventory/supplier_info/new",
  // inventoryViewSupplierInfo: "/inventory/supplier_info/:supplier_infoId/view",

  getMemberUrl: (id: string) => `/members/${id}`,
  editMember: "/members/:memberId/edit",
  adminPersonalProfile: "/profile/personal_profile",
  admincompanyProfile: "/profile/company_profile",
  adminvehicleInformation: "/profile/vehicle_information",
  adminChangePassword: "/profile_change_password",
  viewPersonalProfile: "/viewpersonalprofile",
  personalProfile: "/members/:memberId/edit_personal_profile",
  companyProfile: "/members/:memberId/edit_company_profile",
  vehicleInformation: "/members/:memberId/edit_vehicle_information",
  memberPasswordReset: "/members/:memberId/edit_reset_password",
  personalProfileUrl: (id: any) => `/members/${id}/edit_personal_profile`,
  editMemberUrl: (id: any) => `/members/${id}/edit`,
  vehicleInformationUrl: (id: any) => `/members/${id}/edit_vehicle_information`,
  memberPasswordResetUrl: (id: any) => `/members/${id}/edit_reset_password`,
  companyProfileUrl: (id: any) => `/members/${id}/edit_company_profile`,
  getBillBoardUrl: (id: any) => `/billboards/${id}`,
  editBillBoardUrl: (id: any) => `/billboards/${id}/edit`,
  getBillBoardTicketsUrl: (id: any) => `/billboards/${id}/tickets`,
  getBillBoardTicketsAddUrl: (id: any) => `/billboards/${id}/tickets/new`,
  getBillBoardTicketsActivityFeedUrl: (billboardId: any, ticketId: any) =>
    `/billboards/${billboardId}/tickets/${ticketId}/activities`,
  getBillBoardTicketsIdUrl: (billboardId: any, ticketId: any) =>
    `/billboards/${billboardId}/tickets/${ticketId}/edit`,
  editTicketUrl: (ticketId: any) => `/tickets/${ticketId}/edit`,
  activityTicketUrl: (ticketId: any) => `/tickets/${ticketId}/activity`,
  listMembersUrl: (params: any) =>
    params ? `/members?${filterQueryParams(params)}` : "/members",
  listBillBoardUrl: (params: any) =>
    params ? `/billboards?${filterQueryParams(params)}` : "/billboards",
  listTicketsUrl: (params: any) =>
    params ? `/tickets?${filterQueryParams(params)}` : "/tickets",
  OverViewTicketsUrl: (params: any) =>
    params ? `/ticketOverview?${filterQueryParams(params)}` : "/ticketOverview",
  listMasterBillBoardTypeUrl: (params: any) =>
    params
      ? `/masters/billBoardType?${filterQueryParams(params)}`
      : "/masters/billBoardType",

  // ----------------------------------------inventory--------------------------------------------------
  listSupplierUrl: (params: any) =>
    params
      ? `/masters/suppliers?${filterQueryParams(params)}`
      : "/masters/suppliers",
  listMaterialUrl: (params: any) =>
    params
      ? `/masters/materials?${filterQueryParams(params)}`
      : "/masters/materials",
  listpurchasesUrl: (params: any) =>
    params
      ? `/inventory/purchases?${filterQueryParams(params)}`
      : "/inventory/purchases",
  listmaterialToTechnicianUrl: (params: any) =>
    params
      ? `/inventory/materialToTechnician?${filterQueryParams(params)}`
      : "/inventory/materialToTechnician",
  listmaterialRequestUrl: (params: any) =>
    params
      ? `/inventory/materialRequest?${filterQueryParams(params)}`
      : "/inventory/materialRequest",
  listreturnMaterialFromTechnicianUrl: (params: any) =>
    params
      ? `/inventory/returnMaterialFromTechnician?${filterQueryParams(params)}`
      : "/inventory/returnMaterialFromTechnician",
  listreturnMaterialToSupplierUrl: (params: any) =>
    params
      ? `/inventory/returnMaterialToSupplier?${filterQueryParams(params)}`
      : "/inventory/returnMaterialToSupplier",
  listreplaceMaterialFromSupplierUrl: (params: any) =>
    params
      ? `/inventory/replaceMaterialFromSupplier?${filterQueryParams(params)}`
      : "/inventory/replaceMaterialFromSupplier",

  getMastersViewSupplier: (id: any) => `/masters/suppliers/${id}/view`,
  getMastersEditSupplier: (id: any) => `/masters/suppliers/${id}/edit`,

  getMastersViewMaterial: (id: any) => `/masters/materials/${id}/view`,
  getMastersEditMaterial: (id: any) => `/masters/materials/${id}/edit`,

  getinventoryViewPurchase: (id: any) => `/inventory/purchases/${id}/view`,
  getinventoryEditPurchase: (id: any) => `/inventory/purchases/${id}/edit`,

  getInventoryViewMaterialToTechnician: (id: any) =>
    `/inventory/materialToTechnician/${id}/view`,
  getInventoryEditMaterialToTechnician: (id: any) =>
    `/inventory/materialToTechnician/${id}/edit`,
  getInventoryViewMaterialRequest: (id: any) =>
    `/inventory/materialRequest/${id}/view`,
  getInventoryEditMaterialRequest: (id: any) =>
    `/inventory/materialRequest/${id}/edit`,

  // ----------------------------------------cleaning Module--------------------------------------------------

  Cleaning: "/cleaning",
  listCleaning: "/cleaning",
  newCleaning: "/cleaning/new",
  getCleaning: "/cleaning/:cleaningId",
  editCleaning: "/cleaning/:cleaningId",
  getCleaningTicketsId: "/cleaning/:cleaningId/tickets/:ticketId/edit",
  getCleaningTicketsActivityFeed:
    "/cleaning/:cleaningId/tickets/:ticketId/activities",

  listCleaningUrl: (params: any) =>
    params ? `/cleaning?${filterQueryParams(params)}` : "/cleaning",

  editCleaningUrl: (cleaningId: any, params?: any) =>
    params
      ? `/cleaning/${cleaningId}?${filterQueryParams(params)}`
      : `/cleaning/${cleaningId}`,
  activityCleaningUrl: (cleaningId: any) => `/cleaning/${cleaningId}/activity`,
  getCleaningTicketsIdUrl: (cleaningId: any, ticketId: any) =>
    `/cleaning/${cleaningId}/tickets/${ticketId}/edit`,
  getCleaningTicketsActivityFeedUrl: (cleaningId: any, ticketId: any) =>
    `/cleaning/${cleaningId}/tickets/${ticketId}/activities`,

  listMaster: "/cleaning/master",

  getInventoryViewReturnMaterialFromTechnician: (id: any) =>
    `/inventory/returnMaterialFromTechnician/${id}/view`,
  getInventoryEditReturnMaterialFromTechnician: (id: any) =>
    `/inventory/returnMaterialFromTechnician/${id}/edit`,

  getInventoryViewReturnMaterialToSupplier: (id: any) =>
    `/inventory/returnMaterialToSupplier/${id}/view`,
  getInventoryEditReturnMaterialToSupplier: (id: any) =>
    `/inventory/returnMaterialToSupplier/${id}/edit`,

  getInventoryViewreplaceMaterialFromSupplier: (id: any) =>
    `/inventory/replaceMaterialFromSupplier/${id}/view`,
  getInventoryEditreplaceMaterialFromSupplier: (id: any) =>
    `/inventory/replaceMaterialFromSupplier/${id}/edit`,

  getPersonalProfileUrl: (id: any, params: any) =>
    params
      ? `/members/${id}/edit_personal_profile?${filterQueryParams(params)}`
      : `/members/${id}/edit_personal_profile`,
  getVehicleInformationUrl: (id: any, params: any) =>
    params
      ? `/members/${id}/edit_vehicle_information?${filterQueryParams(params)}`
      : `/members/${id}/edit_vehicle_information`,
  getMemberPasswordResetUrl: (id: any, params: any) =>
    params
      ? `/members/${id}/edit_reset_password?${filterQueryParams(params)}`
      : `/members/${id}/edit_reset_password`,
  getCompanyProfileUrl: (id: any, params: any) =>
    params
      ? `/members/${id}/edit_company_profile?${filterQueryParams(params)}`
      : `/members/${id}/edit_company_profile`,
  getQueryBillBoardUrl: (id: any, params: any) =>
    params
      ? `/billboards/${id}?${filterQueryParams(params)}`
      : `/billboards/${id}`,
  getEditBillBoardUrl: (id: any, params: any) =>
    params
      ? `/billboards/${id}/edit?${filterQueryParams(params)}`
      : `/billboards/${id}/edit`,
  getQueryBillBoardTicketsUrl: (id: any, params: any) =>
    params
      ? `/billboards/${id}/tickets?${filterQueryParams(params)}`
      : `/billboards/${id}/tickets`,
  getQueryBillBoardTicketsAddUrl: (id: any, params: any) =>
    params
      ? `/billboards/${id}/tickets/new?${filterQueryParams(params)}`
      : `/billboards/${id}/tickets/new`,
  getQueryBillBoardTicketsActivityFeedUrl: (
    billboardId: any,
    ticketId: any,
    params: any
  ) =>
    params
      ? `/billboards/${billboardId}/tickets/${ticketId}/activities?${filterQueryParams(
        params
      )}`
      : `/billboards/${billboardId}/tickets/${ticketId}/activities`,
  getQueryBillBoardTicketsIdUrl: (
    billboardId: any,
    ticketId: any,
    params: any
  ) =>
    params
      ? `/billboards/${billboardId}/tickets/${ticketId}/edit?${filterQueryParams(
        params
      )}`
      : `/billboards/${billboardId}/tickets/${ticketId}/edit`,
  getEditTicketUrl: (ticketId: any, params: any) =>
    params
      ? `/tickets/${ticketId}/edit?${filterQueryParams(params)}`
      : `/tickets/${ticketId}/edit`,
  getActivityTicketUrl: (ticketId: any, params: any) =>
    params
      ? `/tickets/${ticketId}/activity?${filterQueryParams(params)}`
      : `/tickets/${ticketId}/activity`,
  getNewBillBoardUrl: (params: any) =>
    params ? `/billboards/new?${filterQueryParams(params)}` : "/billboards/new",
  getUploadBillBoardUrl: (params: any) =>
    params
      ? `/billboards/upload_new?${filterQueryParams(params)}`
      : "/billboards/upload_new",
  getBillOfMaterialsUrl: (id: any, params: any) =>
    params
      ? `/billboards/${id}/bom?${filterQueryParams(params)}`
      : `/billboards/${id}/bom`,
  getNewTicketUrl: (params: any) =>
    params ? `/tickets/new?${filterQueryParams(params)}` : "/tickets/new",
  getMasterUrl: (params: any) =>
    params ? `/masters?${filterQueryParams(params)}` : "/masters",
  getBOMList: (params: any) =>
    params ? `/masters/bom?${filterQueryParams(params)}` : "/masters/bom",
  getEditBOMList: (id: any) => `/masters/bom/${id}/edit`,
  getViewBOMList: (id: any) => `/masters/bom/${id}/view`,
  stockAdjustmentList: (params: any) =>
    params
      ? `/inventory/stockAdjustment?${filterQueryParams(params)}`
      : "/inventory/stockAdjustment",

  getBillBoardMap: (params: any) =>
    params
      ? `/billboards/map?${filterQueryParams(params)}`
      : "/billboards/map",

};
