import { getTrackReferenceId, isLocal } from "@livekit/components-core";
import { AudioTrack, useTracks } from "@livekit/components-react";
import { Track } from "livekit-client";

const RoomAudio = () => {
  const tracks = useTracks(
    [
      Track.Source.Microphone,
      Track.Source.ScreenShareAudio,
      Track.Source.Unknown,
    ],
    {
      updateOnlyOn: [],
      onlySubscribed: true,
    }
  ).filter(
    (ref) =>
      !isLocal(ref.participant) && ref.publication.kind === Track.Kind.Audio
  );
  return (
    <div>
      {tracks.map((trackRef: any) => {
        return (
          <div className=" position-relative">
            <AudioTrack
              key={getTrackReferenceId(trackRef)}
              trackRef={trackRef}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RoomAudio;
