import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "./context/UserProvider";
import AppRoutes from "./routes/AppRoutes";
import "./styles.scss";

export default function ChatApp() {
  // const { isLoaded, progress } = useAppLoad();
  // const room = useRoomContext();
  // const [isConnected, setConnected] = useState<boolean>(false);
  // useEffect(() => {
  //   room.on(RoomEvent.Connected, handleConnect);
  //   return () => {
  //     room.on(RoomEvent.Disconnected, handleDisconnect);
  //   };
  // }, [room]);
  // const handleConnect = () => {
  //   setConnected(true);
  // };
  // const handleDisconnect = () => {
  //   setConnected(false);
  //   alert("user is disconnected");
  //   console.log("livekit is disconnected kks");
  // };
  // console.log(isConnected, "isConnected");
  return (
    <>
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </>
  );
}
