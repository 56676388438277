import { LIVE_URL, post, put } from "../axiosInstance";

interface CreateRoom {
    success: boolean;
    token: string;
}

const connectRoom = async (
    params: {
        roomName: string;
        participantName: string;
    }
): Promise<CreateRoom | undefined> => {
    try {
        const response = await post(`${LIVE_URL}livekit/tokenGenerate`, null, params);
        return response?.data as CreateRoom;
    } catch (error: unknown) {
        console.error("Error while generating token", error);
        return undefined;
    }
};

const sendMessage = async (
    params: {
        roomName: string;
        localParticipant: string;
        message: unknown; 
        dId: unknown; 
        topic: string;
        userName: string;
    }
): Promise<any> => { 
    try {
        const response = await post(`${LIVE_URL}livekit/sendMessage`, null, params);
        return response;
    } catch (error: unknown) {
        console.error("Error sending message to LiveKit", error);
        return null; 
    }
};

const updateMeta = async (
    roomName: string,
    participantName: string,
    newMetadata: unknown 
): Promise<any> => { 
    try {
        const response = await put(`${LIVE_URL}livekit/updateMeta`, null, { roomName, participantName, newMetadata });
        return response;
    } catch (error: unknown) {
        console.error("Error updating metadata in LiveKit", error);
        return null; 
    }
};

const LiveKitService = {
    connectRoom,
    sendMessage,
    updateMeta,
};

export default LiveKitService;
