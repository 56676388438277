import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useEffect, useState } from "react";
import moment from "moment";
import ReactCustomSelect from "../../components/CommonComponents/ReactCustomSelect";
import CustomLocationSelect from "../../components/CommonComponents/CustomLocationSelect";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import { updateText } from "../../utils/api";
import Moment from "react-moment";
import CommonToggleSwitch from "../../components/CommonToggleSwitch/CommonToggleSwitch";
import CommonButton from "../../components/CommonButton/CommonButton";
import FormikField from "../../components/FormikField/FormikField";
import CustomInputField from "../../components/InputField/CustomInputField";
import FormikDateField from "../../components/FormikDateField/FormikDateField";
import { getGeocode, getLatLng } from "use-places-autocomplete";

const CreateBillBoardForm = ({
  context,
  userContext,
  setTextMessage,
  setShowPopUp,
  setUpdatedBillBoardDetail,
  setFormikDetails,
  handleUpdateData,
  setHeaderMessage,
  setBodyMessage,
  screenUnits,
  setShow,
}: any) => {
  const [computerPasswordEye, setComputerPasswordEye] = useState(false);
  const [teamViewerPasswordEye, setTeamViewerPasswordEye] = useState(false);
  const [windowsPasswordEye, setWindowsPasswordEye] = useState(false);
  const { t } = useTranslation();
  const urlParams = useParams();

  let latRegex = /^\d+(\.\d{0,10})?$/;

  const initialValues = {
    board_no: urlParams?.billboardId
      ? context?.billBoard?.board_no
        ? context?.billBoard?.board_no
        : ""
      : "",
    city: urlParams?.billboardId
      ? context?.billBoard?.city
        ? context?.billBoard?.city
        : ""
      : "",
    // city: "",
    board_location: urlParams?.billboardId
      ? context?.billBoard?.board_location
        ? context?.billBoard?.board_location
        : ""
      : "",
    board_type: urlParams?.billboardId
      ? context?.billBoard?.board_type
        ? context?.billBoard?.board_type
        : ""
      : "",
    latitude: context?.billBoard?.location?.coordinates[1]
      ? context?.billBoard?.location?.coordinates[1]
      : "",
    longitude: context?.billBoard?.location?.coordinates[0]
      ? context?.billBoard?.location?.coordinates[0]
      : "",
    screen_width: urlParams?.billboardId
      ? context?.billBoard?.screen_size
        ? context?.billBoard?.screen_size?.replace("X", " ")?.split(" ")[0]
        : ""
      : "",
    screen_height: urlParams?.billboardId
      ? context?.billBoard?.screen_size
        ? context?.billBoard?.screen_size?.replace("X", " ")?.split(" ")[1]
        : ""
      : "",
    screen_units: urlParams?.billboardId
      ? context?.billBoard?.screen_size
        ? context?.billBoard?.screen_size?.replace("X", " ")?.split(" ")[2]
        : ""
      : "",
    installation_date: urlParams?.billboardId
      ? context?.billBoard?.installation_date
        ? moment(context?.billBoard?.installation_date).format("yyyy-MM-DD")
        : ""
      : "",
    operational_date: urlParams?.billboardId
      ? context?.billBoard?.operational_date
        ? moment(context?.billBoard?.operational_date).format("yyyy-MM-DD")
        : ""
      : "",
    installation_done_by: urlParams?.billboardId
      ? context?.billBoard?.installation_done_by
        ? context?.billBoard?.installation_done_by
        : ""
      : "",
    screen_model: urlParams?.billboardId
      ? context?.billBoard?.screen_model
        ? context?.billBoard?.screen_model
        : ""
      : "",
    screen_resolution: urlParams?.billboardId
      ? context?.billBoard?.screen_resolution
        ? context?.billBoard?.screen_resolution
        : ""
      : "",
    no_of_cabinets: urlParams?.billboardId
      ? context?.billBoard?.no_of_cabinets
        ? context?.billBoard?.no_of_cabinets
        : ""
      : "",
    module_no: urlParams?.billboardId
      ? context?.billBoard?.module_no
        ? context?.billBoard?.module_no
        : ""
      : "",
    module_type: urlParams?.billboardId
      ? context?.billBoard?.module_type
        ? context?.billBoard?.module_type
        : ""
      : "",
    light_sensor: urlParams?.billboardId
      ? context?.billBoard?.light_sensor
        ? context?.billBoard?.light_sensor
        : ""
      : "",
    computer_password: urlParams?.billboardId
      ? context?.billBoard?.computer_password
        ? context?.billBoard?.computer_password
        : ""
      : "",
    team_viewer_id: urlParams?.billboardId
      ? context?.billBoard?.team_viewer_id
        ? context?.billBoard?.team_viewer_id
        : ""
      : "",
    team_viewer_password: urlParams?.billboardId
      ? context?.billBoard?.team_viewer_password
        ? context?.billBoard?.team_viewer_password
        : ""
      : "",
    windows_user_id: urlParams?.billboardId
      ? context?.billBoard?.windows_user_id
        ? context?.billBoard?.windows_user_id
        : ""
      : "",
    windows_password: urlParams?.billboardId
      ? context?.billBoard?.windows_password
        ? context?.billBoard?.windows_password
        : ""
      : "",
    screen_pixel: urlParams?.billboardId
      ? context?.billBoard?.screen_pixel
        ? context?.billBoard?.screen_pixel
        : ""
      : "",
    bom: urlParams?.billboardId
      ? context?.billBoard?.bom?._id
        ? context?.billBoard?.bom?._id
        : ""
      : "",
    bom_name: urlParams?.billboardId
      ? context?.billBoard?.bom?.bom_name
        ? context?.billBoard?.bom?.bom_name
        : ""
      : "",
    is_novastar: urlParams?.billboardId
      ? context?.billBoard?.is_novastar
        ? context?.billBoard?.is_novastar
        : false
      : false,
    is_ivms: urlParams?.billboardId
      ? context?.billBoard?.is_ivms
        ? context?.billBoard?.is_ivms
        : false
      : false,
  };

  const validationSchema = Yup.object({
    board_no: Yup.string().required("Billboard Number is required"),
    city: Yup.string(),
    board_location: Yup.string().required("Billboard Location is required"),
    board_type: Yup.string().required("Billboard Type is required"),
    latitude: Yup.string(),
    longitude: Yup.string(),
    // .matches(
    //   latRegex,
    //   "That doesn't look like a Longitude"
    // )
    screen_width: Yup.string()
      .matches(latRegex, "That doesn't look like a Screen Width")
      .required("Screen Width is required"),
    screen_height: Yup.string()
      .matches(latRegex, "That doesn't look like a Screen Height")
      .required("Screen Height is required"),
    screen_units: Yup.string().required("Screen Units is required"),
    screen_resolution: Yup.string().required("Screen Resolution is required"),
    screen_pixel: Yup.string().required("Screen Pixel is required"),
    team_viewer_id: Yup.string().required("Team Viewer Id is required"),
    team_viewer_password: Yup.string().required(
      "Team Viewer Password is required"
    ),
    bom: Yup.string(),
    bom_name: Yup.string(),
    is_novastar: Yup.boolean().required("NovaStar implemented is required"),
    is_ivms: Yup.boolean().required("IVMS implemented is required"),
  });

  const onSubmit = async (
    values: any,
    { setSubmitting, validateForm }: any
  ) => {
    if (!values.bom) {
      delete values?.bom;
    }
    validateForm(values);
    setTextMessage(`${t("successError.update it")}`);
    setHeaderMessage(
      urlParams.billboardId ? `${t("successError.update the billboard")}` : ""
    );
    setShowPopUp(urlParams.billboardId);
    setUpdatedBillBoardDetail(formik.values);
    setFormikDetails(formik);
    if (!urlParams.billboardId) {
      handleUpdateData(formik.values);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });

  const { errors, handleSubmit, isSubmitting, isValid, dirty, setSubmitting } =
    formik;

  const [billBoardtypeList, setBillBoardtypeList] = useState<any>([]);
  const [bomList, setBomList] = useState<any>([]);
  const [boardscreenResolutionList, setBoardscreenResolutionList] =
    useState<any>([]);
  const [boardscreenPixelList, setBoardscreenPixelList] = useState<any>([]);

  useEffect(() => {
    getbillBoardtypeDetail();
    getbillBoardscreenResolutionDetail();
    getbillBoardscreenPixelListDetail();
  }, []);

  useEffect(() => {
    if (formik?.values?.board_type?.length > 0) {
      getBomDetail();
    }
  }, [formik?.values]);

  const getbillBoardtypeDetail = async () => {
    const { success, data } = await userContext?.getAllCleangingConfigDetails(
      {}
    );
    if (success) {
      let res = data?.map((d: any) => {
        return {
          label: updateText(d?.billboard_type?.replace("_", " ")),
          value: d?.billboard_type,
        };
      });
      setBillBoardtypeList(res);
    }
  };

  const getBomDetail = async () => {
    const { success, bom } = await userContext?.getAllBomDetails({
      billboard_type: formik?.values?.board_type || "",
    });
    if (success) {
      let res = bom?.map((d: any) => {
        return {
          label: updateText(d?.bom_name?.replace("_", " ")),
          value: d?._id,
        };
      });
      setBomList(res);
    }
  };

  const getbillBoardscreenResolutionDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "SCREEN_RESOLUTION",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setBoardscreenResolutionList(res);
    }
  };

  const getbillBoardscreenPixelListDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "SCREEN_PIXEL",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setBoardscreenPixelList(res);
    }
  };
  interface billboardAutocompleteProps {
    readonly label: string;
    readonly value: string;
  }

  const getbillBoardtypeList: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { data } = await userContext?.getAllCleangingConfigDetails({
      search: inputValue,
      status: "ACTIVE",
    });
    callback(
      data?.map((d: any) => ({
        label: updateText(d?.billboard_type?.replace("_", " ")),
        value: d?.billboard_type,
      }))
    );
  };

  const getBomList: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { bom } = await userContext?.getAllBomDetails({
      search: inputValue,
      status: "ACTIVE",
      billboard_type: formik?.values?.board_type || "",
    });
    callback(
      bom?.map((d: any) => ({
        label: updateText(d?.bom_name?.replace("_", " ")),
        value: d?._id,
      }))
    );
  };

  const getbillBoardscreenResolutionList: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
      config_type: "SCREEN_RESOLUTION",
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  const getbillBoardscreenPixelList: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
      config_type: "SCREEN_PIXEL",
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  const billBoardDisabled = () => {
    return (
      !!urlParams?.billboardId &&
      (!userContext?.roleAccess["BOARD"].includes("UPDATE") ||
        context?.billBoard?.board_status !== "ACTIVE")
    );
  };

  const handleSelect = async (val: string) => {
    const results = await getGeocode({ address: val });
    const { lat, lng } = await getLatLng(results[0]);

    let city = "";
    results[0].address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
    });
    if (!context?.billBoard?.board_location) {
      formik.setFieldValue("longitude", lng);
      formik.setFieldValue("latitude", lat);
    }
    // if (city) {
    //   formik.setFieldValue("city", city);
    // }
  };

  useEffect(() => {
    if (context?.billBoard?.board_location) {
      handleSelect(context?.billBoard?.board_location);
    }
  }, [context?.billBoard?.board_location]);

  return (
    <div className=" mt-3">
      <FormikProvider value={formik}>
        <Form validated={true} onSubmit={handleSubmit}>
          <div className={`${isMobile ? " " : ""}`}>
            <Row>
              <Col md={12} className=" mb-4">
                <FormikField
                  name="board_no"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.BillBoard_Number")}
                  errors={errors as Record<string, string>}
                  disabled={
                    !userContext?.roleAccess["BOARD"].includes("BOARD_NO") &&
                    (!userContext?.roleAccess["BOARD"].includes("UPDATE") ||
                      context?.billBoard?.board_status !== "ACTIVE" ||
                      !userContext?.roleAccess["BOARD"].includes("BOARD_NO"))
                  }
                />
              </Col>
              <Col md={12} className="formprofile mb-4">
                <Field name="board_location">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("CreateBillBoardForm.BillBoard_Location")}
                        error={errors["board_location"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CustomLocationSelect
                          fiedName={"board_location"}
                          isFieldDisabled={
                            urlParams?.billboardId &&
                            (!userContext?.roleAccess["BOARD"].includes(
                              "UPDATE"
                            ) ||
                              context?.billBoard?.board_status !== "ACTIVE")
                          }
                          className={"autolocation"}
                          updateField={(data: any) => {
                            formik.setFieldValue(
                              "board_location",
                              data?.formatted_address
                            );
                            if (data?.name) {
                              handleSelect(data?.name);
                            }
                          }}
                          defaultValue={formik?.values?.board_location as any}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
              <Col md={12} className="formprofile mb-4">
                <Field name="city">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("CreateBillBoardForm.City")}
                        error={errors["city"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CustomLocationSelect
                          fiedName={"city"}
                          // isFieldDisabled={
                          //   urlParams?.billboardId &&
                          //   (!userContext?.roleAccess["BOARD"].includes(
                          //     "UPDATE"
                          //   ) ||
                          //     context?.billBoard?.board_status !== "ACTIVE")
                          // }
                          className={"autolocation"}
                          updateField={(data: any) => {
                            // if (!formik.values.city) {
                            //   formik.setFieldValue("city", data?.name);
                            // } else {

                            formik.setFieldValue(
                              "city",
                              data?.formatted_address
                            );
                            // }
                          }}
                          defaultValue={formik?.values?.city as any}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="latitude"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.latitude")}
                  errors={errors as Record<string, string>}
                />
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="longitude"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.longitude")}
                  errors={errors as Record<string, string>}
                />
              </Col>

              <Col md={6} className="formprofile mb-4">
                <Field name="board_type">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("CreateBillBoardForm.BillBoard_Type")}
                        error={errors["board_type"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isFieldDisabled={
                            urlParams?.billboardId &&
                            (!userContext?.roleAccess["BOARD"].includes(
                              "UPDATE"
                            ) ||
                              context?.billBoard?.board_status !== "ACTIVE")
                          }
                          isMulti={false}
                          values={{
                            label: updateText(formik?.values?.board_type),
                            value: formik?.values?.board_type,
                          }}
                          // placeholder="-select-"
                          options={billBoardtypeList}
                          cacheOptions
                          loadOptionValue={getbillBoardtypeList}
                          updateField={(data: any) => {
                            formik?.setFieldValue("board_type", data?.value);
                            formik?.setFieldValue("bom", "");
                            formik?.setFieldValue("bom_name", "");
                          }}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={6} className="formprofile mb-4">
                <Field name="bom_name">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={` ${t("CreateBillBoardForm.BOM_Type")} *`}
                        error={errors["bom_name"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isFieldDisabled={
                            !formik?.values?.board_type ||
                            (urlParams?.billboardId &&
                              (!userContext?.roleAccess["BOARD"].includes(
                                "UPDATE"
                              ) ||
                                context?.billBoard?.board_status !== "ACTIVE"))
                          }
                          isMulti={false}
                          values={{
                            label: updateText(formik?.values?.bom_name),
                            value: formik?.values?.bom,
                          }}
                          options={bomList}
                          cacheOptions
                          loadOptionValue={getBomList}
                          updateField={(data: any) => {
                            formik?.setFieldValue("bom", data?.value);
                            formik?.setFieldValue("bom_name", data?.label);
                            context?.setCurrentBomId(data?.value);
                          }}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={6} className="mb-4">
                <FormikField
                  name="team_viewer_id"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Team_Viewer_Id")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>

              <Col md={6} className="mb-4">
                <div className="position-relative">
                  <FormikField
                    name="team_viewer_password"
                    type={teamViewerPasswordEye ? "text" : "password"}
                    errors={errors as Record<string, string>}
                    validationSchema={validationSchema}
                    label={t("CreateBillBoardForm.Team_Viewer_Password")}
                    isPassword={true}
                    disabled={billBoardDisabled()}
                    setPasswordIcon={setTeamViewerPasswordEye}
                    passwordIcon={teamViewerPasswordEye}
                    maxLength={50}
                  />
                </div>
              </Col>

              <Col md={6} className="mb-4">
                <FormikField
                  name="screen_width"
                  type="number"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Screen_width")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="screen_height"
                  type="number"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Screen_height")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>

              <Col md={6} className="formprofile mb-4">
                <Field name="screen_resolution">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("CreateBillBoardForm.Screen_Resolution")}
                        error={errors["screen_resolution"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isFieldDisabled={
                            urlParams?.billboardId &&
                            (!userContext?.roleAccess["BOARD"].includes(
                              "UPDATE"
                            ) ||
                              context?.billBoard?.board_status !== "ACTIVE")
                          }
                          isMulti={false}
                          values={{
                            label: updateText(
                              formik?.values?.screen_resolution
                            ),
                            value: formik?.values?.screen_resolution,
                          }}
                          options={boardscreenResolutionList}
                          cacheOptions
                          loadOptionValue={getbillBoardscreenResolutionList}
                          updateField={(data: any) => {
                            formik?.setFieldValue(
                              "screen_resolution",
                              data?.value
                            );
                          }}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
              <Col md={6} className="formprofile mb-4">
                <Field name="screen_units">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("CreateBillBoardForm.Screen_units")}
                        error={errors["screen_units"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <ReactCustomSelect
                          isFieldDisabled={
                            urlParams?.billboardId &&
                            (!userContext?.roleAccess["BOARD"].includes(
                              "UPDATE"
                            ) ||
                              context?.billBoard?.board_status !== "ACTIVE")
                          }
                          className={"select-wrapper"}
                          feildName={"screen_units"}
                          defaultOptions={{
                            label: `${t(formik?.values?.screen_units)}`,
                            value: formik?.values?.screen_units,
                          }}
                          isMulti={false}
                          updateFieldValue={(data: any) =>
                            formik?.setFieldValue("screen_units", data.value)
                          }
                          dataOptions={screenUnits}
                          searchDisabled={false}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
              <Col md={6} className="formprofile mb-4">
                <Field name="screen_pixel">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("CreateBillBoardForm.screen_pixel")}
                        error={errors["screen_pixel"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isFieldDisabled={
                            urlParams?.billboardId &&
                            (!userContext?.roleAccess["BOARD"].includes(
                              "UPDATE"
                            ) ||
                              context?.billBoard?.board_status !== "ACTIVE")
                          }
                          isMulti={false}
                          values={{
                            label: updateText(formik?.values?.screen_pixel),
                            value: formik?.values?.screen_pixel,
                          }}
                          options={boardscreenPixelList}
                          cacheOptions
                          loadOptionValue={getbillBoardscreenPixelList}
                          updateField={(data: any) => {
                            formik?.setFieldValue("screen_pixel", data?.value);
                          }}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>

              <Col md={6} className="mb-4">
                <FormikField
                  name="screen_model"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Screen_Model")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="module_no"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Module_Number")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="module_type"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Module_Type")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="no_of_cabinets"
                  type="number"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Number_Of_Cabinets")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="light_sensor"
                  type="string"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Light_Sensor")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>
              <Col md={6} className="formprofile mb-4">
                <FormikDateField
                  name={"installation_date"}
                  label={t("CreateBillBoardForm.Installation_Date")}
                  isDisabled={
                    urlParams?.billboardId &&
                    (!userContext?.roleAccess["BOARD"].includes("UPDATE") ||
                      context?.billBoard?.board_status !== "ACTIVE")
                      ? true
                      : false
                  }
                  maxDate={moment(new Date()).toDate()}
                />
              </Col>
              <Col md={6} className="formprofile mb-4">
                <FormikDateField
                  name={"operational_date"}
                  label={t("CreateBillBoardForm.Operational_Date")}
                  isDisabled={
                    urlParams?.billboardId &&
                    (!userContext?.roleAccess["BOARD"].includes("UPDATE") ||
                      context?.billBoard?.board_status !== "ACTIVE")
                      ? true
                      : false
                  }
                />
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="installation_done_by"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Installation_Done_By")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>

              <Col md={6} className="mb-4">
                <div className="position-relative">
                  <FormikField
                    name="computer_password"
                    type={computerPasswordEye ? "text" : "password"}
                    errors={errors as Record<string, string>}
                    validationSchema={validationSchema}
                    label={t("CreateBillBoardForm.Computer_Password")}
                    isPassword={true}
                    disabled={billBoardDisabled()}
                    setPasswordIcon={setComputerPasswordEye}
                    passwordIcon={computerPasswordEye}
                    maxLength={50}
                  />
                </div>
              </Col>
              <Col md={6} className="mb-4">
                <FormikField
                  name="windows_user_id"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("CreateBillBoardForm.Windows_User_Id")}
                  errors={errors as Record<string, string>}
                  disabled={billBoardDisabled()}
                />
              </Col>

              <Col md={6} className="mb-4">
                <div className="position-relative">
                  <FormikField
                    name="windows_password"
                    type={windowsPasswordEye ? "text" : "password"}
                    errors={errors as Record<string, string>}
                    validationSchema={validationSchema}
                    label={t("CreateBillBoardForm.Windows_Password")}
                    isPassword={true}
                    disabled={billBoardDisabled()}
                    setPasswordIcon={setWindowsPasswordEye}
                    passwordIcon={windowsPasswordEye}
                    maxLength={50}
                  />
                </div>
              </Col>

              <Col md={6} className="formprofile mb-4">
                <Field name="is_novastar">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={`${t(
                          "CreateBillBoardForm.NovaStar Implemented"
                        )} `}
                        error={errors["is_novastar"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonToggleSwitch
                          textOff={"No"}
                          textOn={"Yes"}
                          checked={formik.values.is_novastar}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "is_novastar",
                              e.target.checked
                            )
                          }
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
              <Col md={6} className="formprofile mb-4">
                <Field name="is_ivms">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={`${t("CreateBillBoardForm.IVMS Implemented")} `}
                        error={errors["is_ivms"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CommonToggleSwitch
                          textOff={"No"}
                          textOn={"Yes"}
                          checked={formik.values.is_ivms}
                          onChange={(e) =>
                            formik.setFieldValue("is_ivms", e.target.checked)
                          }
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
            </Row>
          </div>

          {userContext?.roleAccess["BOARD"].includes("UPDATE") &&
            (urlParams.billboardId
              ? context?.billBoard?.board_status === "ACTIVE"
              : true) && (
              <>
                <div className="d-flex justify-content-between py-5">
                  <div>
                    <div>
                      {!urlParams.billboardId && context?.currentBomId ? (
                        <CommonButton
                          text={t("masters.View Bom")}
                          variant="outline-secondary"
                          className=" bg-transparent text-secondary"
                          onClick={() => setShow(true)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className=" d-flex align-items-center">
                    <CommonButton
                      text={t("Reset_form")}
                      type="button"
                      isLoading={isSubmitting}
                      variant="outline-danger"
                      className="bg-transparent text-danger"
                      isDisabled={isSubmitting || !dirty}
                      onClick={() => {
                        setTextMessage(`${t("successError.cancel it")}`);
                        setHeaderMessage(
                          `${t("successError.cancel the changes")}`
                        );
                        setBodyMessage(`${t("successError.cancel it")}`);
                        setShowPopUp(true);
                        setUpdatedBillBoardDetail(formik.values);
                        setFormikDetails(formik);
                      }}
                    />
                    <CommonButton
                      text={
                        urlParams.billboardId
                          ? `${t("CreateBillBoardForm.Update_BillBoard")}`
                          : `${t("CreateBillBoardForm.Create_BillBoard")}`
                      }
                      isLoading={isSubmitting}
                      type="submit"
                      variant="success"
                      className="btn-i18n text-white"
                      isDisabled={isSubmitting || !isValid || !dirty}
                    />
                  </div>
                </div>
              </>
            )}
          {(context?.billBoard?.board_status === "REQUEST" ||
            context?.billBoard?.board_status === "NON_OPERATIONAL") && (
            <div
              className=" px-4 border py-3  me-3 mt-3"
              style={{ width: "100%" }}
            >
              <div>
                <label>{"Requested By"} </label>
                <div>{context?.billBoard?.requested_by?.name}</div>
              </div>
              <div>
                <div className="mt-4">
                  <label>{"Requested On"} </label>
                  <div>
                    <Moment format="DD/MM/yyyy">
                      {context?.billBoard?.requested_on}
                    </Moment>
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-4">
                  <label>{"Reason"} </label>
                  <div className="">{context?.billBoard?.reason}</div>
                </div>
              </div>
            </div>
          )}
          {userContext?.roleAccess["BOARD"].includes("APPROVE/REJECT") &&
            context?.billBoard?.board_status === "REQUEST" &&
            urlParams?.billboardId && (
              <div
                className={`${
                  isMobileOnly
                    ? "d-flex justify-content-between py-5"
                    : "d-flex justify-content-end py-5"
                }`}
              >
                <CommonButton
                  text={t("CreateBillBoardForm.reject")}
                  isLoading={isSubmitting}
                  type="button"
                  variant="outline-danger"
                  className="bg-transparent text-danger"
                  onClick={() => {
                    setSubmitting(true);
                    setTextMessage(`${t("successError.mark it")}`);
                    setHeaderMessage(
                      `${t(
                        "successError.reject the billboard as non-operational"
                      )}`
                    );
                    setShowPopUp(true);
                    setSubmitting(false);
                  }}
                />
                <CommonButton
                  text={t("CreateBillBoardForm.approved")}
                  isLoading={isSubmitting}
                  type="button"
                  variant="success"
                  className="btn-i18n text-white"
                  onClick={() => {
                    setSubmitting(true);
                    setTextMessage(`${t("successError.mark it")}`);
                    setHeaderMessage(
                      `${t(
                        "successError.approve the billboard as non-operational"
                      )}`
                    );
                    setShowPopUp(true);
                    setSubmitting(false);
                  }}
                />
              </div>
            )}
        </Form>
      </FormikProvider>
    </div>
  );
};
export default CreateBillBoardForm;
