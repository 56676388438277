import TicketList from "./TicketList";
import ViewTickets from "./ViewTickets";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { routes } from "../../constants/routes";
import { isMobile } from "react-device-detect";
const TicketViewAndActivityFeed = ({ context, userContext }: any) => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParamsDetails = () => {
    let params = {
      sort: "-createdAt",
      // limit: 10,
      // page: 1,
    };
    return params;
  };

  useEffect(() => {
    loadCleaningTicketData();
  }, []);

  const loadCleaningTicketData = async () => {
    const { tickets, success } = await context?.loadCleaningTickets(
      urlParams?.cleaningId,
      queryParamsDetails()
    );
    if (success && tickets.length) {
      if (
        window.location.pathname.includes("/edit") &&
        urlParams?.ticketId !== "0"
      ) {
        navigate(
          routes.getCleaningTicketsIdUrl(
            urlParams.cleaningId,
            urlParams?.ticketId
          )
        );
      } else if (window.location.pathname.includes("/edit")) {
        navigate(
          routes.getCleaningTicketsIdUrl(urlParams.cleaningId, tickets[0].id)
        );
      }
    }
  };

  return (
    <>
      <div>
        {context?.cleaningTickets?.length === 0 && (
          <div className=" d-flex justify-content-center">
            <div>No Tickets Found</div>
          </div>
        )}
        {context?.cleaningTickets?.length > 0 && (
          <div className={`${isMobile ? " row" : " row p-5"}`}>
            <div className=" col-xl-4 col-12 ">
              <div>
                <TicketList context={context} userContext={userContext} />
              </div>
            </div>
            <div className=" col-lg-8 col-12 actfeedheight">
              {location.pathname.includes("/edit") && (
                <ViewTickets context={context} userContext={userContext} />
              )}
              {/* {location.pathname.includes("/activities") && (
                <ActivityFeed context={context} userContext={userContext} />
              )} */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TicketViewAndActivityFeed;
