import React, { ReactElement, useState } from "react";
import User from "../lib/User";
import { api } from "../utils/api";

interface TicketProviderProps {
  children: ReactElement;
}

export interface TicketContextProp {
  isLoading: boolean;
  setIsLoading: Function;
  tickets: any;
  ticket: any;
  setTickets: any;
  meta: any;
  members: any;
  billBoards: any;
  billBoard: any;
  ticketActivityFeed: any;
  loadTickets: Function;
  getTicketDetail: Function;
  updateTicketDetail: Function;
  loadTicketBillBoards: Function;
  loadMembers: Function;
  createTicketDetail: Function;
  approveByStaffTicket: Function;
  approveBySupervisorTicket: Function;
  rejectTicket: Function;
  deleteTicket: Function;
  reassignTicket: Function;
  getTicketActivityFeed: Function;
  loadBillBoards: Function;
  getBillBoardDetail: Function;
  removeTicket: Function;
  removeBillBoard: Function;
  getTicketTitleDetail: Function;
  getDepartmentTitleDetail: Function;
  createGroupConversation: Function;
  autoCompleteApi: Function;
  downloadExcel: Function;
  downloadPdf: Function;
  cityList: Function;
  ticketQueryParamsDetails: Function;
  loadCleaningsList: Function;
}

const TicketContext = React.createContext<TicketContextProp | null>(null);

const TicketProvider = ({ children }: TicketProviderProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [tickets, setTickets] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [ticketActivityFeed, setTicketActivityFeed] = useState<any>([]);
  const [billBoards, setBillBoards] = useState<any>([]);
  const [billBoard, setBillBoard] = useState<any>({});
  const [ticket, setTicket] = useState<any>({});
  const [meta, setMeta] = useState<any>({});

  const loadTickets = async (params: any) => {
    setIsLoading(true);
    const { tickets, meta } = await api.tickets(params);
    setTickets(tickets);
    setMeta(meta);
    setIsLoading(false);
  };

  const loadCleaningsList = async (params: any) => {
    const { cleanings, meta, success } = await api.cleaninglist(params);
    return { cleanings, meta, success };
  };

  const loadMembers = async (params: any) => {
    setIsLoading(true);
    const { success, user, meta } = await api.getMembers(params);
    if (success) {
      setMembers(user);
    }
    setIsLoading(false);
    return { success, user, meta };
  };

  const cityList = async (params: any) => {
    setIsLoading(true);
    const data = await api.cityAutoComplete(params);
    setIsLoading(false);
    return data;
  };

  const getTicketDetail = async (TicketId: any) => {
    if (TicketId !== "0") {
      setIsLoading(true);
      const { success, ticket, error } = await api.ticket(TicketId);
      if (success) {
        setTicket(ticket);
      }
      setIsLoading(false);
      return { success, ticket, error };
    }
  };

  const updateTicketDetail = async (TicketId: any, params: any) => {
    if (TicketId) {
      setIsLoading(true);
      const { data } = await api.updateTicket(TicketId, params);
      const { success, ticket, error } = data || {};
      if (success) {
        setTicket(ticket);
      }
      setIsLoading(false);
      return { success, ticket, error };
    }
  };

  const createTicketDetail = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.createTicket(params);
    const { success, ticket, error } = data || {};
    if (success) {
      setTicket(ticket);
    }
    setIsLoading(false);
    return { success, ticket, error };
  };

  const loadTicketBillBoards = async (id: any) => {
    setIsLoading(true);
    const { tickets, success } = await api.getTicketBasedOnBillBoard(id);
    if (success) {
      setTicket(tickets.map((ticket: any) => new User(ticket)));
    }
    setIsLoading(false);
    return { tickets, success };
  };

  const deleteTicket = async (id: any) => {
    setIsLoading(true);
    const { success, error } = await api.deleteTicket(id);
    setIsLoading(false);
    return { success, error };
  };

  const approveByStaffTicket = async (id: any) => {
    setIsLoading(true);
    const { data } = await api.approveByStaffTicket(id);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const approveBySupervisorTicket = async (id: string, reason: string) => {
    setIsLoading(true);
    const { data } = await api.approveBySupervisorTicket(
      id,
      reason?.length > 0
        ? {
            submitted_reason: reason,
          }
        : {}
    );
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const rejectTicket = async (id: any) => {
    setIsLoading(true);
    const { data } = await api.rejectTicket(id);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const reassignTicket = async (id: any, params: any) => {
    setIsLoading(true);
    const { data } = await api.reassignTicket(id, params);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const getTicketActivityFeed = async (ticketId: any) => {
    if (ticketId !== "0") {
      setIsLoading(true);
      const { success, ticket_activities, error } =
        await api.getTicketActivityFeed(ticketId);
      if (success) {
        setTicketActivityFeed(ticket_activities);
      }
      setIsLoading(false);
      return { success, ticket_activities, error };
    }
  };

  const loadBillBoards = async (params: any) => {
    setIsLoading(true);
    const { billboards, success } = await api.billBoards(params);
    setBillBoards(billboards);
    setIsLoading(false);
    return { success, billboards };
  };

  const getBillBoardDetail = async (billBoardId: any) => {
    if (billBoardId) {
      setIsLoading(true);
      const { success, user, error } = await api.billBoard(billBoardId);
      setBillBoard(user);
      setIsLoading(false);
      return { success, user, error };
    }
  };

  const removeTicket = (value: any) => {
    setTicket(value);
    setTicketActivityFeed(value);
  };

  const removeBillBoard = (value: any) => {
    setBillBoard(value);
  };

  const getTicketTitleDetail = async (value: any) => {
    setIsLoading(true);
    const { success, config, error } = await api.getAllConfig(value);
    setIsLoading(false);
    return { success, config, error };
  };

  const getDepartmentTitleDetail = async (value: any) => {
    setIsLoading(true);
    const { success, config, error } = await api.getAllConfig(value);
    setIsLoading(false);
    return { success, config, error };
  };

  const createGroupConversation = async (id: any) => {
    setIsLoading(true);
    const data = await api.createConversation({ ticket_id: id });
    setIsLoading(false);
    return data?.data;
  };

  const autoCompleteApi = async (params: any) => {
    setIsLoading(true);
    const { success, users, meta } = await api.autoCompleteApi(params);
    setIsLoading(false);
    return { success, users, meta };
  };

  const downloadExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.ticketDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.ticketPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const ticketQueryParamsDetails = () => {
    let params: any = {
      search: queryParams.get("search") ? queryParams.get("search") : "",
      sort: queryParams.get("sort") ? queryParams.get("sort") : "-createdAt",
      role: queryParams.get("role") ? queryParams.get("role") : "",
      status: queryParams.get("status")
        ? queryParams.get("status")?.split(",")?.includes("All")
          ? ""
          : queryParams.get("status")?.split(",")
        : ["All"],
      department: queryParams.get("department")
        ? queryParams.get("department")
        : "",
      bill_board_id: queryParams.get("bill_board_id")
        ? queryParams.get("bill_board_id")
        : "",
      assignee: queryParams.get("assignee") ? queryParams.get("assignee") : "",
      limit: 10,
      page: queryParams.get("page") ? queryParams.get("page") : 1,
      assigneeName: queryParams.get("assigneeName")
        ? queryParams.get("assigneeName")
        : "",
      city: queryParams.get("city") ? queryParams.get("city") : "",
      billBoardNum: queryParams.get("billBoardNum")
        ? queryParams.get("billBoardNum")
        : "",
      is_cleaning: queryParams.get("is_cleaning")
        ? queryParams.get("is_cleaning")
        : "",
    };
    return params;
  };

  const value: TicketContextProp = {
    isLoading,
    setIsLoading,
    tickets,
    meta,
    ticket,
    setTickets,
    ticketActivityFeed,
    billBoards,
    billBoard,
    loadTickets,
    getTicketDetail,
    updateTicketDetail,
    loadTicketBillBoards,
    loadMembers,
    members,
    createTicketDetail,
    approveByStaffTicket,
    approveBySupervisorTicket,
    rejectTicket,
    deleteTicket,
    reassignTicket,
    getTicketActivityFeed,
    loadBillBoards,
    getBillBoardDetail,
    removeTicket,
    removeBillBoard,
    getTicketTitleDetail,
    getDepartmentTitleDetail,
    createGroupConversation,
    autoCompleteApi,
    downloadExcel,
    downloadPdf,
    cityList,
    ticketQueryParamsDetails,
    loadCleaningsList,
  };

  return (
    <TicketContext.Provider value={value}>{children}</TicketContext.Provider>
  );
};

const useTicket = () => React.useContext(TicketContext);

export { TicketProvider, useTicket };
