import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../constants/routes";
import { SOCKET_URL, api } from "../utils/api";
import { io } from "socket.io-client";

interface AuthenticationProviderProps {
  children: ReactElement;
}

export interface AuthenticationContextProp {
  isLoggedIn: boolean;
  isLoading: boolean;
  setIsLoading: Function;
  setIsLoggedIn: Function;
  user: any;
  role: any;
  roleAccess: any;
  login: Function;
  logout: Function;
  update: Function;
  verify: Function;
  getUser: Function;
  getAllConfigDetails: Function;
  getAllCleangingConfigDetails: Function;
  invitationTokenpost: Function;
  forgotpassword: Function;
  uploadFiles: Function;
  updateUserDetail: Function;
  getConfigRoles: Function;
  getRolesDetails: Function;
  updateRoles: Function;
  urlToBase64: Function;
  setGroupConversationInfo: Function;
  groupConversationInfo: any;
  setUserOnline: Function;
  disConnectSocket: Function;
  getNotificationDetails: Function;
  notifications: any;
  isProfileUpdated: any;
  setIsProfileUpdated: Function;
  socket: any;
  setSocket: Function;
  setNotification: Function;
  setUser: Function;
  userName: Function;
  userAvatar: Function;
  currentConversationId: string;
  setCurrentConversationId: Function;
  getAllBomDetails: Function;
  cityList: Function;
  uploadTimeSheet: Function;
  totalCount: number;
  helpCenterMsg: Function;
  isCall: boolean;
  setIsCall: Function;
  setCallAccepted: Function;
  callAccepted: boolean;
}

const AuthenticationContext =
  React.createContext<AuthenticationContextProp | null>(null);

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn]: [boolean, Function] = useState(false);
  const [user, setUser] = useState<any>({});
  const [roleAccess, setRoleAccess] = useState(null);
  const [groupConversationInfo, setGroupConversationInfo] = useState({});
  const [notifications, setNotification] = useState<any>([]);
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const navigate = useNavigate();
  const [socket, setSocket] = useState<any>(null);
  const [currentConversationId, setCurrentConversationId] = useState("");
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isCall, setIsCall] = useState<boolean>(false);
  const [callAccepted, setCallAccepted] = useState<boolean>(false);

  const getAllConfigDetails = async (value: any) => {
    setIsLoading(true);
    const { success, config, error } = await api.getAllConfig(value);
    setIsLoading(false);
    return { success, config, error };
  };

  const getAllCleangingConfigDetails = async (value: any) => {
    setIsLoading(true);
    const { success, data, error } = await api.getAllCleangingConfig(value);
    setIsLoading(false);
    return { success, data, error };
  };

  const getAllBomDetails = async (value: any) => {
    setIsLoading(true);
    const { success, bom, error } = await api.getBomList(value);
    setIsLoading(false);
    return { success, bom, error };
  };

  const userName = (item: any, messageValue: any) => {
    switch (item?.title) {
      case "billboard_operational":
      case "billboard_non_operation":
        return messageValue?.requested_by?.name || "-";
      case "billboard_approved":
        return messageValue?.approved_by?.name || "-";
      case "billboard_created":
        return messageValue?.created_by?.name || "-";
      case "billboard_updated":
        return messageValue?.updated_info?.name || "-";
      case "ticket_created":
        return messageValue?.created_by?.name || "-";
      case "ticket_updated":
        return messageValue?.updated_info?.name || "-";
      case "ticket_viewed":
      case "ticket_submitted":
        return messageValue?.assignee?.name || "-";
      case "ticket_verified":
        return messageValue?.approved_by_supervisor?.name || "-";
      case "ticket_reassigned":
        return messageValue?.reassigned_by?.name || "-";
      case "ticket_approved":
        return messageValue?.approved_by_manager?.name || "-";
      case "ticket_rejected":
        return messageValue?.rejected_by?.name || "-";
      case "user_accept_object":
        return messageValue?.name || "-";
      case "excel_upload":
        return messageValue?.updated_by?.name || "-";
      case "member_invite":
        return Array.isArray(messageValue?.email) &&
          messageValue.email.length > 0
          ? messageValue.email[0].toUpperCase()
          : "-";
      default:
        return "";
    }
  };

  const userAvatar = (item: any, messageValue: any) =>
    item?.title === "billboard_operational"
      ? messageValue?.requested_by?.avatar
        ? messageValue?.requested_by?.avatar
        : ""
      : item?.title === "billboard_non_operation"
      ? messageValue?.requested_by?.avatar
        ? messageValue?.requested_by?.avatar
        : ""
      : item?.title === "billboard_approved"
      ? messageValue?.approved_by?.avatar
        ? messageValue?.approved_by?.avatar
        : ""
      : item?.title === "billboard_created"
      ? messageValue?.created_by?.avatar
        ? messageValue?.created_by?.avatar
        : ""
      : item?.title === "billboard_updated"
      ? messageValue?.updated_info?.avatar
        ? messageValue?.updated_info?.avatar
        : ""
      : item?.title === "ticket_created"
      ? messageValue?.created_by?.avatar
        ? messageValue?.created_by?.avatar
        : ""
      : item?.title === "ticket_updated"
      ? messageValue?.updated_info?.avatar
        ? messageValue?.updated_info?.avatar
        : ""
      : item?.title === "ticket_viewed"
      ? messageValue?.assignee?.avatar
        ? messageValue?.assignee?.avatar
        : ""
      : item?.title === "ticket_submitted"
      ? messageValue?.assignee?.avatar
        ? messageValue?.assignee?.avatar
        : ""
      : item?.title === "ticket_verified"
      ? messageValue?.approved_by_supervisor?.avatar
        ? messageValue?.approved_by_supervisor?.avatar
        : ""
      : item?.title === "ticket_reassigned"
      ? messageValue?.reassigned_by?.avatar
        ? messageValue?.reassigned_by?.avatar
        : ""
      : item?.title === "ticket_approved"
      ? messageValue?.approved_by_manager?.avatar
        ? messageValue?.approved_by_manager?.avatar
        : ""
      : item?.title === "ticket_rejected"
      ? messageValue?.rejected_by?.avatar
        ? messageValue?.rejected_by?.avatar
        : ""
      : item?.title === "user_accept_object"
      ? messageValue?.avatar
        ? messageValue?.avatar
        : ""
      : item?.title === "excel_upload"
      ? messageValue?.updated_by?.avatar
        ? messageValue?.updated_by?.avatar
        : ""
      : "";

  const refreshUser = async () => {
    setIsLoggedIn(true);
    if (sessionStorage.getItem("auth")) {
      const { success, user } = await api.user();
      if (success) {
        setUser(user);
        if (user) {
          const { success, role_access } = await api.getUserRoles(user?.role);
          if (success) {
            return setRoleAccess(role_access);
          }
        }
      }
    }
    setIsLoggedIn(false);
    if (
      !window?.location?.pathname?.includes("accept_invitation") &&
      !window?.location?.pathname?.includes("reset_password")
    ) {
      sessionStorage.removeItem("auth");
      sessionStorage.removeItem("i18nextLng");
      navigate(routes.login);
      return false;
    }
  };

  const handleLogin = async (email: string, password: string) => {
    setIsLoggedIn(true);
    const res = await api.signIn(email, password);
    setIsLoggedIn(false);
    const { success, token, error } = res || {};
    if (success) {
      sessionStorage.setItem("auth", token);
      refreshUser();
    } else {
      setIsLoggedIn(false);
    }
    return { success, error };
  };

  const invitationTokenpost = async (token: string, values: any) => {
    setIsLoggedIn(true);
    const res = await api.verifyTokenpost(token, values);
    const { data } = res || {};
    const { success, user, error } = data || {};
    setIsLoggedIn(false);
    return { success, user, error };
  };

  const tokenVerify = async (token: string) => {
    setIsLoggedIn(true);
    const { success, user } = await api.verifyToken(token);
    setIsLoggedIn(false);
    return { success, user };
  };

  const handleLogout = async () => {
    setIsLoggedIn(true);
    sessionStorage.removeItem("auth");
    sessionStorage.removeItem("i18nextLng");
    setIsLoggedIn(false);
    navigate(routes.login);
  };

  const forgotpassword = async (email: any) => {
    return await api.forgetPassword(email);
  };
  const helpCenterMsg = async (params: any) => {
    const res = await api?.helpCenter(params);
    return res;
  };
  interface UpdateUserProps {
    name: string;
    email: string;
    contactNumber: string;
    whatsappNumber: string;
    location: string;
    gender: string;
    dateOfJoining: string;
    iqamaNo: string;
    biography: string;
    responsibleArea: string;
  }

  const handleUpdate = async (params: UpdateUserProps) => {
    const {
      name,
      email,
      location,
      gender,
      iqamaNo,
      contactNumber,
      whatsappNumber,
      dateOfJoining,
      biography,
      responsibleArea,
    } = params;
    await api.updateUser({
      name,
      email,
      location,
      gender,
      biography,
      contact_number: contactNumber,
      iqama_no: iqamaNo,
      whatsapp_number: whatsappNumber,
      date_of_joining: dateOfJoining,
      responsible_area: responsibleArea,
    });
  };

  const uploadFiles = async (value: any, feature: any) => {
    const { data } = await api.uploadFiles(value, feature);
    const { url, success } = data || {};
    if (success) {
      return { url, success };
    }
  };

  const urlToBase64 = async (url: any) => {
    const { data } = await api.urlToBase64({ data: url });
    const { success } = data || {};
    if (success) {
      return data;
    }
  };

  const updateUserDetail = async (params: any) => {
    const res = await api.updateUser(params);
    const { data } = res || {};
    const { user, success, error } = data || {};
    if (success) {
      setUser(user);
    }
    return { user, success, error };
  };

  const getConfigRoles = async () => {
    const { success, role, modules, features } = await api.getUserRolesConfig();
    return { success, role, modules, features };
  };

  const getRolesDetails = async () => {
    const res = await api.getRolesDetails();
    return res;
  };

  const updateRoles = async (params: any) => {
    await api.updateRoles(params);
    // return { success, role, modules, features }
  };

  useEffect(() => {
    if (!!sessionStorage.getItem("auth")) {
      refreshUser();
    }
    return () => {
      disConnectSocket("onlinestatus");
      disConnectSocket(user?.id + "notification");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotificationDetails = async () => {
    const { success, data } = await api.getNotification();
    if (success) {
      setNotification(data?.messages);
      refreshUser();
    }
  };
  const setUserOnline = useCallback(async () => {
    if (user?.id) {
      io(`${SOCKET_URL}/?user_id=${user?.id}`).emit("");
      setCurrentConversationId("");
      setGroupConversationInfo({});
      return { success: true };
    }
  }, [user?.id]);

  useEffect(() => {
    if (location?.pathname) {
      getAllConversation();
    }
  }, [location]);

  const getAllConversation = async () => {
    if (sessionStorage.getItem("auth")) {
      setIsLoading(true);
      const { success, conversation } = await api?.getAllConversation({});
      if (success) {
        setTotalCount(
          conversation?.reduce((acc: any, cur: any) => acc + cur?.unread, 0)
        );
      }
      setIsLoading(false);
    }
  };

  const disConnectSocket = (value: any) => {
    return socket?.off(value);
  };

  const cityList = async (params: any) => {
    setIsLoading(true);
    const data = await api.cityAutoComplete(params);
    setIsLoading(false);
    return data;
  };

  const uploadTimeSheet = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.uploadTimeSheet(params);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const value: AuthenticationContextProp = {
    isLoggedIn,
    isLoading,
    setIsLoading,
    setIsLoggedIn,
    user,
    role: {},
    roleAccess,
    getAllConfigDetails,
    getAllCleangingConfigDetails,
    login: handleLogin,
    logout: handleLogout,
    update: handleUpdate,
    verify: tokenVerify,
    invitationTokenpost: invitationTokenpost,
    getUser: refreshUser,
    forgotpassword,
    uploadFiles,
    urlToBase64,
    updateUserDetail,
    getConfigRoles,
    getRolesDetails,
    updateRoles,
    groupConversationInfo,
    setGroupConversationInfo,
    setUserOnline,
    disConnectSocket,
    getNotificationDetails,
    notifications,
    isProfileUpdated,
    setIsProfileUpdated,
    socket,
    setSocket,
    setNotification,
    setUser,
    userName,
    userAvatar,
    currentConversationId,
    setCurrentConversationId,
    getAllBomDetails,
    cityList,
    uploadTimeSheet,
    totalCount,
    helpCenterMsg,
    setIsCall,
    isCall,
    setCallAccepted,
    callAccepted,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = () => React.useContext(AuthenticationContext);

export { AuthenticationProvider, useAuthentication };
