import Picker from "emoji-picker-react";
import { useCallback, useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { IoMdRemoveCircle } from "react-icons/io";
import { IoExitOutline } from "react-icons/io5";
import { MdCheck, MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { VscSmiley } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { useChatContext } from "../../../context/ChatProvider";
import { useUserContext } from "../../../context/UserProvider";
import DeletePopup from "../components/DeletePopup/DeletePopup";
import "./ProfileSection.scss";

type ProfileSectionProps = {
  name: string;
  image: string;
  setIsProfileOpen: Function;
  isProfileOpen: boolean;
  groupMembers: [];
  groupId?: any;
};

export default function ProfileSection(props: ProfileSectionProps) {
  const {
    name,
    image,
    setIsProfileOpen,
    isProfileOpen,
    groupMembers,
    groupId,
  } = props;
  const chatContext = useChatContext();
  const userContext = useUserContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [editGroupName, setEditGroupName] = useState(name);
  const [removeMemberPopup, setRemoveMemberPopup] = useState(false);
  const [removeMember, setRemoveMember] = useState<any>({});
  const emojiRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleGroupNameUpdate();
      event.target.value = "";
    }
  };
  const navigate = useNavigate();
  const handleCreateConversation = async (
    userId: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (userId !== userContext?.user.id) {
      const response = await chatContext?.newConversation(userId);
      if (response?.data?.success) {
        e.preventDefault();
        chatContext?.newConversation(userId);
        navigate(`/chat/${response?.data?.conversation?.id}`);
      }
    }
  };
  const handleExitGroup = () => {
    chatContext?.removeUserFromGroup(groupId?._id, userContext?.user?.id);
    chatContext?.setExitGroup(false);
    navigate("/chat");
  };

  function getIdsAndNames(userArray: any) {
    return userArray?.map((user: any) => ({
      id: user._id,
      name: user.name,
      avatar: user.avatar || "",
    }));
  }
  const groupMemberList = getIdsAndNames(groupMembers);

  const handleRemoveMember = (id: string) => {
    chatContext?.removeUserFromGroup(groupId?._id, id);
  };

  const handleGroupNameUpdate = () => {
    chatContext?.setEditGroupName(false);
    chatContext?.updateGroupName(groupId._id, {
      group_name: editGroupName,
    });
  };

  const closeProfileName = () => {
    setIsProfileOpen(false);
    chatContext?.setEditGroupName(false);
  };

  const handleEmojiClick = useCallback((emojiData: { emoji: string }) => {
    const input = inputRef.current;
    if (input) {
      const { selectionStart, selectionEnd } = input;
      const start = selectionStart ?? 0;
      const end = selectionEnd ?? start;
      const currentValue = input.value;
      const newValue =
        currentValue.slice(0, start) +
        emojiData.emoji +
        currentValue.slice(end);
      setEditGroupName(newValue);
      input.value = newValue;
      input.setSelectionRange(
        start + emojiData.emoji.length,
        start + emojiData.emoji.length
      );
      input.focus();
    }
  }, []);
  const formatedName =
    name?.length > 15 ? name?.substring(0, 15) + "....." : name;
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiRef.current &&
        !emojiRef.current.contains(event.target as Node)
      ) {
        setPreviewEmoji(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className={`sidebar-profile-container ${isProfileOpen ? "open" : ""}`}>
      <div className="profile-wrapper position-relative">
        <div className="d-flex align-items-center side-profile-header ">
          <span className="px-3 cursor-pointer">
            <RxCross2
              fontSize={24}
              color="var(--secondary)"
              title="close contact info"
              onClick={() => closeProfileName()}
            />
          </span>
          <h6 className="fw-medium mb-0 ">
            {chatContext?.meta?.participants?.group_id
              ? "Group Information"
              : "Profile Information"}
          </h6>
        </div>
        <div className="PersonalInfo p-4 border-bottom d-flex justify-content-center align-items-center flex-column">
          <div className="AvatarWrapper-profile d-flex justify-content-center align-items-center cursor-pointer">
            {chatContext?.meta?.participants?.group_id?.group_name ===
              undefined &&
            chatContext?.meta?.participants?.participants?.find(
              (item: any) => item.id !== userContext?.user?.id
            )?.avatar ? (
              <img src={image} alt="" className="Avatar" />
            ) : (
              <Avatar
                name={
                  chatContext?.activeChat?.group_id?.group_name ||
                  chatContext?.activeChat?.participants?.find(
                    (item: any) => item._id !== userContext?.user?.id
                  )?.name ||
                  ""
                }
                maxInitials={2}
                size="100%"
                round
              />
            )}
          </div>
          <div className="text-dark mt-3 text-capitalize d-flex align-items-center mb-0 min-width justify-content-center">
            {chatContext?.editGroupName ? (
              <div className=" position-relative w-100">
                <div className=" emoji-wrapper-profile" ref={emojiRef}>
                  {previewEmoji && (
                    <Picker
                      autoFocusSearch={false}
                      searchDisabled
                      lazyLoadEmojis={true}
                      onEmojiClick={handleEmojiClick}
                    />
                  )}
                </div>
                <div>
                  <div className=" d-flex align-items-center justify-content-between profile-input border-bottom ">
                    <input
                      ref={inputRef}
                      className=" w-100 ps-0"
                      placeholder=" Group Name"
                      onChange={(e) => setEditGroupName(e?.target?.value)}
                      onKeyDown={handleKeyDown}
                      id="inputID"
                      maxLength={20}
                      value={editGroupName}
                    />
                    <span
                      onClick={() => setPreviewEmoji(!previewEmoji)}
                      className=" cursor-pointer"
                    >
                      <VscSmiley
                        size={24}
                        color="var(--secondary)"
                        title="emoji"
                      />
                    </span>
                    {editGroupName.length > 1 && (
                      <div
                        className=" mx-2 cursor-pointer"
                        onClick={() => {
                          handleGroupNameUpdate();
                        }}
                      >
                        <MdCheck
                          size={24}
                          color="var(--secondary)"
                          title="Update group name"
                        />
                      </div>
                    )}
                  </div>

                  {editGroupName.length < 1 && (
                    <h6 className="text-danger text-capitalize fs-12 mt-1">
                      Group Name is Required
                    </h6>
                  )}
                </div>
              </div>
            ) : (
              formatedName
            )}

            {!chatContext?.editGroupName &&
              chatContext?.activeChat?.group_id && (
                <span
                  onClick={() => {
                    chatContext?.setEditGroupName(true);
                  }}
                  className="mx-2 cursor-pointer"
                >
                  <MdEdit
                    fontSize={22}
                    color="var(--secondary)"
                    title="Edit Group Name"
                  />
                </span>
              )}
          </div>

          {chatContext?.activeChat?.group_id && (
            <h6 className="text-dark text-capitalize fs-14 mt-2">
              {groupMemberList?.length} Members
            </h6>
          )}
        </div>
        <div className=" group-member-list">
          {chatContext?.activeChat?.group_id &&
            groupMemberList.map((item: any) => (
              <div className=" d-flex align-items-center px-3 py-2 border-bottom justify-content-between ">
                <div
                  className="d-flex gap-2 align-items-center cursor-pointer"
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                    handleCreateConversation(item?.id, e)
                  }
                >
                  <div className="group-member">
                    {item?.avatar ? (
                      <img
                        src={item?.avatar}
                        alt={
                          item?.id === userContext?.user?.id
                            ? "You"
                            : item?.name
                        }
                      />
                    ) : (
                      <Avatar
                        className=""
                        name={item?.name}
                        size="40px"
                        maxInitials={2}
                        round={true}
                      />
                    )}
                  </div>
                  <h6 className=" mb-0 fw-medium">
                    {item?.id === userContext?.user?.id
                      ? "You"
                      : item.name?.length > 15
                      ? item.name?.substring(0, 15) + "..."
                      : item.name}
                  </h6>
                </div>
                {item?.id !== userContext?.user?.id && (
                  <div
                    onClick={() => {
                      setRemoveMemberPopup(true);
                      setRemoveMember(item);
                    }}
                    className="cursor-pointer"
                  >
                    <IoMdRemoveCircle fontSize={22} color="var(--secondary)" />
                  </div>
                )}
              </div>
            ))}
        </div>
        {removeMemberPopup && (
          <DeletePopup
            textMessage={`Are you sure, you want to remove ${removeMember.name} ?`}
            textCancel="No, don't remove!"
            textSuccess="Yes, remove it"
            onClickCancel={() => {
              setRemoveMemberPopup(false);
            }}
            onClickSuccess={() => {
              handleRemoveMember(removeMember?.id);
              setRemoveMemberPopup(false);
            }}
          />
        )}

        {chatContext?.activeChat?.group_id && (
          <div
            className="exit-group-btn d-flex justify-content-center cursor-pointer align-items-center"
            onClick={() => {
              chatContext?.setExitGroup(true);
            }}
          >
            <div className="fw-semibold">
              <span className="mx-2">
                <IoExitOutline fontSize={26} />
              </span>
              Exit Group
            </div>
          </div>
        )}

        {chatContext?.exitGroup && (
          <DeletePopup
            textMessage="Are you sure, you want to exit this group?"
            textCancel="No, don't exit!"
            textSuccess="Yes, exit it"
            onClickCancel={() => {
              chatContext?.setExitGroup(false);
            }}
            onClickSuccess={() => {
              handleExitGroup();
            }}
          />
        )}
      </div>
    </div>
  );
}
