import Avatar from "react-avatar";
import { Modal } from "react-bootstrap";
import { MdOutlineFileDownload } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { formatedDate } from "../../../../../utils/axiosInstance";

interface previewFileProps {
  setPreviewModal: Function;
  previewModal: boolean;
  messageObj: any;
}

const PreviewFilePopup = ({
  previewModal,
  setPreviewModal,
  messageObj,
}: previewFileProps) => {
  const handleDownload = () => {
    if (messageObj.attachment) {
      const link = document.createElement("a");
      link.href = messageObj.attachment;
      link.download = messageObj.attachment.split("/").pop();
      link.click();
    }
  };
  return (
    <Modal show={previewModal} centered>
      <Modal.Body>
        <div className="d-flex align-items-center py-2 justify-content-between">
          <div className="d-flex gap-2 align-items-center">
            <div className="group-member">
              {messageObj?.avatar ? (
                <img
                  src={messageObj?.from?.avatar}
                  alt={messageObj?.from?.name}
                />
              ) : (
                <Avatar
                  className=""
                  name={messageObj?.from.name}
                  size="40px"
                  maxInitials={2}
                  round={true}
                />
              )}
            </div>
            <div>
              <h6 className="mb-0 fw-medium">{messageObj?.from?.name}</h6>
              <h6 className="mb-0 fs-12">
                {formatedDate(messageObj?.createdAt)}
              </h6>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div
              className="cursor-pointer"
              title="Download the image"
              onClick={handleDownload}
            >
              <MdOutlineFileDownload fontSize={25} color="var(--secondary)" />
            </div>

            <div
              className="cursor-pointer"
              onClick={() => {
                setPreviewModal(false);
              }}
              title="Close the preview"
            >
              <RxCross2 fontSize={24} color="var(--secondary)" />
            </div>
          </div>
        </div>
        {/* <div className=" people-list"> */}
        <div className="preview-img-wrapper pt-3">
          <img src={messageObj.attachment} alt="" />
        </div>
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default PreviewFilePopup;
