import "./DeletePopup.scss";
import CommonButton from "../CommonButton/CommonButton";

interface ButtonProps {
  textMessage: string;
  textCancel: string;
  textSuccess: string;
  textWantToBeDeleted?: any;
  onClickCancel: any;
  onClickSuccess: any;
}

const DeletePopup = ({
  textMessage,
  textCancel,
  textSuccess,
  textWantToBeDeleted,
  onClickCancel,
  onClickSuccess,
}: ButtonProps) => {
  return (
    <div className=" delete-popup-wrapper d-flex justify-content-center align-items-center">
      <div className=" position-relative flex-column d-flex justify-content-center align-items-center delete-popup px-5 py-4 rounded-3">
        <h5 className=" fw-semibold text-center ">{textMessage}</h5>
        {textWantToBeDeleted && (
          <h6 className=" fw-semibold text-center mt-3">
            {textWantToBeDeleted}
          </h6>
        )}

        <div className=" d-flex gap-3 mt-3">
          <CommonButton
            text={textCancel}
            type="button"
            variant="outline-danger"
            className="bg-transparent text-danger"
            onClick={onClickCancel}
          />
          <CommonButton
            text={textSuccess}
            type="button"
            variant="outline-success"
            className="text-white bg-success"
            onClick={onClickSuccess}
          />
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
