import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import "./RecordView.scss";
import { IoMdRefreshCircle } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import { useChatContext } from "../../../../context/ChatProvider";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../../../context/UserProvider";
import { blobToFile, fetchBlob } from "../../../../../../utils/axiosInstance";

interface RecordViewProps {
  setAudioRecordshow: Function;
  handleStartRecording: Function;
  stopRecording: Function;
  recordedUrl: string;
  setRecordedUrl: Function;
}

const RecordView = (props: RecordViewProps) => {
  const {
    setAudioRecordshow,
    handleStartRecording,
    stopRecording,
    recordedUrl,
    setRecordedUrl,
  } = props;

  const [time, setTime] = useState(0);

  const timerRef: React.MutableRefObject<null | any> = useRef(null);
  const [audioGranted, setAudioGranted] = useState(false);
  const chatContext = useChatContext();
  const userContext = useUserContext();
  const params = useParams();
  const [recordingState, setRecordingState] = useState("Start");
  const MAX_RECORDING_TIME = 5 * 60;
  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime + 1 >= MAX_RECORDING_TIME) {
          handleStop();
          return MAX_RECORDING_TIME;
        }
        return prevTime + 1;
      });
    }, 1000);
  };
  const pauseTimer = () => {
    clearInterval(timerRef.current);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    if (recordedUrl) {
      const fileName = `recording_${new Date().toISOString()}.webm`;
      handleBlobUpload(recordedUrl, fileName);
      setAudioRecordshow(false);
      setRecordedUrl("");
      setRecordingState("Start");
    }
  };

  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const paddedMinutes = String(minutes).padStart(2, "0");
    const paddedSeconds = String(seconds).padStart(2, "0");
    return `${paddedMinutes}:${paddedSeconds}`;
  };
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setAudioGranted(true);
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch((error) => {
        setAudioGranted(false);
        console.error("Error accessing media devices:", error);
      });
  }, []);
  async function handleBlobUpload(blobUrl: string, fileName: any) {
    const blob = await fetchBlob(blobUrl);
    const file = blobToFile(blob, fileName);
    const formData = new FormData();
    formData.append("file", file);

    const type = "audio";

    try {
      const data = await chatContext.fileUpload(formData, type);
      if (data?.success) {
        const messageBody: any = {};
        messageBody.attachment_type = type.toUpperCase();
        messageBody.attachment = data.url;
        if (chatContext?.repliedMessage?.id) {
          messageBody.is_reply = true;
          messageBody.reply_message_id = chatContext?.repliedMessage?.id;
        }
        chatContext.sendMessage(
          params.id,
          messageBody,
          chatContext?.activeChat?.participants.find(
            (item: any) => item.id !== userContext?.user.id
          ).id,
          userContext?.user.id,
          userContext?.user.name
        );
        setTime(0);
      }
    } catch (error) {
      console.error("File upload failed", error);
    }
  }

  const onClose = () => {
    setAudioRecordshow(false);
    setRecordedUrl("");
  };

  const handleRecording = () => {
    if (recordingState === "Start") {
      startTimer();
      handleStartRecording();
      setRecordingState("Pause");
    } else if (recordingState === "Pause") {
      pauseTimer();
      setRecordingState("Resume");
    } else if (recordingState === "Resume") {
      startTimer();
      setRecordingState("Pause");
    }
  };

  const handleStop = () => {
    stopRecording();
    stopTimer();
    setRecordingState("Send");
  };
  return (
    <>
      {audioGranted && (
        <Modal show={true} onHide={() => onClose()} centered>
          <div className="px-2">
            <div className="d-flex justify-content-between align-items-center pt-4 px-3 pb-3">
              <h6 className="mb-0 fw-semibold">Audio </h6>
              <div className=" d-flex align-items-center gap-2">
                <div className="cursor-pointer">
                  <IoMdRefreshCircle
                    fontSize={24}
                    color="var(--secondary)"
                    onClick={() => stopTimer()}
                    title="Reset"
                  />
                </div>
                <div className="cursor-pointer">
                  <RxCross2
                    fontSize={24}
                    color="var(--secondary)"
                    onClick={() => onClose()}
                    title="Close"
                  />
                </div>
              </div>
            </div>

            <Modal.Body style={{ height: "100%" }}>
              <div className="d-flex flex-column align-items-center">
                <div className="timer-count">
                  <span className="second">{formatTime(time)}</span>
                </div>

                <h3>
                  {recordingState === "Send"
                    ? "Preview Playback"
                    : "Press Start to Record"}
                </h3>
                {recordedUrl && recordingState === "Send" && (
                  <audio controls src={recordedUrl} />
                )}

                <div className="d-flex align-items-center justify-content-end mt-5 gap-3">
                  {recordingState !== "Send" && (
                    <div
                      className={`audio-button ${
                        recordingState === "Pause"
                          ? "btn-audio-warning"
                          : recordingState === "Send" ||
                            recordingState === "Start" ||
                            recordingState === "Resume"
                          ? "btn-audio-success"
                          : "btn-audio-primary"
                      }`}
                      onClick={handleRecording}
                    >
                      {recordingState === "Start"
                        ? "Start"
                        : recordingState === "Pause"
                        ? "Pause"
                        : "Resume"}
                    </div>
                  )}
                  {recordingState === "Send" ? (
                    <div
                      className="audio-button btn-audio-success"
                      onClick={handleStop}
                    >
                      Send
                    </div>
                  ) : recordingState !== "Start" ? (
                    <div
                      className="audio-button btn-audio-danger"
                      onClick={handleStop}
                    >
                      Stop
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
};
export default RecordView;
