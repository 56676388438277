import { get, getCookie } from "../api";
import {
  CHAT_URL,
  filterQueryParams,

  post,
  put,
  _delete,
} from "../axiosInstance";
import { v4 as uuidv4 } from 'uuid';
export interface IConversationFilter {
  page?: number;
  limit?: number;
  sort?: string;
  search?: string;
  group?: boolean;
}
export interface IMessageBody {
  attachment_type?: string;
  attachment?: string;
  message: string;
  reply_message_id?: string;
  is_reply?: boolean;
}

export interface IStartConversation {
  success: boolean;
  conversation: {
    _id?: string;
    participants?: string[];
    group_id?: string | null;
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
    last_message?: string;
    last_message_sent_at?: string;
    id?: string;
  };
}
interface IUpdateGroupNameResponse {
  data: {
    success: boolean;
    groupInfo?: any;
    error?: string[]
  }
  status: number
}

interface IAddMemberToGroupResponse {
  data: {
    success: boolean;
    groupInfo: any;
    error?: any
  }
  status: number
}
const listConversions = async (
  params:
    | {
      page?: number;
      limit?: number;
      sort?: string;
      search?: string;
      group?: boolean;
    }
    | undefined
  // token: string
): Promise<any> => {
  try {
    const response = await get(
      `${CHAT_URL}conversation?${filterQueryParams(params)}`,
      getCookie("auth")
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      conversation: e?.conversation,
    };
  }
};

const listIndividualConversions = async (
  id: string,
  params:
    | {
      page?: number;
      limit?: number;
      sort?: string;
      search?: string;
    }
    | undefined
): Promise<any> => {
  try {
    const response = await get(
      `${CHAT_URL}conversation/${id}/?${filterQueryParams(params)}`,
      getCookie("auth")
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const getIndividualMessage = async (id: string): Promise<any> => {
  try {
    const response = await get(`${CHAT_URL}message/${id}`, getCookie("auth"));
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const getCallLogs = async (id: string): Promise<any> => {
  try {
    const response = await get(`${CHAT_URL}session/`, getCookie("auth"));
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};
const getIndividualConversation = async (id: string): Promise<any> => {
  try {
    const response = await get(
      `${CHAT_URL}getConversationDetail/${id}`,
      getCookie("auth")
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const sendMessage = async (
  id: string,
  params:
    | {
      attachment_type?: string;
      attachment?: string;
      message?: string;
      reply_message_id?: string;
      is_reply?: boolean;
    }
    | undefined
): Promise<any> => {
  try {
    const response = await post(
      `${CHAT_URL}conversation/${id}`,
      getCookie("auth"),
      params
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const forwards = async (
  params:
    | {
      message_id?: [string];
      user_id?: [string];
      group_id?: [string];
    }
    | undefined
): Promise<any> => {
  try {
    const response = await post(
      `${CHAT_URL}forward/messages`,
      getCookie("auth"),
      params
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const readConversation = async (id: string): Promise<any> => {
  try {
    const response = await post(
      `${CHAT_URL}read/conversation/${id}`,
      getCookie("auth")
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const startConversation = async (id: string): Promise<any> => {
  try {
    const response = await post(
      `${CHAT_URL}conversation/member/${id}`,
      getCookie("auth")
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const editMessage = async (
  id: string,
  params:
    | {
      message?: string;
    }
    | undefined
): Promise<any> => {
  try {
    const response = await put(
      `${CHAT_URL}message/${id}`,
      getCookie("auth"),
      params
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const deleteMessage = async (id: string): Promise<any> => {
  try {
    const response = await _delete(
      `${CHAT_URL}message/${id}`,
      getCookie("auth")
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const createGroup = async (
  params:
    | {
      group_name: string;
      group_member: [string];
    }
    | undefined
): Promise<any> => {
  try {
    const response = await post(`${CHAT_URL}group`, getCookie("auth"), params);
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      error: e?.message,
    };
  }
};

const forwardList = async (search?: string | null): Promise<any> => {
  try {
    const repsone = await get(`${CHAT_URL}message/forwardlist?search=${search}`, getCookie("auth"))
    return repsone
  } catch (e) {
    return {
      data: e,
      status: 400
    }
  }
}

const addMemberToGroup = async (
  id: string,
  params:
    | {
      group_member: string[];
    }
    | undefined
): Promise<IAddMemberToGroupResponse> => {
  try {
    const response = await post(
      `${CHAT_URL}group/${id}/addMember`,
      getCookie("auth"),
      params
    );
    return response as IAddMemberToGroupResponse;
  } catch (e: any) {
    return {
      data: e,
      status: e?.status,
    };
  }
};

const updateGroupName = async (
  id: string,
  params: { group_name: string } | undefined
): Promise<IUpdateGroupNameResponse> => {
  try {
    const response = await put(
      `${CHAT_URL}group/${id}`,
      getCookie("auth"),
      params
    );
    return response as IUpdateGroupNameResponse;
  } catch (e: any) {
    return {
      data: e,
      status: 400
    };
  }
};

const removeUserFromGroup = async (
  grpId: string,
  memberId: string
): Promise<any> => {
  try {
    const response = await put(
      `${CHAT_URL}removeUser/${grpId}/${memberId}`,
      getCookie("auth")
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      error: e?.message,
    };
  }
};

const createSession = async (params: object): Promise<any> => {
  try {
    const response = await post(
      `${CHAT_URL}session`,
      getCookie("auth"),
      params
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      error: e?.message,
    };
  }
};

const updateSession = async (id: string, params: object): Promise<any> => {
  try {
    const response = await put(
      `${CHAT_URL}session/${id}`,
      getCookie("auth"),
      params
    );
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      error: e?.message,
    };
  }
};
const generateMessageObj = async (params: any): Promise<any> => {
  try {
    let data = {
      _id: uuidv4(),
      id: uuidv4(),
      attachment_type: params.attachment_type,
      attachment: params.attachment,
      message: params.message,
      reply_message_id: params.reply_message_id,
      is_reply: params?.is_reply,
      conversation_id: params?.conversation_id,
      from: params?.from,
      to: params?.to,
      createdAt: new Date(),
      updatedAt: new Date(),
      reference_id: uuidv4(),
      group_id: params?.group_id,
      seen: {
        [params?.from?.id]: new Date(),
        [params?.to?._id]: null
      },
      is_forwarded: params?.is_forwarded,
      is_delete: params?.is_delete
    }
    const filterData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined)
    );
    return filterData;
  } catch (e) {
    console.error(e)
  }
}


const getSessionById = async (id: string): Promise<any> => {
  try {
    const response = await get(`${CHAT_URL}session/conversation/${id}`, getCookie("auth"));
    return response as any;
  } catch (e: any) {
    return {
      success: false,
      message: e?.message,
    };
  }
};

const ChatService = {
  listConversions,
  listIndividualConversions,
  sendMessage,
  getIndividualMessage,
  forwards,
  startConversation,
  getIndividualConversation,
  readConversation,
  editMessage,
  deleteMessage,
  createGroup,
  addMemberToGroup,
  updateGroupName,
  removeUserFromGroup,
  createSession,
  updateSession,
  getSessionById,
  getCallLogs,
  generateMessageObj,
  forwardList
};

export default ChatService;
