/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import Modal from "react-bootstrap/Modal";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useChatContext } from "../../../context/ChatProvider";
import { useUserContext } from "../../../context/UserProvider";
import ChatLoader from "../../ChatLoadingPage/ChatLoader";
import ChatSendBtn from "../ChatPage/ChatSendBtn";
import SearchField from "../components/SearchField/SearchField";
import NewGroupPopup from "../NewGroup/NewGroupPopup";
import "./ForwardPopup.scss";

interface PopupProps {
  setShow?: (show: boolean) => void;
  show?: boolean;
  forwardModalshow?: boolean;
  setForwardshow?: (show: boolean) => void;
  addMember?: boolean;
  setAddMember?: Function;
  groupID?: string;
}

function ForwardPopup({
  setShow,
  show,
  forwardModalshow,
  setForwardshow,
  addMember,
  setAddMember,
  groupID,
}: PopupProps) {
  const context = useUserContext();
  const chatContext = useChatContext();
  const [searchMember, setSearchMember] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<any>([]);
  const [selectedGrpId, setSelectedGroupId] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [name, setName] = useState<any>([]);
  const [isGroup, setIsGroup] = useState<boolean>(false);
  const [newGroup, setNewGroup] = useState<boolean>(false);

  const navigate = useNavigate();

  const containerRef = useRef(null);

  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      loadMoreData();
    }
  };
  const loadMoreData = useCallback(() => {
    context?.setMemberLimit(context?.memberLimit + 10);
  }, [context?.memberLimit]);

  const handleCheckboxChange = (
    userID: string,
    userName: string,
    is_grp?: boolean
  ) => {
    setIsChecked(!isChecked);
    if (!is_grp) {
      setSelectedUserId((prevIds: any) => {
        let updatedIds;
        if (prevIds.includes(userID)) {
          updatedIds = prevIds.filter(
            (selectedId: any) => selectedId !== userID
          );
        } else {
          updatedIds = [...prevIds, userID];
        }
        return updatedIds;
      });
    } else {
      setSelectedGroupId((prevIds: any) => {
        let updatedIds;
        if (prevIds.includes(userID)) {
          updatedIds = prevIds.filter(
            (selectedId: any) => selectedId !== userID
          );
        } else {
          updatedIds = [...prevIds, userID];
        }
        return updatedIds;
      });
    }
    setName((prevName: any) => {
      let updatedName;
      if (prevName.includes(userName)) {
        updatedName = prevName.filter(
          (selectedName: any) => selectedName !== userName
        );
      } else {
        updatedName = [...prevName, userName];
      }
      return updatedName;
    });
  };

  const handleCreateConversation = async (
    userId: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    chatContext?.setIsLoading(true);
    const response = await chatContext?.newConversation(userId);
    if (response?.data?.success) {
      e.preventDefault();
      chatContext?.newConversation(userId);
      chatContext?.setIsLoading(false);
      navigate(`/chat/${response?.data?.conversation?.id}`);
      setShow?.(false);
    }
  };
  const handleClick = async (
    userId: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isGroup: boolean,
    addMember: boolean | undefined,
    userName: string
  ) => {
    if (disabled) return;
    setDisabled(true);
    if (isGroup || addMember) {
      handleAddToGroup(userId, userName);
    } else {
      await handleCreateConversation(userId, e);
    }
    setTimeout(() => setDisabled(false), 500);
  };

  const handleAddToGroup = (id: string, name: string) => {
    chatContext?.setGroupMembers((prevMembers: any) => {
      const memberExists = prevMembers.some((member: any) => member.id === id);
      if (memberExists) {
        return prevMembers.filter((member: any) => member.id !== id);
      } else {
        return [...prevMembers, { id, name }];
      }
    });
  };

  useEffect(() => {
    if (!forwardModalshow) {
      context?.loadMembers({
        status: "ACTIVE",
        sort: "name",
        search: searchMember,
        limit: context?.memberLimit,
      });
    } else {
      chatContext?.forwardList(searchMember);
    }
  }, [forwardModalshow, searchMember, context?.memberLimit]);
  if (!context || !context.loadMembers) return null;

  const handleForward = () => {
    chatContext?.messageForwards({
      message_id: chatContext?.forwardMessageCount,
      user_id: selectedUserId,
      group_id: selectedGrpId,
    });
    chatContext?.setForwardMessageCount([]);
    setSearchMember("");
    setSelectedUserId([]);
    setSelectedGroupId([]);
    setShow?.(false);
    setForwardshow?.(false);
    setName([]);
    chatContext?.setSelectedMsgIds([]);
  };

  const handleClose = () => {
    chatContext?.setGroupMembers([]);
    setSearchMember("");
    setDisabled(false);
    setSelectedUserId([]);
    setShow?.(false);
    setName([]);
  };

  const addMemberToGroup = async () => {
    try {
      const response = await chatContext?.addMemberToGroup(groupID, {
        group_member: chatContext?.groupMembers.map((item: any) => item.id),
      });
      response?.data?.success === false && window.alert(response?.data?.error);
      response?.data?.success && window.alert("Members Added");
    } catch (error) {
      console.error("Error occurred while adding member:", error);
    }
    chatContext?.setGroupMembers([]);
    setShow?.(false);
  };

  const removeFromSelected = (idToRemove: string | undefined) => {
    chatContext?.setGroupMembers((prevAbd: any) =>
      prevAbd.filter((item: any) => item.id !== idToRemove)
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
      {context?.isLoading && <ChatLoader />}
      <div className="px-3 pt-4">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <h6 className="mb-0 fw-semibold">
            {addMember
              ? "Add Member"
              : newGroup
              ? "New Group"
              : isGroup
              ? "Click on members to add to group"
              : forwardModalshow
              ? "Forward message to"
              : `New Conversation (${
                  context?.meta?.total_count
                    ? context?.meta?.total_count - 1
                    : 0
                })`}
          </h6>
          <div className="cursor-pointer">
            <RxCross2
              fontSize={24}
              color="var(--secondary)"
              onClick={handleClose}
            />
          </div>
        </div>

        <div
          className={`${
            !newGroup && chatContext?.groupMembers.length > 0
              ? "border rounded-2 p-3"
              : " mt-2"
          }`}
        >
          <div
            className={`${
              !newGroup && chatContext?.groupMembers.length > 0
                ? "add-member-select d-flex flex-wrap gap-2 align-items-start"
                : ""
            }`}
          >
            {!newGroup &&
              chatContext?.groupMembers.map((item) => {
                return (
                  <span className="selected-member text-capitalize">
                    {item.name}
                    <span
                      className=" ms-1"
                      onClick={() => removeFromSelected(item?.id)}
                    >
                      <RxCross2 fontSize={18} color="var(--grey)" />
                    </span>
                  </span>
                );
              })}
          </div>
          {!newGroup && (
            <SearchField
              placeholder={
                isGroup
                  ? "Search  members to add to group"
                  : "Search or start a new chat"
              }
              setSearchQuery={setSearchMember}
              searchQuery={searchMember}
              modalPadding
            />
          )}
        </div>
      </div>

      <Modal.Body
        ref={containerRef}
        onScroll={handleScroll}
        className=" people-list"
      >
        <div>
          {newGroup ? (
            <NewGroupPopup setShow={setShow} />
          ) : (
            <div>
              {!addMember && !forwardModalshow && !isGroup && (
                <div
                  className="group-btn-wrapper d-flex align-items-center px-3 py-2 "
                  onClick={() => {
                    setIsGroup(true);
                  }}
                >
                  <div className="group-btn d-flex align-items-center justify-content-center">
                    <BsFillPeopleFill />
                  </div>

                  <span className=" ms-3 fw-semibold">New group</span>
                </div>
              )}
              <div className=" position-relative mt-3">
                {(forwardModalshow
                  ? chatContext?.forwardListWithGroup
                  : context?.members
                )
                  ?.filter((item: any) => item.id !== context?.user.id)
                  .map((user: any) => (
                    <div
                      className={` d-flex align-items-center p-2 mb-2 mx-2  new-conversation-list rounded-2 ${
                        user?.group_name
                          ? selectedGrpId.includes(user?.id)
                          : selectedUserId.includes(user?.id) ||
                            chatContext?.groupMembers.some(
                              (itemb: any) => itemb?.id === user?.id
                            )
                          ? "new-conversation-list-active"
                          : ""
                      }`}
                      key={user.id}
                    >
                      <div className="pe-3 check-box-margin">
                        {forwardModalshow && (
                          <span className="me-3">
                            <label className="checkbox" htmlFor={user?.id}>
                              <input
                                type="checkbox"
                                name="message"
                                value={user?.id}
                                id={user?.id}
                                disabled={
                                  user?.name
                                    ? !selectedUserId.includes(user?.id) &&
                                      selectedUserId.length +
                                        selectedGrpId.length >=
                                        5
                                    : !selectedGrpId.includes(user?.id) &&
                                      selectedUserId.length +
                                        selectedGrpId.length >=
                                        5
                                }
                                checked={
                                  user?.group_name
                                    ? selectedGrpId.includes(user?.id)
                                    : selectedUserId.includes(user?.id)
                                }
                                onChange={() =>
                                  handleCheckboxChange(
                                    user?.id,
                                    user?.name || user?.group_name,
                                    user?.group_name ? true : false
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </span>
                        )}
                      </div>
                      <div
                        className="d-flex align-items-center w-100 cursor-pointer"
                        // onClick={(
                        //   e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        // ) =>
                        //   !forwardModalshow &&
                        //   (isGroup || addMember
                        //     ? handleAddToGroup(user?.id, user?.name)
                        //     : !handleCreateConversation(user?.id, e))
                        // }
                        onClick={(e) =>
                          handleClick(
                            user?.id,
                            e,
                            isGroup,
                            addMember,
                            user?.name
                          )
                        }
                        title={
                          addMember
                            ? `Add ${user?.name} to group`
                            : newGroup
                            ? "New Group"
                            : isGroup
                            ? "Add group members"
                            : forwardModalshow
                            ? `Forward message to ${user?.name}`
                            : "New Conversation"
                        }
                      >
                        <div className="userlist-img-wrapper">
                          {user.avatar ? (
                            <img src={user.avatar} alt="" />
                          ) : (
                            <Avatar
                              name={user.name || user?.group_name}
                              size="50"
                              round={true}
                              maxInitials={2}
                            />
                          )}
                        </div>
                        <div className="ms-3">
                          <h6 className="fw-semibold mb-0 text-capitalize">
                            {user.name || user?.group_name?.length > 20
                              ? user.name ||
                                user?.group_name?.substring(0, 20) + "....."
                              : user.name || user?.group_name}
                          </h6>
                          <div className="role-list">
                            {user.role?.length > 20
                              ? user.role?.substring(0, 20) + "....."
                              : user.role}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {(isGroup || addMember) &&
                  chatContext?.groupMembers.length > 0 && (
                    <div className="add-people d-flex align-items-center justify-content-center">
                      <div
                        className="group-add-people-btn d-flex align-items-center justify-content-center"
                        onClick={() => {
                          isGroup && setNewGroup?.(true);
                          addMember && addMemberToGroup();
                          setIsGroup(false);
                        }}
                      >
                        <FaArrowRightLong
                          title={"Add Selected Members to group"}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      {(selectedUserId.length > 0 || selectedGrpId.length > 0) && (
        <div className="d-flex justify-content-between align-items-center forward-send p-3">
          <h6 className="mb-0 fw-semibold text-capitalize">
            {name?.length > 1
              ? name.map((n: string, index: number) =>
                  index < name.length - 1 ? n + ", " : n
                )
              : name}
          </h6>

          <span
            className="ms-4 cursor-pointer send-btn"
            onClick={() => handleForward()}
            title="send Forward message"
          >
            <ChatSendBtn size="48px" />
          </span>
        </div>
      )}
    </Modal>
  );
}

export default ForwardPopup;
