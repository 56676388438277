import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsArrowsAngleExpand, BsMoon } from "react-icons/bs";
import { MdOutlineLightMode } from "react-icons/md";
import { useAuthentication } from "../../../../../context/AuthenticationProvider";
import { useChatContext } from "../../../context/ChatProvider";
import CallConnection from "../PrivateRoom/CallConnection";
import PrivateRoom from "../PrivateRoom/privateRoom";
import "./AudioAndVideo.scss";

const AudioAndVideo = () => {
  const chatContext = useChatContext();
  const userContext = useAuthentication();
  const showModal = chatContext?.audioCallshow || chatContext?.videoCallshow;
  const [isDark, setIsDark] = useState(false);
  useEffect(() => {
    if (chatContext?.callWaiting) {
      const timer = setTimeout(() => {
        chatContext.handleDisconnect(
          "DISCONNECT",
          userContext?.user?.id,
          "MISCALL",
          chatContext?.activeChat?.id
        );
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [chatContext?.callWaiting]);

  return (
    <Modal
      show={showModal}
      centered
      size="xl"
      className={isDark ? "call-popup-dark" : "call-popup-light"}
    >
      <div>
        <div className="d-flex justify-content-between align-items-center pt-4 px-3">
          <h6 className="mb-0 fw-semibold ">
            {chatContext?.isCallAccepted ? (
              <span className=" d-flex align-items-center gap-3">
                {chatContext?.userInfo?.user?.name}
                <span className="call-users">
                  <BsArrowsAngleExpand size={24} color="var(--grey)" />
                </span>
                {userContext?.user?.name}
              </span>
            ) : (
              <span className=" d-flex align-items-center gap-3">
                {userContext?.user?.name}
                <span className="call-users">
                  <BsArrowsAngleExpand size={24} color="var(--grey)" />
                </span>
                {chatContext?.conversationDetails?.conversationId
                  ? chatContext?.conversationDetails?.name
                  : chatContext?.activeChat?.participants.find(
                      (item: any) => item._id != userContext?.user?.id
                    ).name}
              </span>
            )}
          </h6>
          {chatContext?.callWaiting && (
            <CallConnection ConnectingText="In Another Call" callWaiting />
          )}
          <div
            className="theme-btn d-flex justify-content-center align-items-center"
            onClick={() => {
              setIsDark(!isDark);
            }}
          >
            {isDark ? <BsMoon /> : <MdOutlineLightMode />}
          </div>
        </div>
        <Modal.Body>
          {chatContext?.isCallAccepted ? (
            <PrivateRoom
              conversationId={chatContext?.userInfo?.conversationId}
              audioCallshow={chatContext?.audioCallshow}
              videoCallshow={chatContext?.videoCallshow}
            />
          ) : (
            <PrivateRoom
              conversationId={
                chatContext?.conversationDetails?.conversationId
                  ? chatContext?.conversationDetails?.conversationId
                  : chatContext?.activeChat?.id
              }
              audioCallshow={chatContext?.audioCallshow}
              videoCallshow={chatContext?.videoCallshow}
            />
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default AudioAndVideo;
