import { useState } from "react";
import { useChatContext } from "../../context/ChatProvider";

export default function useChatRoom() {
  const chatCtx = useChatContext();
  const [isShowIcon, setIsShowIcon] = useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  const handleShowIcon = (state: boolean) => {
    setIsShowIcon(state);

    if (state === false) setShouldScrollToBottom(false);
  };

  return {
    activeInbox: chatCtx.activeChat,
    handleShowIcon,
    isShowIcon,
    setShouldScrollToBottom,
    shouldScrollToBottom,
  };
}
