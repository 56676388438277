import { useRef } from "react";
import "./MessagesListSearch.css";
import { IoSearchSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";

interface MessagesListSearchProps {
  setIsSearchOpen: Function;
  isSearchOpen: boolean;
  onSearch?: (query: string) => void;
  setSearchQuery?: any;
  searchQuery?: any;
}

const MessagesListSearch = ({
  setIsSearchOpen,
  isSearchOpen,
  onSearch,
  setSearchQuery,
  searchQuery,
}: MessagesListSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchClick = () => {
    setIsSearchOpen(true);
    inputRef.current?.focus();
  };

  const handleCloseClick = () => {
    setIsSearchOpen(false);
    setSearchQuery("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onSearch) {
      onSearch(searchQuery);
    }
  };

  return (
    <div className={`input-box ${isSearchOpen ? "open" : ""}`}>
      <input
        type="text"
        ref={inputRef}
        placeholder="Search messages..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        autoFocus={isSearchOpen}
      />
      <span
        className="search d-flex justify-content-center align-items-center"
        onClick={handleSearchClick}
      >
        <IoSearchSharp size={24} title="Search" color="var(--secondary)" />
      </span>
      <span className="close-icon">
        <RxCross2
          fontSize={24}
          color="var(--secondary)"
          title="search close"
          onClick={() => handleCloseClick()}
        />
      </span>
    </div>
  );
};

export default MessagesListSearch;
