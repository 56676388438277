interface ChatSendBtnProps {
  size: string;
}

const ChatSendBtn = ({ size }: ChatSendBtnProps) => {
  return (
    <svg
      id="SvgjsSvg1012"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs id="SvgjsDefs1013"></defs>
      <g id="SvgjsG1014">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 7"
          viewBox="0 0 46.35 46.35"
          width={size}
          height={size}
        >
          <path
            fill="var(--primary)"
            d="M31.86 13.21 18.74 26.33l-9.49-4.67 22.61-8.45zM33.15 14.49l-8.46 22.62-4.66-9.5 13.12-13.12z"
          ></path>
          <path
            fill="var(--primary)"
            d="M42.73 10.74a23.17 23.17 0 1 0 3.62 12.43 23 23 0 0 0-3.62-12.43Zm-6.39.35v.11L25.67 39.72a.9.9 0 0 1-.8.59.92.92 0 0 1-.82-.51l-5.74-11.69-11.75-5.74a.9.9 0 0 1-.51-.86.91.91 0 0 1 .59-.81l28.52-10.67h.1a1 1 0 0 1 .17 0H35.73l.14.06h.1l.11.11.11.11v.1a.94.94 0 0 1 .06.15.77.77 0 0 1 0 .15.38.38 0 0 1 0 .15.4.4 0 0 1 .09.23Z"
          ></path>
        </svg>
      </g>
    </svg>
  );
};

export default ChatSendBtn;
