import entryimage2 from "../../../context/assets/images/male-and-female-chatting-with-each-other-through-mobile.svg";

import { isMobile, isTablet } from "react-device-detect";
import Sidebar from "../Sidebar/Sidebar";
import "./UnSelectedChatPage.scss";

export default function UnSelectedChatPage() {
  return (
    <div className=" chat-layout">
      <div className="layout-content">
        <Sidebar />

        <div
          className={`container-unselect ${
            isTablet || isMobile ? "d-none" : ""
          } 
          }`}
        >
          <div className="image-wrapper">
            {/* <img src={entryimage} alt="" className="Image" /> */}
            <img src={entryimage2} alt="" />
          </div>
          <div className="title-unselect">Web Chat Application </div>
          {/* <div className="text-unselect text-center">
            Send and receive messages without keeping your phone online. <br />
            Use Chat App on up to 4 linked devices and 1 phone at the same time.
          </div> */}
          <div className="text-unselect">
            <span>
              Built by -
              <a
                href="https://coducer.github.io"
                target="_blank"
                rel="noreferrer"
                className="company-link text-dark ps-1"
              >
                Coducer Technologies pvt.Ltd
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
