import { FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useState } from "react";
import CommonButton from "../../components/CommonButton/CommonButton";
import FormikField from "../../components/FormikField/FormikField";

const ChangePasswordForm = ({
  isProfile,
  setBodyMessage,
  userContext,
  setUpdateProfileDetail,
  setTextMessage,
  setFormikFunction,
  setShowPopUp,
  setHeaderMessage,
}: any) => {
  const { t } = useTranslation();
  const [oldPasswordEye, setOldPasswordEye] = useState(false);
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);

  let isProfilePassword = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  let isMemberPassword = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  const initialValues = isProfile ? isProfilePassword : isMemberPassword;
  const validationSchema = Yup.object(
    isProfile
      ? {
          old_password: Yup.string()
            .required("Old Password is required")
            .min(8, "Password is too short- should be 8 characters  minimum")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@_`{}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@_`{}~]{8,20}$/,
              "Must contain 8-20 characters, one uppercase, one lowercase, one number, and one special character"
            ),
          password: Yup.string()
            .required("Password is required")
            .min(8, "Password is too short- should be 8 characters  minimum")
            .matches(/^(?=.{6,})/, "Must Contain 6 Characters")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@_`{}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@_`{}~]{8,20}$/,
              "Must contain 8-20 characters, one uppercase, one lowercase, one number, and one special character"
            )
            .notOneOf(
              [Yup.ref("old_password")],
              "Old Password and New password are same"
            ),
          password_confirmation: Yup.string()
            .required("Confirmation Password is required")
            .oneOf([Yup.ref("password")], "Password doesn't match"),
        }
      : {
          password: Yup.string()
            .required("Password is required")
            .min(8, "Password is too short- should be 8 characters  minimum")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@_`{}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@_`{}~]{8,20}$/,
              "Must contain 8-20 characters, one uppercase, one lowercase, one number, and one special character"
            ),
          password_confirmation: Yup.string()
            .required("Confirmation Password is required")
            .oneOf([Yup.ref("password")], "Password doesn't match"),
        }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setTextMessage(`${t("successError.update it")}`);
      setBodyMessage(`${t("successError.Password updated")}`);
      setHeaderMessage(`${t("successError.reset the password")}`);
      setUpdateProfileDetail(
        window?.location?.pathname?.includes("/edit_reset_password")
          ? {
              password: values?.password,
              password_confirmation: values?.password_confirmation,
            }
          : values
      );
      setShowPopUp(true);
      setFormikFunction(formik);
    },
  });

  const { errors, handleSubmit, isSubmitting, isValid, dirty } = formik;
  return (
    <FormikProvider value={formik}>
      <Form validated={true} onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="mb-4">
            <div className="position-relative">
              <FormikField
                name={isProfile ? "old_password" : "password"}
                type={oldPasswordEye ? "text" : "password"}
                errors={errors as Record<string, string>}
                validationSchema={validationSchema}
                label={t(isProfile ? "editpassword.old" : "editpassword.new")}
                isPassword={true}
                disabled={
                  !isProfile &&
                  !userContext?.roleAccess["USER"].includes("UPDATE")
                }
                setPasswordIcon={setOldPasswordEye}
                passwordIcon={oldPasswordEye}
                maxLength={50}
              />
            </div>
          </Col>
          <Col md={6} className="mb-4">
            <div className="position-relative">
              <FormikField
                name={isProfile ? "password" : "password_confirmation"}
                type={passwordEye ? "text" : "password"}
                errors={errors as Record<string, string>}
                validationSchema={validationSchema}
                label={t(
                  isProfile ? "editpassword.new" : "editpassword.confrim"
                )}
                isPassword={true}
                disabled={
                  !isProfile &&
                  !userContext?.roleAccess["USER"].includes("UPDATE")
                }
                setPasswordIcon={setPasswordEye}
                passwordIcon={passwordEye}
                maxLength={50}
              />
            </div>
          </Col>
          {isProfile && (
            <Col className="mb-4">
              <div className="position-relative">
                <FormikField
                  name="password_confirmation"
                  type={confirmPasswordEye ? "text" : "password"}
                  errors={errors as Record<string, string>}
                  validationSchema={validationSchema}
                  label={t("editpassword.confrim")}
                  isPassword={true}
                  disabled={
                    !isProfile &&
                    !userContext?.roleAccess["USER"].includes("UPDATE")
                  }
                  setPasswordIcon={setConfirmPasswordEye}
                  passwordIcon={confirmPasswordEye}
                  maxLength={50}
                />
              </div>
            </Col>
          )}
        </Row>

        {(isProfile
          ? true
          : userContext?.roleAccess["USER"].includes("UPDATE")) && (
          <div className="d-flex justify-content-end my-5">
            <CommonButton
              text={t("editpassword.cancel")}
              type="button"
              isLoading={isSubmitting}
              variant="outline-danger"
              className="bg-transparent text-danger"
              isDisabled={isSubmitting || !dirty}
              onClick={() => {
                setHeaderMessage(`${t("successError.cancel the changes")}`);
                setTextMessage(`${t("successError.cancel it")}`);
                setBodyMessage(`${t("successError.cancel it")}`);
                setFormikFunction(formik);
                setShowPopUp(true);
              }}
            />
            <CommonButton
              text={
                isProfile
                  ? t("editpassword.Update")
                  : t("editpassword.resetPassword")
              }
              isLoading={isSubmitting}
              type="submit"
              variant="success"
              className="btn-i18n text-white"
              isDisabled={isSubmitting || !isValid || !dirty}
            />
          </div>
        )}
      </Form>
    </FormikProvider>
  );
};
export default ChangePasswordForm;
