import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { Button, Form, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { routes } from "../../constants/routes";
import { useAuthentication } from "../../context/AuthenticationProvider";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import FormikField from "../../components/FormikField/FormikField";

let timedPopupSetTimeOut: any = undefined;

const LoginForm = () => {
  const { t, i18n } = useTranslation();
  const context = useAuthentication();
  const navigate = useNavigate();
  const [bodyMessage, setBodyMessage] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hideEyeIcon, setHideEyeIcon] = useState(false);
  const validationSchema: any = Yup.object({
    email: Yup.string()
      .matches(
        /^([_A-Za-z0-9+]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/,
        "Invalid email address or phone number"
      )
      .min(13, "Invalid email address or phone number")
      .required("E-mail Address or phone number is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short- should be 8 characters  minimum")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@_`{}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@_`{}~]{8,20}$/,
        "Must contain 8-20 characters, one uppercase, one lowercase, one number, and one special character"
      ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      const { email, password } = values;
      if (context) {
        const { success, error } = await context.login(email, password);
        setIsSuccess(success);
        if (success) {
          setButtonPopup(true);
          setBodyMessage(`${t("successError.Logged in")}`);
          timeOutPopUp(true);
        } else {
          setButtonPopup(true);
          setBodyMessage(error);
          timeOutPopUp(false);
        }
      }
    },
  });

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.dashboard);
        setBodyMessage("");
      } else {
        setButtonPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const { errors, handleSubmit, isSubmitting, isValid, dirty } = formik;

  return (
    <React.Fragment>
      <FormikProvider value={formik}>
        <Form validated={true} onSubmit={handleSubmit}>
          <div className="floating">
            <FormikField
              name={"email"}
              errors={errors as Record<string, string>}
              validationSchema={validationSchema}
              label={t("login.label")}
              type={"text"}
              maxLength={50}
            />
            <div className="position-relative mt-3">
              <FormikField
                name="password"
                type={hideEyeIcon ? "text" : "password"}
                validationSchema={validationSchema}
                label={t("login.password")}
                errors={errors as Record<string, string>}
                isPassword={true}
                setPasswordIcon={setHideEyeIcon}
                passwordIcon={hideEyeIcon}
                maxLength={50}
              />
            </div>
          </div>
          <Stack className="my-3" direction="horizontal">
            <NavLink
              to={routes.forgetPassword}
              className="forgot text-decoration-none text-primary"
            >
              {t("login.forgot")}
            </NavLink>
          </Stack>
          {context && (
            <Button
              type="submit"
              id="signin"
              variant="primary"
              className="my-5 px-5 py-2 rounded-1 d-flex justify-content-center align-items-center pointer"
              disabled={!isValid || context.isLoggedIn}
            >
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  <span className={` ${!isValid ? "" : "fw-semibold"}`}>
                    {t("login.sign")}
                  </span>
                  <span className={i18n.language === "en" ? "ms-2" : "d-none"}>
                    <FaLongArrowAltRight />
                  </span>
                  <span className={i18n.language === "en" ? "d-none" : "me-2"}>
                    <FaLongArrowAltLeft />
                  </span>
                </>
              )}
            </Button>
          )}
        </Form>
      </FormikProvider>
      <ToastPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>
      {!isSuccess && (
        <ToastPopup
          trigger={context?.isLoggedIn}
          setTrigger={context?.setIsLoggedIn}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
      )}
    </React.Fragment>
  );
};

export default LoginForm;
