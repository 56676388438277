import { LiveKitRoom } from "@livekit/components-react";
import * as Sentry from "@sentry/react";
import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import File404 from "./components/404/File404";
import File502 from "./components/404/File502";
import SuccessErrorToast from "./components/PopupToast/SuccessErrorToast";
import ToastPopup from "./components/PopupToast/ToastPopup";
import { routes } from "./constants/routes";
import {
  AuthenticationContextProp,
  useAuthentication,
} from "./context/AuthenticationProvider";
import ChangePasswordPage from "./pages/AuthenticationPages/ChangePasswordPage";
import ForgetPasswordPage from "./pages/AuthenticationPages/ForgetPasswordPage";
import InvitationPage from "./pages/AuthenticationPages/InvitationPage";
import LoginPage from "./pages/AuthenticationPages/LoginPage";
import ResetPassword from "./pages/AuthenticationPages/ResetPasswordPage";
import BillBoardPages from "./pages/BillBoards";
import CircuitsPages from "./pages/Circuits";
import CleaningPages from "./pages/Cleaning";
import DashboardPages from "./pages/Dashboard";
import HelpCenter from "./pages/HelpCenter";
import InventeryPages from "./pages/Inventory";
import ChatApp from "./pages/livikit/ChatApp";
import ChatProvider from "./pages/livikit/context/ChatProvider";
import AudioVideoCallModal from "./pages/livikit/pages/chat/AudioVideoCallModal";
import MastersPages from "./pages/masters";
import MembersPages from "./pages/members";
import RolesAndAccess from "./pages/Rolesdetails/RolesAndAccess";
import TicketsPages from "./pages/Tickets";
import TimeSheetPage from "./pages/TimeSheet";
import { LIVEKIT_WEBSOCKET } from "./utils/axiosInstance";
import LiveKitService from "./utils/services/livekit.service";
import CommonButton from "./components/CommonButton/CommonButton";
import disconnectSvg from "../src/assets/disconnet.svg";

export const RequireAuth: React.FC<any> = ({ module, feature, children }) => {
  const isLoggedIn = sessionStorage.getItem("auth") ? true : false;
  const userContext: AuthenticationContextProp | null = useAuthentication();
  let hasAccess: boolean = true;
  if (module && feature) {
    hasAccess =
      userContext &&
      userContext?.role &&
      userContext?.role[module] &&
      userContext?.role[module]
        ?.map((obj: string) => obj.toLocaleLowerCase())
        ?.includes(feature.toLocaleLowerCase());
  }

  return isLoggedIn && hasAccess ? (
    children
  ) : (
    <Navigate to={routes.login} replace />
  );
};

function App() {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(navigator.onLine);
  const [livekitToken, setLivekitToken] = useState<string | undefined>();
  const [isDisconnected, setIsDisconnected] = useState(false);
  const setOnline = () => {
    setOnlineStatus(true);
  };
  const setOffline = () => {
    setOnlineStatus(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleDisconnected = () => {
    setIsDisconnected(true);
  };

  const DisconnectedModal = ({ onRefresh }: { onRefresh: () => void }) => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "var(--modal-backdrop-bg-2)",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <div className="d-flex justify-content-center mb-4">
          <div
            className="d-flex justify-content-center "
            style={{ width: "300px", height: "200px" }}
          >
            <img
              src={disconnectSvg}
              alt="disconnect"
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>

        <h2>You're Disconnected</h2>
        <p>Please refresh the page to reconnect.</p>
        <CommonButton text={"Refresh"} onClick={onRefresh} variant="primary" />
      </div>
    </div>
  );
  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  const userContext = useAuthentication();

  const connectWithPublicRoom = useCallback(async () => {
    const userId = userContext?.user?.id;
    if (userId) {
      const res = await LiveKitService.connectRoom({
        roomName: "alarabia",
        participantName: userId,
      });
      if (res?.token) {
        setLivekitToken(res?.token);
      }
    }
  }, [userContext?.user?.id]);

  useEffect(() => {
    if (userContext?.user?.id) {
      connectWithPublicRoom();
    }
    const timer = setTimeout(() => {
      connectWithPublicRoom();
    }, 150000);
    return () => clearTimeout(timer);
  }, [connectWithPublicRoom, userContext?.user?.id]);

  useEffect(() => {
    const getUserDetails = async () => {
      await userContext?.getUser();
    };
    if (userContext) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    document.dir = i18next.dir();
  }, []);

  const token = sessionStorage.getItem("auth");

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path={routes.root}
          element={
            <Navigate to={token ? routes.dashboard : routes.login} replace />
          }
        />

        <Route path={routes.login} element={<LoginPage />} />
        <Route path={routes.forgetPassword} element={<ForgetPasswordPage />} />
        <Route path={routes.changePassword} element={<ChangePasswordPage />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route path={routes.invitation} element={<InvitationPage />} />
        <Route path={routes.file404} element={<File404 />} />
        <Route path={routes.file502} element={<File502 />} />
        <Route path={routes.rolesandaccess} element={<RolesAndAccess />} />
        <Route path={routes.helpCenter} element={<HelpCenter />} />
      </Routes>
      {token && livekitToken && (
        <LiveKitRoom
          serverUrl={LIVEKIT_WEBSOCKET}
          token={livekitToken}
          connect={true}
          video={false}
          audio={false}
          onDisconnected={handleDisconnected}
        >
          <ChatProvider>
            <MembersPages userContext={userContext} />
            <BillBoardPages userContext={userContext} />
            <CircuitsPages userContext={userContext} />
            <TicketsPages userContext={userContext} />
            <DashboardPages userContext={userContext} />
            <InventeryPages userContext={userContext} />
            <CleaningPages userContext={userContext} />
            <MastersPages userContext={userContext} />
            <ChatApp />
            <TimeSheetPage userContext={userContext} />
            {!onlineStatus && (
              <ToastPopup trigger={true}>
                <SuccessErrorToast
                  bodyMessage={"You're currently offline"}
                  isSuccess={false}
                  // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
                  // buttonName='Login Now!'
                  footButton={false}
                />
              </ToastPopup>
            )}
            <AudioVideoCallModal />
          </ChatProvider>
        </LiveKitRoom>
      )}
      {/* {isDisconnected && <DisconnectedModal onRefresh={handleRefresh} />} */}
    </>
  );
}

export default Sentry.withProfiler(App);
