import "./Nodata.scss";

interface NodataProps {
  noDataText: string;
  dataImage?: string;
}

function Nodata({ noDataText, dataImage }: NodataProps) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center flex-column ${
        dataImage ? "pt-5" : "pt-4"
      }`}
    >
      {dataImage && (
        <div className="LogoWrapper">
          <div className=" app-logo">
            <img src={dataImage} alt="logochat" />
          </div>
        </div>
      )}
      <span className="fs-13 fw-medium pt-2"> {noDataText}</span>
    </div>
  );
}

export default Nodata;
