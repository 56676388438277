import React, { ReactElement, useState } from "react";
import User from "../lib/User";
import { api } from "../utils/api";

interface CleaningProviderProps {
  children: ReactElement;
}

export interface CleaningContextProp {
  isLoading: boolean;
  setIsLoading: Function;
  meta: any;
  cleanings: any;
  cleaning: any;
  setCleanings: any;
  loadCleanings: Function;
  createCleaningDetail: Function;
  statsData: any;
  getCleaningStatsOverview: Function;
  downloadExcel: Function;
  downloadPdf: Function;
  cityList: Function;
  getCleaningDetail: Function;
  updateCleaningDetail: Function;
  cleaningTickets: any;
  loadCleaningTickets: Function;
  ticketActions: Function;
  removeCleaningTickets: Function;

  ticket: any;
  members: any;
  billBoards: any;
  billBoard: any;
  ticketActivityFeed: any;
  getTicketDetail: Function;
  updateTicketDetail: Function;
  loadTicketBillBoards: Function;
  loadMembers: Function;
  createTicketDetail: Function;
  approveByStaffTicket: Function;
  approveBySupervisorTicket: Function;
  rejectTicket: Function;
  deleteTicket: Function;
  reassignTicket: Function;
  getTicketActivityFeed: Function;
  loadBillBoards: Function;
  getBillBoardDetail: Function;
  removeTicket: Function;
  removeBillBoard: Function;
  getTicketTitleDetail: Function;
  createGroupConversation: Function;
  autoCompleteApi: Function;

  loadMasterBillBoardsDetails: Function;
}

const CleaningContext = React.createContext<CleaningContextProp | null>(null);

const CleaningProvider = ({ children }: CleaningProviderProps) => {
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [cleanings, setCleanings] = useState<any>([]);
  const [cleaning, setCleaning] = useState<any>({});

  const [members, setMembers] = useState<any>([]);
  const [ticketActivityFeed, setTicketActivityFeed] = useState<any>([]);
  const [billBoards, setBillBoards] = useState<any>([]);
  const [billBoard, setBillBoard] = useState<any>({});
  const [meta, setMeta] = useState<any>({});

  const [statsData, setStatsData] = useState<any>({});
  const [cleaningTickets, setCleaningTickets] = useState<any>({});

  const [ticket, setTicket] = useState<any>({});

  // cleaning module

  // main page
  const loadCleanings = async (params: any) => {
    setIsLoading(true);
    const { cleanings, meta } = await api.cleaninglist(params);
    setCleanings(cleanings);
    setMeta(meta);
    setIsLoading(false);
  };

  const getCleaningStatsOverview = async () => {
    setIsLoading(true);
    const statsData = await api.getCleaningStatsOverview();
    setStatsData(statsData);
    setIsLoading(false);
    return { statsData };
  };

  const downloadExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.cleaningExcelDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.cleaningPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  // uesd in form
  const createCleaningDetail = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.createCleaning(params);
    const { success, cleaning, error } = data || {};
    setIsLoading(false);
    return { success, cleaning, error };
  };

  const loadMasterBillBoardsDetails = async (params: any) => {
    setIsLoading(true);
    const { data, success } = await api.masterBillBoardsDetails(params);
    if (success) {
      setIsLoading(false);
      return data;
    }
  };

  const loadBillBoards = async (params: any) => {
    setIsLoading(true);
    const { billboards, success } = await api.billBoards(params);
    setBillBoards(billboards);
    setIsLoading(false);
    return { success, billboards };
  };
  const loadMembers = async (params: any) => {
    setIsLoading(true);
    const { success, user, meta } = await api.getMembers(params);
    if (success) {
      setMembers(user);
    }
    setIsLoading(false);
    return { success, user, meta };
  };

  const cityList = async (params: any) => {
    setIsLoading(true);
    const data = await api.cityAutoComplete(params);
    setIsLoading(false);
    return data;
  };

  // used while (create or view) form

  const getCleaningDetail = async (cleaningId: any) => {
    if (cleaningId !== "0") {
      setIsLoading(true);
      const { success, cleaning, error } = await api.cleaning(cleaningId);
      //

      if (success) {
        setCleaning(cleaning);
      }
      setIsLoading(false);
      return { success, cleaning, error };
    }
  };

  const updateCleaningDetail = async (cleaningId: any, params: any) => {
    if (cleaningId) {
      setIsLoading(true);
      const data = await api.updateCleaning(cleaningId, params);
      if (data?.data?.success) {
        setCleaning(data?.data?.cleaning);
      }
      setIsLoading(false);
      return {
        success: data?.data?.success,
        cleaning,
        error: data?.data?.error,
      };
    }
  };

  const loadCleaningTickets = async (id: string, params?: any) => {
    setIsLoading(true);
    const { success, tickets, meta } = await api.cleaningTicketlist(id, params);
    setCleaningTickets(tickets);

    setMeta(meta);
    setIsLoading(false);
    return { success, tickets, meta };
  };

  // individual ticket actions (verify, reject, approve)
  const ticketActions = async (
    action: string,
    idList: any,
    rejected_reason?: any
  ) => {
    setIsLoading(true);
    const { data } = await api.cleaningTicketAction(
      action?.length > 0
        ? {
            status: action,
            ticket_id: idList,
            rejected_reason: rejected_reason,
          }
        : {}
    );

    const { success, error } = data || {};

    setIsLoading(false);
    return { success, error };
  };

  const removeCleaningTickets = (value: any) => {
    setCleaningTickets(value);
    // setTicketActivityFeed(value);
  };

  // previous

  const getTicketDetail = async (TicketId: any) => {
    if (TicketId !== "0") {
      setIsLoading(true);
      const { success, ticket, error } = await api.ticket(TicketId);

      if (success) {
        setTicket(ticket);
      }
      setIsLoading(false);
      return { success, ticket, error };
    }
  };

  const updateTicketDetail = async (TicketId: any, params: any) => {
    if (TicketId) {
      setIsLoading(true);
      const { data } = await api.updateTicket(TicketId, params);
      const { success, ticket, error } = data || {};
      if (success) {
        setTicket(ticket);
      }
      setIsLoading(false);
      return { success, ticket, error };
    }
  };

  const createTicketDetail = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.createTicket(params);
    const { success, ticket, error } = data || {};
    if (success) {
      setTicket(ticket);
    }
    setIsLoading(false);
    return { success, ticket, error };
  };

  const loadTicketBillBoards = async (id: any) => {
    setIsLoading(true);
    const { tickets, success } = await api.getTicketBasedOnBillBoard(id);
    if (success) {
      setTicket(tickets.map((ticket: any) => new User(ticket)));
    }
    setIsLoading(false);
    return { tickets, success };
  };

  const deleteTicket = async (id: any) => {
    setIsLoading(true);
    const { success, error } = await api.deleteTicket(id);
    setIsLoading(false);
    return { success, error };
  };

  const approveByStaffTicket = async (id: any) => {
    setIsLoading(true);
    const { data } = await api.approveByStaffTicket(id);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const approveBySupervisorTicket = async (id: string, reason: string) => {
    setIsLoading(true);
    const { data } = await api.approveBySupervisorTicket(
      id,
      reason?.length > 0
        ? {
            submitted_reason: reason,
          }
        : {}
    );
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const rejectTicket = async (id: any) => {
    setIsLoading(true);
    const { data } = await api.rejectTicket(id);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const reassignTicket = async (id: any, params: any) => {
    setIsLoading(true);
    const { data } = await api.reassignTicket(id, params);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const getTicketActivityFeed = async (ticketId: any) => {
    if (ticketId !== "0") {
      setIsLoading(true);
      const { success, ticket_activities, error } =
        await api.getTicketActivityFeed(ticketId);
      if (success) {
        setTicketActivityFeed(ticket_activities);
      }
      setIsLoading(false);
      return { success, ticket_activities, error };
    }
  };

  const getBillBoardDetail = async (billBoardId: any) => {
    if (billBoardId) {
      setIsLoading(true);
      const { success, user, error } = await api.billBoard(billBoardId);
      setBillBoard(user);
      setIsLoading(false);
      return { success, user, error };
    }
  };

  const removeTicket = (value: any) => {
    setTicket(value);
    setTicketActivityFeed(value);
  };

  const removeBillBoard = (value: any) => {
    setBillBoard(value);
  };

  const getTicketTitleDetail = async (value: any) => {
    setIsLoading(true);
    const { success, title, error } = await api.getTicketTitle(value);
    setIsLoading(false);
    return { success, title, error };
  };

  const createGroupConversation = async (id: any) => {
    setIsLoading(true);
    const data = await api.createConversation({ ticket_id: id });
    setIsLoading(false);
    return data?.data;
  };

  const autoCompleteApi = async (params: any) => {
    setIsLoading(true);
    const { success, users, meta } = await api.autoCompleteApi(params);
    setIsLoading(false);
    return { success, users, meta };
  };

  const value: CleaningContextProp = {
    isLoading,
    setIsLoading,
    meta,
    cleanings,
    cleaning,
    setCleanings,
    loadCleanings,
    createCleaningDetail,
    statsData,
    getCleaningStatsOverview,
    downloadExcel,
    downloadPdf,
    cityList,
    getCleaningDetail,
    updateCleaningDetail,
    cleaningTickets,
    loadCleaningTickets,
    ticketActions,
    removeCleaningTickets,

    loadMembers,

    ticket,
    ticketActivityFeed,
    billBoards,
    billBoard,
    getTicketDetail,
    updateTicketDetail,
    loadTicketBillBoards,
    members,
    createTicketDetail,
    approveByStaffTicket,
    approveBySupervisorTicket,
    rejectTicket,
    deleteTicket,
    reassignTicket,
    getTicketActivityFeed,
    loadBillBoards,
    getBillBoardDetail,
    removeTicket,
    removeBillBoard,
    getTicketTitleDetail,
    createGroupConversation,
    autoCompleteApi,

    loadMasterBillBoardsDetails,
  };

  return (
    <CleaningContext.Provider value={value}>
      {children}
    </CleaningContext.Provider>
  );
};

const useCleaning = () => React.useContext(CleaningContext);

export { CleaningProvider, useCleaning };
