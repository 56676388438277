import { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Moment from "react-moment";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import AppLayout from "../../layouts/AppLayout";
import { useTranslation } from "react-i18next";
import avatar from "../../assets/avatarprofile.png";
import edit from "../../assets/edit.png";
import edityellow from "../../assets/edity.png";
import recent from "../../assets/recent.png";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import Pagination from "../../components/Pagination";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import moment from "moment";
import { convertExcel, convertPdf } from "../../utils/api";
import DownloadPopUp from "../../components/DownloadPopUp";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import { useCleaning } from "../../context/CleaningProvider";

import CleaningStats from "./CleaningStats";
import DatePicker from "react-multi-date-picker";
import CommonButton from "../../components/CommonButton/CommonButton";
import CommonSearch from "../../components/CommonSearch/CommonSearch";
import CommonSort from "../../components/CommonSort/CommonSort";
import CommonFilter from "../../components/CommonFilter/CommonFilter";
import { CommonStatusButton } from "../../components/CommonStatusButton/CommonStatusButton";
import CommonLoadingBar from "../../components/LoadingBar/CommonLoadingBar";
import FormikDateField from "../../components/FormikDateField/FormikDateField";
import CommonAvatar from "../../components/Avatar/CommonAvatar";

let timedPopupSetTimeOut: any = undefined;

const ListCleaning = ({ userContext }: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const context = useCleaning();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [timedPopup, setTimedPopup] = useState(false);

  const [sort, setSort] = useState<any>(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );

  const [search, setSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );

  const [role, setRole] = useState<any>(
    queryParams.get("role") ? queryParams.get("role") : ""
  );
  const [status, setStatus] = useState<any>(
    queryParams.get("status") ? queryParams.get("status")?.split(",") : ["All"]
  );
  const [department, setDepartment] = useState<any>(
    queryParams.get("department") ? queryParams.get("department") : ""
  );
  const [billBoardId, setBillBoardId] = useState<any>(
    queryParams.get("bill_board_id") ? queryParams.get("bill_board_id") : ""
  );
  const [billBoardNum, setBillBoardNum] = useState(
    queryParams.get("billBoardNum") ? queryParams.get("billBoardNum") : ""
  );
  const [city, setCity] = useState<any>(
    queryParams.get("city") ? queryParams.get("city") : ""
  );

  const [assigneeId, setAssigneId] = useState(
    queryParams.get("assignee") ? queryParams.get("assignee") : ""
  );
  const [assignee, setAssigne] = useState<any>(
    queryParams.get("assigneeName") ? queryParams.get("assigneeName") : ""
  );

  const [billBoardType, setBillBoardType] = useState(
    queryParams.get("billboard_type") ? queryParams.get("billboard_type") : ""
  );

  const [schedule, setSchedule] = useState<any>(
    queryParams.get("schedule") ? queryParams.get("schedule") : ""
  );

  const [cleaningType, setCleaningType] = useState<any>(
    queryParams.get("cleaning_type") ? queryParams.get("cleaning_type") : ""
  );

  const [priority, setPriority] = useState<any>(
    queryParams.get("priority") ? queryParams.get("priority") : ""
  );
  const [startDate, setStartDate] = useState<any>(
    queryParams.get("start_date") ? queryParams.get("start_date") : ""
  );

  const [endDate, setEndDate] = useState<any>(
    queryParams.get("end_date") ? queryParams.get("end_date") : ""
  );

  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(
    queryParams.get("page") ? queryParams.get("page") : 1
  );
  const [billBoardList, setBillBoardList] = useState<any>([]);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [isDeletePopUp, setisDeletePopUp] = useState(false);
  const [deleteText, setDeleteText] = useState<any>("");
  const [ticketId, setTicketId] = useState<any>("");
  const [headerMessage, setHeaderMessage] = useState<any>("");
  const [masterBillBoardList, setMasterBillBoardList] = useState<any>([]);

  const pdfRef = useRef<any>(null);
  useEffect(() => {
    loadData();
  }, [search, sort, currentPage]);

  // remove
  const loadBillBoard = async () => {
    const { success, billboards } = await context?.loadBillBoards({
      status: "ACTIVE",
      sort: "board_no",
    });
    if (success) {
      let res = billboards?.map((d: any) => {
        return {
          label: d.board_no,
          value: d.id,
        };
      });
      setBillBoardList(res);
    }
    return { success, billboards };
  };
  // remove end

  const getMasterBillBoardDetails = async () => {
    let data = await context?.loadMasterBillBoardsDetails({});

    let res = data.map((d: any) => {
      return {
        label: d.billboard_type,
        value: d.billboard_type,
      };
    });
    setMasterBillBoardList(res);
  };
  const loadMember = async () => {
    const { success, user } = await context?.loadMembers({
      status: "ACTIVE",
      department: "CLEANING",
      sort: "name",
      role: "TECHNICIAN",
    });
    if (success) {
      let res = user?.map((d: any) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setAssigneeList(res);
    }
    return { success, user };
  };
  const cityData = async () => {
    const data = await context?.cityList({ city: "" });
    if (data?.length) {
      let res = data?.map((d: any) => {
        return {
          label: d.city,
          value: d.city,
        };
      });
      setCityList(res);
    }
  };

  useEffect(() => {
    cityData();
    loadMember();
    loadBillBoard();
    getCleaningStatsOverview();
    getMasterBillBoardDetails();
  }, []);

  const getCleaningStatsOverview = async () => {
    await context?.getCleaningStatsOverview();
  };

  const queryParamsDetails = () => {
    let params: any = {
      search,
      sort,
      limit: 10,

      role,
      status: status?.includes("All") ? "" : status,
      department,
      bill_board_id: billBoardId,
      city: city,
      billBoardNum: billBoardNum,

      assigneeName: assignee,
      assignee: assigneeId,
      page: currentPage,
      billboard_type: billBoardType,
      schedule: schedule,
      cleaning_type: cleaningType,
      priority: priority,
      start_date: startDate,
      end_date: endDate,
    };
    return params;
  };

  const loadData = async () => {
    await context?.loadCleanings(queryParamsDetails());
    navigate(routes.listCleaningUrl(queryParamsDetails()));
  };

  const download = async (type: string) => {
    switch (type) {
      case "excel":
        const res = await context?.downloadExcel(queryParamsDetails());
        convertExcel(res, "Cleaning_List");
        break;
      case "pdf":
        const pdfRes = await context?.downloadPdf(queryParamsDetails());
        convertPdf(pdfRes, "Cleaning_List");
        break;
      default:
        break;
    }
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const applyFilter = () => {
    setCurrentPage(1);
    loadData();
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const resetFilter = () => {
    setCurrentPage(1);
    setOpenSort(false);
    setOpenfilter(!openfilter);

    setRole("");
    setStatus(["All"]);
    setDepartment("");
    setBillBoardId("");
    setBillBoardNum("");
    setCity("");

    setAssigneId("");
    setAssigne("");
    setBillBoardType("");
    setSchedule("");
    setCleaningType("");
    setPriority("");
    setStartDate("");
    setEndDate("");
  };

  const filterByOpen = () => {
    setSort(queryParams.get("sort") ? queryParams.get("sort") : "-createdAt");
    setSearch(queryParams.get("search") ? queryParams.get("search") : "");

    setRole(queryParams.get("role") ? queryParams.get("role") : "");
    setStatus(
      queryParams.get("status")
        ? queryParams.get("status")?.split(",")
        : ["All"]
    );
    setDepartment(
      queryParams.get("board_type") ? queryParams.get("board_type") : ""
    );
    setBillBoardId(
      queryParams.get("bill_board_id") ? queryParams.get("bill_board_id") : ""
    );
    setBillBoardNum(
      queryParams.get("billBoardNum") ? queryParams.get("billBoardNum") : ""
    );

    setAssigneId(
      queryParams.get("assignee") ? queryParams.get("assignee") : ""
    );
    setAssigne(
      queryParams.get("assigneeName") ? queryParams.get("assigneeName") : ""
    );
    setCurrentPage(queryParams.get("page") ? queryParams.get("page") : 1);
    setCity(queryParams.get("city") ? queryParams.get("city") : "");

    setBillBoardType(
      queryParams.get("billboard_type") ? queryParams.get("billboard_type") : ""
    );

    setSchedule(queryParams.get("schedule") ? queryParams.get("schedule") : "");
    setCleaningType(
      queryParams.get("cleaning_type") ? queryParams.get("cleaning_type") : ""
    );
    setPriority(queryParams.get("priority") ? queryParams.get("priority") : "");
    setStartDate(
      queryParams.get("start_date") ? queryParams.get("start_date") : ""
    );
    setEndDate(queryParams.get("end_date") ? queryParams.get("end_date") : "");

    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  const timeOutPopUp = (value: any, data?: string) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (data === "reload") {
        loadData();
      }
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        filterByOpen();
      }
    };
    window.addEventListener("keyup", close);
    return () => window.removeEventListener("keyup", close);
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        sortByOpen();
      }
    };
    window.addEventListener("sortDropDown", close);
    return () => window.removeEventListener("sortDropDown", close);
  }, []);

  const deleteTicket = async () => {
    setisDeletePopUp(false);
    const res = await context?.deleteTicket(ticketId);
    setIsSuccess(res?.success);
    if (res?.success) {
      timeOutPopUp(true, "reload");
      setTimedPopup(true);
      setBodyMessage("Ticket Deleted successfull");
    } else {
      timeOutPopUp(false);
      setTimedPopup(true);
      setBodyMessage(res?.error);
    }
  };

  const openDeleteTicket = (id: any, value: any, cancelText: any) => {
    setTicketId(id);
    setisDeletePopUp(true);
    setDeleteText(cancelText);
    setHeaderMessage(value);
  };

  return (
    <AppLayout>
      <div
        className={`${
          isMobile ? "list_members position-relative  " : "list_members  p-3"
        }`}
      >
        <div
          className={`${
            isMobile ? "container-fluid position-relative " : "container "
          }`}
        >
          <div
            className={`${
              isMobile
                ? "d-none "
                : "pt-4 d-flex align-items-center justify-content-between "
            }`}
          >
            {context?.isLoading && <CommonLoadingBar />}
            <div className="members ">
              <h5 className=" mb-0 ">
                {t("cleaning.Cleaning")} (
                {context?.meta ? context?.meta?.total_count : 0})
              </h5>
            </div>
            <div
              className={`${
                isMobile ? "d-none " : "container-fluid p-0 forinput"
              }`}
            >
              <CommonSearch
                placeholder={t("cleaning.Search Groups") || ""}
                onKeyDown={(e: any) => handleEnterKey(e)}
                onChange={(e: any) =>
                  e.target.value.length === 0 && setSearch(e.target.value)
                }
              />
            </div>

            <div className="">
              <div className="d-flex  align-items-center justify-content-end">
                <CommonSort
                  sort={sort}
                  opensort={opensort}
                  opensortClick={sortByOpen}
                  setCurrentPage={setCurrentPage}
                  setSort={setSort}
                  sortAtoZ="group_no"
                  sortZtoA="-group_no"
                />

                <CommonFilter
                  openfilterClick={filterByOpen}
                  isFilterActive={
                    department?.length > 0 ||
                    billBoardId?.length > 0 ||
                    priority?.length > 0 ||
                    schedule?.length > 0 ||
                    cleaningType?.length > 0 ||
                    assignee.length > 0
                  }
                  openfilter={openfilter}
                  resetClick={resetFilter}
                  filterClose={filterByOpen}
                  filterApply={applyFilter}
                  filterDisabled={
                    !(
                      status?.length > 0 ||
                      department?.length > 0 ||
                      billBoardId?.length > 0 ||
                      assignee.length > 0
                    )
                  }
                >
                  <Dropdownfilter
                    setAssigneId={setAssigneId}
                    assigneeList={assigneeList}
                    setAssigne={setAssigne}
                    assigneeId={assigneeId}
                    assignee={assignee}
                    context={context}
                    masterBillBoardList={masterBillBoardList}
                    setBillBoardType={setBillBoardType}
                    billBoardType={billBoardType}
                    setSchedule={setSchedule}
                    schedule={schedule}
                    setCleaningType={setCleaningType}
                    cleaningType={cleaningType}
                    setPriority={setPriority}
                    priority={priority}
                    setStartDate={setStartDate}
                    startDate={startDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                  />
                </CommonFilter>

                <div>
                  <CommonButton
                    text={t("listmember.Download")}
                    variant="outline-secondary"
                    className=" bg-transparent text-secondary btn-i18n"
                    onClick={() => setShow(true)}
                  />
                </div>

                {userContext?.roleAccess["CLEANING"].includes("WRITE") && (
                  <NavLink to={routes.newCleaning}>
                    <CommonButton
                      text={t("cleaning.New Group")}
                      variant="success"
                      className=" btn-i18n text-white"
                    />
                  </NavLink>
                )}
              </div>
            </div>
          </div>
          <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
            <div
              className={`${
                isDesktop
                  ? "d-none "
                  : " d-flex align-items-center justify-content-between flex-wrap "
              }`}
            >
              <div className="members ">
                <h5 className="mb-3">
                  {t("cleaning.Cleaning")} (
                  {context?.meta ? context?.meta?.total_count : 0})
                </h5>
              </div>
              <div className="d-flex">
                {/* ----------------------------------------------------------------------For tablet-------------------------------------------------- */}
                <div className={`${isTablet ? " d-flex" : "d-none  "}`}>
                  <CommonSort
                    sort={sort}
                    opensort={opensort}
                    opensortClick={sortByOpen}
                    setCurrentPage={setCurrentPage}
                    setSort={setSort}
                    sortAtoZ="group_no"
                    sortZtoA="-group_no"
                  />

                  <CommonFilter
                    openfilterClick={filterByOpen}
                    isFilterActive={
                      department?.length > 0 ||
                      billBoardId?.length > 0 ||
                      priority?.length > 0 ||
                      schedule?.length > 0 ||
                      cleaningType?.length > 0 ||
                      assignee.length > 0
                    }
                    openfilter={openfilter}
                    resetClick={resetFilter}
                    filterClose={filterByOpen}
                    filterApply={applyFilter}
                    filterDisabled={
                      !(
                        status?.length > 0 ||
                        department?.length > 0 ||
                        billBoardId?.length > 0 ||
                        assignee.length > 0
                      )
                    }
                  >
                    <Dropdownfilter
                      setAssigneId={setAssigneId}
                      assigneeList={assigneeList}
                      setAssigne={setAssigne}
                      assigneeId={assigneeId}
                      assignee={assignee}
                      context={context}
                      masterBillBoardList={masterBillBoardList}
                      setBillBoardType={setBillBoardType}
                      billBoardType={billBoardType}
                      setSchedule={setSchedule}
                      schedule={schedule}
                      setCleaningType={setCleaningType}
                      cleaningType={cleaningType}
                      setPriority={setPriority}
                      priority={priority}
                      setStartDate={setStartDate}
                      startDate={startDate}
                      setEndDate={setEndDate}
                      endDate={endDate}
                    />
                  </CommonFilter>
                </div>
                <div>
                  <CommonButton
                    text={t("listmember.Download")}
                    variant="outline-secondary"
                    className=" bg-transparent text-secondary btn-i18n"
                    onClick={() => setShow(true)}
                  />
                </div>
                {userContext?.roleAccess["CLEANING"].includes("WRITE") && (
                  <NavLink to={routes.newCleaning} className="">
                    <CommonButton
                      text={t("cleaning.New Group")}
                      variant="success"
                      className=" btn-i18n text-white"
                    />
                  </NavLink>
                )}
              </div>
            </div>
            <div className="pt-5 position-relative">
              <CommonSearch
                placeholder={t("cleaning.Search Groups") || ""}
                onKeyDown={(e: any) => handleEnterKey(e)}
                onChange={(e: any) => {
                  e.target.value.length === 0 && setSearch(e.target.value);
                  if (isMobile) {
                    setMobileSearch(e.target.value);
                  }
                }}
              />

              <Button
                className={
                  i18n.language === "en"
                    ? "mobile-search-btn"
                    : "mobile-search-btn-right"
                }
                onClick={() =>
                  handleEnterKey({
                    key: "Enter",
                    target: { value: mobileSearch },
                  })
                }
              >
                {t("chat.Search")}
              </Button>
            </div>
          </div>

          <div className="overviewCard my-4 p-2">
            <CleaningStats context={context} />
          </div>

          <div className=" pt-1 table-responsive" ref={pdfRef}>
            <Table responsive="sm table_class">
              <thead>
                <tr className="table_white_head">
                  <th
                    className={`${
                      isMobile ? "" : "d-flex justify-content-center py-5"
                    }`}
                  >
                    <div className="memberimageshape">
                      <img src={avatar} alt="logo" />
                    </div>
                  </th>
                  <th className={`${isMobileOnly ? " py-4" : " "}`}>
                    {t("cleaning.Group Number")}
                    <h6 className=" pt-2">{t("cleaning.Assignee")}</h6>
                  </th>
                  <th className={`${isMobileOnly ? " py-4 d-none" : " "}`}>
                    {t("cleaning.GroupName")}
                    <h6 className=" pt-2">
                      {t("cleaning.Duration (in days)")}
                    </h6>
                  </th>

                  <th className={`${isMobile ? "d-none " : " "}`}>
                    {t("cleaning.Schedule")}
                    <h6 className=" pt-2"> {t("cleaning.Billboard Type")}</h6>
                  </th>
                  <th className={`${isMobile ? "d-none " : " "}`}>
                    {t("cleaning.Type")}
                    <h6 className=" pt-2"> {t("cleaning.Priority")}</h6>
                  </th>
                  <th className={`${isMobile ? "d-none " : " "}`}>
                    {t("cleaning.Created By")}
                    <h6 className=" pt-2">{t("cleaning.Created On")}</h6>
                  </th>
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    {t("cleaning.Start Date")}
                    <h6 className=" pt-2">{t("cleaning.End Date")}</h6>
                  </th>
                  <th
                    className={`${
                      isMobileOnly ? "  d-none" : "py-2 text-center"
                    }`}
                  >
                    {t("cleaning.Action")}
                    <h6 className=" pt-2">&nbsp;</h6>
                  </th>
                </tr>
              </thead>
              {context?.cleanings && context?.cleanings?.length > 0 && (
                <tbody className="table_white">
                  {context?.cleanings?.map(
                    (cleaningItem: any, index: number) => {
                      return (
                        <tr key={cleaningItem.id} className="table_white">
                          <td
                            className={`${
                              isMobile
                                ? ""
                                : "  d-flex justify-content-center py-5"
                            }`}
                          >
                            <div className=" position-relative memberimageshape">
                              {cleaningItem?.assignee?.avatar !== null &&
                              cleaningItem?.assignee?.avatar !== undefined ? (
                                <>
                                  <img
                                    src={cleaningItem?.assignee?.avatar}
                                    alt="logo"
                                    width={50}
                                  />

                                  <div
                                    className={
                                      i18n.language === "en"
                                        ? `${
                                            isMobileOnly
                                              ? `${
                                                  cleaningItem.priority ===
                                                  "ACTIVE"
                                                    ? "img_circle_active "
                                                    : cleaningItem.priority ===
                                                      "PENDING"
                                                    ? "img_circle_pending"
                                                    : "img_circle_inactive"
                                                }`
                                              : ""
                                          }`
                                        : `${
                                            isMobileOnly
                                              ? `${
                                                  cleaningItem.priority ===
                                                  "ACTIVE"
                                                    ? "img_circle_activeArabic "
                                                    : cleaningItem.priority ===
                                                      "PENDING"
                                                    ? "img_circle_pendingArabic"
                                                    : "img_circle_inactiveArabic"
                                                }`
                                              : ""
                                          }`
                                    }
                                  ></div>
                                </>
                              ) : (
                                <>
                                  <CommonAvatar
                                    name={cleaningItem?.assignee?.name?.toUpperCase()}
                                    size={(isMobileOnly ? 50 : 50).toString()}
                                    style={{
                                      backgroundColor: "#6b5ca4",
                                    }}
                                  />
                                  <div
                                    className={
                                      i18n.language === "en"
                                        ? `${
                                            isMobileOnly
                                              ? `${
                                                  cleaningItem.priority ===
                                                  "ACTIVE"
                                                    ? "img_circle_active "
                                                    : cleaningItem.priority ===
                                                      "PENDING"
                                                    ? "img_circle_pending"
                                                    : "img_circle_inactive"
                                                }`
                                              : ""
                                          }`
                                        : `${
                                            isMobileOnly
                                              ? `${
                                                  cleaningItem.priority ===
                                                  "ACTIVE"
                                                    ? "img_circle_activeArabic "
                                                    : cleaningItem.priority ===
                                                      "PENDING"
                                                    ? "img_circle_pendingArabic"
                                                    : "img_circle_inactiveArabic"
                                                }`
                                              : ""
                                          }`
                                    }
                                  ></div>
                                </>
                              )}
                            </div>
                          </td>

                          <td
                            className={`${
                              isMobile
                                ? " py-4 d-flex justify-content-between align-items-center"
                                : " "
                            }`}
                          >
                            <div>
                              {cleaningItem.group_no || "-"}
                              <h6 className=" pt-2">
                                {cleaningItem?.assignee?.name || "-"}
                              </h6>
                            </div>

                            {userContext?.roleAccess["CLEANING"].includes(
                              "UPDATE"
                            ) && (
                              <td
                                className={`${
                                  isMobileOnly ? "   " : " d-none  "
                                }`}
                              >
                                <div className="text-decoration-none ">
                                  <div
                                    onClick={() => {
                                      context?.removeTicket(null);
                                      context?.removeCleaningTickets(null);
                                      navigate(
                                        routes.editCleaningUrl(
                                          cleaningItem?.id || cleaningItem?._id
                                        )
                                      );
                                    }}
                                  >
                                    <img
                                      src={
                                        (cleaningItem.status === "VIEWED" ||
                                          cleaningItem.status === "CREATED") &&
                                        moment(new Date())
                                          .add(1, "day")
                                          .format("DD/MM/yyyy") ===
                                          moment(cleaningItem.end_date).format(
                                            "DD/MM/yyyy"
                                          )
                                          ? recent
                                          : cleaningItem.status !== "CLOSED"
                                          ? edit
                                          : edityellow
                                      }
                                      alt=""
                                      width={22}
                                      className="pointer"
                                    />
                                  </div>
                                </div>
                              </td>
                            )}
                            {userContext?.roleAccess["CLEANING"].includes(
                              "DELETE"
                            ) && (
                              <td
                                className={`${
                                  isMobileOnly ? "   " : " d-none  "
                                }`}
                              >
                                <div className="text-decoration-none ">
                                  <div
                                    onClick={() =>
                                      openDeleteTicket(
                                        cleaningItem?.id || cleaningItem?._id,
                                        `${t(
                                          "successError.delete this cleaning group"
                                        )}`,
                                        `${t("successError.delete it")}`
                                      )
                                    }
                                  ></div>
                                </div>
                              </td>
                            )}
                          </td>
                          <td className={`${isMobile ? "d-none " : " "}`}>
                            <div>
                              {cleaningItem.group_name || "-"}
                              <h6 className=" pt-2">
                                {cleaningItem?.duration || "-"}
                              </h6>
                            </div>
                          </td>

                          <td className={`${isMobile ? "d-none " : " "}`}>
                            {cleaningItem?.schedule || "-"}
                            <h6 className=" pt-2">
                              {cleaningItem?.billboard_type || "-"}
                            </h6>
                          </td>
                          <td className={`${isMobile ? "d-none " : " "}`}>
                            <div
                              className={
                                i18n.language === "en"
                                  ? "dateandtime"
                                  : "dateandtimeArabic"
                              }
                            >
                              {cleaningItem.cleaning_type}
                            </div>

                            <h6 className=" pt-2">
                              <CommonStatusButton
                                pillText={cleaningItem.priority}
                                isPill
                              />
                            </h6>
                          </td>

                          <td className={`${isMobileOnly ? "d-none " : " "}`}>
                            {cleaningItem.created_by?.name || "-"}
                            <h6 className=" pt-2">
                              <Moment format="DD/MM/yyyy">
                                {cleaningItem.createdAt || "-"}
                              </Moment>
                            </h6>
                          </td>

                          <td className={`${isMobileOnly ? "d-none " : " "}`}>
                            <Moment format="DD/MM/yyyy">
                              {cleaningItem.start_date || "-"}
                            </Moment>

                            <h6 className=" pt-2">
                              <Moment format="DD/MM/yyyy">
                                {cleaningItem.end_date || "-"}
                              </Moment>
                            </h6>
                          </td>

                          <td
                            className={`${
                              isMobileOnly ? "  py-5  d-none" : " py-5  d-flex "
                            }`}
                          >
                            {userContext?.roleAccess["CLEANING"].includes(
                              "UPDATE"
                            ) && (
                              <div className="text-decoration-none d-flex justify-content-evenly align-items-center">
                                <div
                                  onClick={() => {
                                    context?.removeTicket(null);
                                    context?.removeCleaningTickets(null);
                                    navigate(
                                      routes.editCleaningUrl(
                                        cleaningItem?.id || cleaningItem?._id
                                      )
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      (cleaningItem.status === "VIEWED" ||
                                        cleaningItem.status === "CREATED") &&
                                      moment(new Date())
                                        .add(1, "day")
                                        .format("DD/MM/yyyy") ===
                                        moment(cleaningItem.end_date).format(
                                          "DD/MM/yyyy"
                                        )
                                        ? recent
                                        : cleaningItem.status !== "CLOSED"
                                        ? edit
                                        : edityellow
                                    }
                                    alt=""
                                    width={22}
                                    className="pointer"
                                  />
                                </div>
                              </div>
                            )}
                            {userContext?.roleAccess["CLEANING"].includes(
                              "DELETE"
                            ) &&
                              (cleaningItem.status === "VIEWED" ||
                                cleaningItem.status === "CREATED") && (
                                <div className="text-decoration-none d-flex justify-content-evenly align-items-center ms-2">
                                  <div
                                    onClick={() =>
                                      openDeleteTicket(
                                        cleaningItem?.id || cleaningItem?._id,
                                        `${t(
                                          "successError.delete this cleaning group"
                                        )}`,
                                        `${t("successError.delete it")}`
                                      )
                                    }
                                  ></div>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              )}
            </Table>
            {!context?.cleanings?.length && (
              <div className=" d-flex justify-content-center align-items-center">
                <div>{t("cleaning.No cleanings found")}</div>
              </div>
            )}
          </div>

          {context?.meta &&
            context?.cleanings &&
            context?.cleanings?.length > 0 && (
              <Pagination
                meta={context?.meta}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                currentPageCount={context?.cleanings?.length}
                component={"Cleaning"}
              />
            )}
        </div>
        <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
          <SuccessErrorToast
            bodyMessage={bodyMessage}
            isSuccess={isSuccess}
            footButton={false}
          />
        </ToastPopup>
        {/* ----------------------------------------------------------------------For mobile------------------- */}
        <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
          <div className="sortfilterMobile row m-0">
            <CommonSort
              sort={sort}
              opensort={opensort}
              opensortClick={sortByOpen}
              setCurrentPage={setCurrentPage}
              setSort={setSort}
              sortAtoZ="group_no"
              sortZtoA="-group_no"
              isSortMobile
            />

            <div className="col p-0 d-flex justify-content-evenly align-items-center ">
              <CommonFilter
                isFilterMobile
                openfilterClick={filterByOpen}
                isFilterActive={
                  department?.length > 0 ||
                  billBoardId?.length > 0 ||
                  priority?.length > 0 ||
                  schedule?.length > 0 ||
                  cleaningType?.length > 0 ||
                  assignee.length > 0
                }
                openfilter={openfilter}
                resetClick={resetFilter}
                filterClose={filterByOpen}
                filterApply={applyFilter}
                filterDisabled={
                  !(
                    status?.length > 0 ||
                    department?.length > 0 ||
                    billBoardId?.length > 0 ||
                    assignee.length > 0
                  )
                }
              >
                <Dropdownfilter
                  setAssigneId={setAssigneId}
                  assigneeList={assigneeList}
                  setAssigne={setAssigne}
                  assigneeId={assigneeId}
                  assignee={assignee}
                  context={context}
                  masterBillBoardList={masterBillBoardList}
                  setBillBoardType={setBillBoardType}
                  billBoardType={billBoardType}
                  setSchedule={setSchedule}
                  schedule={schedule}
                  setCleaningType={setCleaningType}
                  cleaningType={cleaningType}
                  setPriority={setPriority}
                  priority={priority}
                  setStartDate={setStartDate}
                  startDate={startDate}
                  setEndDate={setEndDate}
                  endDate={endDate}
                />
              </CommonFilter>
            </div>
          </div>
        </div>
        <ToastPopup
          trigger={context?.isLoading}
          setTrigger={context?.setIsLoading}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
        <ToastPopupSecond trigger={isDeletePopUp} setTrigger={setisDeletePopUp}>
          <DeleteBlockToast
            setTrigger={(status: boolean) => {
              loadData();
              setisDeletePopUp(status);
            }}
            closePopUp={(data: any) => setisDeletePopUp(data)}
            submit={() => deleteTicket()}
            headerMessage={headerMessage}
            textMessage={deleteText}
          />
        </ToastPopupSecond>
        <DownloadPopUp show={show} setShow={setShow} download={download} />
      </div>
    </AppLayout>
  );
};

export default ListCleaning;

const Dropdownfilter = ({
  setAssigneId,
  assigneeList,
  setAssigne,
  assigneeId,
  assignee,
  context,
  masterBillBoardList,
  setBillBoardType,
  billBoardType,
  setSchedule,
  schedule,
  setCleaningType,
  cleaningType,
  setPriority,
  priority,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
}: any) => {
  const { t, i18n } = useTranslation();

  interface billboardAutocompleteProps {
    readonly label: string;
    readonly value: string;
  }

  const getAssigneeOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { users } = await context?.autoCompleteApi({
      department: "CLEANING",
      name: inputValue,
      status: "ACTIVE",
      sort: "name",
      role: "TECHNICIAN",
    });
    callback(
      users.map((d: any) => ({
        label: d?.name,
        value: d?.id,
      }))
    );
  };

  const getMasterBillBoardOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    let data = await context?.loadMasterBillBoardsDetails({
      search: inputValue,
    });

    callback(
      data?.map((d: any) => ({
        label: d?.billboard_type,
        value: d?.billboard_type,
      }))
    );
  };

  return (
    <div>
      <div className=" pt-2">
        <h6> {t("cleaning.Schedule Type")}</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"AUTOMATIC"}
            text={t("cleaning.AUTOMATIC")}
            activeText={schedule}
            onClick={(e: string) => {
              setSchedule(schedule === "AUTOMATIC" ? "" : e);
            }}
          />

          <CommonStatusButton
            pillText={"MANUAL"}
            text={t("cleaning.MANUAL")}
            activeText={schedule}
            onClick={(e: string) => {
              setSchedule(schedule === "MANUAL" ? "" : e);
            }}
          />
        </div>
      </div>

      <div>
        <h6> {t("cleaning.Cleaning Type")}</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"DRY"}
            text={t("cleaning.DRY")}
            activeText={cleaningType}
            onClick={(e: string) => {
              setCleaningType(cleaningType === "DRY" ? "" : e);
            }}
          />
          <CommonStatusButton
            pillText={"WET"}
            text={t("cleaning.WET")}
            activeText={cleaningType}
            onClick={(e: string) => {
              setCleaningType(cleaningType === "WET" ? "" : e);
            }}
          />
        </div>
      </div>

      <div>
        <h6> {t("cleaning.Priority")}</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"HIGH"}
            text={t("cleaning.HIGH")}
            activeText={priority}
            onClick={(e: string) => {
              setPriority(priority === "HIGH" ? "" : e);
            }}
          />

          <CommonStatusButton
            pillText={"MEDIUM"}
            text={t("cleaning.MEDIUM")}
            activeText={priority}
            onClick={(e: string) => {
              setPriority(priority === "MEDIUM" ? "" : e);
            }}
          />

          <CommonStatusButton
            pillText={"LOW"}
            text={t("cleaning.LOW")}
            activeText={priority}
            onClick={(e: string) => {
              setPriority(priority === "LOW" ? "" : e);
            }}
          />
        </div>
      </div>
      <div className="py-2">
        <div className="fordesign">
          <h6> {t("cleaning.Start Date")}</h6>
          <DatePicker
            style={{ width: "100%" }}
            editable={false}
            placeholder="DD-MM-YYYY"
            name={"start_date"}
            value={moment(startDate).toDate()}
            className={""}
            onChange={(e: any) => {
              let test = moment.unix(e?.unix).format("yyyy-MM-DD");
              setStartDate(moment.unix(e?.unix).format("yyyy-MM-DD"));
            }}
          />
        </div>

        <div className="fordesign py-2">
          <h6> {t("cleaning.End Date")}</h6>

          <DatePicker
            style={{ width: "100%" }}
            editable={false}
            placeholder="DD-MM-YYYY"
            name={"end_date"}
            disabled={startDate === ""}
            value={moment(endDate).toDate()}
            minDate={startDate ? moment(startDate).toDate() : ""}
            className={""}
            id={i18n.language === "en" ? "" : "ArabicDate"}
            onChange={(e: any) => {
              let test = moment.unix(e?.unix).format("yyyy-MM-DD");
              setEndDate(moment.unix(e?.unix).format("yyyy-MM-DD"));
            }}
          />
        </div>
      </div>

      <div className="pb-2">
        <h6 className="">{t("ListTickets.Assignee")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            className={"select-wrapper-async"}
            isMulti={false}
            values={{ label: assignee, value: assigneeId }}
            options={assigneeList}
            cacheOptions
            loadOptionValue={getAssigneeOptions}
            updateField={(option: any) => {
              setAssigneId(option?.value);
              setAssigne(option?.label);
            }}
          />
        </div>
      </div>

      <div className="py-2">
        <h6 className=""> {t("cleaning.Billboard Type")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            className={"select-wrapper-async"}
            isMulti={false}
            values={{ label: billBoardType, value: billBoardType }}
            options={masterBillBoardList}
            cacheOptions
            loadOptionValue={getMasterBillBoardOptions}
            updateField={(option: any) => {
              setBillBoardType(option.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
