import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import avatar from "../../../assets/avatarprofile.png";
import filterby from "../../../assets/filter.png";
import reset from "../../../assets/reset.png";
import sorting from "../../../assets/sorting.png";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import CommonToggleSwitch from "../../../components/CommonToggleSwitch/CommonToggleSwitch";
import CommonLoadingBar from "../../../components/LoadingBar/CommonLoadingBar";
import { routes } from "../../../constants/routes";
import { AuthenticationContextProp } from "../../../context/AuthenticationProvider";
import {
  InventeryContextProp,
  useInventery,
} from "../../../context/InventeryProvider";
import Pagination from "../../../components/Pagination";
import CommonAvatar from "../../../components/Avatar/CommonAvatar";

export default function ListStockAdjustment({
  userContext,
}: {
  userContext: AuthenticationContextProp;
}) {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const context = useInventery();
  const [stockAdjustments, setStockAdjustments] = useState<any>([]);
  const [meta, setMeta] = useState<any>();

  const [creditType, setCreditType] = useState(
    queryParams.get("credit_type") ? queryParams.get("credit_type") : ""
  );
  const [sort, setSort] = useState(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );
  const [search, setSearch] = useState<any>(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [stockCode, setStockCode] = useState(
    queryParams.get("stock_code_name") ? queryParams.get("stock_code_name") : ""
  );
  const [stockCodeId, setStockCodeId] = useState(
    queryParams.get("stock_code") ? queryParams.get("stock_code") : ""
  );
  const [createdBy, setCreatedBy] = useState(
    queryParams.get("created_by") ? queryParams.get("created_by") : ""
  );
  const [createdByName, setCreatedByName] = useState(
    queryParams.get("created_by_name") ? queryParams.get("created_by_name") : ""
  );
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const pdfRef = useRef<any>(null);

  const loadData = async () => {
    navigate(routes.stockAdjustmentList(queryParamsDetails()));
    const res = await context?.getStockAdjustments(queryParamsDetails());
    if (res?.success) {
      setStockAdjustments(res.stock_adjustments);
      setMeta(res.meta);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sort, currentPage]);

  const queryParamsDetails = () => {
    let params = {
      search,
      sort: sort ? sort : "-updatedAt",
      credit_type: creditType,
      stock_code: stockCodeId,
      stock_code_name: stockCode,
      created_by: createdBy,
      createdByName: createdByName,
      limit: 10,
      page: currentPage,
    };
    return params;
  };

  const resetLoadStockAdjustmentAdds = async () => {
    let params = {
      search,
      sort,
      limit: 10,
      page: currentPage,
    };
    navigate(routes.stockAdjustmentList(params));
    const res = await context?.getStockAdjustments(params);
    if (res?.success) {
      setStockAdjustments(res.stock_adjustments);
      setMeta(res.meta);
    }
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const applyFilter = () => {
    loadData();
    filterByOpen();
  };

  const resetFilter = () => {
    loadData();
    filterByOpen();
    resetLoadStockAdjustmentAdds();
    setCreditType("");
    setStockCode("");
    setStockCodeId("");
    setCreatedBy("");
    setCreatedByName("");
  };

  const filterByOpen = () => {
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  return (
    <Fragment>
      <Fragment>
        {context?.isLoading && <CommonLoadingBar />}
        <div
          className={`${
            isMobile ? "container-fluid position-relative " : "container "
          }`}
        >
          {/* --------------------------------------------nested inventory------------------------------------------------- */}

          <div
            className={`${
              isMobile
                ? "d-none "
                : "pt-4 d-flex align-items-center justify-content-between "
            }`}
          >
            <div className="members ">
              <h5 className=" mb-0 ">
                {t("inventory.AllStockAdjustment")} (
                {meta ? meta?.total_count : 0})
              </h5>
            </div>
            <div
              className={`${
                isMobile ? "d-none " : "container-fluid p-0 forinput"
              }`}
            >
              <input
                id="doc_searchQueryInput"
                type="text"
                name="doc_searchQueryInput"
                placeholder={t("inventory.Search Stock Adjustment") || ""}
                onKeyDown={(e: any) => handleEnterKey(e)}
                onChange={(e: any) =>
                  e.target.value.length === 0 && setSearch(e.target.value)
                }
                maxLength={50}
              />
            </div>

            <div className="">
              <div className="d-flex  align-items-center justify-content-end">
                <div className=" sort-z">
                  <div className=" position-relative">
                    <div
                      className=" d-flex sortbox "
                      onClick={() => {
                        sortByOpen();
                      }}
                    >
                      <h6 className="m-0 by fw-normal">
                        {sort === "-name"
                          ? `${t("listmember.ZtoA")}`
                          : sort === "name"
                          ? `${t("listmember.AtoZ")}`
                          : sort === "-createdAt"
                          ? `${t("listmember.Recent")}`
                          : sort === "createdAt"
                          ? `${t("listmember.Datedecen")}`
                          : sort === "-updatedAt"
                          ? `${t("listmember.Recentupdate")}`
                          : `${t("listmember.Recent")}`}
                      </h6>
                      <div className="mx-2">
                        <img src={sorting} alt="" width={16} />
                      </div>
                    </div>
                    <div
                      className={`dropdownsort ${
                        opensort ? "active" : "inactive"
                      }`}
                    >
                      <DropdownSort setSort={setSort} sortByOpen={sortByOpen} />
                    </div>
                  </div>
                </div>

                <div className=" filter-z">
                  <div className=" position-relative">
                    <div
                      className=" d-flex filterbox   "
                      onClick={() => filterByOpen()}
                    >
                      <h6 className="m-0 by fw-normal">{t("filter.filter")}</h6>
                      <div className="mx-2">
                        <img src={filterby} alt="" width={16} />
                      </div>
                    </div>
                    <div
                      className={`dropdownfilter ${
                        openfilter ? "active" : "inactive"
                      }`}
                    >
                      <Dropdownfilter
                        filterByOpen={filterByOpen}
                        applyFilter={applyFilter}
                        resetFilter={resetFilter}
                        setCreditType={setCreditType}
                        setStockCode={setStockCode}
                        setCreatedBy={setCreatedBy}
                        setCreatedByName={setCreatedByName}
                        createdBy={createdBy}
                        createdByName={createdByName}
                        setStockCodeId={setStockCodeId}
                        stockCodeId={stockCodeId}
                        stockCode={stockCode}
                        context={context}
                        creditType={creditType}
                      />
                    </div>
                  </div>
                </div>
                {userContext?.roleAccess["INVENTORY"].includes("WRITE") && (
                  <Button
                    onClick={() => {
                      navigate(routes.stockAdjustmentAdd);
                    }}
                    className=" btn_invite_member "
                  >
                    {t("inventory.New StockAdjustment")}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
            <div
              className={`${
                isDesktop
                  ? "d-none "
                  : " d-flex align-items-center justify-content-between "
              }`}
            >
              <div className="members ">
                <h5 className="m-0">
                  {t("inventory.AllStockAdjustment")} (
                  {context?.meta ? context?.meta?.total_count : 0})
                </h5>
              </div>
              <div className="d-flex">
                <div className={`${isTablet ? " d-flex" : "d-none  "}`}>
                  <div className=" sort-z">
                    <div className=" position-relative">
                      <div
                        className=" d-flex sortbox ms-4"
                        onClick={() => {
                          sortByOpen();
                        }}
                      >
                        <h6 className="m-0 by fw-normal">
                          {sort === "-name"
                            ? `${t("listmember.ZtoA")}`
                            : sort === "name"
                            ? `${t("listmember.AtoZ")}`
                            : sort === "-createdAt"
                            ? `${t("listmember.Recent")}`
                            : sort === "createdAt"
                            ? `${t("listmember.Datedecen")}`
                            : sort === "-updatedAt"
                            ? `${t("listmember.Recentupdate")}`
                            : `${t("listmember.Recent")}`}
                        </h6>
                        <div className="mx-2">
                          <img src={sorting} alt="" width={16} />
                        </div>
                      </div>
                      <div
                        className={`dropdownsort ${
                          opensort ? "active" : "inactive"
                        }`}
                      >
                        <DropdownSort
                          setSort={setSort}
                          sortByOpen={sortByOpen}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" filter-z">
                    <div className=" position-relative">
                      <div
                        className=" d-flex filterbox mx-4"
                        onClick={() => filterByOpen()}
                      >
                        <h6 className="m-0 by fw-normal">
                          {t("filter.filter")}
                        </h6>

                        <div className="mx-2">
                          <img src={filterby} alt="" width={16} />
                        </div>
                      </div>
                      <div
                        className={`dropdownfilter ${
                          openfilter ? "active" : "inactive"
                        }`}
                      >
                        <Dropdownfilter
                          filterByOpen={filterByOpen}
                          applyFilter={applyFilter}
                          resetFilter={resetFilter}
                          setCreditType={setCreditType}
                          setStockCode={setStockCode}
                          setStockCodeId={setStockCodeId}
                          setCreatedBy={setCreatedBy}
                          setCreatedByName={setCreatedByName}
                          stockCodeId={stockCodeId}
                          createdBy={createdBy}
                          createdByName={createdByName}
                          stockCode={stockCode}
                          context={context}
                          creditType={creditType}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {userContext?.roleAccess["INVENTORY"].includes("WRITE") && (
                  <div className="d-flex align-items-center ">
                    <Button
                      className={`${
                        isDesktop ? " btn_invite_member" : " btn_in_member"
                      }`}
                      onClick={() => {
                        navigate(routes.stockAdjustmentAdd);
                      }}
                    >
                      {t("inventory.New StockAdjustment")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="pt-5 position-relative">
              <input
                id="doc_searchQueryInput"
                type="text"
                name="doc_searchQueryInput"
                placeholder={t("listmember.search") || ""}
                onKeyDown={(e: any) => handleEnterKey(e)}
                onChange={(e: any) => {
                  e.target.value.length === 0 && setSearch(e.target.value);
                  if (isMobile) {
                    setMobileSearch(e.target.value);
                  }
                }}
                maxLength={50}
              />
              <Button
                className={
                  i18n.language === "en"
                    ? "mobile-search-btn"
                    : "mobile-search-btn-right"
                }
                onClick={() =>
                  handleEnterKey({
                    key: "Enter",
                    target: { value: mobileSearch },
                  })
                }
              >
                {t("chat.Search")}
              </Button>
            </div>
          </div>

          <div className=" pt-4 table-responsive" ref={pdfRef}>
            <Table responsive="sm table_class">
              <thead>
                <tr className="table_white_head">
                  <th
                    className={`${
                      isMobile ? "" : "  d-flex justify-content-center py-5"
                    }`}
                  >
                    <div className="memberimageshape">
                      <img src={avatar} alt="logo" />
                    </div>
                  </th>
                  <th className={`${isMobileOnly ? " py-4" : " "}`}>
                    {t("memberTable.name")}
                    <h6 className=" pt-2"> {t("memberTable.userName")}</h6>
                  </th>
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    {t("inventory.Quantity")}
                  </th>
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    {t("inventory.Current Balance")}
                  </th>
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    {t("inventory.Stock Code")}
                  </th>
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    {t("inventory.credit_type")}
                  </th>
                </tr>
              </thead>
              {!context?.isLoading &&
                stockAdjustments &&
                stockAdjustments?.length > 0 && (
                  <tbody className="table_white">
                    {stockAdjustments.map(
                      (stockAdjustment: any, index: number) => {
                        return (
                          <tr key={stockAdjustment.id} className="table_white">
                            <td
                              className={`${
                                isMobile
                                  ? ""
                                  : "  d-flex justify-content-center py-5"
                              }`}
                            >
                              <div className=" position-relative memberimageshape">
                                {stockAdjustment?.created_by?.avatar !== null &&
                                stockAdjustment?.created_by?.avatar !==
                                  undefined ? (
                                  <img
                                    src={stockAdjustment?.created_by?.avatar}
                                    alt="logo"
                                    width={50}
                                  />
                                ) : (
                                  <CommonAvatar
                                    name={
                                      stockAdjustment?.created_by?.name
                                        ? stockAdjustment?.created_by?.name?.toUpperCase()
                                        : stockAdjustment?.created_by?.email
                                        ? stockAdjustment?.created_by?.email?.toUpperCase()
                                        : stockAdjustment?.created_by
                                            ?.contact_number
                                    }
                                    size={(isMobileOnly ? 50 : 50).toString()}
                                    style={{
                                      backgroundColor: "#6b5ca4",
                                    }}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                isMobile
                                  ? " py-4 d-flex justify-content-between align-items-center"
                                  : " "
                              }`}
                            >
                              <div>
                                {stockAdjustment?.created_by?.name || "-"}
                                <h6
                                  className={
                                    i18n.language === "en"
                                      ? "dateandtime pt-2"
                                      : "dateandtimeArabic pt-2"
                                  }
                                >
                                  {stockAdjustment?.created_by?.user_name ||
                                    "-"}
                                </h6>
                              </div>
                            </td>
                            <td
                              className={`${
                                isMobile
                                  ? " py-4 d-flex justify-content-between align-items-center"
                                  : " "
                              }`}
                            >
                              <div>{stockAdjustment?.quantity || "-"}</div>
                            </td>
                            <td
                              className={`${
                                isMobile
                                  ? " py-4 d-flex justify-content-between align-items-center"
                                  : " "
                              }`}
                            >
                              <div>
                                {stockAdjustment?.current_balance || "-"}
                              </div>
                            </td>
                            <td
                              className={`${
                                isMobile
                                  ? " py-4 d-flex justify-content-between align-items-center"
                                  : " "
                              }`}
                            >
                              <div>
                                {stockAdjustment?.stock_code?.stock_code || "-"}
                              </div>
                            </td>
                            <td
                              className={`${
                                isMobile
                                  ? " py-4 d-flex justify-content-between align-items-center"
                                  : " "
                              }`}
                            >
                              <div>{stockAdjustment?.credit_type || "-"}</div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                )}
            </Table>
            {!stockAdjustments?.length && (
              <div className=" d-flex justify-content-center align-items-center">
                {t("inventory.No Stock Adjustment Found")}
              </div>
            )}
          </div>
          {meta && stockAdjustments && stockAdjustments?.length > 0 && (
            <Pagination
              meta={meta}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              currentPageCount={stockAdjustments?.length}
              component={"StockAdjustment"}
            />
          )}
        </div>
      </Fragment>
    </Fragment>
  );
}

const DropdownSort = ({ setSort, sortByOpen }: any) => {
  const { t } = useTranslation();
  const updateSortFilter = (value: any) => {
    setSort(value);
    sortByOpen();
  };

  return (
    <div>
      <div className=" pt-2 d-flex justify-content-between align-items-center sortingline">
        <h6 className=" fw-bold m-0 "> {t("listmember.sortBy")} </h6>
      </div>
      <div className="pt-3 sortingh6">
        <h6 onClick={() => updateSortFilter("-createdAt")}>
          {t("listmember.Recent")}
        </h6>
        <h6 onClick={() => updateSortFilter("-updatedAt")}>
          {t("listmember.Recentupdate")}
        </h6>
        <h6 onClick={() => updateSortFilter("name")}>{t("listmember.AtoZ")}</h6>
        <h6 onClick={() => updateSortFilter("-name")}>
          {t("listmember.ZtoA")}
        </h6>
        <h6 onClick={() => updateSortFilter("createdAt")}>
          {t("listmember.Datedecen")}
        </h6>
      </div>
    </div>
  );
};

const Dropdownfilter = ({
  applyFilter,
  filterByOpen,
  resetFilter,
  setCreditType,
  setStockCode,
  setStockCodeId,
  setCreatedBy,
  setCreatedByName,
  stockCode,
  stockCodeId,
  context,
  creditType,
  createdBy,
  createdByName,
}: {
  applyFilter: any;
  filterByOpen: any;
  resetFilter: any;
  setCreditType: any;
  setStockCode: any;
  setStockCodeId: any;
  setCreatedBy: any;
  setCreatedByName: any;
  stockCodeId: any;
  stockCode: any;
  context: InventeryContextProp | null;
  creditType: any;
  createdBy: any;
  createdByName: any;
}) => {
  const { t } = useTranslation();
  const [sockList, setStockList] = useState<any>([]);
  const [memberList, setMemberList] = useState<any>([]);

  const getStocks = useCallback(async () => {
    if (context) {
      const { success, stocks } = await context.loadInventoryMaterials({
        status: "ACTIVE",
      });
      if (success) {
        setStockList(
          stocks.map((d: any) => ({
            label: d?.stock_code,
            value: d?.id,
          }))
        );
      } else {
        setStockList([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMember = useCallback(async () => {
    const { success, user } = await context?.loadMembers({
      status: "ACTIVE",
      sort: "name",
      role: "SUPERVISOR,MANAGER,ADMIN",
    });
    if (success) {
      let res = user?.map((d: any) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setMemberList(res);
    }
    return { success, user };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStocks();
    loadMember();
  }, [getStocks, loadMember]);

  const getSupplierOptions: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if (context) {
      const { stocks } = await context.loadInventoryMaterials({
        status: "ACTIVE",
        search: inputValue,
      });
      callback(
        stocks.map((d: any) => ({
          label: d?.stock_code,
          value: d?.id,
        }))
      );
    }
  };
  interface AutocompleteProps {
    readonly label: string;
    readonly value: string;
  }

  const getMemberOptions: any = async (
    inputValue: string,
    callback: (options: AutocompleteProps) => void
  ) => {
    const { user } = await context?.loadMembers({
      status: "ACTIVE",
      sort: "name",
      search: inputValue,
      role: "SUPERVISOR,MANAGER,ADMIN",
    });
    callback(
      user?.map((d: any) => ({
        label: d?.name,
        value: d?.id,
      }))
    );
  };

  return (
    <div className="">
      <div className=" d-flex justify-content-between align-items-center filterline pt-2">
        <h6 className=" fw-bold m-0"> {t("filter.fil")}</h6>
        <div>
          <img
            src={reset}
            alt=""
            width={16}
            onClick={() => {
              resetFilter();
            }}
            className="pointer"
          />
        </div>
      </div>

      <div>
        <div className="py-2">
          <h6 className="">{t("inventory.Stock Code")}</h6>
          <div className={`${isMobile ? " " : "container-fluid p-0"}`}>
            <div className="fordesign">
              <CommonAsyncSelect
                className={"select-wrapper-async"}
                isMulti={false}
                values={
                  // @ts-ignore
                  // @ts-expect-error
                  {
                    label: stockCode,
                    value: stockCodeId,
                  } || ""
                }
                options={sockList}
                cacheOptions
                loadOptionValue={getSupplierOptions}
                updateField={(option: any) => {
                  setStockCode(option.label);
                  setStockCodeId(option.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="py-2">
          <h6 className="">{t("inventory.credit_type")}</h6>
          <div className={`${isMobile ? " " : "container-fluid p-0"}`}>
            <CommonToggleSwitch
              textOff={"MINUS"}
              textOn={"PLUS"}
              checked={creditType === "PLUS"}
              onChange={(e) => {
                setCreditType(e?.target?.checked ? "PLUS" : "MINUS");
              }}
            />
          </div>
        </div>
      </div>
      <div className="py-2">
        <h6 className="">{t("inventory.Created By")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            className={"select-wrapper-async"}
            isMulti={false}
            values={{ label: createdByName, value: createdBy }}
            options={memberList}
            cacheOptions
            loadOptionValue={getMemberOptions}
            updateField={(option: any) => {
              setCreatedBy(option?.value);
              setCreatedByName(option?.label);
            }}
            isClearable={false}
          />
        </div>
      </div>
      <div className=" d-flex justify-content-start pt-3">
        <Button
          type="submit"
          className="  btn_cancel rounded-1 d-flex  justify-content-center   align-items-center"
          onClick={() => filterByOpen()}
        >
          {t("filter.close")}
        </Button>
        <Button
          type="submit"
          className=" mx-3 btn_update rounded-1 d-flex justify-content-center align-items-center"
          onClick={() => applyFilter()}
        >
          {t("filter.apply")}
        </Button>
      </div>
    </div>
  );
};
