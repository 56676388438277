import { useTranslation } from "react-i18next";
import { YOUR_GOOGLE_MAPS_API_KEY } from "../../interfaces/IUser";
import ReactGoogleAutocomplete from "react-google-autocomplete";

export const CustomLocationSelect = ({
  isFieldDisabled,
  updateField = () => {},
  defaultValue,
  className,
  setValue,
}: any) => {
  if (defaultValue === null) {
    defaultValue = "";
  }
  const { t } = useTranslation();

  return (
    <>
      <div className={className}>
        {defaultValue?.length ? (
          <ReactGoogleAutocomplete
            apiKey={YOUR_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(e: google.maps.places.PlaceResult) => {
              if (e?.geometry) {
                updateField(e);
              }
            }}
            disabled={isFieldDisabled}
            onChange={(e: any) => {
              if (setValue) {
                setValue(e);
              } else {
                updateField(e?.target.value);
              }
            }}
            value={defaultValue}
            itemType={"establishment"}
            className="select-wrapper"
            placeholder={t("BillboardMap.search")}
            options={{
              types: [],
              fields: [
                "place_id",
                "address_components",
                "formatted_address",
                "geometry",
                "name",
              ],
            }}
          />
        ) : (
          <ReactGoogleAutocomplete
            apiKey={YOUR_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(e: google.maps.places.PlaceResult) => {
              updateField(e);
            }}
            disabled={isFieldDisabled}
            onChange={(e: any) => {
              if (setValue) {
                setValue(e);
              } else {
                updateField(e?.target.value);
              }
            }}
            value={defaultValue}
            itemType={"establishment"}
            className="select-wrapper"
            placeholder={t("BillboardMap.search")}
            options={{
              types: [],
              fields: [
                "place_id",
                "address_components",
                "formatted_address",
                "geometry",
                "name",
              ],
            }}
          />
        )}
      </div>
    </>
  );
};

export default CustomLocationSelect;
