import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";

const AudioUpload = ({
  setTrigger,
  userContext,
  setButtonPopup,
  isChat = false,
  context = () => {}, // Make sure this is initialized
  uploadAudio = () => {}, // Make sure this is initialized
}: any) => {
  const { t } = useTranslation();

  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [audioFile, setAudioFile] = useState<FormData | null>(null);

  const getAudioUpload = async () => {
    if (!audioFile || !userContext?.uploadFiles) {
      console.error("Audio file or uploadFiles method is missing.");
      return;
    }
    const { success, url } = await userContext.uploadFiles(audioFile, "audio");
    if (success) {
      if (isChat && context?.sendValue) {
        context.sendValue({ url }, "AUDIO");
      } else {
        uploadAudio(url);
      }
      setButtonPopup(false);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond = String(secondCounter).padStart(2, "0");
        let computedMinute = String(minuteCounter).padStart(2, "0");

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((prevCounter) => prevCounter + 1);

        if (minuteCounter >= 3) {
          stopRecording();
          setIsActive(false);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }

  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    blobPropertyBag: { type: "audio/mp3" },
  });

  useEffect(() => {
    if (mediaBlobUrl) {
      handleSave(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  const handleSave = async (mediaBlobUrl: string) => {
    try {
      const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
      const audiofile = new File([audioBlob], "audiofile.mp3", {
        type: "audio/mp3",
      });
      const formData = new FormData();
      formData.append("file", audiofile);
      setAudioFile(formData);
    } catch (error) {
      console.error("Error fetching or saving the audio file:", error);
    }
  };

  return (
    <div>
      <div
        className="d-flex justify-content-end"
        onClick={() => setTrigger(false)}
      >
        <RxCross2 size={24} color="var(--secondary)" />
      </div>

      {mediaBlobUrl && status === "stopped" && (
        <div className="d-flex justify-content-center py-3">
          <audio src={mediaBlobUrl} controls loop></audio>
        </div>
      )}

      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex align-items-center timeload">
          <h1 className="minute fs-1 m-0">{minute}</h1>
          <h1 className="fs-1 m-0">:</h1>
          <h1 className="second fs-1 m-0">{second}</h1>
        </div>
        <div>
          <label htmlFor="icon-button-file">
            <h5 className="m-0 py-3">
              {status === "idle"
                ? t("Audio.press")
                : status === "recording"
                ? t("Audio.recording")
                : status === "paused"
                ? t("Audio.pauseText")
                : status === "stopped"
                ? t("Audio.press")
                : ""}
            </h5>
            <div className="d-flex justify-content-evenly">
              {status !== "idle" && status !== "stopped" && (
                <h6
                  className="btn stopbtn fw-semibold"
                  onClick={() => {
                    stopRecording();
                    setIsActive(false);
                    stopTimer();
                  }}
                >
                  {`${t("Audio.stop")}`}
                </h6>
              )}
              <h6
                className="btn pausebtn fw-semibold mx-3"
                onClick={() => {
                  if (!isActive) {
                    startRecording();
                  } else {
                    pauseRecording();
                  }
                  setIsActive(!isActive);
                }}
              >
                {(status === "idle" || status === "stopped") &&
                  `${t("Audio.record")}`}
                {isActive && status === "recording" && `${t("Audio.pause")}`}
                {!isActive && status === "paused" && `${t("Audio.record")}`}
              </h6>
            </div>
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end d-inline-block pt-5">
        <Button
          disabled={!mediaBlobUrl}
          onClick={() => getAudioUpload()}
          className="btn_update rounded-1 d-flex justify-content-center align-items-center"
        >
          <span className="fw-semibold">{t("Audio.uploadbtn")}</span>
        </Button>
      </div>
    </div>
  );
};

export default AudioUpload;
function useReactMediaRecorder(arg0: {
  video: boolean;
  audio: boolean;
  blobPropertyBag: { type: string };
}): {
  status: any;
  startRecording: any;
  stopRecording: any;
  pauseRecording: any;
  mediaBlobUrl: any;
} {
  throw new Error("Function not implemented.");
}
