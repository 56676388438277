import React, { ReactElement, useCallback, useEffect, useState } from "react";
import UserService from "../../../utils/services/user.service";

interface UserProviderProps {
  children: ReactElement;
}

export interface UserContextProp {
  isLoggedIn: boolean;
  user: any;
  loadMembers: (params: any) => Promise<any>;
  memberLimit: number;
  setMemberLimit: (limit: number) => void;
  members: any[];
  meta: any;
  setPage: (page: number) => void;
  page: number;
  isLoading: boolean;
}

const UserContext = React.createContext<UserContextProp | null>(null);

const UserProvider = ({ children }: UserProviderProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [members, setMembers] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [memberLimit, setMemberLimit] = useState<number>(20);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = useCallback(async () => {
    if (sessionStorage.getItem("auth")) {
      try {
        const { success, user } = await UserService.getUser();

        if (success && user) {
          setUser(user);
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const loadMembers = useCallback(async (params: any) => {
    setIsLoading(true);
    try {
      const { success, user, meta } = await UserService.getMembers(params);
      if (success) {
        setMembers(user);
        setMeta(meta);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error loading members:", error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const value: UserContextProp = {
    isLoggedIn,
    user,
    loadMembers,
    memberLimit,
    setMemberLimit,
    members,
    meta,
    setPage,
    page,
    isLoading,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUserContext = () => React.useContext(UserContext);

export { UserProvider, useUserContext };
