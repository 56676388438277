import { useRoomContext, useTracks } from "@livekit/components-react";
import { Track } from "livekit-client";
import { useEffect, useRef, useState } from "react";
import { BiVideo, BiVideoOff } from "react-icons/bi";
import { FiMic, FiMicOff } from "react-icons/fi";
import { MdCallEnd } from "react-icons/md";
import { useAuthentication } from "../../../../../context/AuthenticationProvider";
import { useChatContext } from "../../../context/ChatProvider";
import CallConnection from "./CallConnection";
import "./CallInterface.css";
import ParticpantView from "./particpantView";
import RoomAudio from "./RoomAudio";
import { RemoteParticipant, RoomEvent } from "livekit-client";
import { useRemoteParticipants } from "@livekit/components-react";

const CallInterface = () => {
  const room = useRoomContext();
  const chatContext = useChatContext();
  const [isClicked, setIsClicked] = useState(false);
  const userContext = useAuthentication();

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false }
  );

  const callInterfaceRef = useRef<HTMLDivElement | null>(null);

  const toggleFullScreen = () => {
    if (callInterfaceRef.current) {
      if (!document.fullscreenElement) {
        callInterfaceRef.current.requestFullscreen().catch((err) => {
          alert(`Error attempting to enable full-screen mode: ${err.message}`);
        });
      } else {
        document.exitFullscreen();
      }
    }
  };
  const remoteParticipants: RemoteParticipant[] = useRemoteParticipants({
    updateOnlyOn: [
      RoomEvent.ParticipantConnected,
      RoomEvent.ParticipantDisconnected,
      RoomEvent.Disconnected,
      RoomEvent.Reconnected,
      RoomEvent.ParticipantMetadataChanged,
      RoomEvent.ParticipantNameChanged,
    ],
  });
  useEffect(() => {
    const opponentId = chatContext?.activeChat?.participants.find(
      (item: any) => item._id != userContext?.user?.id
    )._id;
    const checkRemoteParticaipant = (attempt: number) => {
      const remoteParticipant = remoteParticipants.find(
        (item) => item.identity === opponentId
      );
      if (!userContext?.callAccepted && !remoteParticipant && attempt > 0) {
        setTimeout(() => {
          checkRemoteParticaipant(attempt - 1);
        }, 2000);
      } else if (
        !userContext?.callAccepted &&
        !remoteParticipant &&
        attempt === 0
      ) {
        chatContext.handleDisconnect(
          "DISCONNECT",
          opponentId,
          "MISCALL",
          chatContext?.activeChat?.id
        );
      }
    };
    const timer = setTimeout(() => {
      if (opponentId) {
        checkRemoteParticaipant(2);
      }
    }, 30000);
    return () => clearTimeout(timer);
  }, [remoteParticipants, userContext?.callAccepted]);

  useEffect(() => {
    if (!room) return;
    const handleParticipantDisconnected = (participant: any) => {
      handleLeaveRoom();
    };
    room.on("participantDisconnected", handleParticipantDisconnected);
    return () => {
      room.off("participantDisconnected", handleParticipantDisconnected);
    };
  }, [room]);

  const handleMuteAudio = () => {
    room.localParticipant.isMicrophoneEnabled
      ? room.localParticipant.setMicrophoneEnabled(false)
      : room.localParticipant.setMicrophoneEnabled(true);
  };

  const handleMuteVideo = () => {
    room.localParticipant.isCameraEnabled
      ? room.localParticipant.setCameraEnabled(false)
      : room.localParticipant.setCameraEnabled(true);
  };

  const handleLeaveRoom = async () => {
    let dI = "";
    room.remoteParticipants.forEach((item) => (dI = item.identity));
    if (!dI) {
      dI = chatContext?.activeChat?.participants.find(
        (item: any) => item._id !== userContext?.user.id
      )._id;
    }
    chatContext?.dailTone.pause();
    const data = await chatContext?.handleDisconnect(
      "DISCONNECT",
      dI,
      "CALLEND",
      room.name
    );
    setIsClicked(false);
    if (chatContext?.audioCallshow) {
      chatContext?.setAudioCallshow(false);
    }
    if (chatContext?.videoCallshow) {
      chatContext?.setVideoCallshow(false);
    }
    if (data?.success) {
      room.disconnect();
    }
  };
  return (
    <div
      className="call-interface d-flex flex-column overflow-hidden"
      ref={callInterfaceRef}
    >
      <div className="carousel-container border d-flex align-items-center flex-wrap gap-3 overflow-y-auto px-2 py-3">
        {tracks.map((trackRef) => (
          <div className={tracks.length > 0 ? "participant-2" : ""}>
            <ParticpantView trackRef={trackRef} />
          </div>
        ))}
      </div>
      <div className=" d-flex gap-3 justify-content-center align-items-center my-4">
        <div
          className="control-btn d-flex justify-content-center align-items-center"
          onClick={handleMuteAudio}
        >
          {room.localParticipant.isMicrophoneEnabled ? (
            <FiMic title="Mute Audio" />
          ) : (
            <FiMicOff title="Unmute Audio" />
          )}
        </div>
        <div
          className={`  d-flex justify-content-center align-items-center ${
            isClicked ? "leave-call-disabled" : "leave-call"
          }`}
          onClick={handleLeaveRoom}
        >
          <MdCallEnd
            title="Decline the call"
            fontSize={24}
            color="var(--color-white)"
          />
        </div>
        <div
          className="control-btn d-flex justify-content-center align-items-center"
          onClick={handleMuteVideo}
        >
          {room.localParticipant.isCameraEnabled ? (
            <BiVideo title="Off Video" />
          ) : (
            <BiVideoOff title="On Video" />
          )}
        </div>
      </div>
      <div>
        {chatContext?.isDisconnecting && (
          <CallConnection
            ConnectingText="Call disconnecting..."
            callDisconnecting
          />
        )}
        {chatContext?.callInitializing && (
          <CallConnection ConnectingText="Call Initializing..." callStarting />
        )}
      </div>
      {/* )} */}
      <RoomAudio />
    </div>
  );
};

export default CallInterface;
