import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useState } from "react";

const mapContainerStyle = {
  width: "80vw",
  height: "80vh",
  top: "-250px",
  left: "-400px",
};

const center = {
  lat: 40.7128,
  lng: -74.006,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

export default function GoogleMaps({
  setShowMaps,
}: {
  setShowMaps: (value: boolean) => void;
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBbBT3hpywpFnxQeQsViTbud9tN7A1eCqQ",
  });

  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  } | null>({
    lat: 12.9564672,
    lng: 77.6208384,
  });

  const handleSendLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          alert(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        () => {
          alert("Unable to retrieve your location");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser");
    }
    setShowMaps(false);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <>
      <div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={userLocation || center}
          options={options}
        >
          {userLocation && <Marker position={userLocation} />}
          <Marker position={{ lat: 12.971599, lng: 77.594566 }} />
        </GoogleMap>

        <button
          onClick={handleSendLocation}
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 1000,
            padding: "10px",
            backgroundColor: "blue",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Send Current Location
        </button>
      </div>
    </>
  );
}
