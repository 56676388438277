import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import LoginLayout from "../../layouts/LoginLayout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { routes } from "../../constants/routes";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import Select from "react-select";
import { api } from "../../utils/api";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import PhoneNumber from "../../components/PhoneNumber";

let timedPopupSetTimeOut: any = undefined;

const InvitationPage = () => {
  const { t, i18n } = useTranslation();
  let [searchParams] = useSearchParams();
  const [bodyMessage, setBodyMessage] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [PasswordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [userDetails, setUser] = useState<any>();
  const token = searchParams.get("token");
  const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userDetails?.email,
      department: userDetails?.department?.map((obj: any) => ({
        value: obj,
        label: obj,
      })),
      role: userDetails?.role,
      contactNumber: userDetails?.contact_number,
      name: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid E-mail address")
        .required("E-mail address is required"),
      role: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Role is required"),
      contactNumber: Yup.string()
        .typeError("That doesn't look like a phone number")
        .min(8, "Contact Number must be atleast 8 characters")
        .matches(phoneRegExp, "That doesn't look like a Contact Number")
        .required("Contact Number is required"),
      name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Name is required"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password is too short- should be 8 characters  minimum")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@_`{}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@_`{}~]{8,20}$/,
          "Must contain 8-20 characters, one uppercase, one lowercase, one number, and one special character"
        ),
      confirmPassword: Yup.string()
        .required("Confirmation Password is required")
        .oneOf([Yup.ref("password")], "Password doesn't match"),
    }),
    onSubmit: async (values: any) => {
      setIsSuccess(true);
      setIsLoading(true);
      values = {
        ...values,
        contact_number: values.contactNumber,
        password_confirmation: values.confirmPassword,
      };
      delete values.confirmPassword;
      delete values.contactNumber;
      const res = await api?.verifyTokenpost(token, {
        ...values,
        department:
          values.role !== "SUPERVISOR"
            ? values?.department?.map((value: any) => value.value)
            : [values?.department[0].value],
      });
      const { data } = res || {};
      const { success, user, error } = data || {};
      setIsLoading(false);
      setIsSuccess(success);
      if (success) {
        setButtonPopup(true);
        setBodyMessage(
          `${t("successError.Invitation data is successfully filled")}`
        );
        timeOutPopUp(true);
      } else {
        setButtonPopup(true);
        setBodyMessage(error);
        timeOutPopUp(false);
      }
    },
  });

  useEffect(() => {
    const loadData = async () => {
      setIsSuccess(true);
      const res = await api?.verifyToken(token);
      setUser(res?.user);
      setIsSuccess(false);
    };
    loadData();
  }, [token]);

  const navigate = useNavigate();
  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.login);
        setBodyMessage("");
      } else {
        setButtonPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const { isSubmitting, errors, isValid, values, dirty, handleSubmit } = formik;
  return (
    <>
      <LoginLayout
        title={t("invitation.title")}
        subTitle={t("invitation.subtitle")}
      >
        <FormikProvider value={formik}>
          <Form
            validated={!!errors}
            onSubmit={handleSubmit}
            className="invitation"
          >
            <div className="floating  ">
              <FormGroup className="mt-4">
                <label>{t("invitation.label")}</label>
                <Field
                  name="email"
                  type="email"
                  autoFocus
                  className={`${
                    formik?.values?.email === userDetails?.user_name &&
                    "disabled-field"
                  } form-control`}
                  disabled={formik?.values?.email === userDetails?.user_name}
                  maxLength={50}
                />

                <Form.Control.Feedback
                  type={!!errors.email ? "invalid" : "valid"}
                >
                  <ErrorMessage name="email" />
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup className="mt-4">
                <label>{t("invitation.department")}*</label>
                <Select
                  value={userDetails?.department?.map((obj: any) => ({
                    value: obj,
                    label: obj,
                  }))}
                  name="department"
                  className="basic-multi-select disabled-field"
                  isDisabled
                  isMulti={formik.values.role !== "SUPERVISOR"}
                  classNamePrefix="select"
                />
                <Form.Control.Feedback
                  style={{ display: !!errors.department ? "block" : "none" }}
                  type={!!errors.department ? "invalid" : "valid"}
                >
                  <ErrorMessage name="department" />
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup className="mt-4">
                <label>{t("invitation.role")}</label>
                <Field
                  name="role"
                  type="role"
                  className="disabled-field form-control"
                  disabled
                  maxLength={50}
                />
                <Form.Control.Feedback
                  style={{ display: !!errors.role ? "block" : "none" }}
                  type={!!errors.role ? "invalid" : "valid"}
                >
                  <ErrorMessage name="role" />
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup className="mt-4">
                <label>
                  {t("invitation.contactnumber")} <span className="">*</span>
                </label>
                <Field
                  placeholder=""
                  value={formik?.values?.contactNumber}
                  name="contactNumber"
                  onChange={(e: any) =>
                    formik.setFieldValue("contactNumber", e)
                  }
                  className={`${
                    formik?.values?.contactNumber === userDetails?.user_name &&
                    "disabled-field"
                  } `}
                  disabled={
                    formik?.values?.contactNumber === userDetails?.user_name
                  }
                  error={
                    formik?.values?.contactNumber
                      ? isValidPhoneNumber(formik?.values?.contactNumber)
                        ? undefined
                        : "Invalid phone number"
                      : "Phone number required"
                  }
                  component={PhoneNumber}
                />
                <span style={{ color: "#db2c59" }}>
                  {formik?.values?.contactNumber
                    ? isValidPhoneNumber(formik?.values?.contactNumber)
                      ? undefined
                      : "Invalid phone number"
                    : ""}
                </span>
              </FormGroup>
              <FormGroup className="mt-4">
                <label>{t("invitation.name")}</label>{" "}
                <span className="">*</span>
                <Field
                  name="name"
                  type="name"
                  className="form-control"
                  maxLength={50}
                  autocomplete="off"
                />
                <Form.Control.Feedback
                  style={{ display: !!errors.name ? "block" : "none" }}
                  type={!!errors.name ? "invalid" : "valid"}
                >
                  <ErrorMessage name="name" />
                </Form.Control.Feedback>
              </FormGroup>

              <FormGroup className="mt-4 position-relative">
                <label>{t("invitation.password")}</label>{" "}
                <span className="">*</span>
                <Field
                  name="password"
                  type={PasswordEye ? "text" : "password"}
                  className={`form-control ${
                    errors.password ? "error-field" : ""
                  }`}
                  maxLength={50}
                />
                {values.password.length > 0 && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setPasswordEye(true)}
                  >
                    <AiOutlineEye
                      className="mb-4"
                      color={errors.password ? "#db2c59" : "#2a2b2f80"}
                    />
                  </div>
                )}
                {values.password.length > 0 && PasswordEye && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setPasswordEye(false)}
                  >
                    <AiOutlineEyeInvisible
                      className="mb-4"
                      color={errors.password ? "#db2c59" : "#2a2b2f80"}
                    />
                  </div>
                )}
                <Form.Control.Feedback
                  style={{ display: !!errors.password ? "block" : "none" }}
                  type={!!errors.password ? "invalid" : "valid"}
                >
                  <ErrorMessage name="password" />
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup className="mt-4 position-relative">
                <label>{t("invitation.confirmpassword")}</label>{" "}
                <span className="">*</span>
                <Field
                  name="confirmPassword"
                  type={confirmPasswordEye ? "text" : "password"}
                  className={`form-control ${
                    errors.confirmPassword ? "error-field" : ""
                  }`}
                  maxLength={50}
                />
                {values.confirmPassword.length > 0 && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setConfirmPasswordEye(true)}
                  >
                    <AiOutlineEye
                      className="mb-4"
                      color={errors.confirmPassword ? "#db2c59" : "#2a2b2f80"}
                    />
                  </div>
                )}
                {values.confirmPassword.length > 0 && confirmPasswordEye && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setConfirmPasswordEye(false)}
                  >
                    <AiOutlineEyeInvisible
                      className="mb-4"
                      color={errors.confirmPassword ? "#db2c59" : "#2a2b2f80"}
                    />
                  </div>
                )}
                <Form.Control.Feedback
                  style={{
                    display: !!errors.confirmPassword ? "block" : "none",
                  }}
                  type={!!errors.confirmPassword ? "invalid" : "valid"}
                >
                  <ErrorMessage name="confirmPassword" />
                </Form.Control.Feedback>
              </FormGroup>
            </div>

            <div>
              <Button
                type="submit"
                variant="primary"
                className="my-5 px-5 py-2 rounded-1 d-flex justify-content-center align-items-center"
                disabled={isSubmitting || !isValid || !dirty}
              >
                <span
                  className={`me-2 ${
                    isSubmitting || !isValid || !dirty ? "" : "fw-semibold"
                  }`}
                >
                  {t("invitation.completeinvitation")}
                </span>
                <span className={i18n.language === "en" ? "ms-2" : "d-none"}>
                  <FaLongArrowAltRight />
                </span>
                <span className={i18n.language === "en" ? "d-none" : "me-2"}>
                  <FaLongArrowAltLeft />
                </span>
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </LoginLayout>
      <ToastPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
          // buttonName='Login Now!'
          footButton={false}
        />
      </ToastPopup>
      <ToastPopup trigger={isLoading} setTrigger={setIsLoading}>
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
    </>
  );
};

export default InvitationPage;
