import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useMembers } from "../../context/MembersProvider";
import AppLayout from "../../layouts/AppLayout";
import { useTranslation } from "react-i18next";
import avatar from "../../assets/avatarprofile.png";
import edityellow from "../../assets/edity.png";
import edit from "../../assets/edit.png";
import NewMemberModal from "./NewMemberModal";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import Modal from "../../components/PopupToast/Modal";
import Pagination from "../../components/Pagination";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import CustomLocationSelect from "../../components/CommonComponents/CustomLocationSelect";
import { convertExcel, convertPdf, updateText } from "../../utils/api";
import DownloadPopUp from "../../components/DownloadPopUp";
import { BsThreeDots } from "react-icons/bs";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import CommonButton from "../../components/CommonButton/CommonButton";
import CommonSearch from "../../components/CommonSearch/CommonSearch";
import CommonFilter from "../../components/CommonFilter/CommonFilter";
import { CommonStatusButton } from "../../components/CommonStatusButton/CommonStatusButton";
import CommonSort from "../../components/CommonSort/CommonSort";
import CommonLoadingBar from "../../components/LoadingBar/CommonLoadingBar";
import CommonAvatar from "../../components/Avatar/CommonAvatar";

let timedPopupSetTimeOut: any = undefined;
const ListMembers = ({
  userContext,
  roleOptions,
  departmentOptions,
  vehicleOptions,
}: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const context = useMembers();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [buttonPopup, setButtonPopup] = useState(false);
  const [timedPopup, setTimedPopup] = useState(false);
  const [isBlockOrUnBlockPopUp, setIsBlockOrUnBlockPopUp] = useState(false);
  const [sort, setSort] = useState<any>(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );
  const [role, setRole] = useState<any>(
    queryParams.get("role") ? queryParams.get("role") : ""
  );
  const [search, setSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [status, setStatus] = useState<any>(
    queryParams.get("status") ? queryParams.get("status") : ""
  );
  const [department, setDepartment] = useState<any>(
    queryParams.get("department") ? queryParams.get("department") : ""
  );
  const [responsibleArea, setResponsibleArea] = useState<any>(
    queryParams.get("responsible_area")
      ? queryParams.get("responsible_area")
      : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [currentMemberId, setCurrentMemberId] = useState("");
  const [blockOrUnBlockText, setBlockOrUnBlockText] = useState<any>("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState<any>(false);
  const [resendInvitationPopUp, setResendInvitationPopUp] = useState(false);
  const [pendingInvitationPopUp, setPendingInvitationPopUp] = useState(false);
  const [memberDetail, setMemberDetails] = useState<any>({});
  const [headerMessage, setHeaderMessage] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<any>(
    queryParams.get("page") ? queryParams.get("page") : 1
  );
  const [show, setShow] = useState(false);
  const pdfRef = useRef<any>(null);
  const [departMentList, setDepartMentList] = useState<any>([]);

  const getDepartmentDetail = useCallback(async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "DEPARTMENT",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setDepartMentList(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDepartmentDetail();
  }, [getDepartmentDetail]);

  const queryParamsDetails = () => {
    let params = {
      search,
      sort,
      role,
      status,
      department: department?.replace(" ", "_"),
      responsible_area: responsibleArea,
      limit: 10,
      page: currentPage,
    };
    return params;
  };

  const loadData = useCallback(async () => {
    navigate(routes.listMembersUrl(queryParamsDetails()));
    await context?.loadMembers(queryParamsDetails());
    context?.getUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentPage, sort]);

  useEffect(() => {
    loadData();
  }, [search, currentPage, sort, loadData]);

  const download = async (type: string) => {
    switch (type) {
      case "excel":
        const res = await context?.downloadExcel(queryParamsDetails());
        convertExcel(res, "Member_List");
        break;
      case "pdf":
        const pdfRes = await context?.downloadPdf(queryParamsDetails());
        convertPdf(pdfRes, "Member_List");
        break;
      default:
        break;
    }
  };

  const resetLoadMember = async () => {
    let params = {
      search,
      sort,
      limit: 10,
      page: currentPage,
    };
    navigate(routes.listMembersUrl(params));
    await context?.loadMembers(params);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  const handleUnBlock = async () => {
    const { success, error } = await context?.unBlockMemberAccount(
      currentMemberId
    );
    setIsSuccess(success);
    if (success) {
      setIsBlockOrUnBlockPopUp(false);
      loadData();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(`${t("successError.unblock this member successfully")}`);
    } else {
      setIsBlockOrUnBlockPopUp(false);
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const openBlockPopUp = (id: any, value: any, cancelText: any) => {
    setCurrentMemberId(id);
    setIsBlockOrUnBlockPopUp(true);
    setBlockOrUnBlockText(cancelText);
    setHeaderMessage(value);
  };

  const handleBlock = async () => {
    const { success, error } = await context?.blockMemberAccount(
      currentMemberId
    );
    setIsSuccess(success);
    if (success) {
      setIsBlockOrUnBlockPopUp(false);
      loadData();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(`${t("successError.block this member successfully")}`);
    } else {
      setIsBlockOrUnBlockPopUp(false);
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const applyFilter = () => {
    setCurrentPage(1);
    loadData();
    setOpenfilter(false);
  };

  const resetFilter = () => {
    setCurrentPage(1);
    setRole("");
    setStatus("");
    setDepartment("");
    setResponsibleArea("");
    resetLoadMember();
    filterByOpen();
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const filterByOpen = () => {
    setSort(queryParams.get("sort") ? queryParams.get("sort") : "-createdAt");
    setRole(queryParams.get("role") ? queryParams.get("role") : "");
    setSearch(queryParams.get("search") ? queryParams.get("search") : "");
    setStatus(queryParams.get("status") ? queryParams.get("status") : "");
    setDepartment(
      queryParams.get("department") ? queryParams.get("department") : ""
    );
    setResponsibleArea(
      queryParams.get("responsible_area")
        ? queryParams.get("responsible_area")
        : ""
    );
    setCurrentPage(queryParams.get("page") ? queryParams.get("page") : 1);
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const resendInvitation = async (id: any) => {
    setCurrentMemberId(id);
    if (id) {
      const { success, user } = await context?.getMemberDetail(id);
      if (success) {
        setMemberDetails(user);
        setResendInvitationPopUp(true);
        setPendingInvitationPopUp(false);
      }
    }
  };

  const editPendingInvitation = async (id: any) => {
    setCurrentMemberId(id);
    if (id) {
      const { success, user } = await context?.getMemberDetail(id);
      if (success) {
        setMemberDetails(user);
        setPendingInvitationPopUp(true);
        setResendInvitationPopUp(false);
      }
    }
  };

  const deleteMember = async () => {
    const { success, error } = await context?.deleteMemberAccount(
      currentMemberId
    );
    setIsSuccess(success);
    if (success) {
      setIsBlockOrUnBlockPopUp(false);
      loadData();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(`${t("successError.sucessdelete")}`);
    } else {
      setIsBlockOrUnBlockPopUp(false);
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        filterByOpen();
      }
    };
    window.addEventListener("keyup", close);
    return () => window.removeEventListener("keyup", close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        sortByOpen();
      }
    };
    window.addEventListener("sortDropDown", close);
    return () => window.removeEventListener("sortDropDown", close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppLayout>
      <>
        <div
          className={` list_members ${isMobile ? "position-relative" : "p-3"}`}
        >
          <div
            className={`${
              isMobile ? "container-fluid position-relative " : "container "
            }`}
          >
            <div
              className={`${
                isMobile
                  ? "d-none "
                  : "pt-4 d-flex align-items-center justify-content-between "
              }`}
            >
              {context?.isLoading && <CommonLoadingBar />}
              <div className="members ">
                <h5 className=" mb-0 ">
                  {t("listmember.all")} (
                  {context?.meta ? context?.meta?.total_count : 0})
                </h5>
              </div>
              <div
                className={`${
                  isMobile ? "d-none " : "container-fluid p-0 forinput"
                }`}
              >
                <CommonSearch
                  placeholder={t("listmember.search") || ""}
                  onKeyDown={(e: any) => handleEnterKey(e)}
                  onChange={(e: any) =>
                    e.target.value.length === 0 && setSearch(e.target.value)
                  }
                />
              </div>

              <div className="">
                <div className="d-flex  align-items-center justify-content-end">
                  <CommonSort
                    sort={sort}
                    opensort={opensort}
                    opensortClick={sortByOpen}
                    setCurrentPage={setCurrentPage}
                    setSort={setSort}
                    sortAtoZ="name"
                    sortZtoA="-name"
                  />

                  <CommonFilter
                    openfilterClick={filterByOpen}
                    isFilterActive={
                      role?.length > 0 ||
                      status?.length > 0 ||
                      department?.length > 0 ||
                      responsibleArea?.length > 0
                    }
                    openfilter={openfilter}
                    resetClick={resetFilter}
                    filterClose={filterByOpen}
                    filterApply={applyFilter}
                    filterDisabled={
                      !(
                        role?.length > 0 ||
                        status?.length > 0 ||
                        department?.length > 0 ||
                        responsibleArea?.length > 0
                      )
                    }
                  >
                    <Dropdownfilter
                      setRole={setRole}
                      role={role}
                      status={status}
                      setStatus={setStatus}
                      setDepartment={setDepartment}
                      department={department}
                      setCurrentPage={setCurrentPage}
                      setResponsibleArea={setResponsibleArea}
                      responsibleArea={responsibleArea}
                      departmentOptions={departmentOptions}
                      userContext={userContext}
                      departMentList={departMentList}
                    />
                  </CommonFilter>

                  <div>
                    <CommonButton
                      text={t("listmember.Download")}
                      variant="outline-secondary"
                      className=" bg-transparent text-secondary btn-i18n"
                      onClick={() => setShow(true)}
                    />
                  </div>
                  {userContext?.roleAccess["USER"].includes("WRITE") && (
                    <CommonButton
                      onClick={() => setButtonPopup(true)}
                      text={t("listmember.inviteMember")}
                      variant="success"
                      className=" btn-i18n text-white"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
              <div
                className={`${
                  isDesktop
                    ? "d-none "
                    : " d-flex align-items-center justify-content-between flex-wrap"
                }`}
              >
                <div className="members ">
                  <h5 className="mb-3">
                    {t("listmember.all")} (
                    {context?.meta ? context?.meta?.total_count : 0})
                  </h5>
                </div>
                <div className="d-flex">
                  {/* ------------------------------------------For Tablet responsive------------------------------------- */}

                  <div className={`${isTablet ? " d-flex" : "d-none"}`}>
                    <CommonSort
                      sort={sort}
                      opensort={opensort}
                      opensortClick={sortByOpen}
                      setCurrentPage={setCurrentPage}
                      setSort={setSort}
                      sortAtoZ="name"
                      sortZtoA="-name"
                    />

                    <CommonFilter
                      openfilterClick={filterByOpen}
                      isFilterActive={
                        role?.length > 0 ||
                        status?.length > 0 ||
                        department?.length > 0 ||
                        responsibleArea?.length > 0
                      }
                      openfilter={openfilter}
                      resetClick={resetFilter}
                      filterClose={filterByOpen}
                      filterApply={applyFilter}
                      filterDisabled={
                        !(
                          role?.length > 0 ||
                          status?.length > 0 ||
                          department?.length > 0 ||
                          responsibleArea?.length > 0
                        )
                      }
                    >
                      <Dropdownfilter
                        setRole={setRole}
                        role={role}
                        status={status}
                        setStatus={setStatus}
                        setCurrentPage={setCurrentPage}
                        setDepartment={setDepartment}
                        department={department}
                        setResponsibleArea={setResponsibleArea}
                        responsibleArea={responsibleArea}
                        departmentOptions={departmentOptions}
                        userContext={userContext}
                        departMentList={departMentList}
                      />
                    </CommonFilter>
                  </div>
                  <div>
                    <CommonButton
                      text={t("listmember.Download")}
                      variant="outline-secondary"
                      className=" bg-transparent text-secondary btn-i18n "
                      onClick={() => setShow(true)}
                    />
                  </div>
                  {userContext?.roleAccess["USER"].includes("WRITE") && (
                    <CommonButton
                      onClick={() => setButtonPopup(true)}
                      text={t("listmember.inviteMember")}
                      variant="success"
                      className=" btn-i18n text-white"
                    />
                  )}
                </div>
              </div>
              <div className="pt-5 position-relative">
                <CommonSearch
                  placeholder={t("listmember.search") || ""}
                  onKeyDown={(e: any) => handleEnterKey(e)}
                  onChange={(e: any) => {
                    e.target.value.length === 0 && setSearch(e.target.value);
                    if (isMobile) {
                      setMobileSearch(e.target.value);
                    }
                  }}
                />

                <Button
                  className={
                    i18n.language === "en"
                      ? "mobile-search-btn"
                      : "mobile-search-btn-right"
                  }
                  onClick={() =>
                    handleEnterKey({
                      key: "Enter",
                      target: { value: mobileSearch },
                    })
                  }
                >
                  {t("chat.Search")}
                </Button>
              </div>
            </div>

            <div
              className=" pt-4 table-responsive"
              id="download-content"
              ref={pdfRef}
            >
              <Table responsive="sm table_class">
                <thead>
                  <tr className="table_white_head">
                    <th
                      className={`${
                        isMobile ? "" : "  d-flex justify-content-center py-5"
                      }`}
                    >
                      <div className="memberimageshape">
                        <img src={avatar} alt="logo" width={50} />
                      </div>
                    </th>
                    <th className={`${isMobileOnly ? " py-4" : " "}`}>
                      {t("memberTable.name")}
                      <h6 className=" pt-2"> {t("memberTable.userName")}</h6>
                    </th>
                    <th className={`${isMobile ? "d-none " : " "}`}>
                      {t("memberTable.department")}
                      <h6 className=" pt-2">{t("memberTable.role")}</h6>
                    </th>
                    <th className={`${isMobile ? "d-none " : " "}`}>
                      {t("memberTable.response")}
                      <h6 className=" pt-2">
                        {t("memberTable.branchLocation")}
                      </h6>
                    </th>

                    <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                      {t("memberTable.MemberStatus")}
                      <h6 className=" pt-2">{t("memberTable.ticketcount")}</h6>
                    </th>
                    <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                      {t("memberTable.action")}
                      <h6 className=" ">&nbsp;</h6>
                    </th>
                  </tr>
                </thead>

                {!context?.isLoading &&
                  context?.members &&
                  context?.members?.length > 0 && (
                    <tbody className="table_white">
                      {context?.members.map((member: any, index: number) => {
                        return (
                          <tr key={member.id} className="table_white">
                            <td
                              className={`${
                                isMobile
                                  ? ""
                                  : "  d-flex justify-content-center py-5"
                              }`}
                            >
                              <div className=" position-relative memberimageshape">
                                {member?.avatar !== null &&
                                member?.avatar !== undefined ? (
                                  <>
                                    <img
                                      src={member?.avatar}
                                      alt="logo"
                                      width={50}
                                    />

                                    <div
                                      className={
                                        i18n.language === "en"
                                          ? `${
                                              isMobileOnly
                                                ? `${
                                                    member.status === "ACTIVE"
                                                      ? "img_circle_active "
                                                      : member.status ===
                                                        "PENDING"
                                                      ? "img_circle_pending"
                                                      : "img_circle_inactive"
                                                  }`
                                                : ""
                                            }`
                                          : `${
                                              isMobileOnly
                                                ? `${
                                                    member.status === "ACTIVE"
                                                      ? "img_circle_activeArabic "
                                                      : member.status ===
                                                        "PENDING"
                                                      ? "img_circle_pendingArabic"
                                                      : "img_circle_inactiveArabic"
                                                  }`
                                                : ""
                                            }`
                                      }
                                    ></div>
                                  </>
                                ) : (
                                  <>
                                    <CommonAvatar
                                      name={
                                        member?.name
                                          ? member?.name?.toUpperCase()
                                          : member?.email
                                          ? member?.email?.toUpperCase()
                                          : member?.contact_number
                                      }
                                      size={(isMobileOnly ? 50 : 50).toString()}
                                      style={{
                                        backgroundColor: "#6b5ca4",
                                      }}
                                    />
                                    <div
                                      className={
                                        i18n.language === "en"
                                          ? `${
                                              isMobileOnly
                                                ? `${
                                                    member.status === "ACTIVE"
                                                      ? "img_circle_active "
                                                      : member.status ===
                                                        "PENDING"
                                                      ? "img_circle_pending"
                                                      : "img_circle_inactive"
                                                  }`
                                                : ""
                                            }`
                                          : `${
                                              isMobileOnly
                                                ? `${
                                                    member.status === "ACTIVE"
                                                      ? "img_circle_activeArabic "
                                                      : member.status ===
                                                        "PENDING"
                                                      ? "img_circle_pendingArabic"
                                                      : "img_circle_inactiveArabic"
                                                  }`
                                                : ""
                                            }`
                                      }
                                    ></div>
                                  </>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                isMobile
                                  ? " py-4 d-flex justify-content-between align-items-center flex-wrap"
                                  : " "
                              }`}
                            >
                              <div
                                style={
                                  isMobileOnly ? { minWidth: "155px" } : {}
                                }
                              >
                                {member.name || "-"}
                                <h6
                                  className={
                                    i18n.language === "en"
                                      ? "dateandtime pt-2"
                                      : "dateandtimeArabic pt-2"
                                  }
                                >
                                  {member.user_name || "-"}
                                </h6>
                              </div>
                              <td className={`${isMobileOnly ? "" : "d-none"}`}>
                                {!userContext?.roleAccess["USER"].includes(
                                  "UPDATE"
                                ) ? (
                                  <div className=" d-flex ">
                                    <div
                                      onClick={() =>
                                        navigate(
                                          member.status === "ACTIVE"
                                            ? routes.getPersonalProfileUrl(
                                                member.id,
                                                queryParamsDetails()
                                              )
                                            : ""
                                        )
                                      }
                                      className="  text-decoration-none d-flex justify-content-evenly align-items-center"
                                    >
                                      {member.status === "ACTIVE" &&
                                        (userContext?.roleAccess[
                                          "USER"
                                        ].includes("UPDATE") ? (
                                          <img src={edit} alt="" width={22} />
                                        ) : (
                                          <CommonButton
                                            text={t("listmember.View Details")}
                                            type="submit"
                                            variant="outline-secondary"
                                            className="  bg-transparent text-secondary"
                                          />
                                        ))}
                                    </div>
                                  </div>
                                ) : member.status === "EXPIRED" ? (
                                  <Dropdown className="Actions_title">
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basicActions"
                                    >
                                      <BsThreeDots fontSize={24} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="create-dropdown-css">
                                      {userContext?.roleAccess["USER"].includes(
                                        "WRITE"
                                      ) && (
                                        <Dropdown.Item
                                          className="sort-by"
                                          onClick={() =>
                                            resendInvitation(member.id)
                                          }
                                        >
                                          {t("editdropdown.resendInvitation")}
                                        </Dropdown.Item>
                                      )}
                                      {userContext?.roleAccess["USER"].includes(
                                        "DELETE"
                                      ) && (
                                        <Dropdown.Item
                                          className="sort-by"
                                          onClick={() =>
                                            openBlockPopUp(
                                              member?.id,
                                              `${t(
                                                "successError.delete this member"
                                              )}`,
                                              `${t("successError.delete it")}`
                                            )
                                          }
                                        >
                                          {t("editdropdown.delete")}
                                        </Dropdown.Item>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : member.status === "PENDING" ? (
                                  userContext?.roleAccess["USER"].includes(
                                    "UPDATE"
                                  ) ? (
                                    <img
                                      src={edityellow}
                                      alt=""
                                      width={22}
                                      onClick={() =>
                                        editPendingInvitation(member.id)
                                      }
                                    />
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  <div className="pt-2 Actions">
                                    <Dropdown className="Actions_title">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basicActions"
                                      >
                                        <BsThreeDots fontSize={24} />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="create-dropdown-css">
                                        {member.status === "ACTIVE" && (
                                          <Dropdown.Item
                                            className="sort-by"
                                            onClick={() =>
                                              navigate(
                                                member.status === "ACTIVE"
                                                  ? routes.getPersonalProfileUrl(
                                                      member.id,
                                                      queryParamsDetails()
                                                    )
                                                  : ""
                                              )
                                            }
                                          >
                                            {userContext?.roleAccess[
                                              "USER"
                                            ].includes("UPDATE")
                                              ? t("editdropdown.edit")
                                              : "View Details"}
                                          </Dropdown.Item>
                                        )}

                                        {(member.status === "BLOCKED" ||
                                          member?.status === "ACTIVE") &&
                                          context?.user?.email !==
                                            member?.email &&
                                          userContext?.roleAccess[
                                            "USER"
                                          ].includes("BLOCK/UNBLOCK") && (
                                            <Dropdown.Item
                                              className="sort-by"
                                              onClick={() =>
                                                openBlockPopUp(
                                                  member?.id,
                                                  `${
                                                    member.status === "BLOCKED"
                                                      ? t(
                                                          "successError.unblock this member"
                                                        )
                                                      : t(
                                                          "successError.block this member"
                                                        )
                                                  }`,
                                                  `${
                                                    member.status === "BLOCKED"
                                                      ? t(
                                                          "successError.unblock it"
                                                        )
                                                      : t(
                                                          "successError.block it"
                                                        )
                                                  }`
                                                )
                                              }
                                            >
                                              {member.status === "BLOCKED"
                                                ? t("editdropdown.unblock")
                                                : t("editdropdown.block")}
                                            </Dropdown.Item>
                                          )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )}
                              </td>
                            </td>
                            <td className={`${isMobile ? "d-none " : " "}`}>
                              {member.department.length > 1
                                ? member.department[0]?.replace("_", " ") + " ,"
                                : member.department[0]?.replace("_", " ") ||
                                  "-"}
                              <h6 className=" pt-2">{member.role || "-"} </h6>
                            </td>
                            <td className={`${isMobile ? "d-none " : " "}`}>
                              {member.responsible_area?.length > 25
                                ? member.responsible_area?.slice(0, 25) + "..."
                                : member.responsible_area || "-"}
                              <h6 className=" pt-2">
                                {member.branch_location?.length > 25
                                  ? member.branch_location?.slice(0, 25) + "..."
                                  : member.branch_location || "-"}
                              </h6>
                            </td>
                            <td className={`${isMobileOnly ? "d-none " : " "}`}>
                              <CommonStatusButton
                                pillText={member.status}
                                isPill
                              />
                              <h6 className=" pt-2">
                                {member?.ticket_stats?.pending || 0}
                                &nbsp;{t("header.ticket")}
                              </h6>
                            </td>
                            <td
                              className={`${isMobileOnly ? "d-none" : "py-5"}`}
                            >
                              {!userContext?.roleAccess["USER"].includes(
                                "UPDATE"
                              ) ? (
                                <div className=" d-flex ">
                                  <div
                                    onClick={() =>
                                      navigate(
                                        member.status === "ACTIVE"
                                          ? routes.getPersonalProfileUrl(
                                              member.id,
                                              queryParamsDetails()
                                            )
                                          : ""
                                      )
                                    }
                                    className="  text-decoration-none d-flex justify-content-evenly align-items-center"
                                  >
                                    {member.status === "ACTIVE" &&
                                      (userContext?.roleAccess["USER"].includes(
                                        "UPDATE"
                                      ) ? (
                                        <img src={edit} alt="" width={22} />
                                      ) : (
                                        <CommonButton
                                          text={t("listmember.View Details")}
                                          type="submit"
                                          variant="outline-secondary"
                                          className="  bg-transparent text-secondary"
                                        />
                                      ))}
                                  </div>
                                </div>
                              ) : member.status === "EXPIRED" ? (
                                <Dropdown className="Actions_title">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basicActions"
                                  >
                                    <BsThreeDots fontSize={24} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="create-dropdown-css">
                                    {userContext?.roleAccess["USER"].includes(
                                      "WRITE"
                                    ) && (
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() =>
                                          resendInvitation(member.id)
                                        }
                                      >
                                        {t("editdropdown.resendInvitation")}
                                      </Dropdown.Item>
                                    )}
                                    {userContext?.roleAccess["USER"].includes(
                                      "DELETE"
                                    ) && (
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() =>
                                          openBlockPopUp(
                                            member?.id,
                                            `${t(
                                              "successError.delete this member"
                                            )}`,
                                            `${t("successError.delete it")}`
                                          )
                                        }
                                      >
                                        {t("editdropdown.delete")}
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : member.status === "PENDING" ? (
                                userContext?.roleAccess["USER"].includes(
                                  "UPDATE"
                                ) ? (
                                  <img
                                    src={edityellow}
                                    alt=""
                                    width={22}
                                    onClick={() =>
                                      editPendingInvitation(member.id)
                                    }
                                  />
                                ) : (
                                  "-"
                                )
                              ) : (
                                <div className="pt-2 Actions">
                                  <Dropdown className="Actions_title">
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basicActions"
                                    >
                                      <BsThreeDots fontSize={24} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="create-dropdown-css">
                                      {member.status === "ACTIVE" && (
                                        <Dropdown.Item
                                          className="sort-by"
                                          onClick={() =>
                                            navigate(
                                              member.status === "ACTIVE"
                                                ? routes.getPersonalProfileUrl(
                                                    member.id,
                                                    queryParamsDetails()
                                                  )
                                                : ""
                                            )
                                          }
                                        >
                                          {userContext?.roleAccess[
                                            "USER"
                                          ].includes("UPDATE")
                                            ? t("editdropdown.edit")
                                            : "View Details"}
                                        </Dropdown.Item>
                                      )}

                                      {(member.status === "BLOCKED" ||
                                        member?.status === "ACTIVE") &&
                                        context?.user?.email !==
                                          member?.email &&
                                        userContext?.roleAccess[
                                          "USER"
                                        ].includes("BLOCK/UNBLOCK") && (
                                          <Dropdown.Item
                                            className="sort-by"
                                            onClick={() =>
                                              openBlockPopUp(
                                                member?.id,
                                                `${
                                                  member.status === "BLOCKED"
                                                    ? t(
                                                        "successError.unblock this member"
                                                      )
                                                    : t(
                                                        "successError.block this member"
                                                      )
                                                }`,
                                                `${
                                                  member.status === "BLOCKED"
                                                    ? t(
                                                        "successError.unblock it"
                                                      )
                                                    : t("successError.block it")
                                                }`
                                              )
                                            }
                                          >
                                            {member.status === "BLOCKED"
                                              ? t("editdropdown.unblock")
                                              : t("editdropdown.block")}
                                          </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
              </Table>
              {!context?.members?.length && (
                <div className=" d-flex justify-content-center align-items-center">
                  <div>{t("listmember.No Member Found")}</div>
                </div>
              )}
            </div>
            {context?.meta &&
              context?.members &&
              context?.members?.length > 0 && (
                <Pagination
                  meta={context?.meta}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  currentPageCount={context?.members?.length}
                  component={"member"}
                />
              )}
          </div>
          <Modal
            trigger={
              buttonPopup
                ? buttonPopup
                : resendInvitationPopUp
                ? resendInvitationPopUp
                : pendingInvitationPopUp
            }
            setTrigger={
              pendingInvitationPopUp
                ? setPendingInvitationPopUp
                : resendInvitationPopUp
                ? setResendInvitationPopUp
                : setButtonPopup
            }
          >
            <NewMemberModal
              setTrigger={(status: boolean) => {
                loadData();
                pendingInvitationPopUp
                  ? setPendingInvitationPopUp(status)
                  : resendInvitationPopUp
                  ? setResendInvitationPopUp(status)
                  : setButtonPopup(status);
              }}
              trigger={
                buttonPopup
                  ? buttonPopup
                  : resendInvitationPopUp
                  ? resendInvitationPopUp
                  : pendingInvitationPopUp
              }
              setTimedPopup={setTimedPopup}
              setIsSuccess={setIsSuccess}
              setBodyMessage={setBodyMessage}
              timeOutPopUp={timeOutPopUp}
              resendInvitation={resendInvitationPopUp}
              pendingInvitation={pendingInvitationPopUp}
              currentMemberId={currentMemberId}
              memberDetail={memberDetail}
              roleOptions={roleOptions}
              userContext={userContext}
            />
          </Modal>
          <ToastPopupSecond
            trigger={isBlockOrUnBlockPopUp}
            setTrigger={setIsBlockOrUnBlockPopUp}
          >
            <DeleteBlockToast
              setTrigger={(status: boolean) => {
                loadData();
                setIsBlockOrUnBlockPopUp(status);
              }}
              closePopUp={(data: any) => setIsBlockOrUnBlockPopUp(data)}
              submit={() =>
                headerMessage === `${t("successError.block this member")}`
                  ? handleBlock()
                  : headerMessage === `${t("successError.unblock this member")}`
                  ? handleUnBlock()
                  : deleteMember()
              }
              headerMessage={headerMessage}
              textMessage={blockOrUnBlockText}
            />
          </ToastPopupSecond>
          <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
            <SuccessErrorToast
              bodyMessage={bodyMessage}
              isSuccess={isSuccess}
              footButton={false}
            />
          </ToastPopup>

          {/* ------------------------------------------For Mobile responsive------------------------------------- */}
          <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
            <div className="sortfilterMobile row m-0">
              <CommonSort
                sort={sort}
                opensort={opensort}
                opensortClick={sortByOpen}
                setCurrentPage={setCurrentPage}
                setSort={setSort}
                sortAtoZ="name"
                sortZtoA="-name"
                isSortMobile
              />
              <div className="col p-0 d-flex justify-content-evenly align-items-center ">
                <CommonFilter
                  openfilterClick={filterByOpen}
                  openfilter={openfilter}
                  resetClick={resetFilter}
                  filterClose={filterByOpen}
                  filterApply={applyFilter}
                  filterDisabled={
                    !(
                      role?.length > 0 ||
                      status?.length > 0 ||
                      department?.length > 0 ||
                      responsibleArea?.length > 0
                    )
                  }
                  isFilterMobile
                >
                  <Dropdownfilter
                    setRole={setRole}
                    role={role}
                    status={status}
                    setStatus={setStatus}
                    setCurrentPage={setCurrentPage}
                    setDepartment={setDepartment}
                    department={department}
                    setResponsibleArea={setResponsibleArea}
                    responsibleArea={responsibleArea}
                    departmentOptions={departmentOptions}
                    userContext={userContext}
                    departMentList={departMentList}
                  />
                </CommonFilter>
              </div>
            </div>
          </div>
        </div>
        <ToastPopup
          trigger={context?.isLoading}
          setTrigger={context?.setIsLoading}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
        <DownloadPopUp show={show} setShow={setShow} download={download} />
      </>
    </AppLayout>
  );
};

export default ListMembers;

const Dropdownfilter = ({
  setRole,
  role,
  status,
  setStatus,
  setDepartment,
  department,
  setResponsibleArea,
  responsibleArea,
  userContext,
  departMentList,
}: any) => {
  const { t } = useTranslation();

  interface billboardAutocompleteProps {
    readonly label: string;
    readonly value: string;
  }

  const getDepartmentOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };
  return (
    <div className="">
      <div className="pt-3 pb-2">
        <h6>{t("filter.role")}</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"ADMIN"}
            text={t("filter.superad")}
            activeText={role}
            onClick={(e: string) => {
              setRole(role === "ADMIN" ? "" : e);
            }}
          />
          <CommonStatusButton
            pillText={"MANAGER"}
            text={t("filter.staff")}
            activeText={role}
            onClick={(e: string) => {
              setRole(role === "MANAGER" ? "" : e);
            }}
          />
          <CommonStatusButton
            pillText={"SUPERVISOR"}
            text={t("filter.Super")}
            activeText={role}
            onClick={(e: string) => {
              setRole(role === "SUPERVISOR" ? "" : e);
            }}
          />
          <CommonStatusButton
            pillText={"TECHNICIAN"}
            text={t("filter.tec")}
            activeText={role}
            onClick={(e: string) => {
              setRole(role === "TECHNICIAN" ? "" : e);
            }}
          />
        </div>
      </div>
      <div className="py-2" style={{ width: "100%" }}>
        <h6> {t("filter.department")}</h6>
        <div className="fordesign">
          <CommonAsyncSelect
            className={"select-wrapper-async"}
            isMulti={false}
            values={{
              label: updateText(department?.replace("_", " ")),
              value: department,
            }}
            options={departMentList}
            cacheOptions
            loadOptionValue={getDepartmentOptions}
            updateField={(option: any) => {
              setDepartment(option?.value);
            }}
            isClearable={false}
          />
        </div>
      </div>
      <div className="py-2">
        <h6 className="">{t("filter.response")}</h6>
        <div className={`${isMobile ? " " : "container-fluid p-0"}`}>
          <CustomLocationSelect
            fiedName={"responsibleArea"}
            className={" autolocation-border"}
            isFieldDisabled={false}
            updateField={(data: any) => {
              if (data?.name) {
                setResponsibleArea(data?.name);
              } else {
                setResponsibleArea(data);
              }
            }}
            defaultValue={responsibleArea}
          />
        </div>
      </div>
      <div className="py-2 ">
        <h6>{t("filter.status")}</h6>
        <div className=" d-flex justify-content-between align-items-center flex-wrap">
          <CommonStatusButton
            pillText={"ACTIVE"}
            text={t("filter.active")}
            activeText={status}
            onClick={(e: string) => {
              setStatus(status === "ACTIVE" ? "" : e);
            }}
          />
          <CommonStatusButton
            pillText={"BLOCKED"}
            text={t("filter.blocked")}
            activeText={status}
            onClick={(e: string) => {
              setStatus(status === "BLOCKED" ? "" : e);
            }}
          />
          <CommonStatusButton
            pillText={"EXPIRED"}
            text={t("filter.Expired")}
            activeText={status}
            onClick={(e: string) => {
              setStatus(status === "EXPIRED" ? "" : e);
            }}
          />
          <CommonStatusButton
            pillText={"PENDING"}
            text={t("filter.pending")}
            activeText={status}
            onClick={(e: string) => {
              setStatus(status === "PENDING" ? "" : e);
            }}
          />
        </div>
      </div>
    </div>
  );
};
