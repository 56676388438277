import { useEffect, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Form, FormGroup } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import ReactCustomSelect from "../../../components/CommonComponents/ReactCustomSelect";
import { updateText } from "../../../utils/api";
import { useParams } from "react-router-dom";
import CommonButton from "../../../components/CommonButton/CommonButton";

export const actionsReasons = [
  { value: "SCRAP", label: "SCRAP" },
  { value: "REPAIR", label: "REPAIR" },
  { value: "RETURN_TO_STOCK", label: "RETURN_TO_STOCK" },
];

const StockList = ({
  type,
  context,
  setStocks,
  stocks,
  initialStockValue,
  supplier_info = "",
  isSupplierInfo = false,
  userContext,
  billboardContext,
}: any) => {
  const { t, i18n } = useTranslation();
  const pathName = window?.location?.pathname;
  const urlParams = useParams();

  interface StockCodeAutocompleteProps {
    readonly label: string;
    readonly value: string;
  }
  const [stockcodeList, setStockcodeList] = useState<any>([]);
  const [UnitList, setUnitList] = useState<any>([]);

  useEffect(() => {
    if ((isSupplierInfo && supplier_info?.length > 0) || !isSupplierInfo) {
      getStockCodeDetails();
    }
  }, [supplier_info, isSupplierInfo]);

  const getStockCodeDetails = async () => {
    let params: any = {};
    if (supplier_info?.length > 0) {
      params["supplier_id"] = supplier_info;
    }
    const { success, stock } = await context?.stockAutoComplete(params);
    if (success) {
      let res = stock.map((d: any) => {
        return {
          label: d.stock_code,
          value: d.id,
          supplier_part_no: d?.supplier_part_no,
          part_description: d?.part_description,
          name: d?.supplier_id?.name,
          contact_number: d?.supplier_id?.contact_number,
          material_name: d?.material_name,
        };
      });
      setStockcodeList(res);
    }
  };

  // ----------------------stock code-----------------

  const getStockCodeOptions: any = async (
    inputValue: string,
    callback: (options: StockCodeAutocompleteProps) => void
  ) => {
    let params: any = {};
    if (supplier_info?.length > 0) {
      params["supplier_id"] = supplier_info;
    }
    const { stock } = await context?.stockAutoComplete({
      ...params,
      search: inputValue,
    });
    callback(
      stock.map((d: any) => ({
        label: d?.stock_code,
        value: d?.id,
        supplier_part_no: d?.supplier_part_no,
        part_description: d?.part_description,
        name: d?.supplier_id?.name,
        contact_number: d?.supplier_id?.contact_number,
        material_name: d?.material_name,
      }))
    );
  };

  useEffect(() => {
    if (type === "BOM") {
      getUnitDetail();
    }
  }, [type]);

  const getUnitDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "BOM_UNIT",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setUnitList(res);
    }
  };

  const getUnitOptions: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
      config_type: "BOM_UNIT",
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  const buttonDisabled = (index: number) => {
    let array = Object.values(stocks[index]);
    if (type === "BOM") {
      array.splice(-2);
    } else if (type === "materialToTechnician") {
      if (
        stocks[index]?.delivered_quantity !== undefined &&
        stocks[index]?.delivered_quantity !== null
      ) {
        array.splice(-5);
      } else {
        array.splice(-2);
      }
    }
    if (stocks[index]?.part_description?.length === 0) {
      array.splice(-3);
    }
    return array.some((item, i) => {
      if (i === 5 || i === 9) {
        return false;
      }
      if (typeof item === "number") {
        return item === 0;
      } else {
        return !item?.toString()?.trim()?.length;
      }
    });
  };

  const uploadFile = async (file: any, index: any, item: any) => {
    const formData = new FormData();
    formData.append("file", file);
    const data = await userContext?.uploadFiles(formData, "image");
    if (data?.success) {
      stocks[index] = {
        ...item,
        sample_pic: data?.url,
      };
      setStocks([...stocks]);
    }
  };

  return (
    <div>
      <div className=" d-flex justify-content-between my-3 align-items-center">
        <div className="members ">
          <h5 className=" mb-0 ">{t("inventory.All Stocks")}</h5>
        </div>
      </div>
      <div className="  table-responsive">
        <Table responsive="sm table_class">
          <thead>
            <tr className="table_white_head">
              <th
                className={`${isMobileOnly ? " py-4" : " py-4"}`}
                style={{ minWidth: "200px" }}
              >
                {t("inventory.Stock Code")}
              </th>
              <th className={`${isMobile ? "d-none " : " "}`}>
                {t("inventory.Part No")}
              </th>
              <th
                className={`${isMobile ? "d-none " : " "}`}
                style={{ width: "140px" }}
              >
                {t("inventory.Part Des")}
              </th>
              {type !== "materialToTechnician" && (
                <th
                  className={`${isMobile ? "d-none " : " "}`}
                  style={{ width: "140px" }}
                >
                  {t("inventory.QTY")}
                </th>
              )}
              {type === "materialToTechnician" && (
                <th
                  className={`${isMobile ? "d-none " : " "}`}
                  style={{ width: "140px" }}
                >
                  {t("inventory.Requested Qty")}
                </th>
              )}

              {type === "materialToTechnician" &&
                !pathName?.includes("materialRequest/new") && (
                  <th
                    className={`${isMobile ? "d-none " : " "}`}
                    style={{ width: "140px" }}
                  >
                    {t("inventory.Delivered Qty")}
                  </th>
                )}
              {type === "materialToTechnician" && (
                <th
                  className={`${isMobile ? "d-none " : " "}`}
                  style={{ width: "140px" }}
                >
                  {t("inventory.Sample Pic")}
                </th>
              )}
              {type === "purchases" && (
                <th
                  className={`${isMobile ? "d-none " : " "}`}
                  style={{ minWidth: "80px" }}
                >
                  {t("inventory.Warranty period")}
                </th>
              )}
              {(type === "replaceToSupplier" ||
                type === "returnSupplier" ||
                type === "returnTechnician") && (
                <th
                  className={`${isMobileOnly ? "  d-none" : " "}`}
                  style={{ minWidth: "180px" }}
                >
                  {t("inventory.Defect Type")}
                </th>
              )}
              {(type === "replaceToSupplier" ||
                type === "returnTechnician") && (
                <th
                  className={`${isMobileOnly ? "  d-none" : " "}`}
                  style={{ minWidth: "150px" }}
                >
                  {t("inventory.Reason")}
                </th>
              )}
              {(type === "returnSupplier" || type === "replaceToSupplier") && (
                <th
                  className={`${isMobileOnly ? "  d-none" : " "}`}
                  style={{ width: "140px" }}
                >
                  {t("inventory.Date Recieved")}
                </th>
              )}
              {type === "returnTechnician" && (
                <th
                  className={`${isMobileOnly ? "  d-none" : " "}`}
                  style={{ width: "140px" }}
                >
                  {t("inventory.Date Returned")}
                </th>
              )}
              {type === "BOM" && (
                <th
                  className={`${isMobileOnly ? "  d-none" : " "}`}
                  style={{ width: "140px" }}
                >
                  {t("masters.Unit")}
                </th>
              )}

              {type === "BOM" && (
                <th
                  className={`${isMobileOnly ? "  d-none" : " "}`}
                  style={{ width: "140px" }}
                >
                  {t("inventory.Country")}
                </th>
              )}

              {pathName?.includes("/view") ||
              billboardContext?.billBoard?.bom?._id ||
              billboardContext?.currentBomId ? (
                <></>
              ) : (
                stocks?.length > 0 && (
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    {t("memberTable.action")}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {stocks
              ?.filter((item: any) => !item?.isDelete)
              ?.map((item: any, index: number) => (
                <tr className="table_white" key={index}>
                  <td className="py-4" style={{ maxWidth: "280px" }}>
                    {item?.isEdit ? (
                      <Form>
                        <div className=" formprofile">
                          <FormGroup className="mt-4 roleForm position-relative">
                            <CommonAsyncSelect
                              className={"select-wrapper"}
                              isMulti={false}
                              placeholder={t("inventory.Stock Code") || ""}
                              values={{
                                label: item?.stock_code_name,
                                value: item?.stock_code,
                              }}
                              options={stockcodeList}
                              cacheOptions
                              loadOptionValue={getStockCodeOptions}
                              updateField={(option: any) => {
                                stocks[index] = {
                                  ...item,
                                  supplier_part_no: option.supplier_part_no,
                                  stock_code: option.value,
                                  stock_code_name: option.label,
                                  part_description: option.part_description,
                                };
                                setStocks([...stocks]);
                              }}
                              isFieldDisabled={
                                pathName?.includes("/view") ||
                                urlParams?.materialToTechnicianId
                              }
                              isClearable={false}
                              isShow={true}
                            />
                          </FormGroup>
                        </div>
                      </Form>
                    ) : (
                      item?.stock_code_name
                    )}
                  </td>
                  <td>{item?.supplier_part_no}</td>
                  <td>{item?.part_description}</td>
                  {type !== "materialToTechnician" && (
                    <td>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <input
                                maxLength={20}
                                className={`form-control`}
                                type="text"
                                value={item?.quantity || 0}
                                onChange={(e: any) => {
                                  stocks[index] = {
                                    ...item,
                                    quantity:
                                      e?.target?.value?.length === 0
                                        ? 0
                                        : parseInt(
                                            e?.target?.value?.replace(
                                              /[^0-9]/gi,
                                              ""
                                            )
                                          ),
                                  };
                                  setStocks([...stocks]);
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.quantity
                      )}
                    </td>
                  )}
                  {type === "materialToTechnician" && (
                    <td>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <input
                                maxLength={20}
                                className={`form-control`}
                                type="text"
                                value={item?.requested_quantity || 0}
                                disabled={
                                  urlParams?.materialToTechnicianId
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  stocks[index] = {
                                    ...item,
                                    requested_quantity:
                                      e?.target?.value?.length === 0
                                        ? 0
                                        : parseInt(
                                            e?.target?.value?.replace(
                                              /[^0-9]/gi,
                                              ""
                                            )
                                          ),
                                  };
                                  setStocks([...stocks]);
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.requested_quantity
                      )}
                    </td>
                  )}
                  {type === "materialToTechnician" &&
                    !pathName?.includes("materialRequest/new") && (
                      <td>
                        {item?.isEdit ? (
                          <Form>
                            <div className=" formprofile">
                              <FormGroup className="mt-4 roleForm position-relative">
                                <input
                                  maxLength={20}
                                  className={`form-control`}
                                  type="text"
                                  value={item?.delivered_quantity || 0}
                                  onChange={(e: any) => {
                                    stocks[index] = {
                                      ...item,
                                      delivered_quantity:
                                        e?.target?.value?.length === 0
                                          ? 0
                                          : parseInt(
                                              e?.target?.value?.replace(
                                                /[^0-9]/gi,
                                                ""
                                              )
                                            ),
                                    };
                                    setStocks([...stocks]);
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </Form>
                        ) : (
                          item?.delivered_quantity
                        )}
                      </td>
                    )}
                  {type === "materialToTechnician" && (
                    <td>
                      {item?.sample_pic?.length === 0 ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e: any) => {
                                  uploadFile(e?.target?.files[0], index, item);
                                }}
                                disabled={
                                  pathName?.includes("/view") ||
                                  urlParams?.materialToTechnicianId
                                    ? true
                                    : false
                                }
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        <>
                          <img src={item?.sample_pic} className="w-50" />
                          <div>
                            <a
                              className="position-relative fw-semibold me-3 cursor-pointer text-decoration-none text-center"
                              href={item?.sample_pic}
                              target="_blank"
                            >
                              <h6>View Image</h6>
                            </a>
                          </div>
                        </>
                      )}
                    </td>
                  )}
                  {type === "purchases" && (
                    <td>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <DatePicker
                                editable={false}
                                placeholder="DD-MM-YYYY"
                                name={"warranty_period"}
                                format="DD-MM-YYYY"
                                value={new Date(item?.warranty_period)}
                                className={`form-control`}
                                onChange={(e: any) => {
                                  stocks[index] = {
                                    ...item,
                                    warranty_period: moment
                                      .unix(e?.unix)
                                      .format("yyyy-MM-DD"),
                                  };
                                  setStocks([...stocks]);
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.warranty_period
                      )}
                    </td>
                  )}
                  {(type === "replaceToSupplier" ||
                    type === "returnTechnician") && (
                    <td>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <input
                                type="text"
                                maxLength={20}
                                className={`form-control`}
                                value={item?.defect_type}
                                onChange={(e: any) => {
                                  stocks[index] = {
                                    ...item,
                                    defect_type: e?.target?.value,
                                  };
                                  setStocks([...stocks]);
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.defect_type
                      )}
                    </td>
                  )}
                  {(type === "replaceToSupplier" ||
                    type === "returnSupplier" ||
                    type === "returnTechnician") && (
                    <td>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <ReactCustomSelect
                                feildName={"action"}
                                className={"select-wrapper"}
                                defaultOptions={{
                                  label: `${t(item?.action)}`,
                                  value: item?.action,
                                }}
                                isMulti={false}
                                updateFieldValue={(data: any) => {
                                  stocks[index] = {
                                    ...item,
                                    action: data.value,
                                  };
                                  setStocks([...stocks]);
                                }}
                                dataOptions={actionsReasons}
                                searchDisabled={false}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.action
                      )}
                    </td>
                  )}
                  {(type === "returnSupplier" ||
                    type === "returnTechnician") && (
                    <td>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <DatePicker
                                editable={false}
                                placeholder="DD-MM-YYYY"
                                format="DD-MM-YYYY"
                                name={"date_returned"}
                                value={new Date(item?.date_returned)}
                                className={`form-control `}
                                onChange={(e: any) => {
                                  stocks[index] = {
                                    ...item,
                                    date_returned: moment
                                      .unix(e?.unix)
                                      .format("yyyy-MM-DD"),
                                  };
                                  setStocks([...stocks]);
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.date_returned
                      )}
                    </td>
                  )}
                  {type === "replaceToSupplier" && (
                    <td>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <DatePicker
                                editable={false}
                                placeholder="DD-MM-YYYY"
                                name={"date_recieved"}
                                format="DD-MM-YYYY"
                                value={new Date(item?.date_recieved)}
                                className={`form-control `}
                                onChange={(e: any) => {
                                  stocks[index] = {
                                    ...item,
                                    date_recieved: moment
                                      .unix(e?.unix)
                                      .format("yyyy-MM-DD"),
                                  };
                                  setStocks([...stocks]);
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.date_recieved
                      )}
                    </td>
                  )}
                  {type === "BOM" && (
                    <td style={{ maxWidth: "240px" }}>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <CommonAsyncSelect
                                className={"select-wrapper"}
                                isMulti={false}
                                values={{
                                  label: updateText(
                                    item?.unit?.replace("_", " ")
                                  ),
                                  value: item?.unit,
                                }}
                                options={UnitList}
                                cacheOptions
                                loadOptionValue={getUnitOptions}
                                updateField={(option: any) => {
                                  stocks[index] = {
                                    ...item,
                                    unit: option.value,
                                  };
                                  setStocks([...stocks]);
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.unit
                      )}
                    </td>
                  )}

                  {type === "BOM" && (
                    <td>
                      {item?.isEdit ? (
                        <Form>
                          <div className=" formprofile">
                            <FormGroup className="mt-4 roleForm position-relative">
                              <input
                                maxLength={20}
                                type="text"
                                className={`form-control`}
                                value={item?.country}
                                onChange={(e: any) => {
                                  stocks[index] = {
                                    ...item,
                                    country: e?.target?.value,
                                  };
                                  setStocks([...stocks]);
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Form>
                      ) : (
                        item?.country
                      )}
                    </td>
                  )}

                  {pathName?.includes("/view") ||
                  billboardContext?.billBoard?.bom?._id ||
                  billboardContext?.currentBomId ? (
                    <></>
                  ) : stocks?.filter((item: any) => item?.isEdit)?.length >
                    0 ? (
                    item?.isEdit ? (
                      <td>
                        <CommonButton
                          onClick={() => {
                            stocks[index] = {
                              ...item,
                              isEdit: false,
                            };
                            setStocks([...stocks]);
                          }}
                          text={t("inventory.Save")}
                          variant="success"
                          className=" btn-i18n text-white"
                          isDisabled={buttonDisabled(index)}
                        />
                        {!pathName?.includes("materialRequest") &&
                          stocks
                            ?.filter((item: any) => !item?.isDelete)
                            ?.filter((item: any) => !item?.isEdit)?.length >
                            0 && (
                            <Button
                              style={{ color: "#6b5ca4" }}
                              className="mt-3 btn_cancel rounded-1 d-flex justify-content-center align-items-center btn btn-primary"
                              onClick={() => {
                                const newArray = [...stocks];
                                newArray.splice(index, 1);
                                setStocks([...newArray]);
                              }}
                            >
                              Delete
                            </Button>
                          )}
                      </td>
                    ) : (
                      <></>
                    )
                  ) : (
                    <td className={`${isMobileOnly ? "" : " "}`}>
                      <div className="pt-2 Actions">
                        <Dropdown className="Actions_title">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basicActions"
                          >
                            <BiDotsVerticalRounded fontSize={24} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="create-dropdown-css">
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => {
                                stocks[index] = {
                                  ...item,
                                  isEdit: true,
                                };
                                setStocks([...stocks]);
                              }}
                            >
                              {t("inventory.Edit")}
                            </Dropdown.Item>
                            {!pathName?.includes("materialRequest") && (
                              <Dropdown.Item
                                className="sort-by"
                                onClick={() => {
                                  stocks[index] = { ...item, isDelete: true };
                                  if (
                                    stocks?.filter(
                                      (item: any) => !item?.isDelete
                                    )?.length === 0
                                  ) {
                                    setStocks([
                                      ...stocks,
                                      ...[initialStockValue],
                                    ]);
                                  } else {
                                    setStocks([...stocks]);
                                  }
                                }}
                              >
                                {t("inventory.Delete")}
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
        {pathName?.includes("materialToTechnician") ||
        (pathName?.includes("materialRequest") &&
          pathName?.includes("/edit")) ||
        pathName?.includes("/view") ||
        billboardContext?.billBoard?.bom?._id ||
        billboardContext?.currentBomId ? (
          <></>
        ) : (
          stocks?.filter((item: any) => item?.isEdit)?.length === 0 && (
            <div className="d-flex justify-content-end">
              <Button
                style={{ color: "#6b5ca4" }}
                className="mt-3 pt-2 p-2 Actions btn_update rounded-1 d-flex justify-content-center align-items-center text-white"
                onClick={() => setStocks([...stocks, ...[initialStockValue]])}
              >
                Add
              </Button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default StockList;
