import React, { useEffect, useState } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { isMobile, isMobileOnly } from "react-device-detect";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { routes } from "../../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup, Spinner } from "react-bootstrap";
import {
  InventeryContextProp,
  useInventery,
} from "../../../context/InventeryProvider";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import StockList from "../Purchase/StockList";
import CustomLocationSelect from "../../../components/CommonComponents/CustomLocationSelect";
import DatePicker from "react-multi-date-picker";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MaterialRequestPDF from "../../../components/MaterialRequestPDF";
import CommonButton from "../../../components/CommonButton/CommonButton";

const CreateNewMaterialToTechnician = ({ userContext }: any) => {
  const context = useInventery();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const urlParams = useParams();
  const pathName = window?.location?.pathname;

  return (
    <>
      <AppLayout>
        <div
          className="edit_members p-3 "
          style={
            isMobile
              ? { background: "#FAFAFA", height: "auto" }
              : { background: "#FAFAFA", minHeight: "80vh" }
          }
        >
          <div className="members py-4 mt-2 ">
            <h5
              className={`${
                isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
              }`}
              onClick={() =>
                navigate(
                  pathName?.includes("materialRequest")
                    ? routes.inventoryMaterialRequest
                    : routes.inventoryMaterialToTechnician
                )
              }
            >
              {t(
                pathName?.includes("materialRequest")
                  ? "inventory.All Requests"
                  : "inventory.All Buyers"
              )}
            </h5>
            <>
              <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
                <MdOutlineArrowForwardIos />
              </span>
              <span className={i18n.language === "en" ? "d-none" : "px-2"}>
                <MdArrowBackIos />
              </span>

              <h5
                className={`${
                  isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "
                }`}
              >
                {urlParams?.materialToTechnicianId &&
                pathName?.includes("/edit")
                  ? `${t(
                      pathName?.includes("materialRequest")
                        ? "inventory.Edit Request"
                        : "inventory.Edit Buyer"
                    )}`
                  : pathName?.includes("/view")
                  ? `${t(
                      pathName?.includes("materialRequest")
                        ? "inventory.View Request"
                        : "inventory.View Buyer"
                    )}`
                  : `${t(
                      pathName?.includes("materialRequest")
                        ? "inventory.New Request"
                        : "inventory.New Buyer"
                    )}`}
              </h5>
            </>
          </div>

          {context && (
            <NewTechnicianForm
              context={context}
              urlParams={urlParams}
              pathName={pathName}
              userContext={userContext}
            />
          )}
        </div>
      </AppLayout>
    </>
  );
};

interface NewTechnicianFormProps {
  context: InventeryContextProp;
  urlParams: any;
  pathName: string;
  userContext: any;
}
interface billboardAutocompleteProps {
  readonly label: string;
  readonly value: string;
}

interface StockCodeAutocompleteProps {
  readonly label: string;
  readonly value: string;
}

const NewTechnicianForm = ({
  context,
  urlParams,
  pathName,
  userContext,
}: NewTechnicianFormProps) => {
  const initialStockValue: any = {
    supplier_part_no: "",
    stock_code: "",
    stock_code_name: "",
    part_description: "",
    isEdit: true,
    isDelete: false,
    requested_quantity: 0,
    sample_pic: "",
  };
  if (urlParams?.materialToTechnicianId) {
    initialStockValue["delivered_quantity"] = 0;
  }

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [stocks, setStocks] = useState([...[initialStockValue]]);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    editMaterialToTechnician();
  }, [urlParams?.materialToTechnicianId]);

  const editMaterialToTechnician = async () => {
    if (urlParams?.materialToTechnicianId) {
      const { success, material } = await context?.getMaterialToTechnician(
        urlParams?.materialToTechnicianId
      );
      if (success) {
        const updatedStocks = material?.stocks?.map((item: any) => ({
          supplier_part_no: item?.supplier_part_no,
          stock_code: item?.stock_code?.id,
          stock_code_name: item?.stock_code?.stock_code,
          requested_quantity: item?.requested_quantity,
          sample_pic: item?.sample_pic,
          delivered_quantity: item?.delivered_quantity || 0,
          part_description: item?.part_description,
          isEdit: false,
          isDelete: false,
        }));
        setStocks([...updatedStocks]);
      }
    }
  };

  const getUserDetails = async () => {
    const { success, user } = await context?.loadMembers({
      status: "ACTIVE",
      sort: "name",
      role: "TECHNICIAN",
    });
    if (success) {
      let res = user.map((d: any) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setAssigneeList(res);
    }
  };

  // ----------------------suplier part no-----------------

  // ----------------------stock code-----------------

  const getAssigneeOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { users } = await context?.autoCompleteApi({
      name: inputValue,
      role: "TECHNICIAN",
    });
    callback(
      users.map((d: any) => ({
        label: d?.name,
        value: d?.id,
      }))
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      stock_code: context?.materialToTechnician?.stock_code?.id || "",
      stock_code_name:
        context?.materialToTechnician?.stock_code?.stock_code || "",
      supplier_part_no: urlParams?.materialToTechnicianId
        ? context?.materialToTechnician?.supplier_part_no
        : "",
      requested_by: context?.materialToTechnician?.requested_by?.id || "",
      remarks: context?.materialToTechnician?.remarks || "",
      billboard_id: context?.materialToTechnician?.billboard_id || "",
      project_name: context?.materialToTechnician?.project_name || "",
      recieved_by_name: context?.materialToTechnician?.requested_by?.name || "",
      city: context?.materialToTechnician?.city || "",
      issued_to: context?.materialToTechnician?.issued_to?.id || "",
      issued_to_name: context?.materialToTechnician?.issued_to?.name || "",
      id_no: context?.materialToTechnician?.id_no || "",
      picture: context?.materialToTechnician?.picture || "",
    },
    validationSchema: Yup.object({
      supplier_part_no: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
      project_name: Yup.string(),
      stock_code: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
      requested_by: Yup.string().required("Requested by  is required"),
      billboard_id: Yup.string(),
      stock_code_id: Yup.string(),
      remarks: Yup.string(),
      issued_to:
        pathName?.includes("/materialRequest") &&
        context?.materialToTechnician?.id
          ? Yup.string().required("Issued To  is required")
          : Yup?.string(),
      id_no:
        pathName?.includes("/materialRequest") &&
        context?.materialToTechnician?.id
          ? Yup.string().required("Id Number  is required")
          : Yup?.string(),
      picture: Yup.string(),
    }),

    onSubmit: async (values) => {
      const params = {
        ...values,
        stocks: stocks
          ?.map((item) => (item?.requested_quantity === 0 ? null : item))
          ?.filter((item) => item !== null),
      };
      const { success, error } = context?.materialToTechnician?.id
        ? await context.updateMaterialToTechnicianDetail(
            context?.materialToTechnician?.id,
            { ...params, status: "DELIVERED" }
          )
        : await context.addMaterialToTechnician(params);
      setIsSuccess(success);
      setOpenPopup(true);
      if (success) {
        setBodyMessage(
          context?.materialToTechnician?.id
            ? `${t(
                pathName?.includes("materialRequest")
                  ? "inventory.Material Request updated Successfully"
                  : "inventory.Material issued updated Successfully"
              )}`
            : `${t(
                pathName?.includes("materialRequest")
                  ? "inventory.Material Request added Successfully"
                  : "inventory.Material issued added Successfully"
              )}`
        );
        formik.resetForm();
        timeOutPopUp(true);
      } else {
        setBodyMessage(error);
        timeOutPopUp(false);
      }
    },
  });

  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(
          pathName?.includes("materialRequest")
            ? routes.inventoryMaterialRequest
            : routes.inventoryMaterialToTechnician
        );
        setOpenPopup(false);
        setBodyMessage("");
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const { errors, handleSubmit, isSubmitting, isValid, dirty, resetForm } =
    formik;

  const buttonDisabled = () =>
    context?.materialToTechnician?.id
      ? dirty
      : dirty && stocks?.filter((item) => item?.isEdit)?.length
      ? false
      : true;

  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    const data = await userContext?.uploadFiles(formData, "image");
    if (data?.success) {
      formik?.setFieldValue("picture", data?.url);
    }
  };

  return (
    <>
      {context?.materialToTechnician && (
        <div className="d-flex justify-content-end">
          <PDFDownloadLink
            document={
              <MaterialRequestPDF data={context?.materialToTechnician} />
            }
            fileName={`${context?.materialToTechnician?.material_id}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        </div>
      )}
      <div className="px-4 pb-5">
        <FormikProvider value={formik}>
          <Form noValidate={true} onSubmit={handleSubmit}>
            <div className="">
              <div className="row px-3 pb-4" style={{ background: "#fefefe" }}>
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Recieved By")}
                      <span className="">*</span>
                    </label>
                    <CommonAsyncSelect
                      className={"select-wrapper"}
                      isMulti={false}
                      values={{
                        label: formik?.values?.recieved_by_name,
                        value: formik.values.requested_by,
                      }}
                      options={assigneeList}
                      cacheOptions
                      loadOptionValue={getAssigneeOptions}
                      updateField={(option: any) => {
                        formik?.setErrors({});
                        formik?.setFieldValue("requested_by", option.value);
                        formik?.setFieldValue("recieved_by_name", option.label);
                        formik?.setErrors({});
                      }}
                      isFieldDisabled={
                        pathName?.includes("/view") ||
                        !pathName?.includes("materialRequest")
                      }
                    />
                  </FormGroup>
                </div>

                <div
                  className={
                    i18n.language === "en"
                      ? "col-md-6 col-12 formprofile"
                      : "col-md-6 col-12 formprofile"
                  }
                >
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>{t("inventory.Billboard Id")}</label>
                    <Field
                      name="billboard_id"
                      type="text"
                      maxLength={20}
                      className={`form-control ${
                        errors.billboard_id ? "form-control-invalid" : ""
                      }`}
                      disabled={
                        pathName?.includes("/view") ||
                        !pathName?.includes("materialRequest")
                      }
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.billboard_id ? "block" : "none",
                      }}
                      type={!!errors.billboard_id ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="billboard_id" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
              <div
                className="row px-3 pb-4 mt-4"
                style={{ background: "#fefefe" }}
              >
                <div className="col-12">
                  <div className="row ">
                    <div className="col-md-6 col-12  formprofile">
                      <FormGroup className="mt-4 roleForm position-relative">
                        <label>{t("inventory.Project Name")}</label>
                        <Field
                          name="project_name"
                          type="text"
                          maxLength={20}
                          className={`form-control ${
                            errors.project_name ? "form-control-invalid" : ""
                          }`}
                          disabled={
                            pathName?.includes("/view") ||
                            !pathName?.includes("materialRequest")
                          }
                        />
                        <Form.Control.Feedback
                          style={{
                            display: !!errors.project_name ? "block" : "none",
                          }}
                          type={!!errors.project_name ? "invalid" : "valid"}
                        >
                          <ErrorMessage name="project_name" />
                        </Form.Control.Feedback>
                      </FormGroup>
                    </div>

                    <div
                      className={
                        i18n.language === "en"
                          ? "col-md-6 col-12  formprofile"
                          : "col-md-6 col-12  formprofile "
                      }
                    >
                      <FormGroup className="mt-4 roleForm position-relative">
                        <label>
                          {t("CreateBillBoardForm.City")}
                          <span className="">*</span>
                        </label>
                        <CustomLocationSelect
                          fiedName={"city"}
                          isFieldDisabled={
                            pathName?.includes("/view") ||
                            !pathName?.includes("materialRequest")
                          }
                          className={"autolocation"}
                          updateField={(data: any) =>
                            formik.setFieldValue(
                              "city",
                              data?.formatted_address
                            )
                          }
                          defaultValue={formik?.values?.city}
                        />
                        <Form.Control.Feedback
                          style={{
                            display: !!errors.city ? "block" : "none",
                          }}
                          type={!!errors.city ? "invalid" : "valid"}
                        >
                          <ErrorMessage name="city" />
                        </Form.Control.Feedback>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row px-3 pb-4 mt-4"
                style={{ background: "#fefefe" }}
              >
                <div className="col-12">
                  <div className="row ">
                    <div className="col-md-6 col-12  formprofile">
                      <FormGroup className="mt-4 roleForm position-relative">
                        <label>{t("inventory.Remarks")}</label>
                        <Field
                          name="remarks"
                          type="text"
                          maxLength={20}
                          className={`form-control ${
                            errors.remarks ? "form-control-invalid" : ""
                          }`}
                          disabled={
                            pathName?.includes("/view") ||
                            !pathName?.includes("materialRequest")
                          }
                        />
                        <Form.Control.Feedback
                          style={{
                            display: !!errors.remarks ? "block" : "none",
                          }}
                          type={!!errors.remarks ? "invalid" : "valid"}
                        >
                          <ErrorMessage name="remarks" />
                        </Form.Control.Feedback>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>

              <StockList
                type="materialToTechnician"
                context={context}
                setStocks={setStocks}
                stocks={stocks}
                initialStockValue={initialStockValue}
                userContext={userContext}
              />
              {!pathName?.includes("materialRequest/new") && (
                <>
                  <div
                    className="row px-3 pb-4 mt-4"
                    style={{ background: "#fefefe" }}
                  >
                    <div className="col-12">
                      <div className="row ">
                        <div className="col-md-6 col-12  pe-md-4 formprofile">
                          <FormGroup className="mt-4 roleForm position-relative">
                            <label>
                              {t("inventory.Issued To")}
                              <span className="">*</span>
                            </label>
                            <CommonAsyncSelect
                              className={"select-wrapper"}
                              isMulti={false}
                              values={{
                                label: formik?.values?.issued_to_name,
                                value: formik.values.issued_to,
                              }}
                              options={assigneeList}
                              cacheOptions
                              loadOptionValue={getAssigneeOptions}
                              updateField={(option: any) => {
                                formik?.setErrors({});
                                formik?.setFieldValue(
                                  "issued_to",
                                  option.value
                                );
                                formik?.setFieldValue(
                                  "issued_to_name",
                                  option.label
                                );
                                formik?.setErrors({});
                              }}
                              isFieldDisabled={pathName?.includes("/view")}
                            />
                            <Form.Control.Feedback
                              style={{
                                display: !!errors.issued_to ? "block" : "none",
                              }}
                              type={!!errors.issued_to ? "invalid" : "valid"}
                            >
                              <ErrorMessage name="issued_to" />
                            </Form.Control.Feedback>
                          </FormGroup>
                        </div>
                        <div className="col-md-6 col-12  formprofile">
                          <FormGroup className="mt-4 roleForm position-relative">
                            <label>
                              {t("inventory.Recieved Date")}
                              <span className="">*</span>
                            </label>
                            <DatePicker
                              editable={false}
                              name={"date_of_joining"}
                              format="DD-MM-YYYY"
                              value={
                                new Date(
                                  context?.materialToTechnician?.createdAt
                                )
                              }
                              className={`${"disabled-field"}`}
                              disabled={true}
                              placeholder="DD-MM-YYYY"
                              maxDate={new Date()}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row px-3 pb-4 mt-4"
                    style={{ background: "#fefefe" }}
                  >
                    <div className="col-12">
                      <div className="row ">
                        <div className="col-md-6 col-12  pe-md-4 formprofile">
                          <FormGroup className="mt-4 roleForm position-relative">
                            <label>
                              {t("inventory.Delivered Date")}
                              <span className="">*</span>
                            </label>
                            <DatePicker
                              editable={false}
                              format="DD-MM-YYYY"
                              name={"date_of_joining"}
                              value={
                                new Date(
                                  context?.materialToTechnician?.updatedAt
                                ) || new Date()
                              }
                              className={`${"disabled-field"}`}
                              disabled={true}
                              placeholder="DD-MM-YYYY"
                              maxDate={new Date()}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6 col-12  formprofile">
                          <FormGroup className="mt-4 roleForm position-relative">
                            <label>
                              {t("inventory.ID Number")}
                              <span className="">*</span>
                            </label>
                            <Field
                              name="id_no"
                              type="text"
                              maxLength={20}
                              className={`form-control ${
                                errors.id_no ? "form-control-invalid" : ""
                              }`}
                              disabled={pathName?.includes("/view")}
                            />
                            <Form.Control.Feedback
                              style={{
                                display: !!errors.id_no ? "block" : "none",
                              }}
                              type={!!errors.id_no ? "invalid" : "valid"}
                            >
                              <ErrorMessage name="id_no" />
                            </Form.Control.Feedback>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row px-3 pb-4 mt-4"
                    style={{ background: "#fefefe" }}
                  >
                    <div className="col-12">
                      <div className="row ">
                        <div className="col-md-6 col-12  pe-md-4 formprofile">
                          <FormGroup className="mt-4 roleForm position-relative">
                            <label>
                              {t("inventory.Date")}
                              <span className="">*</span>
                            </label>
                            <DatePicker
                              editable={false}
                              name={"date_of_joining"}
                              format="DD-MM-YYYY"
                              value={
                                new Date(
                                  context?.materialToTechnician?.createdAt
                                )
                              }
                              className={`${"disabled-field"}`}
                              disabled={true}
                              placeholder="DD-MM-YYYY"
                              maxDate={new Date()}
                            />
                            <Form.Control.Feedback
                              style={{
                                display: !!errors.issued_to ? "block" : "none",
                              }}
                              type={!!errors.issued_to ? "invalid" : "valid"}
                            >
                              <ErrorMessage name="issued_to" />
                            </Form.Control.Feedback>
                          </FormGroup>
                        </div>
                        <div className="col-md-6 col-12  formprofile">
                          <FormGroup className="mt-4 roleForm position-relative">
                            <label>{t("inventory.Picture")}</label>
                            <div>
                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e: any) => {
                                  uploadFile(e?.target?.files[0]);
                                }}
                                disabled={pathName?.includes("/view")}
                              />
                              {formik?.values?.picture?.type?.startsWith(
                                "image/"
                              ) ? (
                                <div className="pt-2">
                                  <img
                                    src={URL?.createObjectURL(
                                      formik?.values?.picture
                                    )}
                                    alt=""
                                    width={100}
                                    height={100}
                                  />
                                </div>
                              ) : formik?.values?.picture?.length > 0 ? (
                                <img
                                  src={formik?.values?.picture}
                                  alt=""
                                  width={100}
                                  height={100}
                                />
                              ) : null}
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!pathName?.includes("/view") && (
                <div className="d-flex justify-content-end py-5">
                  <CommonButton
                    text={t("inventory.Cancel")}
                    isLoading={isSubmitting}
                    variant="outline-danger"
                    className=" bg-transparent text-danger"
                    isDisabled={isSubmitting}
                    onClick={() => resetForm()}
                  />
                  <CommonButton
                    text={
                      context?.materialToTechnician?.id
                        ? `${t(
                            pathName?.includes("materialRequest")
                              ? "inventory.Update Request"
                              : "inventory.Update Technician"
                          )}`
                        : `${t(
                            pathName?.includes("materialRequest")
                              ? "inventory.Add Request"
                              : "inventory.Add Technician"
                          )}`
                    }
                    isLoading={isSubmitting}
                    type="submit"
                    variant="success"
                    className="btn-i18n text-white"
                    isDisabled={isSubmitting || !buttonDisabled()}
                  />
                </div>
              )}
            </div>
          </Form>
        </FormikProvider>
        <ToastPopup
          trigger={context?.isLoading}
          setTrigger={context?.setIsLoading}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
        <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
          <SuccessErrorToast
            bodyMessage={bodyMessage}
            isSuccess={isSuccess}
            footButton={false}
          />
        </ToastPopup>
      </div>
    </>
  );
};

export default CreateNewMaterialToTechnician;
